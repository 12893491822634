import axios from 'axios'
import { createToast, returnErrors } from './messages'
import {
  GET_PAYEES, EDIT_PAYEES, PAYEE_LOADING, PAYEE_FAIL
} from './types'
import { tokenConfig } from './auth'
import { getTransactions } from './transactions'

const proxy = process.env.REACT_APP_PROXY

// Get Payees
export const getPayees = () => (dispatch, getState) => {
  dispatch({ type: PAYEE_LOADING })
  axios.get(`${proxy}/v1/payees/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_PAYEES,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: PAYEE_FAIL })
      })
}

/**
 * This function is used to bulk update payees.
 * @param {Object} payeeForm - Data needed to update a bulk list of
 * payees.
 * @param {string} yearMonth - The year month prop.
 */
export const bulkPayeeUpdate = (payeeForm, yearMonth) => (dispatch, getState) => {
  axios.post(`${proxy}/v1/payees/`, payeeForm, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ payee: 'Payees Updated' }))
        dispatch({
          type: EDIT_PAYEES,
          payload: res.data
        })
        dispatch(getPayees())
        dispatch(getTransactions(yearMonth, null))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
