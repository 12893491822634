import { GET_BUDGET_MONTH, EDIT_BUDGET_MONTH } from './types'

// Get budget month
export const getBudgetMonth = () => (dispatch, getState) => {
  dispatch({
    type: GET_BUDGET_MONTH,
    payload: getState
  })
}

// Edit budget month
export const editBudgetMonth = (yearMonth) => (dispatch, getState) => {
  dispatch({
    type: EDIT_BUDGET_MONTH,
    payload: yearMonth
  })
}