import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody,
  TableContainer, TableCell, TableHead, TableRow, Paper, Hidden
} from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import numeral from 'numeral'

import { sharedStyles } from '../common/styles'
import { backgroundColors, borderColors } from '../chart/charts'
import { formatMoneyLabel, formatPercentLabel } from '../utilities/numbers'

const styles = theme => ({
  ...sharedStyles(theme),
  positiveAmount: {
    color: theme.palette.success.main,
    fontWeight: 500
  },
  negativeAmount: {
    color: theme.palette.error.main,
    fontWeight: 500
  },
  middleAlign: {
    verticalAlign: 'middle'
  }
})

const months = [undefined, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  plugins: {
    title: {
      display: false,
      text: 'Total Expenses by Category',
    },
    legend: {
      position: 'right'
    },
  },
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'point'
  }
}

/**
 * This component uses a budget date to sift through the data to
 * display the correct data the user wants. The budget date is a string
 * of the month and year squished together. e.g. 12022 is January 2022.
 * @param {Object} props - All the props for this component.
 * @param {Object} props.reports - Contains reporting information.
 * @return {JSX.Element}
 * @constructor
 */
function CategorizedExpenses(props) {
  const [budgetDate, setBudgetDate] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [dateList, setDateList] = useState(null)
  const [tableData, setTableData] = useState(null)

  const { classes, reports } = withPropsValidation(
      useSelector(({ reportReducer }) => ({
        classes: props.classes,
        reports: reportReducer.reports,
      })))

  useEffect(() => {
    // If page load get current budget date
    let currentBudgetDate = null
    if (!budgetDate) {
      if (reports && reports.end_month && reports.end_year) {
        const month = reports.end_month
        const year = reports.end_year
        currentBudgetDate = `${month}${year}`
        setBudgetDate(currentBudgetDate)
        return false
      }
    }

    const categorizedExpenses = reports && reports.monthly_category_expense_report
        ? reports.monthly_category_expense_report : []
    // Filtering based on month and year and less than 0 *** No leading 0 ***
    let monthlyExpenses = categorizedExpenses.filter((report) => `${report.month}${report.year}` === budgetDate && report.total < 0)
    const labels = monthlyExpenses.map((report) => report.category_name)
    const total = monthlyExpenses.map((report) => report.total)

    // Get monthly total expenses and percentage
    let totalMonthlyExpenses = numeral(0)
    for (const monthlyExpense of monthlyExpenses) totalMonthlyExpenses.add(monthlyExpense.total)
    totalMonthlyExpenses = totalMonthlyExpenses.value()
    monthlyExpenses = monthlyExpenses.map(e => {
      let percent = numeral(e.total).divide(totalMonthlyExpenses)
      percent = percent && percent.value() ? percent.value() : 0
      return {
        ...e,
        percent
      }
    })


    // Get list of valid budget dates
    let dateList = []
    categorizedExpenses.forEach((report) => {
      const month = report.month
      const year = report.year
      const newDate = { value: `${month}${year}`, label: `${months[month]} ${year}` }
      if (dateList.length > 0) {
        if (!dateList.some(item => item.value === newDate.value)) dateList.push(newDate)
      } else dateList.push(newDate)
    })

    // chart data
    const data = {
      labels,
      datasets: [
        {
          label: 'Category',
          data: total,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 2,
        }
      ],
    }

    setTableData(monthlyExpenses)
    setChartData(data)
    setDateList(dateList)
  }, [reports, budgetDate])

  /**
   * Used to update the budget date being displayed for this report.
   * @param {Object} event - The change event.
   * @param {Object} newValue - The selected value object.
   */
  const handleBudgetDateChange = (event, newValue) => {
    setBudgetDate(event.target.value)
  }

  return (
      <Fragment>
        <Grid container spacing={3}>
          {budgetDate && dateList &&
              <Grid item xs={12}>
                <FormControl variant="standard" sx={{ width: 200 }}>
                  <InputLabel>Budget Month</InputLabel>
                  <Select
                      value={budgetDate}
                      onChange={handleBudgetDateChange}
                      label="Budget Date">
                    {dateList.map((item) => (
                        <MenuItem key={`budget-date-${item.value}`}
                                  value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
          }
        </Grid>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item md={1} lg={2} />
          </Hidden>
          {chartData &&
              <Grid item xs={12} md={10} lg={8}>
                <Pie options={options} data={chartData} />
              </Grid>
          }
          <Hidden smDown>
            <Grid item md={1} lg={2} />
          </Hidden>
          {tableData &&
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 400 }} aria-label="categorized expense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Category</TableCell>
                        <TableCell align="center">Total</TableCell>
                        <TableCell align="center">Percent</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row, index) => (
                          <TableRow key={`category-expense-${index}`} hover>
                            <TableCell align="left">{row.category_name}</TableCell>
                            <TableCell align="center">{formatMoneyLabel(row.total)}</TableCell>
                            <TableCell align="center">{formatPercentLabel(row.percent)}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
          }
        </Grid>
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', '')
  return props
}

const propTypes = {
  classes: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired
}


export default withStyles(styles)(CategorizedExpenses)
