import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import numeral from 'numeral'
import { Box, Typography, Grid } from '@mui/material'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'

const styles = theme => ({
  ...sharedStyles(theme),
})

class HeaderSummary extends Component {


  render() {
    const { classes, categoryBudgets, transactions } = this.props

    let totalInflow = (transactions && transactions[0]) ? transactions[0].total_inflow : 0
    let goal = numeral(0)
    let budgeted = numeral(0)
    let overspent = numeral(0)
    let fund = numeral(0)

    // Sum budgeted, goal, and overspent
    for (const budget of categoryBudgets) {
      goal.add(budget.goal)
      budgeted.add(budget.budgeted)
      if (budget.balance < 0) overspent.add(budget.balance)
      if (budget.goal > budget.budgeted) {
        const fundShort = numeral(budget.goal).subtract(budget.budgeted)
        fund.add(fundShort.value())
      }
    }

    goal = goal.value()
    budgeted = budgeted.value()
    overspent = overspent.value()
    fund = fund.value()

    const overspentColor = overspent < 0 ? classes.budgetError : null

    return (
        <Box marginY={0.5}>
          <Grid container>
            <Grid item xs={12}>
              <Typography align="center" variant="subtitle2" noWrap
                          title={formatMoneyLabel(totalInflow)}
              >
                Inflow: {formatMoneyLabel(totalInflow)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="subtitle2" noWrap
                          title={formatMoneyLabel(fund)}
              >
                Fund Short: {formatMoneyLabel(fund)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="subtitle2" noWrap className={overspentColor}
                          title={formatMoneyLabel(overspent)}
              >
                Overspent: {formatMoneyLabel(overspent)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
    )
  }
}

HeaderSummary.propTypes = {
  categoryBudgets: PropTypes.array.isRequired,
  transactions: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
  transactions: state.transactionReducer.transactions,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(HeaderSummary))
