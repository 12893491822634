import { GET_TRANSACTION_ID, EDIT_TRANSACTION_ID } from './types'

// Get Transaction Id
export const getTransactionId = () => (dispatch, getState) => {
  dispatch({
    type: GET_TRANSACTION_ID,
    payload: getState
  })
}

// Edit Transaction Id
export const editTransactionId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_TRANSACTION_ID,
    payload: id
  })
}
