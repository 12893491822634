import React, { useState, useEffect } from 'react'

import AppLoading from '../loading/AppLoading'
import Overview from './Overview'
import OAuthResponse from '../link/OAuthResponse'

/**
 * oauth_state_id is used by plaid for oauth authentication.
 * This logic needs to be at the root route.
 * @returns {JSX.Element}
 */
const Dashboard = () => {
  const [stateId, setStateId] = useState(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const stateId = urlParams.has('oauth_state_id') ? urlParams.get('oauth_state_id') : null
    if (stateId) setStateId(stateId)
  }, [])


  return (
      <AppLoading>
        {!stateId
            ? <Overview />
            : <OAuthResponse stateId={stateId} />
        }
      </AppLoading>
  )
}

export default Dashboard
