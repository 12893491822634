import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { TabBar, TabList, TabButton } from '../common/styles'
import AppLoading from '../loading/AppLoading'
import { Loading } from '../loading/Loading'
import TabPanel from '../layout/TabPanel'
import IncomeVsExpense from './IncomeVsExpense'
import NetWorth from './NetWorth'
import CategorizedExpenses from './CategorizedExpenses'
import AccountForm from '../layout/AccountForm'
import { getReports } from '../../actions/reports'
import ActionButtons from '../layout/ActionButtons'
import CollectionForm from '../layout/CollectionForm'
import CategoryForm from '../layout/CategoryForm'
import HoldingForm from '../layout/HoldingForm'
import TransactionForm from '../layout/TransactionForm'
import MoveBalanceForm from '../layout/MoveBalanceForm'

function ReportDashboard() {
  const [tabIndex, setTabIndex] = useState(0)

  const { isFetching, reports } = withPropsValidation(
      useSelector(({ reportReducer }) => ({
        isFetching: reportReducer.isFetching,
        reports: reportReducer.reports
      })))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getReports())
  }, [dispatch])

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
      <AppLoading>

        {isFetching &&
            <Box mb={1}>
              <Loading />
            </Box>
        }

        <TabBar>
          <TabList value={tabIndex} onChange={handleTabChange} centered variant="fullWidth"
                   aria-label="report tabs">
            <TabButton label="Income vs Expense" index={0} />
            <TabButton label="Net Worth" index={1} />
            <TabButton label="Categorized Expenses" index={2} />
          </TabList>
        </TabBar>

        <TabPanel value={tabIndex} index={0}><IncomeVsExpense reports={reports} /></TabPanel>
        <TabPanel value={tabIndex} index={1}><NetWorth reports={reports} /></TabPanel>
        <TabPanel value={tabIndex} index={2}><CategorizedExpenses reports={reports} /></TabPanel>

        <ActionButtons />
        <AccountForm />
        <CollectionForm />
        <CategoryForm />
        <HoldingForm />
        <TransactionForm />
        <MoveBalanceForm />
      </AppLoading>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', '')
  return props
}

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  reports: PropTypes.object.isRequired
}

export default ReportDashboard
