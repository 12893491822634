import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { DatePicker } from '@mui/lab'
import withStyles from '@mui/styles/withStyles'
import {
  FormControlLabel, FormControl, Button, IconButton, TextField, Dialog,
  DialogActions, DialogContent, DialogTitle, Grid, Box, Typography, Checkbox
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Save, Add, Cancel, Delete, Label, LabelOutlined, Edit, LabelOffOutlined } from '@mui/icons-material'

import { sharedStyles, SwitchPositive } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import FlagForm from './FlagForm'
import { addTransaction } from '../../actions/transactions'
import { editLinkTransaction } from '../../actions/link'
import { fullScreen, Transition } from '../utilities/dialogs'
import { moneyInput, formatMoneyLabel, formatMoney } from '../utilities/numbers'
import { returnErrors } from '../../actions/messages'

const filter = createFilterOptions()
const styles = theme => ({
  ...sharedStyles(theme),
})

class LinkTransactionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        name: { error: null, helperText: '', },
        amount: { error: null, helperText: '', },
        category: { error: null, helperText: '', },
        account: { error: null, helperText: '', },
        date: { error: null, helperText: '', },
        notes: { error: null, helperText: '', },
      },
      open: false,
      datePickerOpen: false,
      name: { id: 0, name: '' },
      amountPositive: false,
      amount: '',
      category: { id: 0, name: '' },
      account: { id: 0, name: '' },
      date: DateTime.local(),
      splitCategories: [
        { id: 0, category: { id: 0, name: '' }, name: { id: 0, name: '' }, amount: '', notes: '' },
        { id: 0, category: { id: 0, name: '' }, name: { id: 0, name: '' }, amount: '', notes: '' }
      ],
      remainingSplitAmount: 0,
      accountOptions: [],
      categoryOptions: [],
      splitCategoryOptions: [],
      notes: '',
      flagId: 0,
      reconciled: true,
      transferAccountId: 0,
      payeeOptions: [],
      flagFormOpen: false,
      transactionFlagColors: [],
      splitPayeeOptions: []
    }

    this.updateAccountOptions = this.updateAccountOptions.bind(this)
    this.updatePayeeOptions = this.updatePayeeOptions.bind(this)
    this.updateCategoryOptions = this.updateCategoryOptions.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleAccountChange = this.handleAccountChange.bind(this)
    this.handleAmountPositive = this.handleAmountPositive.bind(this)
    this.handleReconciled = this.handleReconciled.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSplitAmountChange = this.handleSplitAmountChange.bind(this)
    this.handleSplitNameChange = this.handleSplitNameChange.bind(this)
    this.handleSplitNotesChange = this.handleSplitNotesChange.bind(this)
    this.handleSplitCategoryChange = this.handleSplitCategoryChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSplitCategoryAdd = this.handleSplitCategoryAdd.bind(this)
    this.handleSplitCategoryRemove = this.handleSplitCategoryRemove.bind(this)
    this.getRemainingSplitAmount = this.getRemainingSplitAmount.bind(this)
    this.usersTransactionFlags = this.usersTransactionFlags.bind(this)
    this.handleFlagClick = this.handleFlagClick.bind(this)
    this.openFlagForm = this.openFlagForm.bind(this)
    this.closeFlagForm = this.closeFlagForm.bind(this)
  }

  componentDidMount() {
    this.updateAccountOptions()
    this.updatePayeeOptions()
    this.updateCategoryOptions()
    this.usersTransactionFlags()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      linkTransaction, accounts, categories, payees, flagColors, transactionFlags
    } = this.props

    if (linkTransaction && linkTransaction !== prevProps.linkTransaction) {
      let linkAmount = linkTransaction.amount ? linkTransaction.amount : null
      if (!linkAmount) return false
      let linkName = linkTransaction.merchant_name ? linkTransaction.merchant_name : linkTransaction.name
      linkName = (linkName || '')
      let linkDate = linkTransaction.date
          ? DateTime.fromISO(linkTransaction.date)
          : DateTime.local()
      // link transactions are reversed.
      let amountPositive = !(linkAmount > 0)
      let amount = Math.abs(linkAmount)
      let account = { id: 0, name: '' }
      let category = { id: 0, name: '' }
      let name = { name: linkName }
      let transferAccountId = 0

      // Get account
      for (const a of accounts) {
        if (a.link_id === linkTransaction.account_id) {
          account = a
          break
        }
      }

      this.setState({
        open: true,
        name,
        amountPositive: amountPositive,
        amount: amount,
        category,
        account,
        date: linkDate,
        transferAccountId,
      })
    }

    // Update account options
    if (accounts && accounts !== prevProps.accounts) {
      this.updateAccountOptions()
    }

    // Update payee options
    if ((payees && payees !== prevProps.payees) || (accounts && accounts !== prevProps.accounts)) {
      this.updatePayeeOptions()
    }

    // Update split category options
    if ((categories && categories !== prevProps.categories)) {
      this.updateCategoryOptions()
    }

    // Update transaction flag options
    if ((transactionFlags && transactionFlags !== prevProps.transactionFlags)
        || (flagColors && flagColors !== prevProps.flagColors)) {
      this.usersTransactionFlags()
    }

  }

  /**
   * Update the available account options to assign a transaction to.
   */
  updateAccountOptions = () => {
    const { accounts } = this.props

    const accountOptions = []
    for (const account of accounts) {
      if (account.archived) continue
      const accountTypeCode = account.type_code ? account.type_code.trim().toUpperCase() : null
      let group = 'Cash'

      if (accountTypeCode === 'C') {
        group = 'Credit Cards'
      } else if (accountTypeCode === 'E') {
        group = 'Loans'
      } else if (accountTypeCode === 'F') {
        continue
      }

      accountOptions.push({ group, ...account })
    }

    this.setState({ accountOptions })
  }

  /**
   * Update the transaction flag options to assign a transaction to.
   */
  usersTransactionFlags = () => {
    const { flagColors, transactionFlags } = this.props

    // Get the users flag colors and labels.
    const transactionFlagColors = []
    for (const flagColor of flagColors) {
      for (const transactionFlag of transactionFlags) {
        if (flagColor.id === transactionFlag.color_id) {
          transactionFlagColors.push({
            id: transactionFlag.id,
            colorId: flagColor.id,
            color: flagColor.name,
            name: transactionFlag.name
          })
        }
      }
    }

    // Update state.
    this.setState({ transactionFlagColors })
  }

  /**
   * If the payee or account prop is updated then we need to update
   * the payee and split payee options state.
   */
  updatePayeeOptions = () => {
    const { accounts, payees } = this.props

    let payeeOptions = []
    let splitPayeeOptions = []

    for (const a of accounts) {
      const code = a.type_code ? a.type_code.trim().toUpperCase() : null

      if (!a.archived) {
        // Investment accounts can not make transactions
        if (code === 'F') continue
        // Loan accounts
        if (code === 'E') {
          let group = 'Loan Payment'
          let debt = {}
          debt.name = a.name
          debt.id = `T-${a.id}`
          payeeOptions.push({ group, ...debt })
        } else if (code === 'C') {
          // Transfer for credit card payment
          let group = 'Credit Card Payment'
          let transfer = {}
          transfer.name = a.name
          transfer.id = `T-${a.id}`
          payeeOptions.push({ group, ...transfer })
        } else {
          // Transfer accounts
          let group = 'Transfers'
          let transfer = {}
          transfer.name = a.name
          transfer.id = `T-${a.id}`
          payeeOptions.push({ group, ...transfer })
        }
      }
    }

    for (const p of payees) {
      let group = 'Payees'
      const name = p.name ? p.name.trim() : null

      // An existing transaction cannot be turned into an adjustment.
      if (name && name !== '') {
        payeeOptions.push({ group, ...p })
      }

      // A split transaction cannot be an adjustment.
      if ((name && name !== '') && name.toLowerCase() !== 'adjustment') {
        splitPayeeOptions.push(p)
      }
    }

    this.setState({ payeeOptions, splitPayeeOptions })
  }

  /**
   * If the categories props is updated then update the category
   * options and the split category options.
   *
   * Remove any category that is linked to an account.
   * Remove any categories that are archived.
   */
  updateCategoryOptions = () => {
    const { accounts, categories } = this.props
    let categoryOptions = []
    let splitCategoryOptions = []
    const linkCategories = accounts.filter(a => a.category_id).map(a => a.category_id)

    for (const c of categories) {
      if (linkCategories.includes(c.id)) continue
      if (c.archived) continue
      const name = c.name ? c.name.trim().toLowerCase() : null
      let group = 'Categories'

      if (name === 'budget') {
        group = 'Income to Budget'
      } else if (name === 'split') {
        group = 'Multiple Categories'
      }

      categoryOptions.push({ group: group, ...c })
    }

    // Create split category options
    for (const c of categories) {
      if (linkCategories.includes(c.id)) continue
      if (c.archived) continue
      const name = c.name ? c.name.trim().toLowerCase() : null
      if (name === 'split') continue
      let group = 'Categories'

      if (name === 'budget') {
        group = 'Income to Budget'
      }

      splitCategoryOptions.push({ group: group, ...c })
    }

    this.setState({ categoryOptions, splitCategoryOptions })
  }

  /**
   * Used to clear the form and close the dialog
   */
  clearForm = () => {
    const { clearLinkTransaction } = this.props

    // Don't clear the date state in case they want the same date as the last transaction.
    this.setState({
      open: false,
      name: { id: 0, name: '' },
      amountPositive: false,
      amount: '',
      category: { id: 0, name: '' },
      account: { id: 0, name: '' },
      splitCategories: [
        { id: 0, category: { id: 0, name: '' }, name: { id: 0, name: '' }, amount: '', notes: '' },
        { id: 0, category: { id: 0, name: '' }, name: { id: 0, name: '' }, amount: '', notes: '' }
      ],
      remainingSplitAmount: 0,
      notes: '',
      flagId: 0,
      reconciled: true,
      transferAccountId: 0,
      flagFormOpen: false
    })
    clearLinkTransaction()
  }

  /**
   * Handles text input change.
   * @param {Object} e - The event object.
   */
  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  /**
   * Update the amount field.
   * Update the remaining split amount available.
   * @param {Object} e - The event object.
   */
  handleInputChange = e => {
    const { splitCategories } = this.state

    if (moneyInput(e.target.value)) {
      let remaining = this.getRemainingSplitAmount(e.target.value, splitCategories)

      this.setState({
        [e.target.name]: e.target.value,
        remainingSplitAmount: remaining
      })
    }
  }

  /**
   * This function is used to update the name state. Cannot update the
   * name if a transfer. Name can not be adjustment. Determines if the
   * selected payee is a transfer account.
   * @param {Object} e - The event object
   * @param {Object} value - The selected value object. ie {id, name}
   * @param {string} reason - The reason the event was triggered.
   */
  handleNameChange = (e, value, reason) => {
    if (reason === 'clear') {
      this.setState({
        name: { id: 0, name: '' },
        transferAccountId: 0
      })
    } else if (value) {
      const { category } = this.state
      const { accounts, categories } = this.props

      if (value.id) {
        const id = value.id
        let updateCategory = category
        let linkCategoryId = 0

        // Check if account transfer
        let transferAccountId = (typeof id === 'string' && id.indexOf('T-') >= 0) ? id.substring(2) : 0

        if (transferAccountId > 0) {

          // If loan type account then get the link category.
          for (const account of accounts) {
            if (`${account.id}` === transferAccountId) {
              const typeCode = account.type_code ? account.type_code.trim().toUpperCase() : null
              if (typeCode === 'E') linkCategoryId = account.category_id
            }
          }

          // If a loan account get the link category.
          if (linkCategoryId > 0) {
            const linkCategory = categories.filter(c => c.id === linkCategoryId)
            updateCategory = linkCategory && linkCategory[0] ? linkCategory[0] : { id: 0, name: '' }
          } else if ((transferAccountId > 0)
              || (value.name && value.name.trim().toLowerCase() === 'adjustment')
              || (name && name.name && name.name.trim().toLowerCase() === 'adjustment')) {
            // If a transfer account or an adjustment remove the category
            updateCategory = { id: 0, name: '' }
          }
        }

        this.setState({
          name: value,
          category: updateCategory,
          transferAccountId
        })
      } else {
        // Adding a new payee If an adjustment remove the category
        const updateCategory = ((name && name.name && name.name.trim().toLowerCase() === 'adjustment')
            || (value.inputValue && value.inputValue.trim().toLowerCase() === 'adjustment'))
            ? { id: 0, name: '' }
            : category

        this.setState({
          name: { id: 0, name: value.inputValue },
          category: updateCategory
        })
      }
    }
  }

  /**
   * This function is used to update the category state.
   * @param {Object} e - The event object
   * @param {Object} value - The selected value object. ie {id, name}
   * @param {string} reason - The reason the event was triggered.
   */
  handleCategoryChange = (e, value, reason) => {
    if (reason === 'clear') {
      this.setState({ category: { id: 0, name: '' } })
    } else if (value) {
      this.setState({ category: value })
    }
  }

  /**
   * This function is used to update the account state. Cannot update the
   * account if a transfer.
   * @param {Object} e - The event object
   * @param {Object} value - The selected value object. ie {id, name}
   * @param {string} reason - The reason the event was triggered.
   */
  handleAccountChange = (e, value, reason) => {
    if (reason === 'clear') {
      this.setState({ account: { id: 0, name: '' } })
    } else if (value) {
      this.setState({ account: value })
    }
  }

  handleAmountPositive = () => this.setState({ amountPositive: !this.state.amountPositive })

  handleReconciled = () => this.setState({ reconciled: !this.state.reconciled })

  handleDateChange = date => this.setState({ date })

  /**
   * Updates the correct split amount based on index.
   * Update the remaining split amount available.
   * @param {Object} e - The event object.
   * @param {number} index - The index of the split category
   */
  handleSplitAmountChange = (e, index) => {
    const { amount, splitCategories } = this.state

    if (moneyInput(e.target.value)) {
      splitCategories[index].amount = e.target.value
      let remaining = this.getRemainingSplitAmount(amount, splitCategories)

      this.setState({
        splitCategories,
        remainingSplitAmount: remaining
      })
    }
  }

  /**
   * Updates the correct split name(payee) based on index.
   * @param {Object} e - The event object
   * @param {number} index - The index of the split category
   * @param {Object} value - The selected value object. ie {id, name}
   * @param {string} reason - The reason the event was triggered.
   */
  handleSplitNameChange = (e, index, value, reason) => {
    const { splitCategories } = this.state

    if (reason === 'clear') {
      splitCategories[index].name = { id: 0, name: '' }
      this.setState({ splitCategories })
    } else if (value) {
      if (value.id) {
        // Existing payee
        splitCategories[index].name = value
      } else {
        // Adding a new payee
        splitCategories[index].name = { id: 0, name: value.inputValue }
      }

      this.setState({ splitCategories })
    }
  }

  /**
   * Updates the correct split notes based on index.
   * @param {Object} e - The event object.
   * @param {number} index - The index of the split category
   */
  handleSplitNotesChange = (e, index) => {
    const { splitCategories } = this.state

    splitCategories[index].notes = e.target.value

    this.setState({
      splitCategories
    })
  }

  /**
   * This function is used to update the split category state.
   * @param {Object} e - The event object
   * @param {number} index - The index of the split category
   * @param {Object} value - The selected value object. ie {id, name}
   * @param {string} reason - The reason the event was triggered.
   */
  handleSplitCategoryChange = (e, index, value, reason) => {
    const { splitCategories } = this.state

    if (reason === 'clear') {
      splitCategories[index].category = { id: 0, name: '' }
      this.setState({ splitCategories })
    } else if (value) {
      splitCategories[index].category = value
      this.setState({ splitCategories })
    }
  }

  /**
   * Submit the link transaction form
   * This function is used to add the link transaction to the users'
   * transaction list.
   * @param {Object} e - The event object.
   */
  handleSubmit = e => {
    e.preventDefault()

    const {
      name, amountPositive, amount, category, account, date, notes,
      flagId, reconciled, splitCategories, transferAccountId
    } = this.state
    const {
      yearMonth, addTransaction, linkTransaction, returnErrors
    } = this.props
    const { user } = this.props.auth
    const id = (linkTransaction && linkTransaction.id) ? linkTransaction.id : null

    // If no link transaction id return false.
    if (!id) return false

    // Payee name cannot be blank or adjustment
    const payee = name && name.name ? name.name.trim() : null
    if (!payee) {
      returnErrors({ updateTransaction: 'Payee/Payer cannot be blank.' }, 500)
      return false
    } else if (payee && payee.trim().toLowerCase() === 'adjustment') {
      returnErrors({ updateTransaction: 'This transaction cannot be an adjustment.' }, 500)
    }

    // Turn the number positive or negative based on toggle.
    let netAmount = amountPositive ? amount : -Math.abs(amount)
    netAmount = formatMoney(netAmount)

    // Check for a split transaction and handle accordingly.
    let splitTransactionCategory = JSON.parse(JSON.stringify(splitCategories))
    if (category && category.name && category.name.toLowerCase().trim() === 'split') {
      // Validate the amount and split amount match.
      const remaining = this.getRemainingSplitAmount(amount, splitTransactionCategory)
      if (remaining !== 0) {
        returnErrors({ updateTransaction: 'Split amounts must equal transaction amount.' }, 500)
        return false
      }

      // Turn split numbers positive or negative based on toggle.
      for (const sc of splitTransactionCategory) {
        const splitAmount = formatMoney(sc.amount)
        sc.amount = amountPositive ? splitAmount : -Math.abs(splitAmount)
        sc.name = sc.name && sc.name.name ? sc.name.name : ''
        sc.notes = sc.notes ? sc.notes : ''
        sc.category_id = sc.category && sc.category.id ? sc.category.id : null
        sc.user_id = user.id
        sc.transaction_id = 0
      }
    } else {
      // Not a split
      splitTransactionCategory = []
    }

    /**
     * If account transfer or adjustment no category. But if debt
     * payment transfer then it needs a category.
     * If the account is a loan type account then no category.
     */
    const accountTypeCode = account.type_code ? account.type_code.trim().toUpperCase() : null
    let categoryId = category.id
    if (accountTypeCode === 'E') {
      categoryId = null
    } else {
      categoryId = (transferAccountId > 0) || (payee.toLowerCase() === 'adjustment') ? null : category.id
    }

    const transaction = {
      name: payee,
      amount: netAmount,
      category_id: categoryId,
      account_id: account.id,
      date: date.toISODate(),
      split_categories: splitTransactionCategory,
      notes,
      flag_id: (flagId || null),
      reconciled: reconciled,
      user_id: user.id,
      link_id: id,
      transfer_account_id: transferAccountId,
    }
    addTransaction(transaction, yearMonth)

    this.clearForm()
  }

  /**
   * This function is used to update the status of the link transaction
   * to remove it from the list.
   */
  handleDelete = () => {
    const { linkTransaction, editLinkTransaction } = this.props

    if (linkTransaction && linkTransaction.id) {
      editLinkTransaction(linkTransaction)
    }

    this.clearForm()
  }

  /**
   * Add another category split to the transaction form.
   */
  handleSplitCategoryAdd = () => {
    this.setState(({
      splitCategories: [...this.state.splitCategories, {
        category: { id: 0, name: '' },
        name: { id: 0, name: '' },
        amount: '',
        notes: ''
      }]
    }))
  }

  /**
   * Remove a category split to the transaction form. Update the
   * remainingSplitAmount state.
   * @param {Object} e - The event object
   * @param {number} index - The index of the split category
   */
  handleSplitCategoryRemove = (e, index) => {
    const { amount, splitCategories } = this.state

    splitCategories.splice(index, 1)
    let remaining = this.getRemainingSplitAmount(amount, splitCategories)

    this.setState({
      splitCategories,
      remainingSplitAmount: remaining
    })
  }

  /**
   * Calculates the remaining amount that needs to be in split
   * categories to equal the transaction amount.
   * @param {number} amount - The current amount for the transaction
   * @param {Object[]} splitCategories - The current amount for the
   * transaction
   * @param {number} splitCategories[].amount - The amount for the
   * category split.
   * @return {number|string} remaining - The remaining amount.
   * Or 'Error'
   */
  getRemainingSplitAmount = (amount, splitCategories) => {
    try {
      let remaining = numeral(amount)

      for (const splitCategory of splitCategories) remaining = remaining.subtract(splitCategory.amount)

      return remaining.value()
    } catch (error) {
      return 'Error'
    }
  }

  /**
   * Handles the selecting and deselecting of the flag checkboxes.
   * @param {Object} e - The event object.
   * @param {number} id = The color id.
   */
  handleFlagClick = (e, id) => {
    const checked = e && e.target ? e.target.checked : false

    if (checked) {
      this.setState({ flagId: id })
    } else {
      this.setState({ flagId: 0 })
    }
  }

  /**
   * Opens the transaction flag form.
   */
  openFlagForm = () => {
    this.setState({ flagFormOpen: true })
  }

  /**
   * Closes the transaction flag form.
   */
  closeFlagForm = () => {
    this.setState({ flagFormOpen: false })
  }

  render() {
    const {
      error, date, open, datePickerOpen, name, amountPositive, amount,
      category, splitCategories, account, notes, flagId, reconciled,
      transferAccountId, accountOptions, categoryOptions, splitCategoryOptions, payeeOptions,
      splitPayeeOptions, remainingSplitAmount, flagFormOpen, transactionFlagColors
    } = this.state
    const { classes, theme, linkTransaction } = this.props
    const isASplit = (category && category.name && category.name.trim().toLowerCase() === 'split')
    const isATransfer = (transferAccountId > 0)
    const isAnAdjustment = (name && name.name && name.name.trim().toLowerCase() === 'adjustment')
    const isALoanAccount = (account && account.type_code && account.type_code.trim().toUpperCase() === 'E')

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="transaction-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Link Transaction
              <Button onClick={this.handleDelete}
                      className={`${classes.dangerButton} ${classes.floatRight}`}
                      variant="outlined">
                <Delete className={classes.pr1} />Delete
              </Button>
            </DialogTitle>


            {linkTransaction && linkTransaction.id > 0
                ? (
                    <Grid container direction="row" justifyContent="center" alignItems="center"
                          className={classes.mb1}
                    >

                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" style={{ color: theme.palette.text.secondary }}>
                          {linkTransaction && linkTransaction.name ? `${linkTransaction.name} ` : ''}
                          {linkTransaction && linkTransaction.merchant_name ? linkTransaction.merchant_name : ''}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" style={{ color: theme.palette.text.secondary }}>
                          {linkTransaction && linkTransaction.category && Array.isArray(linkTransaction.category)
                              ? linkTransaction.category.join(', ')
                              : null
                          }
                        </Typography>
                      </Grid>

                    </Grid>
                ) : null
            }

            {linkTransaction && linkTransaction.pending
                ?
                <>
                  <DialogContent>
                    <Box p={3}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            This transaction is still pending at your institution.
                            Since the amount may change the transaction cannot be added.
                            Once the transaction is posted you can add it.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.clearForm()} color="inherit" variant="outlined">
                      <Cancel />&nbsp;Cancel
                    </Button>
                  </DialogActions>
                </>
                :
                <form onSubmit={this.handleSubmit}>
                  <DialogContent>
                    <Grid container spacing={2} justifyContent="center">

                      <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" item xs={12}
                            style={{ padding: 0 }}
                      >
                        <FormControlLabel label="Posted"
                                          labelPlacement="start"
                                          control={<SwitchPositive checked={reconciled}
                                                                   onChange={this.handleReconciled}
                                                                   name="reconciled"
                                                                   inputProps={{ 'aria-label': 'amount-positive' }}
                                          />}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth={true}
                                     error={error.date.error}>
                          <DatePicker
                              name="date"
                              label="Date"
                              open={datePickerOpen}
                              onClose={() => this.setState({ datePickerOpen: false })}
                              showTodayButton
                              autoOk
                              animateYearScrolling
                              showDaysOutsideCurrentMonth
                              clearable={false}
                              value={date}
                              toolbarFormat="MMMM d yyyy"
                              inputProps={{ readOnly: true }}
                              onChange={date => this.handleDateChange(date)}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      onClick={() => this.setState({ datePickerOpen: true })}
                                  />)}
                              okText={<span style={{ color: theme.palette.text.primary }}>Ok</span>}
                              cancelText={<span style={{ color: theme.palette.text.primary }}>Cancel</span>}
                              todayText={<span style={{ color: theme.palette.text.primary }}>Today</span>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container alignItems="center" justifyContent="center" spacing={1} wrap="nowrap"
                              sx={{ margin: 'auto' }}
                        >
                          <Grid item>-</Grid>
                          <Grid item>
                            <SwitchPositive checked={amountPositive}
                                            onChange={this.handleAmountPositive}
                                            name="amountPositive"
                                            inputProps={{ 'aria-label': 'amount-positive' }}
                            />
                          </Grid>
                          <Grid item>+</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField required
                                   name="amount"
                                   value={amount}
                                   autoFocus
                                   onChange={this.handleInputChange}
                                   error={error.amount.error}
                                   helperText={error.amount.helperText}
                                   inputProps={{ inputMode: 'decimal' }}
                                   margin="dense"
                                   id="transactionAmount"
                                   label="Amount"
                                   type="text"
                                   fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                            name="name"
                            clearOnEscape
                            openOnFocus
                            autoComplete
                            autoHighlight
                            includeInputInList
                            value={name}
                            options={payeeOptions}
                            groupBy={(option) => option.group}
                            isOptionEqualToValue={(option, value,) => value.value === option.value}
                            getOptionLabel={(option) => option.inputValue ? option.inputValue : option.name}
                            onChange={this.handleNameChange}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params)
                              const { inputValue } = params
                              const isExisting = options.some((option) => inputValue === option.name)
                              // Suggest the creation of a new value
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  name: `Add "${inputValue}"`,
                                })
                              }

                              return filtered
                            }}
                            renderInput={(params) => (
                                <TextField required
                                           error={error.name.error}
                                           helperText={error.name.helperText}
                                           margin="dense"
                                           label={amountPositive ? 'Payer (From)' : 'Payee (To)'}
                                           type="text"
                                           fullWidth {...params}
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                            name="category"
                            clearOnEscape
                            openOnFocus
                            autoComplete
                            autoHighlight
                            includeInputInList
                            disabled={!!(isATransfer || isAnAdjustment || isALoanAccount)}
                            value={category}
                            options={categoryOptions}
                            groupBy={(option) => option.group}
                            isOptionEqualToValue={(option, value,) => value.value === option.value}
                            getOptionLabel={(option) => option.inputValue ? option.inputValue : option.name}
                            onChange={this.handleCategoryChange}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            filterOptions={(options, params) => filter(options, params)}
                            renderInput={(params) => (
                                <TextField required
                                           error={error.category.error}
                                           helperText={error.category.helperText}
                                           margin="dense"
                                           label={(transferAccountId > 0) || isAnAdjustment || isALoanAccount
                                               ? 'No category needed'
                                               : 'Category'}
                                           type="text"
                                           fullWidth {...params}
                                />
                            )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                            name="account"
                            clearOnEscape
                            openOnFocus
                            autoComplete
                            autoHighlight
                            includeInputInList
                            value={account}
                            options={accountOptions}
                            groupBy={(option) => option.group}
                            isOptionEqualToValue={(option, value,) => value.value === option.value}
                            getOptionLabel={(option) => option.inputValue ? option.inputValue : option.name}
                            onChange={this.handleAccountChange}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            filterOptions={(options, params) => filter(options, params)}
                            renderInput={(params) => (
                                <TextField required
                                           error={error.account.error}
                                           helperText={error.account.helperText}
                                           margin="dense"
                                           label={amountPositive ? 'Account (To)' : 'Account (From)'}
                                           type="text"
                                           fullWidth {...params}
                                />
                            )}
                        />
                      </Grid>
                      {isASplit &&
                          <Grid container item align="center" alignItems="center"
                                justifyContent="center">
                            <Grid item xs={12}
                                  className={remainingSplitAmount !== 0 ? classes.fontError : null}>
                              Remaining&nbsp;{formatMoneyLabel(remainingSplitAmount)}
                            </Grid>
                          </Grid>
                      }
                      {isASplit && splitCategories.map((element, index) => {
                        let groupStyling = classes.splitGroup
                        if (index === 0) groupStyling += ` ${classes.firstSplit}`
                        else if (splitCategories.length === index + 1) groupStyling += ` ${classes.lastSplit}`
                        return (
                            <Grid container item align="center" alignItems="center"
                                  key={`split-${index}-${element.id}`}
                                  justifyContent="center">
                              <Grid container item spacing={2}
                                    className={groupStyling}>
                                <Grid item xs={2}>
                                  {splitCategories && splitCategories.length > 2
                                      ?
                                      <IconButton onClick={(e) => this.handleSplitCategoryRemove(e, index)}
                                                  aria-label="remove-split" size="small">
                                        <Cancel color="error" fontSize="small" />
                                      </IconButton>
                                      : null
                                  }
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField required
                                             name="splitAmount"
                                             value={element.amount}
                                             onChange={(e) => this.handleSplitAmountChange(e, index)}
                                             error={error.amount.error}
                                             helperText={error.amount.helperText}
                                             inputProps={{ inputMode: 'decimal' }}
                                             margin="dense"
                                             label="Amount"
                                             type="text"
                                             fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Autocomplete
                                      name="splitName"
                                      clearOnEscape
                                      openOnFocus
                                      autoComplete
                                      autoHighlight
                                      includeInputInList
                                      value={element.name || null}
                                      options={splitPayeeOptions}
                                      isOptionEqualToValue={(option, value,) => value.value === option.value}
                                      getOptionLabel={(option) => option.inputValue ? option.inputValue : option.name}
                                      onChange={(e, value, reason) => this.handleSplitNameChange(e, index, value, reason)}
                                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(options, params)
                                        const { inputValue } = params
                                        const isExisting = options.some((option) => inputValue === option.name)
                                        // Suggest the creation of a new value
                                        if (inputValue !== '' && !isExisting) {
                                          filtered.push({
                                            inputValue,
                                            name: `Add "${inputValue}"`,
                                          })
                                        }

                                        return filtered
                                      }}
                                      renderInput={(params) => (
                                          <TextField error={error.name.error}
                                                     helperText={error.name.helperText}
                                                     margin="dense"
                                                     label="Payee"
                                                     type="text"
                                                     fullWidth {...params}
                                          />
                                      )}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Autocomplete
                                      name="splitCategory"
                                      clearOnEscape
                                      openOnFocus
                                      autoComplete
                                      autoHighlight
                                      includeInputInList
                                      value={element.category || null}
                                      options={splitCategoryOptions}
                                      groupBy={(option) => option.group}
                                      isOptionEqualToValue={(option, value,) => value.value === option.value}
                                      getOptionLabel={(option) => option.inputValue ? option.inputValue : option.name}
                                      onChange={(e, value, reason) => this.handleSplitCategoryChange(e, index, value, reason)}
                                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                      filterOptions={(options, params) => filter(options, params)}
                                      renderInput={(params) => (
                                          <TextField required
                                                     error={error.category.error}
                                                     helperText={error.category.helperText}
                                                     margin="dense"
                                                     label="Category"
                                                     type="text"
                                                     fullWidth {...params}
                                          />
                                      )}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField name="splitNotes"
                                             value={element.notes}
                                             onChange={(e) => this.handleSplitNotesChange(e, index)}
                                             error={error.notes.error}
                                             helperText={error.notes.helperText}
                                             margin="dense"
                                             label="Notes"
                                             type="text"
                                             fullWidth
                                  />
                                </Grid>
                              </Grid>
                              {splitCategories && splitCategories.length === index + 1 &&
                                  <Grid item xs={12} className={classes.splitAddButton}>
                                    <IconButton onClick={this.handleSplitCategoryAdd} aria-label="add-split"
                                                size="small">
                                      <Add color="inherit" />
                                    </IconButton>
                                  </Grid>
                              }
                            </Grid>
                        )
                      })}
                      <Grid item xs={12}>
                        <TextField name="notes"
                                   value={notes ? notes : ''}
                                   onChange={this.handleChange}
                                   error={error.notes.error}
                                   helperText={error.notes.helperText}
                                   margin="dense"
                                   label="Notes"
                                   type="text"
                                   fullWidth
                        />
                      </Grid>
                      <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" item xs={12}
                      >
                        <FormControlLabel label="Flag"
                                          labelPlacement="top"
                                          control={<Grid container>
                                            {transactionFlagColors.map(f => (
                                                <Checkbox
                                                    key={f.id}
                                                    checked={f.id === flagId}
                                                    onClick={(e) => this.handleFlagClick(e, f.id)}
                                                    icon={<LabelOutlined />} checkedIcon={<Label />}
                                                    sx={{
                                                      color: f.color,
                                                      '&.Mui-checked': {
                                                        color: f.color,
                                                      },
                                                    }}
                                                />
                                            ))
                                            }
                                            <Checkbox
                                                checked={false}
                                                onClick={(e) => this.handleFlagClick({}, 0)}
                                                icon={<LabelOffOutlined />}
                                            />
                                            <Checkbox
                                                checked={false}
                                                onClick={this.openFlagForm}
                                                icon={<Edit />}
                                            />
                                          </Grid>}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button type="submit" color="primary" variant="contained">
                      <Save />&nbsp;Save
                    </Button>
                    <Button onClick={() => this.clearForm()} color="inherit" variant="outlined">
                      <Cancel />&nbsp;Cancel
                    </Button>
                  </DialogActions>
                </form>
            }
          </Dialog>
          <FlagForm open={flagFormOpen}
                    closeForm={this.closeFlagForm}
                    flagId={flagId}
                    handleFlagClick={this.handleFlagClick}
                    transactionFlagColors={transactionFlagColors}
          />
        </Fragment>
    )
  }
}

LinkTransactionForm.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  payees: PropTypes.array.isRequired,
  transfers: PropTypes.array.isRequired,
  splits: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  linkTransaction: PropTypes.object,
  flagColors: PropTypes.array.isRequired,
  transactionFlags: PropTypes.array.isRequired,
  addTransaction: PropTypes.func.isRequired,
  editLinkTransaction: PropTypes.func.isRequired,
  returnErrors: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  accounts: state.accountReducer.accounts,
  payees: state.payeeReducer.payees,
  transfers: state.transferReducer.transfers,
  splits: state.splitReducer.splits,
  categories: state.categoryReducer.categories,
  flagColors: state.flagColorReducer.flagColors,
  transactionFlags: state.transactionFlagReducer.transactionFlags,
})

const mapDispatchToProps = {
  addTransaction,
  editLinkTransaction,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LinkTransactionForm))
