import React, { Component, Fragment } from 'react'

import AppLoading from '../loading/AppLoading'
import Settings from './Settings'
import AccountForm from '../layout/AccountForm'

class SettingsDashboard extends Component {

  render() {
    return (
        <AppLoading>
          <Fragment>
            <Settings />
            <AccountForm />
          </Fragment>
        </AppLoading>
    )
  }
}

export default SettingsDashboard
