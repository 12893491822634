import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Grid, Card, CardContent, Typography } from '@mui/material'
import { ShoppingBasket, LocalAtm } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'
import TransactionList from './TransactionList'

const styles = theme => ({
  ...sharedStyles(theme),
})

class Transactions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentAccount: '',
      currentTransactions: [],
      currentLinkTransactions: []
    }
  }

  componentDidMount() {
    const { accounts, transactions, linkTransactions, accountKey } = this.props

    let currentAccount = accountKey === 0 ? 'All' : accounts.filter(a => a.id === accountKey).map(a => (a))
    currentAccount = currentAccount && currentAccount !== 'All' && currentAccount.length > 0 ? currentAccount[0] : 'All'

    const currentTransactions = accountKey === 0
        ? transactions.map(t => (t))
        : transactions.filter(t => t.account_id === accountKey).map(t => (t))

    const currentLinkTransactions = (currentAccount === 'All' && linkTransactions)
        ? []
        : linkTransactions.filter(l => l.account_id === currentAccount.link_id).map(l => (l))

    this.setState({
      currentAccount,
      currentTransactions,
      currentLinkTransactions
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accounts, transactions, linkTransactions, accountKey } = this.props

    // On transaction change update the table
    if (transactions !== prevProps.transactions) {
      const currentTransactions = accountKey === 0
          ? transactions.map(t => (t))
          : transactions.filter(t => t.account_id === accountKey).map(t => (t))

      this.setState({
        currentTransactions
      })
    }

    // On link transaction change update table.
    if (linkTransactions && linkTransactions !== prevProps.linkTransactions) {
      let currentAccount = accountKey === 0 ? 'All' : accounts.filter(a => a.id === accountKey).map(a => (a))
      currentAccount = currentAccount && currentAccount !== 'All' && currentAccount.length > 0 ? currentAccount[0] : 'All'

      const currentLinkTransactions = (currentAccount === 'All' && linkTransactions)
          ? []
          : linkTransactions.filter(l => l.account_id === currentAccount.link_id).map(l => (l))

      this.setState({
        currentAccount,
        currentLinkTransactions
      })
    }

    // On accountKey change update table.
    if (accountKey !== prevProps.accountKey) {
      let currentAccount = accountKey === 0 ? 'All' : accounts.filter(a => a.id === accountKey).map(a => (a))
      currentAccount = currentAccount && currentAccount !== 'All' && currentAccount.length > 0 ? currentAccount[0] : 'All'

      const currentTransactions = accountKey === 0
          ? transactions.map(t => (t))
          : transactions.filter(t => t.account_id === accountKey).map(t => (t))

      const currentLinkTransactions = (currentAccount === 'All' && linkTransactions)
          ? []
          : linkTransactions.filter(l => l.account_id === currentAccount.link_id).map(l => (l))

      this.setState({
        currentAccount,
        currentTransactions,
        currentLinkTransactions
      })
    }


  }

  render() {
    const { currentTransactions, currentLinkTransactions } = this.state
    const { classes, accountKey, accounts } = this.props

    let accountName = accounts.filter(a => a.id === accountKey).map(a => (a.name))
    accountName = (typeof accountName !== 'undefined' && accountName != null && accountName.length > 0) ? accountName[0] : 'All'

    // Get available balance
    let availableBalance = accounts.filter(a => a.id === accountKey).map(a => (a.available_balance))
    availableBalance = (availableBalance && typeof availableBalance[0] !== 'undefined') ? availableBalance[0] : null
    availableBalance = (!availableBalance && availableBalance !== 0 && accounts && accounts[0]) ? accounts[0].total_available : availableBalance
    if (!availableBalance) availableBalance = 0

    // Get posted balance
    let postedBalance = accounts.filter(a => a.id === accountKey).map(a => (a.posted_balance))
    postedBalance = (postedBalance && typeof postedBalance[0] !== 'undefined') ? postedBalance[0] : null
    postedBalance = (!postedBalance && postedBalance !== 0 && accounts && accounts[0]) ? accounts[0].total_posted : postedBalance
    if (!postedBalance) postedBalance = 0

    // Get pending balance
    let pendingBalance = accounts.filter(a => a.id === accountKey).map(a => (a.pending_balance))
    pendingBalance = (pendingBalance && typeof pendingBalance[0] !== 'undefined') ? pendingBalance[0] : null
    pendingBalance = (!pendingBalance && pendingBalance !== 0 && accounts && accounts[0]) ? accounts[0].total_pending : pendingBalance
    if (!pendingBalance) pendingBalance = 0

    let totalActivity = 0
    let totalInflow = 0
    for (let c of currentTransactions) {
      totalActivity += c.amount < 0 ? c.amount : 0
      totalInflow += c.amount > 0 ? c.amount : 0
    }

    return (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Card className={classes.cardActivity}>
                <CardContent className={classes.budgetCardsContent}>
                  <Typography variant="subtitle1" className={classes.cardText}>
                    {formatMoneyLabel(totalActivity)}
                  </Typography>
                  <Typography variant="body2" className={classes.cardText}>
                    Activity
                  </Typography>
                  <ShoppingBasket className={`${classes.cardIcon} ${classes.cardActivityIcon}`} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.cardInflow}>
                <CardContent className={classes.budgetCardsContent}>
                  <Typography variant="subtitle1" className={classes.cardText}>
                    {formatMoneyLabel(totalInflow)}
                  </Typography>
                  <Typography variant="body2" className={classes.cardText}>
                    Inflow
                  </Typography>
                  <LocalAtm className={`${classes.cardIcon} ${classes.cardInflowIcon}`} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <TransactionList
              currentTransactions={currentTransactions}
              currentLinkTransactions={currentLinkTransactions}
              accountName={accountName}
              accountKey={accountKey}
              availableBalance={availableBalance}
              postedBalance={postedBalance}
              pendingBalance={pendingBalance}
          />
        </Fragment>
    )
  }
}

Transactions.propTypes = {
  accounts: PropTypes.array.isRequired,
  transactions: PropTypes.array.isRequired,
  linkTransactions: PropTypes.array.isRequired,
  transfers: PropTypes.array.isRequired,
  accountKey: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  accounts: state.accountReducer.accounts,
  transactions: state.transactionReducer.transactions,
  linkTransactions: state.linkReducer.linkTransactions,
  transfers: state.transferReducer.transfers
})

export default connect(mapStateToProps)(withStyles(styles)(Transactions))
