import React, { Component, Fragment } from 'react'

import AppLoading from '../loading/AppLoading'
import LinkAccount from './LinkAccount'
import AccountForm from '../layout/AccountForm'

class LinkAccountDashboard extends Component {

  render() {
    return (
        <AppLoading>
          <Fragment>
            <LinkAccount />
            <AccountForm />
          </Fragment>
        </AppLoading>
    )
  }
}

export default LinkAccountDashboard
