import {
  GET_ACCOUNTS, EDIT_ACCOUNT, DELETE_ACCOUNT, ADD_ACCOUNT, REORDER_ACCOUNTS,
  ACCOUNT_LOADING, ACCOUNT_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  accounts: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        accounts: action.payload
      }
    case EDIT_ACCOUNT:
    case REORDER_ACCOUNTS:
      return {
        ...state,
        error: false,
        isFetching: false,
        accounts: state.accounts.map(account => {
          return account.id === action.payload.id ? action.payload : account
        }) // Replace matched account and returns the state.
      }
    case DELETE_ACCOUNT:
      return {
        ...state,
        error: false,
        isFetching: false,
        accounts: state.accounts.filter(account => account.id !== action.payload)
      }
    case ADD_ACCOUNT:
      return {
        ...state,
        error: false,
        isFetching: false,
        accounts: [...state.accounts, action.payload]
      }
    case ACCOUNT_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true,
      }
    case ACCOUNT_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}