import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, ListItem, ListItemText, Badge } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SortableElement } from 'react-sortable-hoc'
import { MoreVert, Link as LinkIcon, LinkOff } from '@mui/icons-material'

import { DragHandle } from '../common/DragHandle'
import { formatMoneyLabel } from '../utilities/numbers'

export const SortableAccounts = SortableElement(
    ({ accountClick, account, linkAccount, count, classes }) => {

      let balanceColor = (account.available_balance) > 0 ? ' green-text ' : ' red-text '
      let accountMoreButton = <MoreVert fontSize="small" />
      if (linkAccount && linkAccount.id) {
        const status = linkAccount && (!linkAccount.status || linkAccount.status === '') ? null : linkAccount.status
        if (!status) accountMoreButton = <LinkIcon fontSize="small" />
        else if (status && status !== '') accountMoreButton = <LinkOff fontSize="small" />
      }

      const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          border: `2px solid ${theme.palette.background.paper}`,
          padding: '0 4px',
          color: '#fff'
        },
      }))

      return (
          <ListItem dense
                    className={`${classes.topIndex} ${classes.dragHandleParent} ${classes.p0} `}
          >
            <ListItem button dense disableGutters
                      to={`/accounts/${account.id}`}
                      component={Link}
                      key={`account-${account.id}`}
                      className={`${classes.sideNavAccountItem}`}
            >
              <IconButton aria-label="drag" className={classes.dragHandleButton} size="small">
                <DragHandle classes={classes} />
              </IconButton>
              <ListItemText
                  primary={account.name}
                  secondary={formatMoneyLabel(account.available_balance)}
                  secondaryTypographyProps={{ className: `text-darken-2 ${balanceColor}` }}
              />
            </ListItem>
            <IconButton
                aria-label="Update account settings"
                className={classes.listItemMoreButton}
                onClick={accountClick.bind(this, account.id)}
                size="small">
              <StyledBadge color="accent" badgeContent={count} max={99}
                           anchorOrigin={{
                             vertical: 'top',
                             horizontal: 'left',
                           }}
              >
                {accountMoreButton}
              </StyledBadge>
            </IconButton>
          </ListItem>
      )
    }
)
