import {
  GET_TRANSFERS, TRANSFER_LOADING, TRANSFER_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  transfers: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFERS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        transfers: action.payload
      }
    case TRANSFER_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case TRANSFER_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}