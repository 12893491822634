import { GET_BUDGET_MONTH, EDIT_BUDGET_MONTH } from '../actions/types'

const NOW = new Date()

const initialState = {
  yearMonth: `${NOW.getFullYear()}${NOW.getMonth() + 1}`
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BUDGET_MONTH:
      return {
        ...state
      }
    case EDIT_BUDGET_MONTH:
      return {
        ...state,
        yearMonth: action.payload
      }
    default:
      return state
  }
}