import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Paper, Typography, Toolbar, TableContainer, Table, TableBody,
  TableHead, TableRow, TableCell, Grid
} from '@mui/material'
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import LinkAccountForm from '../layout/LinkAccountForm'
import { formatISODateTime } from '../utilities/dates'

const styles = theme => ({
  ...sharedStyles(theme),
  fontSecondary: {
    color: theme.palette.text.secondary
  }
})

class LinkAccount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      linkAccount: {}
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.linkAccountClick = this.linkAccountClick.bind(this)
  }

  /**
   * Close the dialog and reset the link account.
   */
  closeDialog = () => this.setState({ open: false, linkAccount: {} })

  /**
   * Open the dialog and set the selected link account.
   * @param {Object} row - The selected link account.
   */
  linkAccountClick = row => this.setState({ open: true, linkAccount: row })

  render() {
    const { open, linkAccount } = this.state
    const { theme, classes, linkAccounts } = this.props

    return (
        <Fragment>
          <Paper elevation={3}>
            <Toolbar>
              <Typography variant="h6" component="div">
                Link Accounts
              </Typography>
            </Toolbar>
            <TableContainer>
              <Table aria-label="link accounts">
                <TableHead>
                  <TableRow>
                    <TableCell>Institution</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {linkAccounts && linkAccounts.length > 0
                      ? linkAccounts.map((row) => (
                              <TableRow key={row.id} hover onClick={() => this.linkAccountClick(row)}>

                                <TableCell style={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  color: row.institution_primary_color
                                }}>
                                  {row.institution_name}
                                </TableCell>

                                <TableCell>
                                  <Grid container>
                                    <Grid item xs={12}>{row.name}</Grid>
                                    <Grid item xs={12} className={classes.fontSecondary}>{row.official_name}</Grid>
                                  </Grid>
                                </TableCell>

                                <TableCell style={{ textTransform: 'capitalize' }}>

                                  <Grid>
                                    <Grid container item xs={12} direction="row" justifyContent="flex-start"
                                          alignItems="center">
                                      {(row.sub_type || '')}
                                    </Grid>
                                    <Grid item xs={12} className={classes.fontSecondary}>
                                      <Typography variant="body2" noWrap>
                                        {(row.type || '')}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                </TableCell>

                                {row && (!row.status || row.status === '')
                                    ? (
                                        <TableCell>
                                          <Grid>
                                            <Grid container item xs={12} direction="row" justifyContent="flex-start"
                                                  alignItems="center">
                                              <LinkIcon fontSize="medium" color="success" />
                                              &nbsp;Connected
                                            </Grid>
                                            <Grid item xs={12} className={classes.fontSecondary}>
                                              <Typography variant="body2" noWrap>
                                                {formatISODateTime(row.updated)}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                    ) : (
                                        <TableCell>
                                          <Grid>
                                            <Grid container item xs={12} direction="row" justifyContent="flex-start"
                                                  alignItems="center">
                                              <LinkOffIcon fontSize="medium" color="error" />
                                              &nbsp;Broken
                                            </Grid>
                                            <Grid item xs={12} className={classes.fontSecondary}>
                                              <Typography variant="body2" noWrap>
                                                {formatISODateTime(row.updated)}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                    )
                                }
                              </TableRow>
                          )
                      )
                      :
                      (
                          <TableRow>
                            <TableCell align="center" colSpan={10}>No accounts linked.</TableCell>
                          </TableRow>
                      )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <LinkAccountForm open={open}
                           closeForm={this.closeDialog}
                           linkAccount={linkAccount} />
        </Fragment>
    )
  }
}

LinkAccount.propTypes = {
  linkAccounts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  linkAccounts: state.linkReducer.linkAccounts,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LinkAccount))
