import React, { Component, Fragment } from 'react'

import AppLoading from '../loading/AppLoading'
import Archive from './Archive'
import AccountForm from '../layout/AccountForm'

class ArchiveDashboard extends Component {

  render() {
    return (
        <AppLoading>
          <Fragment>
            <Archive />
            <AccountForm />
          </Fragment>
        </AppLoading>
    )
  }
}

export default ArchiveDashboard
