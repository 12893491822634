import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { DateTime } from 'luxon'
import { Cancel } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import RecurringTransactionList from '../recurring/RecurringTransactionList'
import { fullScreen, Transition } from '../utilities/dialogs'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
}))

/**
 *
 * @param {boolean} open - Is the modal open or closed.
 * @param {function} handleClose - Function to close the modal.
 * @param {Array} events - The recurring events to
 * display.
 * @param {DateTime} recurringDate - The date of events. JS Date
 * display.
 * @param {function} onSelectEvent - Call when a user selects an event.
 * @returns {JSX.Element}
 * @constructor
 */
const RecurringDateDetail = ({ open, handleClose, events, recurringDate, onSelectEvent }) => {
  const theme = useTheme()
  const classes = useStyles()

  /**
   * Handles the user selecting an event from the list of recurring.
   * @param {Object} event - The event the user selected.
   */
  const handleSelectEvent = (event) => onSelectEvent(event)

  return (
      <Fragment>
        <Dialog open={open}
                aria-labelledby="date-events-list"
                TransitionComponent={Transition}
                fullScreen={fullScreen(theme)}
                PaperComponent={DraggableDialogWrapper}
                maxWidth="sm" fullWidth={true}
        >
          <DialogTitle className={classes.moveCursor}>
            {DateTime.fromJSDate(recurringDate).toLocaleString({
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
          </DialogTitle>
          <DialogContent>

            <RecurringTransactionList events={events} onSelectEvent={handleSelectEvent} />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit" variant="outlined">
              <Cancel />&nbsp;Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'RecurringDateDetail')
  return props
}

const propTypes = {}

RecurringDateDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  recurringDate: PropTypes.instanceOf(Date),
  onSelectEvent: PropTypes.func.isRequired
}

export default RecurringDateDetail
