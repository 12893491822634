import { GET_ACCOUNT_KEY, EDIT_ACCOUNT_KEY } from '../actions/types'

const initialState = {
  accountKey: 0
}

/**
 * Account key is used to select and account for showing transactions.
 * @param state
 * @param action
 * @returns {{accountKey: *}|{accountKey: number}}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_KEY:
      return {
        ...state
      }
    case EDIT_ACCOUNT_KEY:
      return {
        ...state,
        accountKey: action.payload
      }
    default:
      return state
  }
}