import { GET_COLLECTION_ID, EDIT_COLLECTION_ID } from '../actions/types'

const initialState = {
  collectionId: -1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTION_ID:
      return {
        ...state
      }
    case EDIT_COLLECTION_ID:
      return {
        ...state,
        collectionId: action.payload
      }
    default:
      return state
  }
}