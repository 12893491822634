import axios from 'axios'
import { createToast, returnErrors } from './messages'
import {
  GET_CATEGORY_BUDGETS, EDIT_CATEGORY_BUDGET, CATEGORY_BUDGET_LOADING,
  CATEGORY_BUDGET_FAIL
} from './types'
import { getAccounts } from './accounts'
import { tokenConfig } from './auth'
import { getCollections } from './collections'
import { getCategories } from './categories'
import { getTransactions } from './transactions'
import { getHoldings } from './holdings'

const proxy = process.env.REACT_APP_PROXY

// Get Categories Budgets
export const getCategoryBudgets = (yearMonth) => (dispatch, getState) => {
  dispatch({ type: CATEGORY_BUDGET_LOADING })
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/category_budgets/?m=${mParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_CATEGORY_BUDGETS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: CATEGORY_BUDGET_FAIL })
      })
}

/**
 * This function is used to update a category budget.
 * @param {Object} budget - The category budget form.
 * @param {string} yearMonth - The current yearMonth.
 */
export const editCategoryBudget = (budget, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.put(`${proxy}/v1/category_budgets/${budget.id}/?m=${mParm}`, budget, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCategoryBudget: 'Budget Updated' }))
        dispatch({
          type: EDIT_CATEGORY_BUDGET,
          payload: res.data
        })
        dispatch(getCategoryBudgets(mParm))
        dispatch(getCollections(mParm))
        dispatch(getAccounts())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

/**
 * This function is used to bulk update category budgets.
 * @param {Object} budget - Data needed to update a bulk list of
 * category budgets.
 * @param {string} yearMonth - The current yearMonth.
 */
export const bulkCategoryBudgetUpdate = (budget, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.post(`${proxy}/v1/category_budgets/`, budget, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCategoryBudget: 'Budget Updated' }))
        dispatch({
          type: EDIT_CATEGORY_BUDGET,
          payload: res.data
        })
        dispatch(getCategoryBudgets(mParm))
        dispatch(getCollections(mParm))
        dispatch(getAccounts())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

/**
 * When the user changes the budget month we need to update data.
 * Start with the category budget as collection data is based off it.
 * @param {string} yearMonth - The year and month as a string.
 */
export const categoryBudgetMonthChange = (yearMonth) => (dispatch, getState) => {
  dispatch({ type: CATEGORY_BUDGET_LOADING })
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/category_budgets/?m=${mParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_CATEGORY_BUDGETS,
          payload: res.data
        })
        dispatch(getCollections(mParm))
        dispatch(getCategories(mParm))
        dispatch(getTransactions(mParm))
        dispatch(getHoldings())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: CATEGORY_BUDGET_FAIL })
      })
}
