import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Box, Grid, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Tooltip, Typography
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { EventRepeat } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
}))

/**
 *
 * @param {Array} events - The list of events.
 * @param {function} onSelectEvent - Call when a user selects an event.
 * @returns {JSX.Element}
 * @constructor
 */
const RecurringTransactionList = ({ events, onSelectEvent }) => {

  const theme = useTheme()
  const classes = useStyles()

  /**
   * Handles the user selecting an event from the list of recurring.
   * @param {Object} event - The event the user selected.
   */
  const handleSelectEvent = (event) => onSelectEvent(event)

  return (
      <Fragment>
        {events && events.length > 0
            ? (
                <TableContainer>
                  <Table aria-label="recurring transaction table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Upcoming</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Event</TableCell>
                        <TableCell>Est. Next Event</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {events.map((row, index) => (
                          <TableRow key={`upcoming-event-${index}`} hover
                                    onClick={() => handleSelectEvent(row)}
                          >

                            <TableCell>
                              <Grid container>
                                <Grid item xs={12} style={{ whiteSpace: 'nowrap' }}>
                                  {row.account}
                                </Grid>
                                <Grid item xs={12}
                                      style={{
                                        whiteSpace: 'nowrap',
                                        color: theme.palette.text.secondary
                                      }}
                                >
                                  {row.prettyFrequency}
                                </Grid>
                              </Grid>
                            </TableCell>

                            <TableCell>
                              {row.description}{row.merchantName ? `: ${row.merchantName}` : ''}
                            </TableCell>

                            <TableCell>
                              <Grid container>
                                <Grid item xs={12} style={{ whiteSpace: 'nowrap' }}>
                                  {row.prettyLastDate}
                                </Grid>
                                <Grid item xs={12}
                                      style={{
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                        color: row.lastAmountPositive ? theme.palette.success.main : theme.palette.text.secondary
                                      }}
                                >
                                  {formatMoneyLabel(row.lastAmount)}
                                </Grid>
                              </Grid>
                            </TableCell>

                            <TableCell>
                              {row.isActive
                                  ? (
                                      <Grid container>
                                        <Grid item xs={12} style={{ whiteSpace: 'nowrap' }}>
                                          {row.prettyNextDate}
                                        </Grid>
                                        <Grid item xs={12}
                                              style={{
                                                textTransform: 'capitalize',
                                                whiteSpace: 'nowrap',
                                                color: row.lastAmountPositive ? theme.palette.success.main : theme.palette.text.secondary
                                              }}
                                        >
                                          {formatMoneyLabel(row.lastAmount)}
                                        </Grid>
                                      </Grid>
                                  )
                                  : 'None'
                              }
                            </TableCell>

                            <TableCell align="center">
                              <Tooltip title={row.status === 'MATURE'
                                  ? 'A long term recurring transaction.'
                                  : row.status === 'EARLY_DETECTION'
                                      ? 'A new recurring transaction.'
                                      : 'No future transactions detected.'
                              }>
                                {row.statusIcon}
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
            ) : (
                <Box p={3}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                      <EventRepeat fontSize="large" className={classes.noDataIcon} />
                    </Grid>
                    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                      <Typography variant="h6">No recurring events detected yet.</Typography>
                    </Grid>
                    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                      <Typography variant="subtitle2">
                        If your accounts are linked any detected recurring events like subscriptions or bills will show
                        up here.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
            )}
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'RecurringTransactionList')
  return props
}

const propTypes = {}

RecurringTransactionList.propTypes = {
  events: PropTypes.array.isRequired,
  onSelectEvent: PropTypes.func.isRequired
}

export default RecurringTransactionList
