import axios from 'axios'
import { returnErrors } from './messages'
import { GET_THEMES, THEME_LOADING, THEME_FAIL } from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get AccountTypes
export const getThemes = () => (dispatch, getState) => {
  dispatch({ type: THEME_LOADING })
  axios.get(`${proxy}/v1/themes/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_THEMES,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: THEME_FAIL })
      })
}
