import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

import { formatPercent } from '../utilities/numbers'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey['400'],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6
  },
}))

const HorizontalProgressBar = (props) => {
  const { step, steps } = withPropsValidation(
      useSelector(() => ({
        step: props.step,
        steps: props.steps,
      })))

  const [value, setValue] = useState(0)
  const [progressColor, setProgressColor] = useState('rgba(0, 0, 0, 0.12)')

  /**
   * Set the percentage of the progress bar. If over 100 is set to
   * 100.
   */
  useEffect(() => {
    let value = Math.floor((step / steps) * 100)
    if (step !== 0) {
      if (value >= 100 || step >= steps) value = 100
    }

    setValue(value)
  }, [step, steps])

  useEffect(() => {
    const errorColor = '#FF0000 !important'
    const warningColor = '#FFBF00 !important'
    const successColor = '#0F4E5B !important'

    if (value < 50) {
      setProgressColor(errorColor)
    } else if (value >= 50 && value < 100) {
      setProgressColor(warningColor)
    } else if (value >= 100) {
      setProgressColor(successColor)
    }
  }, [value])

  return (
      <Box sx={{ flexGrow: 1 }}>
        <BorderLinearProgress
            variant="determinate"
            value={value}
            sx={{
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: progressColor,
              },
            }}
        />
      </Box>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'HorizontalProgressBar')
  return props
}

const propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired
}

export default HorizontalProgressBar
