import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Snackbar } from '@mui/material'
import { Alert, AlertTitle } from '@mui/material'
import { Transition } from '../utilities/dialogs'

class Alerts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      status: '',
      toast: '',
      variant: '',
    }

    this.showAlert = this.showAlert.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { error, toast } = this.props

    if (error !== prevProps.error) {
      if (error.msg && !(Array.isArray(error.msg))) this.showAlert(true, 'Error', error.msg, 'error')
      if (error.msg && Array.isArray(error.msg) && error.msg[0]) this.showAlert(true, 'Error', error.msg[0], 'error')
      if (error.msg.detail) this.showAlert(true, 'Error', error.msg.detail, 'error')
      if (error.msg.new_password) this.showAlert(true, 'Error', error.msg.new_password, 'error')
      if (error.msg.token) this.showAlert(true, 'Error', error.msg.token, 'error')
      if (error.msg.amount) this.showAlert(true, 'Error', `Amount: ${error.msg.amount.join()}`, 'error')
      if (error.msg.name) this.showAlert(true, 'Error', `Name: ${error.msg.name.join()}`, 'error')
      if (error.msg.date) this.showAlert(true, 'Error', `Date: ${error.msg.date.join()}`, 'error')
      if (error.msg.account_id) this.showAlert(true, 'Error', `Account: ${error.msg.account_id.join()}`, 'error')
      if (error.msg.collection_id) this.showAlert(true, 'Error', `Collection: ${error.msg.collection_id.join()}`, 'error')
      if (error.msg.non_field_errors) this.showAlert(true, 'Error', error.msg.non_field_errors.join(), 'error')
      if (error.msg.first_name) this.showAlert(true, 'Error', `First Name: ${error.msg.first_name.join()}`, 'error')
      if (error.msg.last_name) this.showAlert(true, 'Error', `Last Name: ${error.msg.last_name.join()}`, 'error')
      if (error.msg.email) this.showAlert(true, 'Error', `Email: ${error.msg.email.join()}`, 'error')
      if (error.msg.password) this.showAlert(true, 'Error', `Password: ${error.msg.password.join()}`, 'error')
      if (error.msg.re_password) this.showAlert(true, 'Error', `Confirm Password: ${error.msg.re_password.join()}`, 'error')
      if (error.msg.registration) this.showAlert(true, 'Error', error.msg.registration, 'error')
      if (error.msg.profile) this.showAlert(true, 'Error', error.msg.profile, 'error')
      if (error.msg.passwordsNotMatch) this.showAlert(true, 'Error', error.msg.passwordsNotMatch, 'error')
      if (error.msg.goal) this.showAlert(true, 'Error', error.msg.goal.join(), 'error')
      if (error.msg.budgeted) this.showAlert(true, 'Error', error.msg.budgeted.join(), 'error')
      if (error.msg.addCategoryBudget) this.showAlert(true, 'Error', error.msg.addCategoryBudget, 'error')
      if (error.msg.updateCategoryBudget) this.showAlert(true, 'Error', error.msg.updateCategoryBudget, 'error')
      if (error.msg.payee) this.showAlert(true, 'Error', error.msg.payee, 'error')
      if (error.msg.addTransaction) this.showAlert(true, 'Error', error.msg.addTransaction, 'error')
      if (error.msg.updateTransaction) this.showAlert(true, 'Error', error.msg.updateTransaction, 'error')
      if (error.msg.deleteTransaction) this.showAlert(true, 'Error', error.msg.deleteTransaction, 'error')
      if (error.msg.holding) this.showAlert(true, 'Error', error.msg.holding, 'error')
      if (error.msg.addHolding) this.showAlert(true, 'Error', error.msg.addHolding, 'error')
      if (error.msg.updateHolding) this.showAlert(true, 'Error', error.msg.updateHolding, 'error')
      if (error.msg.deleteHolding) this.showAlert(true, 'Error', error.msg.deleteHolding, 'error')
      if (error.msg.addCategory) this.showAlert(true, 'Error', error.msg.addCategory, 'error')
      if (error.msg.updateCategory) this.showAlert(true, 'Error', error.msg.updateCategory, 'error')
      if (error.msg.deleteCategory) this.showAlert(true, 'Error', error.msg.deleteCategory, 'error')
      if (error.msg.addCollection) this.showAlert(true, 'Error', error.msg.addCollection, 'error')
      if (error.msg.updateCollection) this.showAlert(true, 'Error', error.msg.updateCollection, 'error')
      if (error.msg.deleteCollection) this.showAlert(true, 'Error', error.msg.deleteCollection, 'error')
      if (error.msg.addAccount) this.showAlert(true, 'Error', error.msg.addAccount, 'error')
      if (error.msg.updateAccount) this.showAlert(true, 'Error', error.msg.updateAccount, 'error')
      if (error.msg.deleteAccount) this.showAlert(true, 'Error', error.msg.deleteAccount, 'error')
      if (error.msg.updateGroup) this.showAlert(true, 'Error', error.msg.updateGroup, 'error')
      if (error.msg.available_balance) this.showAlert(true, 'Error', error.msg.available_balance, 'error')
      if (error.msg.linkAPI) this.showAlert(true, 'Error', error.msg.linkAPI, 'error')
      if (error.msg.stripeAPI) this.showAlert(true, 'Error', error.msg.stripeAPI, 'error')
    }

    if (toast && toast !== prevProps.toast) {
      // Success Toasts
      if (toast.deleteTransaction) this.showAlert(true, 'Success', toast.deleteTransaction, 'success')
      if (toast.addTransaction) this.showAlert(true, 'Success', toast.addTransaction, 'success')
      if (toast.updateTransaction) this.showAlert(true, 'Success', toast.updateTransaction, 'success')
      if (toast.addCollection) this.showAlert(true, 'Success', toast.addCollection, 'success')
      if (toast.deleteCollection) this.showAlert(true, 'Success', toast.deleteCollection, 'success')
      if (toast.updateCollection) this.showAlert(true, 'Success', toast.updateCollection, 'success')
      if (toast.addCategory) this.showAlert(true, 'Success', toast.addCategory, 'success')
      if (toast.deleteCategory) this.showAlert(true, 'Success', toast.deleteCategory, 'success')
      if (toast.updateCategory) this.showAlert(true, 'Success', toast.updateCategory, 'success')
      if (toast.addCategoryBudget) this.showAlert(true, 'Success', toast.addCategoryBudget, 'success')
      if (toast.updateCategoryBudget) this.showAlert(true, 'Success', toast.updateCategoryBudget, 'success')
      if (toast.addHolding) this.showAlert(true, 'Success', toast.addHolding, 'success')
      if (toast.updateHolding) this.showAlert(true, 'Success', toast.updateHolding, 'success')
      if (toast.deleteHolding) this.showAlert(true, 'Success', toast.deleteHolding, 'success')
      if (toast.payee) this.showAlert(true, 'Success', toast.payee, 'success')
      if (toast.addAccount) this.showAlert(true, 'Success', toast.addAccount, 'success')
      if (toast.updateAccount) this.showAlert(true, 'Success', toast.updateAccount, 'success')
      if (toast.deleteAccount) this.showAlert(true, 'Success', toast.deleteAccount, 'success')
      if (toast.updateGroup) this.showAlert(true, 'Success', toast.updateGroup, 'success')
      if (toast.updateProfile) this.showAlert(true, 'Success', toast.updateProfile, 'success')
      if (toast.linkAPI) this.showAlert(true, 'Success', toast.linkAPI, 'success')
      if (toast.stripeAPI) this.showAlert(true, 'Success', toast.stripeAPI, 'success')
      if (toast.email) this.showAlert(true, 'Error', `Email: ${error.msg.email.join()}`, 'error')
      if (toast.passwordsNotMatch) this.showAlert(true, 'Error', toast.passwordsNotMatch, 'error')

      // Info Toasts
      if (toast.holdingInfo) this.showAlert(true, 'Info', toast.holdingInfo, 'info')
    }
  }

  showAlert = (show, status, toast, variant) => {
    this.setState({
      show,
      status,
      toast,
      variant,
    })
  }

  hideAlert = () => {
    this.setState({
      show: false,
      status: '',
      toast: '',
      variant: '',
    })
  }

  render() {
    const { show, status, toast, variant } = this.state
    if (!show) return <Fragment />

    return (
        <Snackbar open={show} autoHideDuration={6500}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  TransitionComponent={Transition}
                  key={toast}
                  onClose={this.hideAlert}>
          <Alert severity={variant} onClose={this.hideAlert}>
            <AlertTitle>{status}</AlertTitle>
            {toast}
          </Alert>
        </Snackbar>
    )
  }

}

Alerts.propTypes = {
  error: PropTypes.object,
  toast: PropTypes.object,
}

const mapStateToProps = state => ({
  error: state.errorReducer,
  toast: state.messageReducer.toast,
})

export default connect(mapStateToProps)(Alerts)
