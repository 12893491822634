import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import {
  Typography, Toolbar, Grid, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'
import HorizontalProgressBar from '../common/HorizontalProgressBar'
import { editCategoryBudgetId } from '../../actions/categoryBudgetId'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  positiveAmount: {
    color: theme.palette.success.main,
    fontWeight: 600
  },
  negativeAmount: {
    color: theme.palette.error.main,
    fontWeight: 600
  },
  tableRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)'
    }
  },
  tableLabel: {
    color: theme.palette.text.secondary
  }
}))

const FundShortCategories = () => {
  const { categoryBudgets } = withPropsValidation(
      useSelector(({ categoryBudgetReducer }) => ({
        categoryBudgets: categoryBudgetReducer.categoryBudgets
      })))

  const [fundShortCategories, setFundShortCategories] = useState([])
  const [isExpanded, setIsExpanded] = useState(true)

  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()

  /**
   * Get the list of category budgets that need more money to complete
   * their goal.
   */
  useEffect(() => {
    const budgets = categoryBudgets.filter(cat => cat.budgeted < cat.goal)

    setFundShortCategories(budgets)
  }, [categoryBudgets])

  /**
   * Handles clicking a category budget row.
   * @param {number} id - The category ID
   */
  const handleCategoryBudgetClick = id => dispatch(editCategoryBudgetId(id))

  return (
      <Fragment>
        {fundShortCategories && fundShortCategories.length > 0
            ? (
                <Accordion key={`fund-short-categories-group`}
                           expanded={isExpanded}
                           square
                           onChange={() => setIsExpanded(!isExpanded)}
                >
                  <AccordionSummary expandIcon={<ExpandMore fontSize="small" style={{ color: '#FFF' }} />}>
                    <Toolbar>
                      <Typography variant="h6" component="div">
                        Categories Needing Funding
                      </Typography>
                    </Toolbar>
                  </AccordionSummary>

                  <AccordionDetails>
                    {fundShortCategories.map(row => {
                      const balance = row && row.balance ? row.balance : 0
                      const balanceClass = balance > 0 ? classes.positiveAmount : balance < 0 ? classes.negativeAmount : null

                      return (
                          <Grid key={`fund-short-category-${row.id}`} container
                                className={classes.tableRow}
                                onClick={() => handleCategoryBudgetClick(row.category_id)}
                          >

                            <Grid container item xs={4}
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                            >
                              <Typography variant="body1">{row.category}</Typography>
                            </Grid>

                            <Grid container item xs={4} direction="column">

                              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <Typography variant="body2"
                                            className={classes.tableLabel}>Budgeted:&nbsp;</Typography>
                                <Typography variant="body2">
                                  {formatMoneyLabel(row.budgeted)}
                                </Typography>
                              </Grid>

                              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <Typography variant="body2" className={classes.tableLabel}>Goal:&nbsp;</Typography>
                                <Typography variant="body2">
                                  {formatMoneyLabel(row.goal)}
                                </Typography>
                              </Grid>

                            </Grid>

                            <Grid container item xs={4} direction="column" justifyContent="center"
                                  alignItems="flex-end">
                              <Typography variant="body2" className={classes.tableLabel}>Balance</Typography>
                              <Typography variant="body2" className={balanceClass}>
                                {formatMoneyLabel(row.balance)}
                              </Typography>
                            </Grid>

                            <Grid container item xs={12}
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                            >
                              <HorizontalProgressBar step={row.budgeted} steps={row.goal} />
                            </Grid>

                          </Grid>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
            ) : null
        }
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'FundShortCategories')
  return props
}

const propTypes = {
  categoryBudgets: PropTypes.array.isRequired
}

export default FundShortCategories
