import {
  GET_CUSTOMER, CREATE_CHECKOUT_SESSION, DELETE_CHECKOUT_SESSION,
  CUSTOMER_LOADING, CREATE_CHECKOUT_SESSION_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isLoading: false,
  isLoaded: false,
  checkoutSessionURL: null,
  customer: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
        error: false,
        isLoading: false,
        isLoaded: true,
        checkoutSessionURL: null,
        customer: action.payload
      }
    case CREATE_CHECKOUT_SESSION:
      return {
        ...state,
        error: false,
        isLoaded: true,
        checkoutSessionURL: action.payload
      }
    case DELETE_CHECKOUT_SESSION:
      return {
        ...state,
        error: false,
        isLoaded: true,
        checkoutSessionURL: null
      }
    case CUSTOMER_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true,
        isLoaded: false,
      }
    case CREATE_CHECKOUT_SESSION_FAIL:
      return {
        ...state,
        error: true,
        isLoading: false,
        isLoaded: true,
        checkoutSessionURL: null
      }
    default:
      return state
  }
}
