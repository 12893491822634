import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox } from '@mui/material'
import { Save, Cancel, Label, LabelOutlined } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { fullScreen, Transition } from '../utilities/dialogs'
import { editTransactionFlags } from '../../actions/transactionFlags'
import { returnErrors } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

class FlagForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        name: { error: null, helperText: '', },
      },
      flags: []
    }

    this.updateFlags = this.updateFlags.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.updateFlags()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { transactionFlagColors } = this.props

    if (transactionFlagColors && transactionFlagColors !== prevProps.transactionFlagColors) this.updateFlags()
  }

  /**
   * Update the flags state.
   */
  updateFlags = () => {
    const { transactionFlagColors } = this.props
    this.setState({ flags: transactionFlagColors })
  }

  /**
   * Used to clear the form and close the dialog.
   */
  clearForm = () => {
    this.setState({
      error: {
        name: { error: null, helperText: '', },
      }
    })

    this.props.closeForm()
  }

  /**
   * Used to handle the flag name update input.
   * @param {Object} e - The event object.
   * @param {number} index - The index of the array that changed..
   * @param {string} color - The color of the flag object.
   */
  handleChange = (e, index, color) => {
    const { flags } = this.state

    if (flags && flags[index]) {
      flags[index].name = e.target.value
      this.setState({ flags })
    }
  }

  /**
   * Handles the selecting and deselecting of the flag checkboxes.
   * @param {Object} e - The event object.
   * @param {number} id = The flag transaction Id.
   */
  handleClick = (e, id) => this.props.handleFlagClick(e, id)

  /**
   * Submit the budget form
   * @param {Object} e - The event object.
   */
  handleSubmit = e => {
    e.preventDefault()
    const { editTransactionFlags } = this.props

    try {
      const { flags } = this.state
      const { user } = this.props.auth

      // Update flags
      const transactionFlags = {
        flags,
        user_id: user.id,
      }

      editTransactionFlags(transactionFlags)
      this.clearForm()
    } catch (e) {
      returnErrors({ updateTransaction: 'Cannot update flags at this time.' }, 500)
    }
  }

  render() {
    const { flags } = this.state
    const { classes, theme, open, flagId } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="transaction-flag-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Flag
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {flags.map((f, index) => (
                          <Grid key={f.id} item xs={12} container>
                            <Grid item xs={2} container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                            >
                              <Checkbox icon={<LabelOutlined />} checkedIcon={<Label />}
                                        checked={f.id === flagId}
                                        onClick={(e) => this.handleClick(e, f.id)}
                                        sx={{
                                          color: f.color,
                                          '&.Mui-checked': {
                                            color: f.color,
                                          },
                                        }}
                              />
                            </Grid>
                            <Grid item xs={10} container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                            >
                              <TextField name={`name-${f.id}`}
                                         onChange={(e) => this.handleChange(e, index, f.color)}
                                         value={f.name ? f.name : ''}
                                         placeholder="Add Custom Flag..."
                                         variant="outlined" size="small" fullWidth
                                         sx={{
                                           input: {
                                             borderRadius: '4px',
                                             background: f.color,
                                             fontWeight: 600
                                           },
                                           '& .MuiOutlinedInput-root': {
                                             '&.Mui-focused fieldset': {
                                               borderColor: f.color,
                                             },
                                           }
                                         }}
                              />
                            </Grid>
                          </Grid>
                      )
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={() => this.clearForm()} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

FlagForm.propTypes = {
  open: PropTypes.bool.isRequired,
  flagId: PropTypes.number.isRequired,
  transactionFlagColors: PropTypes.array.isRequired,
  handleFlagClick: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  editTransactionFlags: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
})

const mapDispatchToProps = {
  returnErrors,
  editTransactionFlags
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(FlagForm))
