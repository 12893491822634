import {
  GET_ACCOUNT_TYPES, ACCOUNT_TYPE_LOADING, ACCOUNT_TYPE_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  accountTypes: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_TYPES:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        accountTypes: action.payload
      }
    case ACCOUNT_TYPE_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case ACCOUNT_TYPE_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}