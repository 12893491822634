import { CREATE_TOAST, CREATE_MESSAGE, CLEAR_MESSAGES } from '../actions/types'

const initialState = {
  message: null,
  toast: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_TOAST:
      return {
        ...state,
        message: null,
        toast: action.payload
      }
    case CREATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
        toast: null
      }
    case CLEAR_MESSAGES:
      return {
        ...state,
        message: initialState.message,
        toast: initialState.toast
      }
    default:
      return state
  }
}
