import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Loading } from './Loading'

class AuthLoading extends Component {
  state = {
    loading: true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isLoading, user } = this.props.auth
    const { profile } = this.props

    if (isLoading !== prevProps.auth.isLoading) {
      this.setState({ loading: isLoading })
    }

    if (user !== prevProps.auth.user || profile !== prevProps.profile) {
      this.loadHelpScoutBeacon()
    }
  }

  /**
   * Setup Help Scout Beacon to identify the logged-in user
   */
  loadHelpScoutBeacon = () => {
    const { user } = this.props.auth
    const { profile } = this.props
    const email = user && user.email ? user.email : null

    if (email) {
      const signature = profile && profile.web_signature ? profile.web_signature : ''
      const name = `${(user && user.first_name) || ''} ${(user && user.last_name) || ''}`

      Beacon('identify', {
        email: email,
        name: name,
        signature: signature
      })
    }
  }

  render() {
    return this.state.loading ? <Loading /> : this.props.children
  }
}

AuthLoading.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  profile: state.profileReducer.profile
})

export default connect(mapStateToProps)(AuthLoading)
