import React, { Fragment } from 'react'

import SpendingVsLastMonth from './SpendingVsLastMonth'
import OverspentCategories from './OverspentCategories'
import FundShortCategories from './FundShortCategories'
import LinkTransactions from './LinkTransactions'
import CategoryBudgetForm from '../layout/CategoryBudgetForm'
import ActionButtons from '../layout/ActionButtons'
import AccountForm from '../layout/AccountForm'
import CollectionForm from '../layout/CollectionForm'
import CategoryForm from '../layout/CategoryForm'
import HoldingForm from '../layout/HoldingForm'
import TransactionForm from '../layout/TransactionForm'
import MoveBalanceForm from '../layout/MoveBalanceForm'

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Overview = () => {

  return (
      <Fragment>
        <SpendingVsLastMonth />
        <OverspentCategories />
        <FundShortCategories />
        <LinkTransactions />

        <ActionButtons />
        <AccountForm />
        <CollectionForm />
        <CategoryForm />
        <HoldingForm />
        <TransactionForm />
        <MoveBalanceForm />

        <CategoryBudgetForm />
      </Fragment>
  )
}

export default Overview
