import React, { Fragment } from 'react'

import AppLoading from '../loading/AppLoading'
import Budget from './Budget'
import AccountForm from '../layout/AccountForm'
import CollectionForm from '../layout/CollectionForm'
import CategoryForm from '../layout/CategoryForm'
import HoldingForm from '../layout/HoldingForm'
import TransactionForm from '../layout/TransactionForm'
import CategoryBudgetForm from '../layout/CategoryBudgetForm'
import MoveBalanceForm from '../layout/MoveBalanceForm'
import ActionButtons from '../layout/ActionButtons'

const BudgetDashboard = () => {

  return (
      <AppLoading>
        <Fragment>
          <Budget />
          <ActionButtons />
          <AccountForm />
          <CollectionForm />
          <CategoryForm />
          <HoldingForm />
          <TransactionForm />
          <CategoryBudgetForm />
          <MoveBalanceForm />
        </Fragment>
      </AppLoading>
  )
}

export default BudgetDashboard
