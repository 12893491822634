import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_REPORTS, REPORT_LOADING, REPORT_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get reports
export const getReports = (yearMonth) => (dispatch, getState) => {
  dispatch({ type: REPORT_LOADING })

  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/net_worth/?m=${mParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_REPORTS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: REPORT_FAIL })
      })
}
