import axios from 'axios'
import { createMessage, createToast, returnErrors } from './messages'
import {
  GET_TRANSACTION_FLAGS, EDIT_TRANSACTION_FLAGS, TRANSACTION_FLAG_LOADING, TRANSACTION_FLAG_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get transaction flags
export const getTransactionFlags = () => (dispatch, getState) => {
  dispatch({ type: TRANSACTION_FLAG_LOADING })
  axios.get(`${proxy}/v1/transaction_flags/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_TRANSACTION_FLAGS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: TRANSACTION_FLAG_FAIL })
      })
}

/**
 * Edit transaction flags
 * @param {Object} transactionFlags - Transaction flag information
 * @param {number} transactionFlags.user_id - User Id
 * @param {Object[]} transactionFlags.flags - Array of all of the transaction
 * @param {number} transactionFlags[].id - The Id of all of the flags
 * @param {string} transactionFlags[].name - Label for the flag
 */
export const editTransactionFlags = (transactionFlags) => (dispatch, getState) => {
  axios.post(`${proxy}/v1/transaction_flags/`, transactionFlags, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateTransaction: 'Flags Updated' }))
        dispatch({
          type: EDIT_TRANSACTION_FLAGS,
          payload: res.data
        })
        dispatch(getTransactionFlags())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: TRANSACTION_FLAG_FAIL })
      })
}
