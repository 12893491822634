import axios from 'axios'
import { createToast, returnErrors } from './messages'
import {
  GET_ACCOUNTS, EDIT_ACCOUNT, DELETE_ACCOUNT, ADD_ACCOUNT, REORDER_ACCOUNTS,
  ACCOUNT_LOADING, ACCOUNT_FAIL
} from './types'
import { tokenConfig } from './auth'
import { getTransactions } from './transactions'
import { getLinkTransactions } from './link'
import { getPayees } from './payees'
import { getAccountGroups } from './accountGroups'
import { getCategories } from './categories'
import { getHoldings } from './holdings'
import { getSecurities } from './securities'

const proxy = process.env.REACT_APP_PROXY

// Get Accounts
export const getAccounts = () => (dispatch, getState) => {
  dispatch({ type: ACCOUNT_LOADING })
  axios.get(`${proxy}/v1/accounts/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_ACCOUNTS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: ACCOUNT_FAIL })
      })
}

// Edit Account
export const editAccount = (account, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter

  axios.put(`${proxy}/v1/accounts/${account.id}/`, account, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateAccount: 'Account Updated' }))
        dispatch({
          type: EDIT_ACCOUNT,
          payload: res.data
        })
        dispatch(getAccounts())
        dispatch(getTransactions(mParm, fParm))
        dispatch(getLinkTransactions())
        dispatch(getPayees())
        dispatch(getAccountGroups())
        dispatch(getCategories(mParm))
        dispatch(getHoldings())
        dispatch(getSecurities(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Reorder the accounts
export const reorderAccounts = (account) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/accounts/reorder/${account.id}/`, account, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateAccount: 'Accounts Updated' }))
        dispatch({
          type: REORDER_ACCOUNTS,
          payload: res.data
        })
        dispatch(getAccounts())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Delete Account
export const deleteAccount = (id, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter

  axios.delete(`${proxy}/v1/accounts/${id}/`, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateAccount: 'Account Updated' }))
        dispatch({
          type: DELETE_ACCOUNT,
          payload: id
        })
        dispatch(getAccounts())
        dispatch(getTransactions(mParm, fParm))
        dispatch(getLinkTransactions())
        dispatch(getPayees())
        dispatch(getAccountGroups())
        dispatch(getHoldings())
        dispatch(getSecurities(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Unarchive Account
export const unarchiveAccount = (account, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter

  axios.put(`${proxy}/v1/accounts/${account.id}/`, account, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateAccount: 'Account Updated' }))
        dispatch({
          type: EDIT_ACCOUNT,
          payload: res.data
        })
        dispatch(getAccounts())
        dispatch(getTransactions(mParm, fParm))
        dispatch(getLinkTransactions())
        dispatch(getPayees())
        dispatch(getAccountGroups())
        dispatch(getHoldings())
        dispatch(getSecurities(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Add Account
export const addAccount = (account, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.post(`${proxy}/v1/accounts/`, account, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ addAccount: 'Account Added' }))
        dispatch({
          type: ADD_ACCOUNT,
          payload: res.data
        })
        dispatch(getAccounts())
        dispatch(getAccountGroups())
        dispatch(getCategories(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
