import numeral from 'numeral'

/**
 * Determine if the input is a valid money field input.
 * @param input - The users input
 * @returns {boolean} - True if valid money field input
 */
export const moneyInput = input => {
  // const re = /^[0-9]*\.?[0-9]*$/
  const re = /^\d*(\.\d{0,2})?$/
  return (re.test(input))
}

/**
 * Determine if the input is a valid investment number field input.
 * Investment quantity and prices are to 6 decimal places.
 * @param input - The users input
 * @returns {boolean} - True if valid investment field input
 */
export const investmentInput = input => {
  // const re = /^[0-9]*\.?[0-9]*$/
  const re = /^\d*(\.\d{0,6})?$/
  return (re.test(input))
}

/**
 * TODO: Add logic to adjust the return based on the users settings.
 * Format the money input to the logged-in users money field settings.
 * @param input
 * @returns {string} - The input formatted to the users money settings.
 */
export const formatMoneyLabel = input => {
  return numeral(input).format('$0,0.00')
}

/**
 * TODO: Add logic to adjust the return based on the users settings.
 * Format the money input to the logged in users money field settings.
 * @param input
 * @returns {number} - The input formatted to the users money settings.
 */
export const formatMoney = input => {
  return numeral(input).format('0.00')
}

/**
 * Format the input to a percent string.
 * @param input
 * @returns {string} - The input formatted to a percent string.
 */
export const formatPercentLabel = input => {
  return numeral(input).format('0,0.0%')
}

/**
 * TODO: Add logic to adjust the return based on the users settings.
 * Format the input to a whole percentage. e.g. 0.4 => 40
 * @param input
 * @returns {number} - value - The percentage
 */
export const formatPercent = input => {
  const percent = numeral(input).format('00.0')
  let value = 0
  if (!isNaN(percent)) {
    value = percent * 100
  }

  return value
}

/**
 * Checks if the passed value is an int.
 * @param {any} value
 * @return {boolean}
 */
export const isInt = value => {
  try {
    return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))
  } catch (e) {
    return false
  }
}
