import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_HOLDINGS, EDIT_HOLDING, DELETE_HOLDING, ADD_HOLDING, HOLDING_LOADING, HOLDING_FAIL,
  GET_HOLDING_HISTORY, HOLDING_HISTORY_LOADING, HOLDING_HISTORY_FAIL
} from './types'
import { getAccounts } from './accounts'
import { getAccountGroups } from './accountGroups'
import { tokenConfig } from './auth'
import { isInt } from '../components/utilities/numbers'
import { ROWS_PER_PAGE_OPTIONS } from '../components/utilities/tables'

const PROXY = process.env.REACT_APP_PROXY

// Get Holdings
export const getHoldings = () => (dispatch, getState) => {
  dispatch({ type: HOLDING_LOADING })

  axios.get(`${PROXY}/v1/holdings/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_HOLDINGS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: HOLDING_FAIL })
      })
}

/**
 * Get Holding History
 * @param {number} id - A holding id to pull the history for.
 * @param {number} [page=0] - The page of results to retrieve. 0 indexed.
 * @param {number} [pageSize] - The page size of results to retrieve.
 * Defaults to ROWS_PER_PAGE_OPTIONS[0].
 * @param {string} [startDate] - The starting date to search.
 * yyyymmdd format.
 * @param {string} [endDate] - The end date to search. yyyymmdd format.
 */
export const getHoldingHistory = (id, page, pageSize, startDate, endDate) => (dispatch, getState) => {
  dispatch({ type: HOLDING_HISTORY_LOADING })

  if (!isInt(id)) {
    dispatch(returnErrors('Unable to find holding.', 500))
    dispatch({ type: HOLDING_HISTORY_FAIL })
    return false
  }

  const pageParam = (page || 0)
  const pageSizeParam = (pageSize || ROWS_PER_PAGE_OPTIONS[0])
  const start = (startDate || '')
  const end = (endDate || '')


  const header = tokenConfig(getState)
  header.params = {
    id: id,
    p: pageParam,
    ps: pageSizeParam,
    sd: start,
    ed: end
  }

  axios.get(`${PROXY}/v1/holding_history/`, header)
      .then(res => {
        dispatch({
          type: GET_HOLDING_HISTORY,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: HOLDING_HISTORY_FAIL })
      })
}

// Edit Holding
export const editHolding = (holding, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth

  axios.put(`${PROXY}/v1/holdings/${holding.id}/`, holding, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateHolding: 'Holding Updated' }))
        dispatch({
          type: EDIT_HOLDING,
          payload: res.data
        })
        dispatch(getAccounts())
        dispatch(getHoldings())
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Delete Holding
export const deleteHolding = (id) => (dispatch, getState) => {
  axios.delete(`${PROXY}/v1/holdings/${id}/`, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ deleteHolding: 'Holding Deleted' }))
        dispatch({
          type: DELETE_HOLDING,
          payload: id
        })
        dispatch(getAccounts())
        dispatch(getHoldings())
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Add Holding
export const addHolding = (holding, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth

  axios.post(`${PROXY}/v1/holdings/`, holding, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ addHolding: 'Holding Added' }))
        dispatch({
          type: ADD_HOLDING,
          payload: res.data
        })
        dispatch(getAccounts())
        dispatch(getHoldings())
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
