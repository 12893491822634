import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Cancel, Unarchive } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { fullScreen, Transition } from '../utilities/dialogs'
import { unarchiveAccount } from '../../actions/accounts'
import { unarchiveCategory } from '../../actions/categories'
import { returnErrors } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

class ArchiveForm extends Component {
  constructor(props) {
    super(props)

    this.handleUnarchive = this.handleUnarchive.bind(this)
  }

  /**
   * Unarchive the item based on table key.
   * item.table 'account' | 'category'
   * @param {Object} e - The event object.
   */
  handleUnarchive = e => {
    e.preventDefault()

    try {
      const { yearMonth, closeForm, item, unarchiveAccount, unarchiveCategory } = this.props
      const table = item && item.table ? item.table : null

      if (!item || !item.archived) throw Error('Not Archived')
      if (!table) throw Error('Not found')

      item.archived = false

      if (table === 'account') {
        unarchiveAccount(item, yearMonth)
      } else if (table === 'category') {
        unarchiveCategory(item, yearMonth)
      } else {
        throw Error('Not found')
      }

      closeForm()
    } catch (e) {
      this.props.returnErrors({ updateAccount: 'Unable to unarchive account. Please try again later.' }, 500)
    }
  }

  render() {
    const { theme, classes, open, closeForm, item } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="unarchive-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Unarchive {item.name}
            </DialogTitle>
            <DialogContent>
              <Box p={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Would you like to unarchive and add this back into your budget?
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      Note that archived item's data are still reflected in your budget. They are just hidden and
                      unable to be used while archived.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleUnarchive} color="primary" variant="contained">
                <Unarchive className={classes.pr1} />Unarchive
              </Button>
              <Button onClick={() => closeForm()} color="inherit" variant="outlined">
                <Cancel />&nbsp;Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
    )
  }
}

ArchiveForm.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  unarchiveAccount: PropTypes.func.isRequired,
  unarchiveCategory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  yearMonth: state.budgetMonthReducer.yearMonth
})

const mapDispatchToProps = {
  unarchiveAccount,
  unarchiveCategory,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ArchiveForm))
