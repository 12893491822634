import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import arrayMove from 'array-move'
import { Grid, Box, Typography } from '@mui/material'
import { CategoryOutlined } from '@mui/icons-material'
import numeral from 'numeral'

import { sharedStyles } from '../common/styles'
import { SortContainer } from '../common/SortContainer'
import { SortableCollections } from './SortCollections'
import { bulkCategoryBudgetUpdate } from '../../actions/categoryBudgets'
import { editCollectionId } from '../../actions/collectionId'
import { reorderCollections } from '../../actions/collections'
import CategoryDetail from '../category/CategoryDetail'

const styles = theme => ({
  ...sharedStyles(theme),
  noDataIcon: {
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light,
    fontSize: '56px'
  },
})

class Collections extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deriveProps: true,
      collections: this.props.collections,
      budgetedSummed: 0,
      goalSummed: 0,
      totalGoalProgress: 0,
      activitySummed: 0,
      previousActivity: 0,
      balanceSummed: 0,
      fundShort: 0,
      overspent: 0,
      overbudget: 0,
    }

    this.updateBudgetData = this.updateBudgetData.bind(this)
    this.collectionClick = this.collectionClick.bind(this)
    this.handleMultiActionClick = this.handleMultiActionClick.bind(this)
  }

  componentDidMount() {
    this.updateBudgetData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      categories, categoryBudgets, transactions, categoriesChecked
    } = this.props

    /**
     * If a user clicks a checkbox or the user updates a category
     * budget while a checkbox is clicked then we need to update the
     * toolbar numbers.
     */
    if ((categoriesChecked && categoriesChecked !== prevProps.categoriesChecked) ||
        (categoryBudgets && categoryBudgets !== prevProps.categoryBudgets) ||
        (categories && categories !== prevProps.categories) ||
        (transactions && transactions !== prevProps.transactions)) {
      this.updateBudgetData()
    }

  }

  /**
   * On component load and when data changes calculate the budget
   * data
   */
  updateBudgetData = () => {
    const { categories, categoryBudgets, transactions, categoriesChecked } = this.props

    try {
      let budgetedSummed = numeral(0)
      let goalSummed = numeral(0)
      let totalGoalProgress = numeral(0)
      let activitySummed = numeral(0)
      let previousActivity = numeral(0)
      let balanceSummed = numeral(0)
      let fundShort = numeral(0)
      let overspent = numeral(0)
      let progressBudgeted = numeral(0)
      let overbudget = numeral(0)

      if (categoriesChecked.length > 0) {
        for (const id of categoriesChecked) {
          // Sum budgeted, goal, and balance
          for (const budget of categoryBudgets) {
            if (`${budget.category_id}` === id) {
              budgetedSummed.add(budget.budgeted)
              goalSummed.add(budget.goal)
              balanceSummed.add(budget.balance)

              // Get fund short
              if (budget.goal > budget.budgeted) {
                const fund = numeral(budget.goal).subtract(budget.budgeted)
                fundShort.add(fund.value())
              }

              // Get overspent
              if (budget.balance < 0) {
                overspent.add(budget.balance)
              }

              // Get overbudget
              if (budget.budgeted > budget.goal) {
                const over = numeral(budget.budgeted).subtract(budget.goal)
                overbudget.add(over.value())
              }

              // Calculate budgeted for progress goal metric
              if (budget.budgeted > budget.goal) progressBudgeted.add(budget.goal)
              else progressBudgeted.add(budget.budgeted)

              break
            }
          }

          // Sum activity
          for (const category of categories) {
            if (`${category.id}` === id) {
              const categoryActivity = category && category.activity ? category.activity : 0
              const categoryPreviousActivity = category && category.previous_activity ? category.previous_activity : 0
              activitySummed.add(categoryActivity)
              previousActivity.add(categoryPreviousActivity)
              break
            }
          }
        }
      } else {
        // Don't filter the totals
        if (transactions && transactions[0] && transactions[0].total_activity) activitySummed.set(transactions[0].total_activity)
        if (categoryBudgets && categoryBudgets[0] && categoryBudgets[0].total_budgeted) budgetedSummed.set(categoryBudgets[0].total_budgeted)
        if (categoryBudgets && categoryBudgets[0] && categoryBudgets[0].total_goal) goalSummed.set(categoryBudgets[0].total_goal)

        // Get the total previous month activity
        for (const category of categories) {
          const lastMonthActivity = category && category.previous_activity ? category.previous_activity : 0
          previousActivity.add(lastMonthActivity)
        }

        // Get the total balance and overspent if no categories selected
        for (const budget of categoryBudgets) {
          balanceSummed.add(budget.balance)

          // Calculate overspent
          if (budget.balance < 0) overspent.add(budget.balance)

          // Calculate fund short
          if (budget.goal > budget.budgeted) {
            const fund = numeral(budget.goal).subtract(budget.budgeted)
            fundShort.add(fund.value())
          }

          // Get overbudget
          if (budget.budgeted > budget.goal) {
            const over = numeral(budget.budgeted).subtract(budget.goal)
            overbudget.add(over.value())
          }

          // Calculate budgeted for progress goal metric
          if (budget.budgeted > budget.goal) progressBudgeted.add(budget.goal)
          else progressBudgeted.add(budget.budgeted)
        }
      }


      budgetedSummed = budgetedSummed.value()
      goalSummed = goalSummed.value()
      activitySummed = activitySummed.value()
      previousActivity = previousActivity.value()
      balanceSummed = balanceSummed.value()
      fundShort = fundShort.value()
      overspent = overspent.value()
      progressBudgeted = progressBudgeted.value()
      overbudget = overbudget.value()

      //  Get the total goal.
      if (progressBudgeted && progressBudgeted > 0) totalGoalProgress = goalSummed && goalSummed > 0 ? numeral(progressBudgeted).divide(goalSummed).multiply(100) : numeral(100)
      totalGoalProgress = totalGoalProgress.value()

      this.setState({
        budgetedSummed,
        goalSummed,
        totalGoalProgress,
        activitySummed,
        previousActivity,
        balanceSummed,
        fundShort,
        overspent,
        overbudget
      })
    } catch (e) {

    }
  }

  collectionClick = id => this.props.editCollectionId(id)

  /**
   * This function is used to set the goal for each selected row to
   * what the goal was the previous month.
   * @param {Object} e - The on click event.
   * @param {string} action - The action code to perform on the
   * selected categories.
   */
  handleMultiActionClick = (e, action) => {
    const { yearMonth, bulkCategoryBudgetUpdate, categoriesChecked } = this.props
    const { user } = this.props.auth
    const budgetYear = yearMonth.substring(0, 4)
    const budgetMonth = yearMonth.substring(4, 6)
    const budgetDate = `${budgetYear}-${budgetMonth}-01`
    const budget = {
      action: action,
      goal_id: 1,
      user_id: user.id,
      date: budgetDate,
      categories: categoriesChecked
    }

    bulkCategoryBudgetUpdate(budget, yearMonth)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    try {
      const { collections, yearMonth, reorderCollections } = this.props

      let oldPositionElement = collections[oldIndex]
      let newPositionElement = collections[newIndex]
      if (oldPositionElement !== newPositionElement) {
        this.setState({
          deriveProps: false, // Temporarily stop props affecting state
          collections: arrayMove(collections, oldIndex, newIndex),
        })
        oldPositionElement.new_index = newIndex
        reorderCollections(oldPositionElement, yearMonth)

        // After a very short wait, allow props to affect state again
        setTimeout(() => {
          this.setState(() => ({
            deriveProps: true,
          }))
        }, 3000)
      }
    } catch (e) {
      //  TODO: dispatch error
    }
  }

  // onSortStart = ({ node, index, collection, isKeySorting }, event) => {}

  // Update state with prop changes, except for a brief period after the order has changed,
  // to stop flickering from happening
  static getDerivedStateFromProps(props, state) {
    if (state.deriveProps) {
      return {
        collections: props.collections,
      }
    }
    return null
  }

  render() {
    const {
      classes, theme, accounts, categoryFilter, categories, categoryBudgets,
      yearMonth, collectionsChecked, categoriesChecked, expandCollections,
      handleExpandClick, handleCollectionCheckbox, handleCategoryCheckbox
    } = this.props
    const {
      collections, budgetedSummed, goalSummed, totalGoalProgress,
      activitySummed, previousActivity, balanceSummed, fundShort, overspent, overbudget
    } = this.state
    const selectedCount = (categoriesChecked && categoriesChecked.length > 0) ? categoriesChecked.length : 0

    return (
        <Fragment>
          <Box mt={1}>
            <Grid container spacing={2}
                  sx={{
                    width: 'calc(100% - 248px)'
                  }}
            >
              <Grid item xs={12}>
                <SortContainer onSortEnd={this.onSortEnd} useDragHandle className={classes.p0}>
                  {collections && collections.length > 0

                      ? collections.map((collection, index) => {
                        const checked = (collectionsChecked && collectionsChecked.indexOf(`${collection.id}`) >= 0)
                        const collectionCategories = categories.filter(c => c.collection_id === collection.id).map(c => `${c.id}`)
                        let indeterminate = false
                        for (const cc of collectionCategories) if (categoriesChecked.indexOf(cc) >= 0) {
                          indeterminate = true
                          break
                        }
                        indeterminate = (!checked && indeterminate)
                        return (
                            <SortableCollections key={`sortable-collection-${collection.id}`}
                                                 index={index}
                                                 collectionItem={collection}
                                                 collectionClick={this.collectionClick}
                                                 handleCollectionCheckbox={handleCollectionCheckbox}
                                                 handleCategoryCheckbox={handleCategoryCheckbox}
                                                 collectionsChecked={checked}
                                                 indeterminate={indeterminate}
                                                 categoriesChecked={categoriesChecked}
                                                 categories={categories}
                                                 categoryBudgets={categoryBudgets}
                                                 yearMonth={yearMonth}
                                                 classes={classes}
                                                 theme={theme}
                                                 accounts={accounts}
                                                 expandCollections={expandCollections}
                                                 handleExpandClick={handleExpandClick}
                            />
                        )
                      })
                      : (
                          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2
                          }>
                            <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                              <CategoryOutlined className={classes.noDataIcon} />
                            </Grid>
                            <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                              <Typography variant="h5" align="center">
                                {categoryFilter === 1 || categoryFilter === 2
                                    ? categoryFilter === 1
                                        ? 'You betcha, that\'s some good work! No overspending this month.'
                                        : 'Keep\'er movin\', you\'re doing fantastic! All your categories are funded.'
                                    : 'Ope, no categories found'
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                      )
                  }
                </SortContainer>
              </Grid>
            </Grid>
          </Box>

          <CategoryDetail
              categoryIds={categoriesChecked}
              budgetedSummed={budgetedSummed}
              goalSummed={goalSummed}
              totalGoalProgress={totalGoalProgress}
              activitySummed={activitySummed}
              previousActivity={previousActivity}
              balanceSummed={balanceSummed}
              fundShort={fundShort}
              overspent={overspent}
              overbudget={overbudget}
              numberSelected={selectedCount}
              actionClick={this.handleMultiActionClick}
          />

        </Fragment>
    )
  }
}

Collections.propTypes = {
  auth: PropTypes.object.isRequired,
  yearMonth: PropTypes.string.isRequired,
  bulkCategoryBudgetUpdate: PropTypes.func.isRequired,
  editCollectionId: PropTypes.func.isRequired,
  reorderCollections: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  categoryFilter: PropTypes.number.isRequired,
  collections: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
  transactions: PropTypes.array.isRequired,
  expandCollections: PropTypes.array.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handleCollectionCheckbox: PropTypes.func.isRequired,
  handleCategoryCheckbox: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  accounts: state.accountReducer.accounts,
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
  transactions: state.transactionReducer.transactions,
})

const mapDispatchToProps = {
  bulkCategoryBudgetUpdate,
  editCollectionId,
  reorderCollections,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Collections))
