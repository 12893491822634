import { GET_CATEGORY_BUDGET_ID, EDIT_CATEGORY_BUDGET_ID } from './types'

// Get Categories Budget ID
export const getCategoryBudgetId = () => (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORY_BUDGET_ID,
    payload: getState
  })
}

// Edit Categories Budget ID
export const editCategoryBudgetId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_CATEGORY_BUDGET_ID,
    payload: id
  })
}
