import {
  GET_TRANSACTIONS, EDIT_TRANSACTION, DELETE_TRANSACTION, ADD_TRANSACTION,
  TRANSACTION_LOADING, TRANSACTION_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  transactions: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        transactions: action.payload
      }
    case EDIT_TRANSACTION:
      return {
        ...state,
        error: false,
        isFetching: false,
        transactions: state.transactions.map(transaction => {
          return transaction.id === action.payload.id ? action.payload : transaction
        }) // Replace matched transaction and returns the state.
      }
    case DELETE_TRANSACTION:
      return {
        ...state,
        error: false,
        isFetching: false,
        transactions: state.transactions.filter(transaction => transaction.id !== action.payload)
      } // Return the state minus the passed transaction id.
    case ADD_TRANSACTION:
      return {
        ...state,
        error: false,
        isFetching: false,
        transactions: [...state.transactions, action.payload]
      }
    case TRANSACTION_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case TRANSACTION_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}