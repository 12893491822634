import { GET_CATEGORY_BALANCE_ID, EDIT_CATEGORY_BALANCE_ID } from './types'

// Get category balance Id
export const getCategoryBalanceId = () => (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORY_BALANCE_ID,
    payload: getState
  })
}

// Edit category balance Id
export const editCategoryBalanceId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_CATEGORY_BALANCE_ID,
    payload: id
  })
}
