import { GET_CATEGORY_BALANCE_ID, EDIT_CATEGORY_BALANCE_ID } from '../actions/types'

const initialState = {
  categoryBalanceId: -1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_BALANCE_ID:
      return {
        ...state
      }
    case EDIT_CATEGORY_BALANCE_ID:
      return {
        ...state,
        categoryBalanceId: action.payload
      }
    default:
      return state
  }
}