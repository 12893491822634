import {
  GET_HOLDINGS, GET_HOLDING_HISTORY, EDIT_HOLDING, DELETE_HOLDING, ADD_HOLDING, HOLDING_LOADING,
  HOLDING_FAIL, HOLDING_HISTORY_LOADING, HOLDING_HISTORY_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  holdings: [],
  historyIsFetching: true,
  historyIsLoaded: false,
  history: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOLDINGS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        holdings: action.payload
      }
    case GET_HOLDING_HISTORY:
      return {
        ...state,
        error: false,
        historyIsFetching: false,
        historyIsLoaded: true,
        history: action.payload
      }
    case EDIT_HOLDING:
      return {
        ...state,
        error: false,
        isFetching: false,
        historyIsFetching: false,
        holdings: state.holdings.map(holding => {
          return holding.id === action.payload.id ? action.payload : holding
        }), // Replace matched holding and returns the state.
        history: state.history.map(h => {
          return h.id === action.payload.id ? action.payload : h
        }) // Replace matched history and returns the state.
      }
    case DELETE_HOLDING:
      return {
        ...state,
        error: false,
        isFetching: false,
        holdings: state.holdings.filter(holding => holding.id !== action.payload),
        history: state.history.filter(holding => holding.id !== action.payload)
      }
    case ADD_HOLDING:
      return {
        ...state,
        error: false,
        isFetching: false,
        holdings: [...state.holdings, action.payload],
        history: [...state.history, action.payload]
      }
    case HOLDING_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case HOLDING_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    case HOLDING_HISTORY_LOADING:
      return {
        ...state,
        error: false,
        historyIsFetching: true,
        historyIsLoaded: false,
      }
    case HOLDING_HISTORY_FAIL:
      return {
        ...state,
        error: true,
        historyIsFetching: false,
        historyIsLoaded: true,
      }
    default:
      return state
  }
}
