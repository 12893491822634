import React, { useState, useEffect, Fragment } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import withStyles from '@mui/styles/withStyles'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { clearMessages } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

/**
 * Creates a user interactive message. The user must take action to
 * dismiss message.
 */
const Message = ({ classes }) => {
  const { message } = useSelector(({ messageReducer }) => messageReducer)

  const [open, setOpen] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(null)

  const dispatch = useDispatch()

  /**
   * When message from prop store changes open dialog and
   * display new message.
   */
  useEffect(() => {
    if (message && message.title) {
      setOpen(true)
      setDisplayMessage(message)

      // Reset messages
      dispatch(clearMessages())
    }

  }, [message])

  const handleClose = () => {
    setOpen(false)
  }

  return !open
      ? <Fragment />
      : (
          <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={DraggableDialogWrapper}
                aria-labelledby="message-dialog"
            >

              <DialogTitle className={classes.moveCursor}>{displayMessage && displayMessage.title}</DialogTitle>

              {displayMessage && displayMessage.content &&
              <DialogContent>
                <DialogContentText>{displayMessage.content}</DialogContentText>
              </DialogContent>
              }

              <DialogActions>
                {displayMessage && displayMessage.buttons
                    ? displayMessage.buttons
                    : <Button color="primary" variant="contained" onClick={handleClose}>OK</Button>
                }
              </DialogActions>

            </Dialog>
          </>
      )
}

export default withStyles(styles, { withTheme: true })(Message)
