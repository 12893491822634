import axios from 'axios'
import { returnErrors } from './messages'
import {
  GET_ACCOUNT_TYPES, ACCOUNT_TYPE_LOADING, ACCOUNT_TYPE_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get AccountTypes
export const getAccountTypes = () => (dispatch, getState) => {
  dispatch({ type: ACCOUNT_TYPE_LOADING })
  axios.get(`${proxy}/v1/account_types/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_ACCOUNT_TYPES,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: ACCOUNT_TYPE_FAIL })
      })
}
