import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AuthLayout from './AuthLayout'
import withStyles from '@mui/styles/withStyles'
import { Typography, Divider, Grid, } from '@mui/material'

import { sharedStyles } from '../common/styles'

const styles = theme => ({
  ...sharedStyles(theme),
})

class ResetPasswordAcknowledge extends Component {

  render() {
    const { classes, isAuthenticated } = this.props

    if (isAuthenticated) return <Navigate to="/" />

    return (
        <AuthLayout heading="Password reset requested!">
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Typography>
                You should get an email soon with instructions on how to reset your password.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                Note: If you can't find the email in your inbox, please check your spam folder.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12}
                  justifyContent="center">
              <Link to="/login" className={classes.textLinks}>Login</Link>
            </Grid>

            <Divider />

          </Grid>
        </AuthLayout>
    )
  }
}

ResetPasswordAcknowledge.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPasswordAcknowledge))
