import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { usePlaidLink } from 'react-plaid-link'

import { addLinkTransactions } from '../../actions/link'

const OAuthResponse = props => {
  const { linkToken, stateId } = props
  const oAuthConfirmation = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      oAuthConfirmation.current.click()
    }, 3000)
  }, [])

  const onSuccess = useCallback((publicToken, metadata) => {
    const { addLinkTransactions } = props
    // send public_token to server
    addLinkTransactions(publicToken)
  }, [])

  const onEvent = useCallback((eventName, metadata) => {
    if (eventName === 'HANDOFF' || eventName === 'EXIT') location.href = process.env.REACT_APP_O_AUTH_REDIRECT
  }, [])

  const onExit = useCallback((err, metadata) => {
  }, [])

  const config = {
    token: linkToken,
    receivedRedirectUri: stateId,
    onSuccess,
    onEvent,
    onExit
  }

  const { open, ready, error } = usePlaidLink(config)

  return (
    <Fragment>
      {!ready || error || !linkToken || !stateId
        ?
        <div />
        :
        <div onClick={() => open()} ref={oAuthConfirmation} />
      }
    </Fragment>
  )
}

OAuthResponse.propTypes = {
  linkToken: PropTypes.string.isRequired,
  stateId: PropTypes.string.isRequired,
  addLinkTransactions: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  linkToken: state.linkReducer.linkToken,
  user: state.authReducer.user
})

const mapDispatchToProps = {
  addLinkTransactions,
}

export default connect(mapStateToProps, mapDispatchToProps)(OAuthResponse)
