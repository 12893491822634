import React, { Component, Fragment } from 'react'

import AppLoading from '../loading/AppLoading'
import Payee from './Payee'
import AccountForm from '../layout/AccountForm'

class PayeeDashboard extends Component {

  render() {
    return (
        <AppLoading>
          <Fragment>
            <Payee />
            <AccountForm />
          </Fragment>
        </AppLoading>
    )
  }
}

export default PayeeDashboard
