import {
  GET_CATEGORIES, EDIT_CATEGORY, REORDER_CATEGORIES, DELETE_CATEGORY,
  ADD_CATEGORY, CATEGORY_LOADING, CATEGORY_FAIL,
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  categories: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        categories: action.payload
      }
    case EDIT_CATEGORY:
    case REORDER_CATEGORIES:
      return {
        ...state,
        error: false,
        isFetching: false,
        categories: state.categories.map(category => {
          return category.id === action.payload.id ? action.payload : category
        }) // Replace matched category and returns the state.
      }
    case DELETE_CATEGORY:
      return {
        ...state,
        error: false,
        isFetching: false,
        categories: state.categories.filter(category => category.id !== action.payload)
      }
    case ADD_CATEGORY:
      return {
        ...state,
        error: false,
        isFetching: false,
        categories: [...state.categories, action.payload]
      }
    case CATEGORY_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case CATEGORY_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
