import {
  GET_SECURITIES, EDIT_SECURITY, DELETE_SECURITY, ADD_SECURITY, SECURITY_LOADING, SECURITY_FAIL,
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  securities: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SECURITIES:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        securities: action.payload
      }
    case EDIT_SECURITY:
      return {
        ...state,
        error: false,
        isFetching: false,
        securities: state.securities.map(security => {
          return security.id === action.payload.id ? action.payload : security
        }) // Replace matched security and returns the state.
      }
    case DELETE_SECURITY:
      return {
        ...state,
        error: false,
        isFetching: false,
        securities: state.securities.filter(security => security.id !== action.payload)
      }
    case ADD_SECURITY:
      return {
        ...state,
        error: false,
        isFetching: false,
        securities: [...state.securities, action.payload]
      }
    case SECURITY_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case SECURITY_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
