import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppLoading from '../loading/AppLoading'
import { withRouter } from '../utilities/withRouter'
import AccountDetail from '../account/AccountDetail'
import ActionButtons from '../layout/ActionButtons'
import AccountForm from '../layout/AccountForm'
import CollectionForm from '../layout/CollectionForm'
import CategoryForm from '../layout/CategoryForm'
import HoldingForm from '../layout/HoldingForm'
import TransactionForm from '../layout/TransactionForm'
import { editAccountKey } from '../../actions/accountKey'


class TransactionDashboard extends Component {
  constructor(props) {
    super(props)

    this.handleAccountKeyUpdate = this.handleAccountKeyUpdate.bind(this)
  }

  componentDidMount() {
    const { accountKey, match: { params } } = this.props

    if (params && params.accountKey) {
      if (params.accountKey !== accountKey) this.handleAccountKeyUpdate(params.accountKey)
    } else {
      this.handleAccountKeyUpdate(0)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match: { params } } = this.props

    if (params && params.accountKey) {
      if (params.accountKey !== prevProps.match.params.accountKey) this.handleAccountKeyUpdate(params.accountKey)
    } else {
      this.handleAccountKeyUpdate(0)
    }
  }

  /**
   * If the account key in the url changes update the account key.
   * @param {(number|string)} accountKey - The account key to set.
   */
  handleAccountKeyUpdate = (accountKey) => {
    const newKey = accountKey ? parseInt(accountKey) : 0
    this.props.editAccountKey(newKey)
  }

  render() {
    const { accountKey } = this.props

    return (
        <AppLoading>
          <Fragment>
            <AccountDetail accountKey={accountKey} />
            <ActionButtons />
            <AccountForm />
            <CollectionForm />
            <CategoryForm />
            <HoldingForm />
            <TransactionForm />
          </Fragment>
        </AppLoading>
    )
  }
}

TransactionDashboard.propTypes = {
  editAccountKey: PropTypes.func.isRequired,
  accountKey: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  accountKey: state.accountKeyReducer.accountKey
})

const mapDispatchToProps = {
  editAccountKey
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransactionDashboard))
