import { Typography, Grid, ListItem, ListItemText } from '@mui/material'

/**
 * Array of the table size options.
 * @type {number[]} - All of the options.
 */
export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 200]

/**
 * Download passed data as passed file name.
 * @param {Object} data
 * @param {string} [fileName=budgetDownload.csv]
 */
export const downloadFile = (data, fileName) => {
  try {
    fileName = (fileName || 'budgetDownload.csv')
    if (!data) return false
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (e) {

  }
}

/**
 * This builds and returns a table header.
 * @param {string} title - The name of the table.
 * @param {string} subtitle - The subtitle name of the table.
 * @return {JSX.Element}
 * @constructor
 */
export const TableHeader = ({ title, subtitle }) => {

  return (
      <Grid container>
        <Grid item xs={12}>
          <ListItem dense disableGutters>
            <ListItemText disableTypography
                          sx={{
                            position: 'relative',
                            background: 'linear-gradient(to right, #3A7985, #0f4e5b, #0f4e5b, #3A7985)',
                            borderRadius: '4px',
                            border: '4px solid #3A7985',
                            color: '#fff',
                            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                            '&:after, &:before': {
                              left: '100%',
                              top: '50%',
                              border: 'solid transparent',
                              content: '""',
                              height: 0,
                              width: 0,
                              position: 'absolute'
                            }
                          }}
                          primary={
                            <Typography align="center" variant="body1" noWrap>
                              {title}
                            </Typography>
                          }
                          secondary={
                            <Typography align="center" variant="subtitle1" noWrap>
                              {subtitle}
                            </Typography>
                          }
            />
          </ListItem>
        </Grid>
      </Grid>
  )
}
