import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  ListItem, ListItemText
} from '@mui/material'
import { Save, Cancel, DoneAll } from '@mui/icons-material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { editCategoryBudget } from '../../actions/categoryBudgets'
import { fullScreen, Transition } from '../utilities/dialogs'
import { moneyInput, formatMoney, formatMoneyLabel } from '../utilities/numbers'
import { returnErrors } from '../../actions/messages'

const filter = createFilterOptions()
const styles = theme => ({
  ...sharedStyles(theme),
})

class MoveBudgetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        amount: { error: null, helperText: '', },
        category: { error: null, helperText: '', },
      },
      filteredCategories: [],
      amount: '',
      categoryBudgetId: 0,
      categoryBudgetBudgeted: 0,
      category: { id: 0, name: '' },
    }

    this.handleCategoryBudgetUpdate = this.handleCategoryBudgetUpdate.bind(this)
    this.handleCategoryOptions = this.handleCategoryOptions.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.handleBudgetAll = this.handleBudgetAll.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.handleCategoryOptions()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { category } = this.state
    const { categories, categoryBudgets } = this.props

    // Filter out budget from category options.
    if ((categoryBudgets !== prevProps.categoryBudgets) ||
        (categories !== prevProps.categories)) {
      this.handleCategoryOptions()
    }

    // Get the category budget for this category.
    if (category !== prevState.category) {
      this.handleCategoryBudgetUpdate()
    }
  }

  /**
   * Set the selected category budget information.
   */
  handleCategoryBudgetUpdate = () => {
    const { category } = this.state
    const { categoryBudgets } = this.props

    for (const categoryBudget of categoryBudgets) {
      if (category && category.id === categoryBudget.category_id) {
        this.setState({
          categoryBudgetId: categoryBudget.id,
          categoryBudgetBudgeted: categoryBudget.budgeted
        })
        // End loop
        break
      }
    }
  }

  /**
   * Populates the category options for the dropdown.
   */
  handleCategoryOptions = () => {
    const { classes, categories, categoryBudgets } = this.props
    let filteredCategories = []

    for (const c of categories) {
      for (const cb of categoryBudgets) {
        if (c.id === cb.category_id) {
          if (!c.archived) {
            const balance = cb.balance ? formatMoneyLabel(cb.balance) : '$0.00'
            const styles = cb.balance >= 0 ? classes.dropdownListItem : classes.dropdownListItemError
            const name = c.name ? c.name.toLowerCase().trim() : null

            if (name !== 'budget' && name !== 'split') {
              const label = `${c.name}: ${balance}`

              filteredCategories.push({
                id: c.id,
                name: c.name,
                balance: balance,
                label: label,
                styles: styles
              })
            }
            // End inner loop
            break
          }
        }
      }
    }

    this.setState({ filteredCategories })
  }

  /**
   * Used to clear the form and close the dialog.
   */
  clearForm = () => {
    this.setState({
      amount: '',
      categoryBudgetId: 0,
      categoryBudgetBudgeted: 0,
      category: { id: 0, name: '' },
    })

    this.props.closeBudgetForm()
  }

  /**
   * Applies the total amount available to budget to the amount input
   * field.
   */
  handleBudgetAll = () => {
    const { accounts } = this.props
    const needToBudget = (accounts && accounts[0] && accounts[0].need_to_budget) ? accounts[0].need_to_budget : 0

    if (needToBudget > 0) {
      this.setState({ amount: needToBudget })
    } else {
      this.props.returnErrors({ updateCategoryBudget: 'No available dollars to apply.' }, 500)
    }
  }

  handleInputChange = e => {
    if (moneyInput(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleCategoryChange = (e, values, reason) => {
    if (reason === 'clear') {
      this.setState({ category: { id: 0, name: '' } })
    } else if (values) {
      this.setState({ category: values })
    }
  }

  /**
   * Submit the budget form
   * @param {Object} e - The event object.
   */
  handleSubmit = e => {
    e.preventDefault()
    try {
      const { amount, categoryBudgetId, categoryBudgetBudgeted, category } = this.state
      const { yearMonth, editCategoryBudget } = this.props
      const { user } = this.props.auth
      let budgetYear = yearMonth.substring(0, 4)
      let budgetMonth = yearMonth.substring(4, 6)
      let budgetDate = `${budgetYear}-${budgetMonth}-01`
      const netAmount = Math.abs(amount)
      let newBudgeted = netAmount + categoryBudgetBudgeted
      newBudgeted = formatMoney(newBudgeted)

      // Edit Budget
      const budget = {
        id: categoryBudgetId,
        budgeted: newBudgeted,
        category_id: category.id,
        user_id: user.id,
        date: budgetDate
      }

      editCategoryBudget(budget, yearMonth)
      this.clearForm()
    } catch (e) {
      this.props.returnErrors({ updateCategoryBudget: 'Unable to move money at this time. Please try again later.' }, 500)
    }
  }

  render() {
    const { error, filteredCategories, amount, category } = this.state
    const { classes, theme, open } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="move-budget-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >

            <DialogTitle className={classes.moveCursor}>
              Move Budget Money to Category

              <Button fullWidth color="primary" variant="text"
                      aria-label="Apply all available dollars to the category."
                      onClick={this.handleBudgetAll}
                      sx={{ textTransform: 'capitalize' }}
              ><DoneAll fontSize="small" />&nbsp;Budget All
              </Button>
            </DialogTitle>

            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField required
                               name="amount"
                               value={amount}
                               autoFocus
                               onChange={this.handleInputChange}
                               error={error.amount.error}
                               helperText={error.amount.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="transactionAmount"
                               label="Amount"
                               type="text"
                               fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        name="category"
                        clearOnEscape
                        openOnFocus
                        autoComplete
                        autoHighlight
                        includeInputInList
                        value={category}
                        options={filteredCategories}
                        isOptionEqualToValue={(option, value,) => value.value === option.value}
                        getOptionLabel={(option) => {
                          if (option.inputValue) return option.inputValue
                          else if (option.label) return option.label
                          else return option.name
                        }}
                        onChange={this.handleCategoryChange}
                        filterOptions={(options, params) => filter(options, params)}
                        renderOption={(props, option) => (
                            <ListItem key={option.id} {...props}>
                              <ListItemText align="left" primary={option.name} />
                              <ListItemText align="right" className={option.styles} primary={option.balance} />
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField required
                                       error={error.category.error}
                                       helperText={error.category.helperText}
                                       margin="dense"
                                       label="To Category"
                                       type="text"
                                       fullWidth {...params}
                            />
                        )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={() => this.clearForm()} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

MoveBudgetForm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeBudgetForm: PropTypes.func.isRequired,
  yearMonth: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
  editCategoryBudget: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  accounts: state.accountReducer.accounts,
  categories: state.categoryReducer.categories,
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
})

const mapDispatchToProps = {
  editCategoryBudget,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MoveBudgetForm))
