import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button, Dialog, DialogActions, DialogContent, Typography, DialogTitle } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { createLinkToken } from '../../actions/link'
import Link from '../link/Link'
import { fullScreen, Transition } from '../utilities/dialogs'

const styles = theme => ({
  ...sharedStyles(theme),
})

class LinkForm extends Component {

  componentDidMount() {
    const { user, open, linkToken, createLinkToken } = this.props

    if (open && user.id && !linkToken) {
      createLinkToken()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, open, linkToken, createLinkToken } = this.props

    // Only create the link token if the user clicked "Link" from the
    // create button.
    if (open && open !== prevProps.open) {
      if (user.id && !linkToken) {
        createLinkToken()
      }
    }
  }

  handleFormClose = () => this.props.onFormClose()

  render() {
    const { classes, theme, open, linkToken } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="link-form"
                  disableEnforceFocus
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Link Account
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                You can connect your bank to have your transactions
                automagically flow into your budget leaving you to just
                categorize them.
              </Typography>

            </DialogContent>
            <DialogActions>
              {linkToken && <Link linkToken={linkToken} />}
              <Button onClick={this.handleFormClose} color="inherit" variant="outlined">
                <CheckCircle />&nbsp;Done
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
    )
  }
}

LinkForm.propTypes = {
  open: PropTypes.bool.isRequired,
  createLinkToken: PropTypes.func.isRequired,
  onFormClose: PropTypes.func.isRequired,
  linkToken: PropTypes.string
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  linkToken: state.linkReducer.linkToken,
})

const mapDispatchToProps = {
  createLinkToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LinkForm))
