// Budget Month
export const GET_BUDGET_MONTH = 'GET_BUDGET_MONTH'
export const EDIT_BUDGET_MONTH = 'EDIT_BUDGET_MONTH'

// Account Group
export const GET_ACCOUNT_GROUPS = 'GET_ACCOUNT_GROUPS'
export const EDIT_ACCOUNT_GROUPS = 'EDIT_ACCOUNT_GROUPS'
export const REORDER_ACCOUNT_GROUPS = 'REORDER_ACCOUNT_GROUPS'
export const ACCOUNT_GROUPS_LOADING = 'ACCOUNT_GROUPS_LOADING'
export const ACCOUNT_GROUPS_FAIL = 'ACCOUNT_GROUPS_FAIL'

// Transaction
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'
export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION'
export const TRANSACTION_FAIL = 'TRANSACTION_FAIL'
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING'
export const GET_TRANSACTION_ID = 'GET_TRANSACTION_ID'
export const EDIT_TRANSACTION_ID = 'EDIT_TRANSACTION_ID'

// Holdings
export const GET_HOLDINGS = 'GET_HOLDINGS'
export const GET_HOLDING_HISTORY = 'GET_HOLDING_HISTORY'
export const EDIT_HOLDING = 'EDIT_HOLDING'
export const DELETE_HOLDING = 'DELETE_HOLDING'
export const ADD_HOLDING = 'ADD_HOLDING'
export const HOLDING_LOADING = 'HOLDING_LOADING'
export const HOLDING_FAIL = 'HOLDING_FAIL'
export const HOLDING_HISTORY_LOADING = 'HOLDING_HISTORY_LOADING'
export const HOLDING_HISTORY_FAIL = 'HOLDING_HISTORY_FAIL'

export const GET_HOLDING_ID = 'GET_HOLDING_ID'
export const EDIT_HOLDING_ID = 'EDIT_HOLDING_ID'

// Securities
export const GET_SECURITIES = 'GET_SECURITIES'
export const EDIT_SECURITY = 'EDIT_SECURITY'
export const DELETE_SECURITY = 'DELETE_SECURITY'
export const ADD_SECURITY = 'ADD_SECURITY'
export const SECURITY_LOADING = 'SECURITY_LOADING'
export const SECURITY_FAIL = 'SECURITY_FAIL'


// Transaction Flag
export const GET_TRANSACTION_FLAGS = 'GET_TRANSACTION_FLAGS'
export const EDIT_TRANSACTION_FLAGS = 'EDIT_TRANSACTION_FLAGS'
export const TRANSACTION_FLAG_LOADING = 'TRANSACTION_FLAG_LOADING'
export const TRANSACTION_FLAG_FAIL = 'TRANSACTION_FLAG_FAIL'

// Flag Color
export const GET_FLAG_COLORS = 'GET_FLAG_COLORS'
export const FLAG_COLOR_LOADING = 'FLAG_COLOR_LOADING'
export const FLAG_COLOR_FAIL = 'FLAG_COLOR_FAIL'

// Category
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const REORDER_CATEGORIES = 'REORDER_CATEGORIES'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const CATEGORY_LOADING = 'CATEGORY_LOADING'
export const CATEGORY_FAIL = 'CATEGORY_FAIL'
export const GET_CATEGORY_ID = 'GET_CATEGORY_ID'
export const EDIT_CATEGORY_ID = 'EDIT_CATEGORY_ID'

// Category Budget
export const GET_CATEGORY_BUDGETS = 'GET_CATEGORY_BUDGETS'
export const EDIT_CATEGORY_BUDGET = 'EDIT_CATEGORY_BUDGET'
export const CATEGORY_BUDGET_LOADING = 'CATEGORY_BUDGET_LOADING'
export const CATEGORY_BUDGET_FAIL = 'CATEGORY_BUDGET_FAIL'
export const GET_CATEGORY_BUDGET_ID = 'GET_CATEGORY_BUDGET_ID'
export const EDIT_CATEGORY_BUDGET_ID = 'EDIT_CATEGORY_BUDGET_ID'
export const GET_CATEGORY_BALANCE_ID = 'GET_CATEGORY_BALANCE_ID'
export const EDIT_CATEGORY_BALANCE_ID = 'EDIT_CATEGORY_BALANCE_ID'

// Collection
export const GET_COLLECTION_ID = 'GET_COLLECTION_ID'
export const EDIT_COLLECTION_ID = 'EDIT_COLLECTION_ID'
export const GET_COLLECTIONS = 'GET_COLLECTIONS'
export const EDIT_COLLECTION = 'EDIT_COLLECTION'
export const REORDER_COLLECTIONS = 'REORDER_COLLECTIONS'
export const DELETE_COLLECTION = 'DELETE_COLLECTION'
export const ADD_COLLECTION = 'ADD_COLLECTION'
export const COLLECTION_LOADING = 'COLLECTION_LOADING'
export const COLLECTION_FAIL = 'COLLECTION_FAIL'

// Account Type
export const GET_ACCOUNT_TYPES = 'GET_ACCOUNT_TYPES'
export const ACCOUNT_TYPE_LOADING = 'ACCOUNT_TYPE_LOADING'
export const ACCOUNT_TYPE_FAIL = 'ACCOUNT_TYPE_FAIL'

// Payee
export const GET_PAYEES = 'GET_PAYEES'
export const EDIT_PAYEES = 'EDIT_PAYEES'
export const PAYEE_LOADING = 'PAYEE_LOADING'
export const PAYEE_FAIL = 'PAYEE_FAIL'

// Report
export const GET_REPORTS = 'GET_REPORTS'
export const REPORT_LOADING = 'REPORT_LOADING'
export const REPORT_FAIL = 'REPORT_FAIL'

// Transfer
export const GET_TRANSFERS = 'GET_TRANSFERS'
export const TRANSFER_LOADING = 'TRANSFER_LOADING'
export const TRANSFER_FAIL = 'TRANSFER_FAIL'

// Split
export const GET_SPLITS = 'GET_SPLITS'
export const SPLIT_LOADING = 'SPLIT_LOADING'
export const SPLIT_FAIL = 'SPLIT_FAIL'

// Account
export const GET_ACCOUNT_KEY = 'GET_ACCOUNT_KEY'
export const EDIT_ACCOUNT_KEY = 'EDIT_ACCOUNT_KEY'
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT'
export const REORDER_ACCOUNTS = 'REORDER_ACCOUNTS'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING'
export const ACCOUNT_FAIL = 'ACCOUNT_FAIL'
export const GET_ACCOUNT_ID = 'GET_ACCOUNT_ID'
export const EDIT_ACCOUNT_ID = 'EDIT_ACCOUNT_ID'

// Message
export const GET_ERRORS = 'GET_ERRORS'
export const CREATE_TOAST = 'CREATE_TOAST'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

// Auth
export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL'
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT'
export const DELETE_USER_ACCOUNT_FAIL = 'DELETE_USER_ACCOUNT_FAIL'

// Settings
export const GET_PROFILE_SETTINGS = 'GET_PROFILE_SETTINGS'
export const ADD_PROFILE_SETTINGS = 'ADD_PROFILE_SETTINGS'
export const EDIT_PROFILE_SETTINGS = 'EDIT_PROFILE_SETTINGS'
export const PROFILE_SETTINGS_LOADING = 'PROFILE_SETTINGS_LOADING'
export const PROFILE_SETTINGS_FAIL = 'PROFILE_SETTINGS_FAIL'

// Theme
export const GET_THEMES = 'GET_THEMES'
export const THEME_LOADING = 'THEME_LOADING'
export const THEME_FAIL = 'THEME_FAIL'

// Profile
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL'

// Link
export const CREATE_LINK_TOKEN = 'CREATE_LINK_TOKEN'
export const CREATE_LINK_TOKEN_FAIL = 'CREATE_LINK_TOKEN_FAIL'
export const DELETE_LINK_TOKEN_SUCCESS = 'DELETE_LINK_TOKEN_SUCCESS'
export const CREATE_FIX_TOKEN = 'CREATE_FIX_TOKEN'
export const CREATE_FIX_TOKEN_FAIL = 'CREATE_FIX_TOKEN_FAIL'
export const DELETE_FIX_TOKEN_SUCCESS = 'DELETE_FIX_TOKEN_SUCCESS'
export const GET_LINK_ACCOUNTS = 'GET_LINK_ACCOUNTS'
export const LINK_ACCOUNTS_LOADING = 'LINK_ACCOUNTS_LOADING'
export const LINK_ACCOUNTS_FAIL = 'LINK_ACCOUNTS_FAIL'
export const CREATE_LINK_TRANSACTIONS = 'CREATE_LINK_TRANSACTIONS'
export const GET_LINK_TRANSACTIONS = 'GET_LINK_TRANSACTIONS'
export const EDIT_LINK_TRANSACTION = 'EDIT_LINK_TRANSACTION'
export const LINK_TRANSACTIONS_LOADING = 'LINK_TRANSACTIONS_LOADING'
export const LINK_TRANSACTIONS_FAIL = 'LINK_TRANSACTIONS_FAIL'

// Recurring
export const GET_RECURRING_TRANSACTIONS = 'GET_RECURRING_TRANSACTIONS'
export const RECURRING_TRANSACTIONS_LOADING = 'RECURRING_TRANSACTIONS_LOADING'
export const RECURRING_TRANSACTIONS_FAIL = 'RECURRING_TRANSACTIONS_FAIL'

// History Transactions
export const GET_HISTORY_TRANSACTIONS = 'GET_HISTORY_TRANSACTIONS'
export const HISTORY_TRANSACTION_LOADING = 'HISTORY_TRANSACTION_LOADING'
export const HISTORY_TRANSACTION_FAIL = 'HISTORY_TRANSACTION_FAIL'

// Customer
export const GET_CUSTOMER = 'GET_CUSTOMER'
export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION'
export const DELETE_CHECKOUT_SESSION = 'DELETE_CHECKOUT_SESSION'
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING'
export const CREATE_CHECKOUT_SESSION_FAIL = 'CREATE_CHECKOUT_SESSION_FAIL'
