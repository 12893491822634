import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import {
  Box, Typography, List, ListItemText, ListItemButton, Collapse
} from '@mui/material'

import { sharedStyles } from '../common/styles'
import { editTransactionId } from '../../actions/transactionId'
import { formatMoneyLabel } from '../utilities/numbers'
import { formatISODate, months } from '../utilities/dates'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  container: {
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    width: '100%',
    padding: theme.spacing(2)
  },
  cardText: {
    color: theme.palette.secondary.contrastText,
  }
}))

/**
 * Displays the most recent transactions. Can be filtered down to
 * selected categories.
 * @param {String[]} categoryIds - An array of category IDs strings.
 * @param {number} numberSelected - Number of selected categories
 * @returns {JSX.Element}
 * @constructor
 */
const RecentTransactions = ({ categoryIds, numberSelected }) => {
  const { transactions, splits, yearMonth } = withPropsValidation(
      useSelector(({ transactionReducer, splitReducer, budgetMonthReducer }
      ) => ({
        transactions: transactionReducer.transactions,
        splits: splitReducer.splits,
        yearMonth: budgetMonthReducer.yearMonth
      })))

  const [list, setList] = useState([])
  const [month, setMonth] = useState(null)

  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()

  /**
   * Refreshes table when selected categories or transactions change.
   */
  useEffect(() => {
    try {
      let list = []

      if (!Array.isArray(categoryIds) || !categoryIds.length) {
        // array does not exist, is not an array, or is empty
        list = transactions

        setList(list)
      } else {
        // Filter to selected categories.
        for (const categoryId of categoryIds) {
          // Check transactions for matching Ids.
          for (const transaction of transactions) {
            const transactionCategory = `${transaction.category_id}`

            if (categoryId === transactionCategory) list.push(transaction)
          }

          // Check splits for matching Ids.
          for (const split of splits) {
            const splitCategory = `${split.category_id}`

            if (categoryId === splitCategory) {
              // Get matching transaction for split
              const transaction = transactions.find(t => t.id === split.transaction_id)
              if (transaction) {
                let splitTransaction = { ...transaction }
                splitTransaction.amount = split && split.amount ? split.amount : transaction.amount
                list.push(splitTransaction)
              }
            }
          }
        }
      }

      // Grab first 10 records.
      list = list.slice(0, 10)
      setList(list)
    } catch (e) {

    }
  }, [categoryIds, transactions, splits])

  /**
   * Update the display month whenever the month changes. Refreshes
   * table.
   */
  useEffect(() => {
    try {
      let budgetMonth = yearMonth.substring(4, 6)
      const monthIndex = budgetMonth && !isNaN(Number(budgetMonth)) ? Number(budgetMonth) : null
      if (monthIndex) {
        const month = months[monthIndex]

        setMonth(month)
      }

    } catch (e) {

    }
  }, [yearMonth])

  /**
   * This function is used to update the transaction form with
   * row that was clicked on.
   * @param {number} id - The selected transaction Id.
   */
  const transactionClick = (id) => dispatch(editTransactionId(id))

  return (
      <Box marginY={1}>

        {list && list.length > 0
            ? <Typography variant="body1" align="center">{`Recent ${month} Transactions`}</Typography>
            : <Typography variant="body1" align="center">No Recent Transactions</Typography>
        }


        <Collapse in={(list && list.length > 0)}>
          <List sx={{ width: '100%' }}>
            {list.map((row, index) => {
              const id = row && row.id ? row.id : null
              if (!id) return

              const date = row && row.date ? formatISODate(row.date) : ''
              const name = row && row.name ? row.name : ''
              const account = row && row.account ? row.account : ''
              const category = row && row.category ? row.category : ''
              const amount = row && row.amount ? formatMoneyLabel(row.amount) : ''
              return (
                  <ListItemButton key={`recent-transaction-${row.id}-${index}`} dense
                                  onClick={() => transactionClick(row.id)}
                                  sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                  >
                    <ListItemText
                        primary={account}
                        secondary={category}
                        secondaryTypographyProps={{ className: `text-darken-2` }}
                        sx={{
                          textAlign: 'left'
                        }}
                    />
                    <ListItemText
                        primary={name}
                        secondary={amount}
                        secondaryTypographyProps={{ className: `text-darken-2` }}
                        sx={{
                          textAlign: 'right'
                        }}
                    />
                  </ListItemButton>
              )
            })}
          </List>
        </Collapse>
      </Box>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'RecentTransactions')
  return props
}

const propTypes = {
  transactions: PropTypes.array.isRequired,
  splits: PropTypes.array.isRequired,
  yearMonth: PropTypes.string.isRequired,
}

RecentTransactions.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  numberSelected: PropTypes.number.isRequired
}

export default RecentTransactions
