import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import {
  Paper, Grid, Button, Tooltip, TextField, FormLabel, FormGroup
} from '@mui/material'
import { ArrowDropUp, ArrowDropDown, Link, LinkOff } from '@mui/icons-material'
import { DatePicker } from '@mui/lab'
import MUIDataTable from 'mui-datatables'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import clsx from 'clsx'

import AppLoading from '../loading/AppLoading'
import { sharedStyles } from '../common/styles'
import { getHoldingHistory } from '../../actions/holdings'
import { formatISODate } from '../utilities/dates'
import { formatMoneyLabel, isInt } from '../utilities/numbers'
import { Loading } from '../loading/Loading'
import { ROWS_PER_PAGE_OPTIONS, TableHeader } from '../utilities/tables'
import { editHoldingId } from '../../actions/holdingId'
import { returnErrors } from '../../actions/messages'
import ActionButtons from '../layout/ActionButtons'
import AccountForm from '../layout/AccountForm'
import CollectionForm from '../layout/CollectionForm'
import CategoryForm from '../layout/CategoryForm'
import HoldingForm from '../layout/HoldingForm'
import TransactionForm from '../layout/TransactionForm'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  tableChips: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  positiveAmount: {
    background: 'rgba(0, 255, 0, 0.2)',
    color: theme.palette.success.main,
    fontWeight: 500,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}`,
    whiteSpace: 'nowrap'
  },
  negativeAmount: {
    background: 'rgba(255, 0, 0, 0.2)',
    color: theme.palette.error.main,
    fontWeight: 500,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}`,
    whiteSpace: 'nowrap'
  }
}))

/**
 * Displays the history and details about a specific Holding.
 * @returns {JSX.Element}
 * @constructor
 */
const HoldingDetail = () => {
  const { historyIsFetching, history, holdings, accounts } = withPropsValidation(
      useSelector(({ holdingReducer, accountReducer }) => ({
        historyIsFetching: holdingReducer.historyIsFetching,
        holdings: holdingReducer.holdings,
        history: holdingReducer.history,
        accounts: accountReducer.accounts
      })))


  const [holdingId, setHoldingId] = useState(null)
  const [account, setAccount] = useState(null)
  const [currentHolding, setCurrentHolding] = useState(null)
  const [tableData, setTableData] = useState([])
  const [tableOptions, setTableOptions] = useState({})
  const [filterList, setFilterList] = useState([])
  const [startDateOpen, setStartDateOpen] = useState(false)
  const [endDateOpen, setEndDateOpen] = useState(false)

  const params = useParams()
  const optionsRef = useRef()
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  optionsRef.current = tableOptions

  useEffect(() => {
    try {
      const holdingKey = params && params.holdingKey ? params.holdingKey : null
      const holdingId = holdingKey && isInt(holdingKey) ? parseInt(holdingKey) : null
      const currentHolding = holdings.find(h => h.id === holdingId)
      const account = currentHolding ? accounts.find(a => a.id === currentHolding.account_id) : null

      setAccount(account)
      setCurrentHolding(currentHolding)
      setHoldingId(holdingId)
      updateTableData(holdingId, 0, 0, [])
    } catch (e) {
      dispatch(returnErrors({ holding: 'Unable to find holding history. Please try again later.' }, 500))
    }
  }, [params, holdings, accounts])

  useEffect(() => {
    try {
      const count = history && history[0] && history[0].count ? history[0].count : 0
      const page = history && history[0] && history[0].page ? history[0].page : 0
      const rowsPerPage = history && history[0] && history[0].page_size ? history[0].page_size : ROWS_PER_PAGE_OPTIONS[0]


      const displayList = []
      for (const h of history) {
        let institutionValue = h && h.institution_value ? h.institution_value : 0
        institutionValue = numeral(institutionValue)
        let costBasis = h && h.cost_basis ? h.cost_basis : 0
        const profitLoss = institutionValue.subtract(costBasis)
        const shares = h && h.quantity ? h.quantity : 0
        costBasis = numeral(costBasis)
        const averagePrice = shares > 0 ? costBasis.divide(shares) : costBasis
        // If holding is added manually calculate the price (institution_price).
        let institutionPrice = h && h.institution_price ? h.institution_price : 0
        if (!institutionPrice) {
          let marketValue = h && h.institution_value ? h.institution_value : 0
          marketValue = numeral(marketValue)
          institutionPrice = marketValue.divide(shares)
          institutionPrice = institutionPrice.value()
        }

        displayList.push({
              ...h,
              average_price: averagePrice.value(),
              profit_loss: profitLoss.value(),
              institution_price: institutionPrice
            }
        )
      }


      setTableData(displayList)
      setTableOptions({
        count,
        page,
        rowsPerPage,
        responsive: 'vertical',
        selectableRows: 'none',
        elevation: 0,
        rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
        serverSide: true,
        rowHover: true,
        print: false,
        sort: false,
        download: false,
        viewColumns: true,
        confirmFilters: true,
        filter: true,
        search: false,
        searchPlaceholder: 'Search Holding',
        onRowClick: rowData => dispatch(editHoldingId(rowData[0])),
        // Calling the applyNewFilters parameter applies the selected filters to the table
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
            <div style={{ marginTop: theme.spacing(4) }}>
              <Button variant="contained" fullWidth
                      onClick={() => handleFilterSubmit(applyNewFilters)}>
                Apply Filters
              </Button>
            </div>
        ),
        onChangePage: page => updatePage(page),
        onChangeRowsPerPage: numberOfRows => updateRowsPerPage(numberOfRows),
        onFilterChipClose: (index, removedFilter, filterList) => {
          let newFilters = () => (filterList)
          handleFilterSubmit(newFilters)
        },
        setFilterChipProps: (colIndex, colName, data) => {
          return {
            color: 'primary',
            variant: 'contained',
            className: classes.tableChips,
          }
        }
      })
    } catch (e) {

    }
  }, [history])

  /**
   * Sends request for transaction history based on
   * page changing.
   * @param {number} page - The page to display. 0 indexed.
   */
  const updatePage = (page) => {
    const rowsPerPage = optionsRef && optionsRef.current && optionsRef.current.rowsPerPage
        ? optionsRef.current.rowsPerPage
        : ROWS_PER_PAGE_OPTIONS[0]

    updateTableData(holdingId, page, rowsPerPage, filterList)
  }

  /**
   * Sends request for transaction history based on
   * number of rows changing. Reset the current page back to 0.
   * @param {number}  rowsPerPage- The rows per page to display.
   */
  const updateRowsPerPage = (rowsPerPage) => {
    updateTableData(holdingId, 0, rowsPerPage, filterList)
  }

  /**
   * Applies the filters to the table and saves to state. Makes
   * a call to update table data. Reset page back to 0
   * @param {Function} newFilters - Function to create new filters
   */
  const handleFilterSubmit = (newFilters) => {
    let filterList = newFilters()
    setFilterList(filterList)
    const rowsPerPage = optionsRef && optionsRef.current && optionsRef.current.rowsPerPage
        ? optionsRef.current.rowsPerPage
        : ROWS_PER_PAGE_OPTIONS[0]

    updateTableData(holdingId, 0, rowsPerPage, filterList)
  }

  /**
   * Calls the action to make a request to the server for table data
   * based on the passed query params.
   * @param {number} holdingId - The id of the holding we want the
   * history for.
   * @param {number} page - The page of data. 0 indexed.
   * @param {number} rowsPerPage - The size of the page.
   * @param {Array} filterList - The table filters.
   */
  const updateTableData = (holdingId, page, rowsPerPage, filterList) => {
    const startDate = filterList && filterList[1] && filterList[1][0]
        ? DateTime.fromISO(filterList[1][0]).toFormat('yyyyMMdd')
        : null
    const endDate = filterList && filterList[2] && filterList[2][0]
        ? DateTime.fromISO(filterList[2][0]).toFormat('yyyyMMdd')
        : null

    dispatch(getHoldingHistory(holdingId, page, rowsPerPage, startDate, endDate))
  }

  /**
   *  *** WARNING ***
   *  Some columns are indexed based, so if the position of a column
   *  is changed it could break the logic.
   */
  const tableColumns = [{
    label: 'ID', name: 'id',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
      searchable: false,
      download: false
    }
  }, {
    label: 'Start Date', name: 'start_date',
    options: {
      searchable: false,
      display: 'excluded',
      viewColumns: false,
      download: false,
      filter: true,
      filterType: 'custom',
      // filterList: [formatISODate(DateTime.local().plus({ months: -1 }))],
      customFilterListOptions: {
        render: v => {
          if (v[0]) {
            return `Start Date: ${formatISODate(v[0])}`
          }
          return false
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Start Date</FormLabel>
              <FormGroup row>
                <DatePicker open={startDateOpen}
                            onChange={value => {
                              filterList[index] = [value]
                              onChange(filterList[index], index, column)
                            }}
                            value={filterList && filterList[index] && filterList[index][0] ? formatISODate(filterList[index][0]) : null}
                            onClose={() => setStartDateOpen(false)}
                            clearable={false}
                            inputProps={{ readOnly: true }}
                            renderInput={(params) => (
                                <TextField fullWidth
                                           {...params}
                                           onClick={() => setStartDateOpen(true)}
                                />)}
                />
              </FormGroup>
            </div>
        ),
      },
    },
  }, {
    label: 'End Date', name: 'end_date',
    options: {
      searchable: false,
      display: 'excluded',
      viewColumns: false,
      download: false,
      filter: true,
      filterType: 'custom',
      // filterList: [formatISODate(DateTime.local())],
      customFilterListOptions: {
        render: v => {
          if (v[0]) {
            return `End Date: ${formatISODate(v[0])}`
          }
          return false
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>End Date</FormLabel>
              <FormGroup row>
                <DatePicker open={endDateOpen}
                            onChange={value => {
                              filterList[index] = [value]
                              onChange(filterList[index], index, column)
                            }}
                            value={filterList && filterList[index] && filterList[index][0] ? formatISODate(filterList[index][0]) : null}
                            onClose={() => setEndDateOpen(false)}
                            clearable={false}
                            inputProps={{ readOnly: true }}
                            renderInput={(params) => (
                                <TextField fullWidth
                                           {...params}
                                           onClick={() => setEndDateOpen(true)}
                                />)}
                />
              </FormGroup>
            </div>
        ),
      },
    },
  }, {
    label: 'Name', name: 'name',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
      download: false,
      searchable: false,
    }
  }, {
    label: 'Ticker', name: 'ticker_symbol',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const companyName = tableMeta && tableMeta.rowData && tableMeta.rowData[3] ? tableMeta.rowData[3] : ''
        return (
            <Grid container>
              <Grid item xs={12}>
                {companyName}
              </Grid>
              <Grid item xs={12} style={{ fontSize: '12px' }}>
                {value}
              </Grid>
            </Grid>
        )
      },
    }
  }, {
    label: 'Price', name: 'institution_price',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => (
          value && value > 0
              ? formatMoneyLabel(value)
              : '-'
      ),
    }
  }, {
    label: 'Shares', name: 'quantity',
    options: {
      filter: false,
      download: false,
      searchable: false,
    }
  }, {
    label: 'Cost Basis', name: 'cost_basis',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => (
          formatMoneyLabel(value)
      ),
    }
  }, {
    label: 'Value', name: 'institution_value',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => (
          formatMoneyLabel(value)
      ),
    }
  }, {
    label: 'Average Price', name: 'average_price',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => (
          formatMoneyLabel(value)
      ),
    }
  }, {
    label: 'P/L', name: 'profit_loss',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const profitLoss = formatMoneyLabel(value)
        const plStyles = profitLoss.includes('-') ? classes.negativeAmount : classes.positiveAmount
        const plArrow = profitLoss.includes('-')
            ? <ArrowDropDown className={classes.middleAlign} fontSize="small" color="error" />
            : <ArrowDropUp className={classes.middleAlign} fontSize="small" color="success" />
        return (
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  wrap="nowrap"
                  className={plStyles}
            >
              {plArrow}
              {profitLoss}
            </Grid>
        )
      },
    }
  }, {
    label: 'Date', name: 'institution_price_as_of',
    options: {
      filter: false,
      download: false,
      searchable: false,
      customBodyRender: value => (
          <Grid container>
            <Grid item xs={12}>
              {DateTime.fromISO(value).toLocaleString()}
            </Grid>
          </Grid>
      ),
    }
  }, {
    label: 'Linked', name: 'link_account_id',
    options: {
      filter: false,
      download: false,
      searchable: false,
      setCellHeaderProps: () => {
        return {
          style: {
            padding: '4px',
            maxWidth: '60px',
            minWidth: '60px'
          },
          className: clsx({
            [classes.centerTableHead]: true,
          })
        }
      },
      customBodyRender: (value, tableMeta) => {
        return !!value
            ? (
                <Grid>
                  <Tooltip title="Holding Linked">
                    <Link fontSize="16" />
                  </Tooltip>
                </Grid>
            ) : (<Tooltip title="Manual Holding">
                  <LinkOff fontSize="16" />
                </Tooltip>
            )
      },
    },
  }]


  return (
      <AppLoading>
        <Fragment>
          <Paper elevation={4}>
            {historyIsFetching &&
                <Loading />
            }

            <MUIDataTable
                title={
                  <TableHeader
                      title={`${currentHolding && currentHolding.name || ''} - ${(currentHolding && currentHolding.ticker_symbol || '')}`}
                      subtitle={(account && account.name || '')}
                  />
                }
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
          </Paper>
          <ActionButtons />
          <AccountForm />
          <CollectionForm />
          <CategoryForm />
          <HoldingForm />
          <TransactionForm />
        </Fragment>
      </AppLoading>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'HoldingDetail')
  return props
}

const propTypes = {
  historyIsFetching: PropTypes.bool.isRequired,
  holdings: PropTypes.array.isRequired,
  history: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired
}

HoldingDetail.propTypes = {}

export default HoldingDetail
