import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_RECURRING_TRANSACTIONS, RECURRING_TRANSACTIONS_LOADING, RECURRING_TRANSACTIONS_FAIL
} from './types'
import { tokenConfig } from './auth'

const PROXY = process.env.REACT_APP_PROXY


// Get recurring transactions.
export const getRecurringTransactions = () => (dispatch, getState) => {
  dispatch({ type: RECURRING_TRANSACTIONS_LOADING })

  axios.get(`${PROXY}/v1/recurring_transactions/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_RECURRING_TRANSACTIONS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: RECURRING_TRANSACTIONS_FAIL })
      })
}
