import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import {
  Grid, Hidden, ListItem, IconButton, ListItemText, Typography, Tooltip, Checkbox, Button,
  ListItemButton
} from '@mui/material'
import { Link as LinkIcon } from '@mui/icons-material'

import { DragHandle } from '../common/DragHandle'
import { formatMoneyLabel } from '../utilities/numbers'

export const SortableCategories = SortableElement(
    ({
       checkboxChange, checked, categoryClick,
       categoryBudgetClick, categoryBalanceClick,
       category, categoryBudgets, accountCategoryId, classes, theme
     }) => {

      let categoryActivity = category.activity
      let categoryBudgetGoal = 0.00
      let categoryBudgetBudgeted = 0.00
      let categoryBalance = 0.00
      // Get the goal, budgeted, and balance for category.
      for (let categoryBudget of categoryBudgets) {
        if (category.id === categoryBudget.category_id) {
          categoryBudgetGoal = categoryBudget.goal
          categoryBudgetBudgeted = categoryBudget.budgeted
          categoryBalance = categoryBudget.balance
          break
        }
      }
      let goalProgressColor = 'rgba(0, 0, 0, 0.12)'
      let goalProgress = 0
      let categoryBalanceColor = classes.categoryLabel
      let categoryBalanceTitle = 'Your budget is on track!'
      let categoryBudgetedColor = classes.categoryLabel
      let categoryBudgetedTitle = 'You have reached your goal!'

      if (categoryBalance < 0) {
        categoryBalanceColor = classes.fontError
        categoryBalanceTitle = 'You need to budget more money for this category.'
      }

      if (categoryBudgetGoal > categoryBudgetBudgeted) {
        categoryBudgetedColor = classes.fontWarning
        categoryBudgetedTitle = 'Budget more money to reach your goal.'
        goalProgress = Math.floor((categoryBudgetBudgeted / categoryBudgetGoal) * 100)
      }

      if (categoryBudgetBudgeted !== 0) {
        if (goalProgress >= 100 || categoryBudgetBudgeted >= categoryBudgetGoal) goalProgress = 100
      }

      if (goalProgress < 50) {
        goalProgressColor = 'linear-gradient(to right, #F45C43, #FF0000)'
      } else if (goalProgress >= 50 && goalProgress < 100) {
        goalProgressColor = 'linear-gradient(to right, #FFC837, #FFBF00)'
      } else if (goalProgress >= 100) {
        goalProgressColor = 'linear-gradient(to right, #206470, #0f4e5b)'
      }

      goalProgress = `${goalProgress}%`
      const selectedRow = checked ? classes.selectedRow : null

      return (
          <Grid container key={category.id}
                className={`${selectedRow} ${classes.budgetRow} ${classes.dragHandleParent}`}
                direction="row"
                alignItems="center"
          >

            <Grid item xs={4} lg={3} zeroMinWidth>
              <ListItem disableGutters dense className={`${classes.m0} ${classes.p0}`}>
                <IconButton aria-label="drag" size="small" className={classes.dragHandleButton}>
                  <DragHandle classes={classes} />
                  {accountCategoryId &&
                      <LinkIcon fontSize="small" color="primary" className={classes.accountCategoryIcon} />
                  }
                </IconButton>
                <Checkbox
                    checked={checked}
                    name={`${category.id}`}
                    onChange={checkboxChange}
                    size="small"
                    inputProps={{ 'aria-label': 'category checkbox' }}
                />
                <ListItemButton dense disableGutters onClick={categoryClick.bind(this, category.id)}
                                sx={{
                                  padding: '11px 0'
                                }}
                >
                  <ListItemText className={`${classes.m0} ${classes.p0}`}
                                primary={
                                  <Typography noWrap className={classes.categoryLabel}
                                              variant="subtitle2" align="left">
                                    {category.name}
                                  </Typography>
                                }
                  />
                </ListItemButton>
              </ListItem>
            </Grid>

            <Grid item xs={4} lg={3} zeroMinWidth>
              <Tooltip title={categoryBudgetedTitle}>
                <ListItemButton disableGutters dense onClick={categoryBudgetClick.bind(this, category.id)}
                                className={`${classes.m0} ${classes.p0}`}
                >
                  <ListItemText className={`${classes.m0} ${classes.p0}`}
                                primary={
                                  <Typography noWrap className={`${categoryBudgetedColor}`}
                                              variant="subtitle2" align="right">
                                    {formatMoneyLabel(categoryBudgetBudgeted)}
                                  </Typography>
                                }
                                secondary={
                                  <Typography noWrap className={`${classes.categoryLabel}`}
                                              variant="subtitle2" align="right">
                                    {formatMoneyLabel(categoryBudgetGoal)}
                                  </Typography>
                                }
                  />
                </ListItemButton>
              </Tooltip>
            </Grid>

            <Hidden lgUp>
              <Grid item xs={4} zeroMinWidth>
                <Tooltip title={categoryBudgetedTitle}>
                  <ListItem
                      sx={{
                        margin: 0,
                        padding: theme.spacing(1),
                        textAlign: 'right'
                      }}
                  >
                    <ListItemText className={`${classes.m0} ${classes.p0}`}
                                  primary={
                                    <Typography noWrap
                                                className={`${classes.categoryLabel} ${classes.pr1}`}
                                                variant="subtitle2" align="right">
                                      {formatMoneyLabel(categoryActivity)}
                                    </Typography>
                                  }
                                  secondary={
                                    <Button size="small"
                                            color={categoryBalance >= 0
                                                ? categoryBalance > 0
                                                    ? 'success'
                                                    : 'inherit'
                                                : 'error'
                                            }
                                            variant="contained"
                                            onClick={categoryBalanceClick.bind(this, category.id)}
                                            className={classes.p0}
                                    >
                                      {formatMoneyLabel(categoryBalance)}
                                    </Button>
                                  }
                    />
                  </ListItem>
                </Tooltip>
              </Grid>
            </Hidden>

            <Hidden lgDown>
              <Grid item lg={3} zeroMinWidth>
                <ListItem sx={{
                  margin: 0,
                  padding: 0,
                  textAlign: 'right'
                }}
                >
                  <ListItemText primary={
                    <Typography noWrap className={`${classes.categoryLabel}`}
                                variant="subtitle2" align="right">
                      {formatMoneyLabel(categoryActivity)}
                    </Typography>
                  } />
                </ListItem>
              </Grid>
              <Grid item lg={3}>
                <Tooltip title={categoryBalanceTitle}>
                  <ListItem sx={{
                    margin: 0,
                    padding: 0,
                    paddingRight: theme.spacing(1),
                    textAlign: 'right'
                  }}
                  >
                    <ListItemText primary={
                      <Button size="small"
                              color={categoryBalance >= 0
                                  ? categoryBalance > 0
                                      ? 'success'
                                      : 'inherit'
                                  : 'error'
                              }
                              variant="contained"
                              onClick={categoryBalanceClick.bind(this, category.id)}
                              className={classes.p0}
                      >
                        {formatMoneyLabel(categoryBalance)}
                      </Button>
                    } />
                  </ListItem>
                </Tooltip>
              </Grid>
            </Hidden>

            <div className={`${classes.progressBar} `} style={{
              width: goalProgress,
              background: goalProgressColor
            }} />
          </Grid>
      )
    }
)
