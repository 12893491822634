import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  ListItem, ListItemText, ListItemIcon, Typography, ClickAwayListener,
  Grow, Paper, Popper, MenuList, MenuItem, Divider
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AccountBox, Settings, Loyalty, Receipt, Inventory, Link as LinkIcon, Lock
} from '@mui/icons-material'

import { logout } from '../../../actions/auth'

// This gets the topIndex plus 1
const useStyles = makeStyles((theme) => ({
  menuList: {
    zIndex: 10000
  },
  popperIcon: {
    padding: '0 16px'
  },
  selected: {
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '-3px'
  },
  selectedAnchor: {
    borderLeft: '3px solid',
    borderColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light
  }
}))

function ProfileMenu({ user, logout }) {
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState(false)

  const anchorRef = React.useRef(null)
  const { pathname } = useLocation()
  const classes = useStyles()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  /**
   * Close the popper menu.
   * @param {Object} event - The click event.
   */
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  /**
   * Closes the menu and calls logout.
   * @param {Object} event - The event object.
   */
  function handleLogout(event) {
    handleClose(event)
    logout()
  }

  /**
   * return focus to the button when we transitioned from !open -> open
   */
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  /**
   * Check to see if a menu in the profile is selected.
   */
  useEffect(() => {
    if (pathname === '/payees' ||
        pathname === '/archives' ||
        pathname === '/link-accounts' ||
        pathname === '/membership' ||
        pathname === '/settings') {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [pathname])

  return (
      <ListItem button key="Profile" ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={selected ? classes.selectedAnchor : null}
      >
        <ListItemIcon><AccountBox className={selected ? classes.selected : null} /></ListItemIcon>
        <ListItemText primary={
          <Typography variant="subtitle2" noWrap className={selected ? classes.selected : null}>
            {user ? `${user.first_name} ${user.last_name}` : ''}
          </Typography>
        } />
        <Popper open={open}
                anchorEl={anchorRef.current}
                className={classes.menuList}
                placement="bottom"
                transition disablePortal>
          {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <ListItem button key="ManagePayees" to="/payees"
                                component={Link} onClick={handleClose}
                                className={pathname === '/payees' ? classes.selectedAnchor : null}
                      >
                        <ListItemIcon>
                          <Receipt className={pathname === '/payees' ? classes.selected : null} />
                        </ListItemIcon>
                        <ListItemText primary={
                          <Typography variant="subtitle2" noWrap
                                      className={pathname === '/payees' ? classes.selected : null}>
                            Manage Payees
                          </Typography>
                        } />
                      </ListItem>
                      <ListItem button key="Archives" to="/archives"
                                component={Link} onClick={handleClose}
                                className={pathname === '/archives' ? classes.selectedAnchor : null}
                      >
                        <ListItemIcon>
                          <Inventory className={pathname === '/archives' ? classes.selected : null} />
                        </ListItemIcon>
                        <ListItemText primary={
                          <Typography variant="subtitle2" noWrap
                                      className={pathname === '/archives' ? classes.selected : null}
                          >
                            Archives
                          </Typography>
                        } />
                      </ListItem>
                      <ListItem button key="LinkAccounts" to="/link-accounts"
                                component={Link} onClick={handleClose}
                                className={pathname === '/link-accounts' ? classes.selectedAnchor : null}
                      >
                        <ListItemIcon>
                          <LinkIcon className={pathname === '/link-accounts' ? classes.selected : null} />
                        </ListItemIcon>
                        <ListItemText primary={
                          <Typography variant="subtitle2" noWrap
                                      className={pathname === '/link-accounts' ? classes.selected : null}
                          >
                            Link Accounts
                          </Typography>
                        } />
                      </ListItem>
                      <Divider variant="middle" />
                      <ListItem button key="Membership" to="/membership"
                                component={Link} onClick={handleClose}
                                className={pathname === '/membership' ? classes.selectedAnchor : null}
                      >
                        <ListItemIcon>
                          <Loyalty className={pathname === '/membership' ? classes.selected : null} />
                        </ListItemIcon>
                        <ListItemText primary={
                          <Typography variant="subtitle2" noWrap
                                      className={pathname === '/membership' ? classes.selected : null}
                          >
                            Membership
                          </Typography>
                        } />
                      </ListItem>
                      <ListItem button key="Settings" to="/settings"
                                component={Link} onClick={handleClose}
                                className={pathname === '/settings' ? classes.selectedAnchor : null}
                      >
                        <ListItemIcon>
                          <Settings className={pathname === '/settings' ? classes.selected : null} />
                        </ListItemIcon>
                        <ListItemText primary={
                          <Typography variant="subtitle2" noWrap
                                      className={pathname === '/settings' ? classes.selected : null}
                          >
                            Settings
                          </Typography>
                        } />
                      </ListItem>
                      <Divider variant="middle" />
                      <MenuItem onClick={e => handleLogout(e)}>
                        <ListItemIcon><Lock className={classes.icon} /></ListItemIcon>
                        <ListItemText primary={
                          <Typography variant="subtitle2" noWrap>
                            Logout
                          </Typography>
                        } />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
          )}
        </Popper>
      </ListItem>
  )
}

ProfileMenu.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,

}

const mapDispatchToProps = {
  logout,
}

export default connect(null, mapDispatchToProps)(ProfileMenu)
