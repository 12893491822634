import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Box, Typography, Divider, Grid, Button } from '@mui/material'
import { Send } from '@mui/icons-material'

import AuthLayout from './AuthLayout'
import { resendActivationEmail } from '../../actions/auth'
import { sharedStyles } from '../common/styles'

const styles = theme => ({
  ...sharedStyles(theme),
})

class RegisterAcknowledge extends Component {

  onSubmit = e => {
    e.preventDefault()
    const { accountCreated } = this.props

    const normalizedEmail = accountCreated ? accountCreated.toLowerCase() : ''

    this.props.resendActivationEmail(normalizedEmail)
  }

  render() {
    const { classes, isAuthenticated, accountCreated, accountActivated } = this.props

    if (isAuthenticated) return <Navigate to="/" />
    if (accountActivated) return <Navigate to="/login" />

    return (
        <AuthLayout heading="Thank you for signing up!">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                You should receive an email shortly to verify and activate your account. Once your email is verified,
                you will be able to log in.
              </Typography>
            </Grid>
          </Grid>

          {accountCreated &&
              <Box marginY={3}>
                <form onSubmit={this.onSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} align="center">
                      <Typography>
                        Email: <b>{accountCreated}</b>
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Button fullWidth={true} type="submit" color="primary" variant="contained">
                        <Send className={classes.pr1} fontSize="small" />
                        Resend Activation
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
          }

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Note: If you can't find the confirmation email in your inbox, please check your spam folder.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12}
                  justifyContent="center">
              <Link to="/login" className={classes.textLinks}>Login</Link>
            </Grid>

            <Divider />

          </Grid>
        </AuthLayout>
    )
  }
}

RegisterAcknowledge.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  accountActivated: PropTypes.bool.isRequired,
  resendActivationEmail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  accountCreated: state.authReducer.accountCreated,
  accountActivated: state.authReducer.accountActivated
})

const mapDispatchToProps = {
  resendActivationEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterAcknowledge))
