import { GET_CATEGORY_BUDGET_ID, EDIT_CATEGORY_BUDGET_ID } from '../actions/types'

const initialState = {
  categoryBudgetId: -1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_BUDGET_ID:
      return {
        ...state
      }
    case EDIT_CATEGORY_BUDGET_ID:
      return {
        ...state,
        categoryBudgetId: action.payload
      }
    default:
      return state
  }
}