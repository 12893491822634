import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid
} from '@mui/material'
import { Save, Cancel, Delete } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { addCollection, editCollection, deleteCollection } from '../../actions/collections'
import { editCollectionId } from '../../actions/collectionId'
import { fullScreen, Transition } from '../utilities/dialogs'

const styles = theme => ({
  ...sharedStyles(theme),
})

class CollectionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        name: {
          error: null,
          helperText: '',
        }
      },
      open: false,
      name: '',
    }

    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { collectionId, collections } = this.props

    if (collectionId !== prevProps.collectionId && collectionId >= 0) {
      if (collectionId === 0) {
        // New collection record.
        this.setState({ open: true })
      } else if (collectionId > 0) {
        // Update an existing collection record.
        for (let collection of collections) {
          if (collectionId === collection.id) {
            this.setState({
              open: true,
              name: collection.name,
            })

            // End loop
            break
          }
        }
      }
    }
  }

  clearForm = () => {
    this.props.editCollectionId(-1)
    this.setState({
      open: false,
      name: '',
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = e => {
    e.preventDefault()
    const { name } = this.state
    const { user } = this.props.auth
    const { collectionId } = this.props

    if (collectionId > 0) {
      // Edit Collection
      const collection = { id: collectionId, name, user_id: user.id }
      this.props.editCollection(collection)
    } else {
      // Add Collection
      const collection = { name, user_id: user.id }
      this.props.addCollection(collection)
    }

    this.clearForm()
  }

  handleDelete = () => {
    const { collectionId } = this.props

    this.props.deleteCollection(collectionId)
    this.clearForm()
  }

  render() {
    const { open, error, name } = this.state
    const { classes, theme, collectionId } = this.props

    const deleteButton = (
        <Button onClick={this.handleDelete}
                className={`${classes.dangerButton} ${classes.floatRight}`}
                variant="outlined">
          <Delete className={classes.pr1} />Delete
        </Button>
    )

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="collection-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Collection
              {collectionId > 0 ? deleteButton : ''}
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <TextField required
                               name="name"
                               value={name}
                               onChange={this.handleChange}
                               error={error.name.error}
                               helperText={error.name.helperText}
                               autoFocus
                               margin="dense"
                               id="collectionName"
                               label="Collection Name"
                               type="text"
                               fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={this.clearForm} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

CollectionForm.propTypes = {
  addCollection: PropTypes.func.isRequired,
  editCollection: PropTypes.func.isRequired,
  deleteCollection: PropTypes.func.isRequired,
  editCollectionId: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  collections: PropTypes.array.isRequired,
  collectionId: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  collectionId: state.collectionIdReducer.collectionId,
  collections: state.collectionReducer.collections,
})

const mapDispatchToProps = {
  addCollection,
  editCollection,
  deleteCollection,
  editCollectionId,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CollectionForm))
