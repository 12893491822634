import { GET_HOLDING_ID, EDIT_HOLDING_ID } from '../actions/types'

const initialState = {
  holdingId: -1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOLDING_ID:
      return {
        ...state
      }
    case EDIT_HOLDING_ID:
      return {
        ...state,
        holdingId: action.payload
      }
    default:
      return state
  }
}
