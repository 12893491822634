import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import {
  Drawer, Button, Divider, Box, Grid, Card, Toolbar, Typography,
  List, ListItemButton, ListItemText, Collapse
} from '@mui/material'
import numeral from 'numeral'

import { sharedStyles } from '../common/styles'
import { editCategoryId } from '../../actions/categoryId'
import { formatMoneyLabel } from '../utilities/numbers'
import ProgressCircle from '../common/ProgressCircle'
import CompareActivityBar from '../common/CompareActivityBar'
import RecentTransactions from './RecentTransactions'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  container: {
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    width: '100%',
    padding: theme.spacing(2)
  },
  cardText: {
    color: theme.palette.secondary.contrastText,
  }
}))

/**
 * Populates the detail card with category details based on the
 * selection of the user.
 * @param {String[]} categoryIds - An array of category IDs strings.
 * @param {number} budgetedSummed
 * @param {number} goalSummed
 * @param {number} totalGoalProgress
 * @param {number} activitySummed
 * @param {number} previousActivity
 * @param {number} balanceSummed
 * @param {number} fundShort
 * @param {number} overspent
 * @param {number} numberSelected
 * @param {number} overbudget
 * @param {function} actionClick
 * @returns {JSX.Element}
 * @constructor
 */
const CategoryDetail = ({
                          categoryIds, budgetedSummed, goalSummed, totalGoalProgress, activitySummed,
                          previousActivity, balanceSummed, fundShort, overspent, numberSelected,
                          overbudget, actionClick
                        }) => {
  const {
    categories
  } = withPropsValidation(useSelector(({ categoryReducer, categoryBudgetReducer }) => ({
    categories: categoryReducer.categories
  })))

  const [halfBudget, setHalfBudget] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()


  /**
   * Calculate the half budget amount everytime the goal or budgeted
   * changes.
   */
  useEffect(() => {
    try {
      let halfFund = numeral(0)
      let goal = goalSummed
      let halfBudget = goal ? numeral(goal).divide(2) : numeral(0)
      halfBudget = halfBudget.value()

      if (halfBudget !== budgetedSummed) {
        halfFund = numeral(halfBudget).subtract(budgetedSummed)
      }

      halfFund = halfFund.value()
      setHalfBudget(halfFund)
    } catch (e) {
    }
  }, [goalSummed, budgetedSummed])

  /**
   * If the categories or the selected categories changes update the
   * selected category. We are only setting if a single category is
   * selected.
   */
  useEffect(() => {
    try {
      // Array does not exist, is not an array, or is empty
      if (!Array.isArray(categoryIds) || !categoryIds.length) return false
      // Array has more than 1 record
      if (categoryIds && categoryIds.length > 1) return false

      const categoryId = categoryIds[0]
      const selectedCategory = categories.find(c => `${c.id}` === categoryId)
      if (selectedCategory) setSelectedCategory(selectedCategory)
    } catch (e) {
    }
  }, [categories, categoryIds])

  /**
   * This function is used to open the category edit form.
   * @param {number} id - The selected category Id.
   */
  const categoryClick = (id) => dispatch(editCategoryId(id))

  return (
      <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            width: '264px',
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: '264px',
              boxSizing: 'border-box'
            },
          }}
      >

        <Toolbar />
        <Box marginY={1}>
          <Divider />
        </Box>

        <Box
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1)
            }}
        >
          <Box
              sx={{
                overflow: 'scroll',
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                height: 'calc(100vh - 160px)'
              }}
          >
            <Grid container spacing={1}>

              <Collapse in={(numberSelected === 1 && selectedCategory ? true : false)}
                        sx={{ width: '100%', marginLeft: theme.spacing(1), paddingBottom: 0 }}
              >
                <List>
                  <ListItemButton dense onClick={() => categoryClick(selectedCategory.id)}>
                    <ListItemText
                        primary={selectedCategory ? selectedCategory.name : ''}
                        secondary={selectedCategory ? selectedCategory.notes : ''}
                        secondaryTypographyProps={{ className: `text-darken-2` }}
                    />
                  </ListItemButton>
                </List>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Collapse>

              <Collapse in={(numberSelected > 1 ? true : false)}
                        sx={{ width: '100%', marginLeft: theme.spacing(1), paddingBottom: 0 }}
              >
                <List>
                  <ListItemText primary={`Categories Selected (${numberSelected})`} />
                </List>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Collapse>

              <Grid container item direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" align="left" noWrap>Fund Short</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="right" noWrap>{formatMoneyLabel(fundShort)}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" align="left" noWrap>Overspent</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="right" noWrap>{formatMoneyLabel(overspent)}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" align="left" noWrap>Overbudget</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="right" noWrap>{formatMoneyLabel(overbudget)}</Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Collapse in={(numberSelected >= 1 ? true : false)}
                        sx={{
                          width: '100%',
                          marginLeft: theme.spacing(1),
                          marginTop: theme.spacing(1)
                        }}
              >

                <Typography variant="body1" align="center">Quick Actions</Typography>

                <Grid container spacing={1}>

                  <Grid container direction="row" item xs={12}>
                    <Button fullWidth color="primary" variant="contained"
                            aria-label="Set the budgeted to zero dollars"
                            onClick={(e) => actionClick(e, 'empty_fund')}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="left"
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        textTransform: 'capitalize'
                                      }}
                          >Zero Budget</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="right" noWrap
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400'
                                      }}
                          >{formatMoneyLabel(budgetedSummed)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" align="left"
                                      sx={{
                                        fontSize: '0.5',
                                        fontWeight: '300',
                                        textTransform: 'capitalize'
                                      }}
                          >Clear budgeted dollars.</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>

                  <Grid container direction="row" item xs={12}>
                    <Button fullWidth color="primary" variant="contained"
                            aria-label="Fund half of the goal"
                            onClick={(e) => actionClick(e, 'half_fund')}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="left"
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        textTransform: 'capitalize'
                                      }}
                          >Budget Half</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="right" noWrap
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                      }}
                          >{formatMoneyLabel(-Math.abs(halfBudget))}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" align="left"
                                      sx={{
                                        fontSize: '0.5',
                                        fontWeight: '300',
                                        textTransform: 'capitalize'
                                      }}
                          >Fund half of the goal.</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>

                  <Grid container direction="row" item xs={12}>
                    <Button fullWidth color="primary" variant="contained"
                            aria-label="Fund the total goal"
                            onClick={(e) => actionClick(e, 'full_fund')}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="left"
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        textTransform: 'capitalize'
                                      }}
                          >Budget Full</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="right" noWrap
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400'
                                      }}
                          >{formatMoneyLabel(-Math.abs(fundShort))}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" align="left"
                                      sx={{
                                        fontSize: '0.5',
                                        fontWeight: '300',
                                        textTransform: 'capitalize'
                                      }}
                          >Fund the total goal.</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>

                  <Grid container direction="row" item xs={12}>
                    <Button fullWidth color="primary" variant="contained"
                            aria-label="Clear the balance"
                            onClick={(e) => actionClick(e, 'zero_balance')}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="left"
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        textTransform: 'capitalize'
                                      }}
                          >Zero Balance</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" align="right" noWrap
                                      sx={{
                                        fontSize: '0.875rem',
                                        fontWeight: '400'
                                      }}
                          >{formatMoneyLabel(balanceSummed)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" align="left"
                                      sx={{
                                        fontSize: '0.5',
                                        fontWeight: '300',
                                        textTransform: 'capitalize'
                                      }}
                          >Clear the balance.</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                </Grid>
              </Collapse>

              <Grid item xs={12}>
                <Card className={classes.container}>
                  <Grid container>

                    <Grid item xs={6}
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                    >
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>Budgeted</Typography>
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>Goal</Typography>
                    </Grid>

                    <Grid item xs={6}
                          container
                          direction="column"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                    >
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>
                        {formatMoneyLabel(budgetedSummed)}
                      </Typography>
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>
                        {formatMoneyLabel(goalSummed)}
                      </Typography>
                    </Grid>
                  </Grid>


                  <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                  >
                    <ProgressCircle value={totalGoalProgress}
                                    size={80}
                                    thickness={6}
                                    showLabel
                                    color="info"
                    />
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card className={classes.container}>
                  <Grid container>

                    <Grid item xs={6}
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                    >
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>Last Month</Typography>
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>Current</Typography>
                    </Grid>

                    <Grid item xs={6}
                          container
                          direction="column"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                    >
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>
                        {formatMoneyLabel(previousActivity)}
                      </Typography>
                      <Typography variant="subtitle2" noWrap className={classes.cardText}>
                        {formatMoneyLabel(activitySummed)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                  >
                    <CompareActivityBar previous={previousActivity} current={activitySummed} />
                  </Grid>

                </Card>
              </Grid>

              <Grid item xs={12}>
                <RecentTransactions categoryIds={categoryIds} numberSelected={numberSelected} />
              </Grid>

            </Grid>
          </Box>
        </Box>
        <Divider />
      </Drawer>


  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'CategoryDetail')
  return props
}

const propTypes = {
  categories: PropTypes.array.isRequired
}

CategoryDetail.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  budgetedSummed: PropTypes.number.isRequired,
  goalSummed: PropTypes.number.isRequired,
  totalGoalProgress: PropTypes.number.isRequired,
  activitySummed: PropTypes.number.isRequired,
  previousActivity: PropTypes.number.isRequired,
  balanceSummed: PropTypes.number.isRequired,
  fundShort: PropTypes.number.isRequired,
  overspent: PropTypes.number.isRequired,
  overbudget: PropTypes.number.isRequired,
  actionClick: PropTypes.func.isRequired
}

export default CategoryDetail
