import React, { forwardRef } from 'react'
import { List, ListItem, ListItemText, Typography, Hidden } from '@mui/material'

const MonthPickerInput = (props, ref) => {
  const { onClick, value, dwp } = props

  return (
      <List disablePadding onClick={onClick}>
        <ListItem button dense disableGutters>
          <ListItemText
              primary={<Typography align="center" variant="h6" noWrap>
                {value}
              </Typography>}
              secondary={<Typography align="center" variant="subtitle2" noWrap>
                <Hidden smUp>
                  DWP:&nbsp;
                </Hidden>
                <Hidden smDown>
                  Days without paycheck:&nbsp;
                </Hidden>
                {dwp > 0 ? dwp : '???'}
              </Typography>} />
        </ListItem>
      </List>
  )
}

export default forwardRef(MonthPickerInput)
