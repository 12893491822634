import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { sharedStyles } from './styles'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  label: {
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightBold
  }
}))

/**
 * Builds a progress circle
 * @param props
 * @param {string} props.color - The color of the chart.
 * @param {number} props.value - The value of the progress 0-100.
 * @param {number} props.size - The size of the circle.
 * @param {number} props.thickness - The thickness of the circle.
 * @param {boolean} props.showLabel - Show the percent label in the
 * center of the circle.
 * @returns {JSX.Element}
 * @constructor
 */
function ProgressCircle(props) {
  const chartProps = { ...props }
  delete chartProps.showLabel
  const classes = useStyles()

  return (
      <Box sx={{
        verticalAlign: 'middle',
        position: 'relative',
        display: 'inline-flex'
      }}
      >
        <CircularProgress
            color={props.color}
            size={props.size}
            thickness={props.thickness}
            variant="determinate"
            {...chartProps}
        />
        <Box sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          {props.showLabel &&
              <Typography variant="subtitle2" noWrap className={classes.label}>
                {`${Math.round(props.value)}%`}
              </Typography>
          }

        </Box>
      </Box>
  )
}

ProgressCircle.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  showLabel: PropTypes.bool.isRequired
}

export default ProgressCircle
