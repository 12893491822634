import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import HistoryTransactionList from './HistoryTransactionList'


/**
 * Dashboard for the transaction history page.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Dashboard = () => {

  return (
      <Fragment>
        <HistoryTransactionList />
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'Dashboard')
  return props
}

const propTypes = {}

export default Dashboard
