import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_COLLECTIONS, EDIT_COLLECTION, DELETE_COLLECTION, ADD_COLLECTION,
  REORDER_COLLECTIONS, COLLECTION_LOADING, COLLECTION_FAIL
} from './types'
import { tokenConfig } from './auth'
import { getCategories } from './categories'

const proxy = process.env.REACT_APP_PROXY

// Get Collections
export const getCollections = (yearMonth) => (dispatch, getState) => {
  dispatch({ type: COLLECTION_LOADING })
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/collections/?m=${mParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_COLLECTIONS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: COLLECTION_FAIL })
      })
}

// Edit Collection
export const editCollection = (collection) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/collections/${collection.id}/`, collection, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCollection: 'Collection Updated' }))
        dispatch({
          type: EDIT_COLLECTION,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Reorder the Collections
export const reorderCollections = (collection, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.put(`${proxy}/v1/collections/reorder/${collection.id}/`, collection, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCollection: 'Collections Updated' }))
        dispatch({
          type: REORDER_COLLECTIONS,
          payload: res.data
        })
        dispatch(getCollections(mParm))
        dispatch(getCategories(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Delete Collection
export const deleteCollection = (id) => (dispatch, getState) => {
  axios.delete(`${proxy}/v1/collections/${id}/`, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ deleteCollection: 'Collection Deleted' }))
        dispatch({
          type: DELETE_COLLECTION,
          payload: id
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Add Collection
export const addCollection = (collection) => (dispatch, getState) => {
  axios.post(`${proxy}/v1/collections/`, collection, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ addCollection: 'Collection Added' }))
        dispatch({
          type: ADD_COLLECTION,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
