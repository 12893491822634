import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import {
  Box, Paper, Typography, Grid, ListItemText, Button,
  FormControl, MenuItem, Checkbox, InputLabel, Select, Tooltip, TextField,
  FormLabel, FormGroup
} from '@mui/material'
import { DatePicker } from '@mui/lab'
import MUIDataTable, { debounceSearchRender } from 'mui-datatables'
import { Verified, NewReleases, Inventory } from '@mui/icons-material'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { sharedStyles } from '../common/styles'
import { ROWS_PER_PAGE_OPTIONS } from '../utilities/tables'
import { editTransactionId } from '../../actions/transactionId'
import { downloadTransactionHistory, getTransactionHistory } from '../../actions/history'
import { Loading } from '../loading/Loading'
import { formatISODate } from '../utilities/dates'
import { formatMoneyLabel } from '../utilities/numbers'


const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  tableChips: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  tableAttributeContainer: {
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    color: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5)
  },
  positiveAmount: {
    color: theme.palette.success.main,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  negativeAmount: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
}))

const HistoryTransactionList = () => {
  const {
    transactions,
    flagColors,
    transactionFlags,
    isFetching,
    accounts,
    categories
  } = withPropsValidation(
      useSelector(({
                     historyReducer,
                     flagColorReducer,
                     transactionFlagReducer,
                     accountReducer,
                     categoryReducer
                   }) => ({
        transactions: historyReducer.transactions,
        isFetching: historyReducer.isFetching,
        flagColors: flagColorReducer.flagColors,
        transactionFlags: transactionFlagReducer.transactionFlags,
        accounts: accountReducer.accounts,
        categories: categoryReducer.categories
      })))

  const [searchText, setSearchText] = useState('')
  const [filterList, setFilterList] = useState([])
  const [startDateOpen, setStartDateOpen] = useState(false)
  const [endDateOpen, setEndDateOpen] = useState(false)
  const [accountOptions, setAccountOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [flagOptions, setFlagOptions] = useState([])
  const [tableData, setTableData] = useState([])
  const [tableOptions, setTableOptions] = useState({})

  const optionsRef = useRef()
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  optionsRef.current = tableOptions

  /**
   * Page load and if transactions change.
   */
  useEffect(() => {
    updateTable()
  }, [transactions])

  /**
   * Update the account options for filtering.
   */
  useEffect(() => {
    const accountOptions = []

    for (const account of accounts) {
      accountOptions.push({
        id: account.id,
        name: account.name
      })
    }

    setAccountOptions(accountOptions)
  }, [accounts])

  /**
   * Update the category options for filtering.
   */
  useEffect(() => {
    const categoryOptions = []

    for (const category of categories) {
      let categoryName = category && category.name ? category.name : ''
      categoryName = categoryName.trim().toLowerCase()

      if (categoryName === 'split' || categoryName === '') continue

      categoryOptions.push({
        id: category.id,
        name: category.name
      })
    }

    setCategoryOptions(categoryOptions)
  }, [categories])


  /**
   * Update the table with the new transaction data.
   */
  const updateTable = () => {
    try {
      const count = transactions && transactions[0] && transactions[0].count ? transactions[0].count : 0
      const page = transactions && transactions[0] && transactions[0].page ? transactions[0].page : 0
      const rowsPerPage = transactions && transactions[0] && transactions[0].page_size ? transactions[0].page_size : ROWS_PER_PAGE_OPTIONS[0]
      const flagOptions = []

      // Get the users flag colors and labels.
      for (const flagColor of flagColors) {
        for (const transactionFlag of transactionFlags) {
          if (flagColor.id === transactionFlag.color_id) {
            flagOptions.push({
              id: transactionFlag.id,
              colorId: flagColor.id,
              color: flagColor.name,
              name: transactionFlag.name
            })
          }
        }
      }

      setFlagOptions(flagOptions)
      setTableData(transactions)
      setTableOptions({
        count,
        page,
        rowsPerPage,
        responsive: 'vertical',
        selectableRows: 'none',
        elevation: 0,
        rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
        serverSide: true,
        rowHover: false,
        print: false,
        sort: false,
        download: true,
        viewColumns: true,
        confirmFilters: true,
        search: true,
        searchText: searchText,
        searchPlaceholder: 'Search Transaction History',
        customSearchRender: debounceSearchRender(500),
        downloadOptions: {
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true
          }
        },
        // Calling the applyNewFilters parameter applies the selected filters to the table
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
            <div style={{ marginTop: theme.spacing(4) }}>
              <Button variant="contained" fullWidth
                      onClick={() => handleFilterSubmit(applyNewFilters)}>
                Apply Filters
              </Button>
            </div>
        ),
        // onRowClick: rowData => transactionClick(rowData)
        onDownload: (buildHead, buildBody, columns, data) => {
          const displayColumns = columns.map(c => c.name)
          downloadHistoryTransactions(displayColumns, searchText, filterList)
          return false
        },
        onSearchChange: searchText => searchTransactions(searchText),
        onSearchClose: () => resetSearchTransactions(),
        onChangePage: page => updatePage(page),
        onChangeRowsPerPage: numberOfRows => updateRowsPerPage(numberOfRows),
        onFilterChipClose: (index, removedFilter, filterList) => {
          let newFilters = () => (filterList)
          handleFilterSubmit(newFilters)
        },
        setFilterChipProps: (colIndex, colName, data) => {
          return {
            color: 'primary',
            variant: 'contained',
            className: classes.tableChips,
          }
        }
      })

    } catch (e) {
      // console.log({ error: e })
    }
  }


  /**
   * Gets attributes about the current record set to display in
   * the toolbar.
   * @param {Object} data - The display data for the table.
   * @returns {JSX.Element}
   */
  const TableToolbar = ({ data }) => {
    let sum = 0
    let min = 0
    let max = 0
    let average = 0
    const displayRow = data && data[0] ? data[0] : null
    if (displayRow) {
      sum = displayRow.sum ? displayRow.sum : 0
      min = displayRow.min ? displayRow.min : 0
      max = displayRow.max ? displayRow.max : 0
      average = displayRow.average ? displayRow.average : 0
    }

    return (
        <Grid container>

          <Grid item xs={6} md={3}>
            <ListItemText disableTypography
                          className={classes.tableAttributeContainer}
                          primary={
                            <Typography align="center" variant="body2" noWrap>
                              {formatMoneyLabel(sum)}
                            </Typography>
                          }
                          secondary={
                            <Typography align="center" variant="subtitle2" noWrap>
                              Sum
                            </Typography>
                          }
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <ListItemText disableTypography
                          className={classes.tableAttributeContainer}
                          primary={
                            <Typography align="center" variant="body2" noWrap>
                              {formatMoneyLabel(average)}
                            </Typography>
                          }
                          secondary={
                            <Typography align="center" variant="subtitle2" noWrap>
                              Average
                            </Typography>
                          }
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <ListItemText disableTypography
                          className={classes.tableAttributeContainer}
                          primary={
                            <Typography align="center" variant="body2" noWrap>
                              {formatMoneyLabel(min)}
                            </Typography>
                          }
                          secondary={
                            <Typography align="center" variant="subtitle2" noWrap>
                              Min
                            </Typography>
                          }
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <ListItemText disableTypography
                          className={classes.tableAttributeContainer}
                          primary={
                            <Typography align="center" variant="body2" noWrap>
                              {formatMoneyLabel(max)}
                            </Typography>
                          }
                          secondary={
                            <Typography align="center" variant="subtitle2" noWrap>
                              Max
                            </Typography>
                          }
            />
          </Grid>

        </Grid>
    )
  }


  /**
   * This function is used to update the transaction form with
   * row that was clicked on.
   * @param {Array} rowData - The link transaction actioned.
   */
  const transactionClick = rowData => dispatch(editTransactionId(rowData[0]))

  /**
   * Sends request for transaction history based on
   * number of rows changing. Reset the current page back to 0.
   * @param {number}  rowsPerPage- The rows per page to display.
   */
  const updateRowsPerPage = (rowsPerPage) => {
    const query = searchText && searchText.length > 2 ? searchText : ''

    updateTableData(0, rowsPerPage, query, filterList)
  }

  /**
   * Sends request for transaction history based on
   * page changing.
   * @param {number} page - The page to display. 0 indexed.
   */
  const updatePage = (page) => {
    const rowsPerPage = optionsRef && optionsRef.current && optionsRef.current.rowsPerPage
        ? optionsRef.current.rowsPerPage
        : ROWS_PER_PAGE_OPTIONS[0]
    const query = searchText && searchText.length > 2 ? searchText : ''

    updateTableData(page, rowsPerPage, query, filterList)
  }

  /**
   * Search the transaction history based on the passed query string.
   * We reset the page number and rows per page for the search.
   * Don't search until 3 or more characters.
   * @param {string} query - The query to search against.
   */
  const searchTransactions = query => {
    setSearchText(query)

    if (query && query.length > 2) {
      updateTableData(0, 0, query, filterList)
    }
  }

  /**
   * Handles the user closing the search.
   */
  const resetSearchTransactions = () => updateTableData(0, 0, '', filterList)

  /**
   * Applies the filters to the table and saves to state. Makes
   * a call to update table data. Reset page back to 0
   * @param {Function} newFilters - Function to create new filters
   */
  const handleFilterSubmit = (newFilters) => {
    let filterList = newFilters()
    setFilterList(filterList)
    const rowsPerPage = optionsRef && optionsRef.current && optionsRef.current.rowsPerPage
        ? optionsRef.current.rowsPerPage
        : ROWS_PER_PAGE_OPTIONS[0]
    const query = searchText && searchText.length > 2 ? searchText : ''
    updateTableData(0, rowsPerPage, query, filterList)
  }


  /**
   * Calls the action to make a request to the server for table data
   * based on the passed query params.
   * *** *** WARNING *** ***
   * All of the table columns and filters are index based.
   * @param {number} page - The page of data. 0 indexed.
   * @param {number} rowsPerPage - The size of the page.
   * @param {string} query - A search query to filter on.
   * @param {Array} filterList - The table filters.
   */
  const updateTableData = (page, rowsPerPage, query, filterList) => {
    try {
      const startDate = filterList && filterList[2] && filterList[2][0]
          ? DateTime.fromISO(filterList[2][0]).toFormat('yyyyMMdd')
          : null
      const endDate = filterList && filterList[3] && filterList[3][0]
          ? DateTime.fromISO(filterList[3][0]).toFormat('yyyyMMdd')
          : null
      const flags = filterList && filterList[5] && filterList[5].length > 0
          ? filterList[5].toString()
          : null
      const payee = filterList && filterList[6] && filterList[6][0]
          ? filterList[6][0]
          : null
      const filterAccounts = filterList && filterList[7] && filterList[7].length > 0
          ? filterList[7].toString()
          : null
      const filterCategories = filterList && filterList[8] && filterList[8].length > 0
          ? filterList[8].toString()
          : null
      const posted = filterList && filterList[11]
          ? filterList[11][0]
          : null
      const archived = filterList && filterList[12]
          ? filterList[12][0]
          : null

      dispatch(getTransactionHistory(page, rowsPerPage, query, startDate, endDate, flags, payee, filterAccounts, filterCategories, posted, archived))
    } catch (e) {

    }
  }


  /**
   * Downloads history transaction data to CSV file.
   * *** *** WARNING *** ***
   * All of the table columns and filters are index based.
   * @param {Array} columns - A list of columns to download
   * @param {string} query - A search query to filter on.
   * @param {Array} filterList - The table filters.
   */
  const downloadHistoryTransactions = (columns, query, filterList) => {
    try {
      const startDate = filterList && filterList[2] && filterList[2][0]
          ? DateTime.fromISO(filterList[2][0]).toFormat('yyyyMMdd')
          : null
      const endDate = filterList && filterList[3] && filterList[3][0]
          ? DateTime.fromISO(filterList[3][0]).toFormat('yyyyMMdd')
          : null
      const flags = filterList && filterList[5] && filterList[5].length > 0
          ? filterList[5].toString()
          : null
      const payee = filterList && filterList[6] && filterList[6][0]
          ? filterList[6][0]
          : null
      const filterAccounts = filterList && filterList[7] && filterList[7].length > 0
          ? filterList[7].toString()
          : null
      const filterCategories = filterList && filterList[8] && filterList[8].length > 0
          ? filterList[8].toString()
          : null
      const posted = filterList && filterList[11]
          ? filterList[11][0]
          : null
      const archived = filterList && filterList[12]
          ? filterList[12][0]
          : null

      dispatch(downloadTransactionHistory(columns, query, startDate, endDate, flags, payee, filterAccounts, filterCategories, posted, archived))
    } catch (e) {

    }
  }

  /**
   *  *** WARNING ***
   *  Some columns are indexed based, so if the position of a column
   *  is changed it could break the logic.
   */
  const tableColumns = [{
    label: 'ID', name: 'id',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
      searchable: false,
      download: false
    }
  }, {
    label: 'Flag Color', name: 'flag_color',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
      searchable: false,
      download: false
    }
  }, {
    label: 'Start Date', name: 'start_date',
    options: {
      searchable: false,
      display: 'excluded',
      viewColumns: false,
      download: false,
      filter: true,
      filterType: 'custom',
      // filterList: [formatISODate(DateTime.local().plus({ months: -1 }))],
      customFilterListOptions: {
        render: v => {
          if (v[0]) {
            return `Start Date: ${formatISODate(v[0])}`
          }
          return false
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Start Date</FormLabel>
              <FormGroup row>
                <DatePicker open={startDateOpen}
                            onChange={value => {
                              filterList[index] = [value]
                              onChange(filterList[index], index, column)
                            }}
                            value={filterList && filterList[index] && filterList[index][0] ? formatISODate(filterList[index][0]) : null}
                            onClose={() => setStartDateOpen(false)}
                            clearable={false}
                            inputProps={{ readOnly: true }}
                            renderInput={(params) => (
                                <TextField fullWidth
                                           {...params}
                                           onClick={() => setStartDateOpen(true)}
                                />)}
                />
              </FormGroup>
            </div>
        ),
      },
    },
  }, {
    label: 'End Date', name: 'end_date',
    options: {
      searchable: false,
      display: 'excluded',
      viewColumns: false,
      download: false,
      filter: true,
      filterType: 'custom',
      // filterList: [formatISODate(DateTime.local())],
      customFilterListOptions: {
        render: v => {
          if (v[0]) {
            return `End Date: ${formatISODate(v[0])}`
          }
          return false
        },
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>End Date</FormLabel>
              <FormGroup row>
                <DatePicker open={endDateOpen}
                            onChange={value => {
                              filterList[index] = [value]
                              onChange(filterList[index], index, column)
                            }}
                            value={filterList && filterList[index] && filterList[index][0] ? formatISODate(filterList[index][0]) : null}
                            onClose={() => setEndDateOpen(false)}
                            clearable={false}
                            inputProps={{ readOnly: true }}
                            renderInput={(params) => (
                                <TextField fullWidth
                                           {...params}
                                           onClick={() => setEndDateOpen(true)}
                                />)}
                />
              </FormGroup>
            </div>
        ),
      },
    },
  }, {
    label: 'Date', name: 'date',
    options: {
      filter: false,
      download: true,
      searchable: false,
      customBodyRender: value => formatISODate(value),
    }
  }, {
    label: 'Flag', name: 'flag_name', searchable: true,
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const flagColor = tableMeta && tableMeta.rowData && tableMeta.rowData[1]
            ? tableMeta.rowData[1]
            : theme.palette.divider
        return (
            <Box className={classes.tableLabel}
                 style={{
                   border: `1px solid ${flagColor}`,
                   color: flagColor
                 }}
            >
              <Typography variant="caption" align="center" noWrap>
                {value}
              </Typography>
            </Box>
        )
      },
      filter: true,
      filterType: 'custom',
      customFilterListOptions: {
        render: v => {
          const selectedColors = []

          for (const flag of flagOptions) {
            if (v.includes(flag.color)) {
              const name = flag && flag.name ? flag.name.trim() : ''
              if (name) {
                selectedColors.push(name)
              } else {
                selectedColors.push(flag.color)
              }
            }
          }

          return selectedColors
        },
        update: (filterList, filterPos, index) => {
          filterList[index].splice(filterPos, 1)
          return filterList
        }
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <FormControl variant="standard">
              <InputLabel>
                Flag
              </InputLabel>
              <Select multiple
                      value={filterList[index]}
                      renderValue={selected => {
                        const selectedColors = []

                        for (const flag of flagOptions) {
                          if (selected.includes(flag.color)) {
                            const name = flag && flag.name ? flag.name.trim() : ''
                            if (name) {
                              selectedColors.push(name)
                            } else {
                              selectedColors.push(flag.color)
                            }
                          }
                        }

                        return selectedColors.join(', ')
                      }}
                      onChange={event => {
                        filterList[index] = event.target.value
                        onChange(filterList[index], index, column)
                      }}
              >
                {flagOptions.map(item => (
                        <MenuItem key={item.id} value={item.color}>
                          <Checkbox
                              checked={filterList[index].indexOf(item.color) > -1}
                              sx={{
                                color: item.color,
                                '&.Mui-checked': {
                                  color: item.color,
                                },
                              }}
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
        )
      }
    }
  }, {
    label: 'Payee', name: 'name', searchable: true,
    options: {
      filter: true,
      filterType: 'textField'
    }
  }, {
    label: 'Account', name: 'account', searchable: true,
    options: {
      filter: true,
      filterType: 'custom',
      customFilterListOptions: {
        render: v => {
          const selectedAccounts = []

          for (const account of accountOptions) {
            if (v.includes(account.id)) {
              const name = account && account.name ? account.name.trim() : ''
              selectedAccounts.push(name)
            }
          }

          return selectedAccounts.join(', ')
        },
        update: (filterList, filterPos, index) => {
          filterList[index].splice(filterPos, 1)
          return filterList
        }
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <FormControl variant="standard">
              <InputLabel>
                Account
              </InputLabel>
              <Select multiple
                      value={filterList[index]}
                      renderValue={selected => {
                        const selectedAccounts = []

                        for (const account of accountOptions) {
                          if (selected.includes(account.id)) {
                            const name = account && account.name ? account.name.trim() : ''
                            selectedAccounts.push(name)
                          }
                        }

                        return selectedAccounts.join(', ')
                      }}
                      onChange={event => {
                        filterList[index] = event.target.value
                        onChange(filterList[index], index, column)
                      }}
              >
                {accountOptions.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={filterList[index].indexOf(item.id) > -1} />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
        )
      }
    }
  }, {
    label: 'Category', name: 'category', searchable: true,
    options: {
      filter: true,
      filterType: 'custom',
      customFilterListOptions: {
        render: v => {
          const selectedCategories = []

          for (const category of categoryOptions) {
            if (v.includes(category.id)) {
              const name = category && category.name ? category.name.trim() : ''
              selectedCategories.push(name)
            }
          }

          return selectedCategories.join(', ')
        },
        update: (filterList, filterPos, index) => {
          filterList[index].splice(filterPos, 1)
          return filterList
        }
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => (
            <FormControl variant="standard">
              <InputLabel>
                Category
              </InputLabel>
              <Select multiple
                      value={filterList[index]}
                      renderValue={selected => {
                        const selectedCategories = []

                        for (const category of categoryOptions) {
                          if (selected.includes(category.id)) {
                            const name = category && category.name ? category.name.trim() : ''
                            selectedCategories.push(name)
                          }
                        }

                        return selectedCategories.join(', ')
                      }}
                      onChange={event => {
                        filterList[index] = event.target.value
                        onChange(filterList[index], index, column)
                      }}
              >
                {categoryOptions.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={filterList[index].indexOf(item.id) > -1} />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
        )
      }
    }
  }, {
    label: 'Notes', name: 'notes', searchable: true,
    options: {
      filter: false,
    }
  }, {
    label: 'Amount', name: 'amount', searchable: true,
    options: {
      setCellHeaderProps: () => {
        return {
          className: clsx({
            [classes.rightTableHead]: true,
          })
        }
      },
      setCellProps: value => {
        return {
          style: {
            textAlign: 'right'
          },
        }
      },
      customBodyRender: value => {
        const transactionAmount = value ? formatMoneyLabel(value) : '???'
        return (
            <Grid container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  wrap="nowrap"
                  className={transactionAmount && transactionAmount.includes('-') ? classes.negativeAmount : classes.positiveAmount}
            >
              {transactionAmount}
            </Grid>
        )
      },
      filter: false,
    }
  }, {
    label: 'Posted', name: 'reconciled', searchable: false,
    options: {
      setCellHeaderProps: () => {
        return {
          style: {
            padding: '4px',
            maxWidth: '60px',
            minWidth: '60px'
          },
          className: clsx({
            [classes.centerTableHead]: true,
          })
        }
      },
      setCellProps: value => {
        return {
          style: {
            textAlign: 'center',
            padding: '4px'
          },
          className: clsx({
            [classes.positiveAmount]: value === true,
            [classes.negativeAmount]: value === false,
          })
        }
      },
      customBodyRender: (value, tableMeta) => {
        const archived = tableMeta && tableMeta.rowData && tableMeta.rowData[12]
            ? tableMeta.rowData[12]
            : false
        return value
            ? archived ? (
                <Grid>
                  <Tooltip title="Transaction Posted">
                    <Verified fontSize="16" color="success" />
                  </Tooltip>
                  <Tooltip title="Transaction Archived">
                    <Inventory fontSize="16" color="error" />
                  </Tooltip>
                </Grid>
            ) : (<Tooltip title="Transaction Posted">
                  <Verified fontSize="16" color="success" />
                </Tooltip>
            )
            : archived ? (
                <Grid>
                  <Tooltip title="Transaction Pending">
                    <NewReleases fontSize="16" color="disabled" />
                  </Tooltip>
                  <Tooltip title="Transaction Archived">
                    <Inventory fontSize="16" color="error" />
                  </Tooltip>
                </Grid>
            ) : (
                <Tooltip title="Transaction Pending">
                  <NewReleases fontSize="16" color="disabled" />
                </Tooltip>
            )
      },
      filter: true,
      filterType: 'checkbox',
      filterOptions: {
        names: [true, false],
        renderValue: v => v
            ? (
                <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                  Posted
                </Typography>
            )
            : (
                <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                  Not Posted
                </Typography>
            ),
      },
      customFilterListOptions: {
        render: v => {
          if (v === false) return 'Not Posted'
          else if (v === true) return 'Posted'
        }
      },
    },
  }, {
    label: 'Archived', name: 'archived',
    options: {
      display: 'excluded',
      viewColumns: false,
      searchable: false,
      filter: true,
      filterType: 'checkbox',
      filterOptions: {
        names: [true, false],
        renderValue: v => v
            ? (
                <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                  Archived
                </Typography>
            )
            : (
                <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                  Not Archived
                </Typography>
            ),
      }, customFilterListOptions: {
        render: v => {
          if (v === false) return 'Not Archived'
          else if (v === true) return 'Archived'
        }
      },
    }
  }]

  return (
      <Paper elevation={4}>
        {isFetching &&
            <Loading />
        }

        <TableToolbar data={tableData} />

        <MUIDataTable
            title={<Typography variant="h6">Transaction History</Typography>}
            data={tableData}
            columns={tableColumns}
            options={tableOptions}
        />
      </Paper>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'HistoryTransactionList')
  return props
}

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  transactions: PropTypes.array.isRequired,
  flagColors: PropTypes.array.isRequired,
  transactionFlags: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired
}

HistoryTransactionList.propTypes = {
  editTransactionId: PropTypes.func,
  downloadTransactionHistory: PropTypes.func
}

export default HistoryTransactionList
