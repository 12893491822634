import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { TextField, Divider, Button, Grid, Link as Anchor } from '@mui/material'
import { LockOpen } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { login } from '../../actions/auth'
import AuthLayout from './AuthLayout'

const styles = theme => ({
  ...sharedStyles(theme),
})

class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onSubmit = e => {
    e.preventDefault()
    const { email, password } = this.state
    const normalizedEmail = email ? email.toLowerCase() : ''

    this.props.login(normalizedEmail, password)
  }

  render() {
    if (this.props.isAuthenticated) return <Navigate to="/" />

    const { email, password } = this.state
    const { classes } = this.props
    return (
        <AuthLayout heading="Login">
          <form onSubmit={this.onSubmit}>
            <Grid container
                  spacing={3}>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={email}
                           name="email"
                           type="email"
                           label="Email"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={password}
                           name="password"
                           label="Password"
                           type="password"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth={true} type="submit" color="primary" variant="contained">
                  <LockOpen className={classes.pr1} fontSize="small" />Log In
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Link to="/reset-password" className={classes.textLinks}>
                  Forgot Password
                </Link>
                &nbsp;&#183;&nbsp;
                <Link to="/register" className={classes.textLinks}>
                  Need Account
                </Link>
              </Grid>

              <Grid container item xs={12} justifyContent="center">
                <Anchor color="inherit" underline="hover" target="_blank" href="https://www.ploutosbudget.com">
                  Learn More
                </Anchor>
              </Grid>

              <Divider />
            </Grid>
          </form>
        </AuthLayout>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated
})

const mapDispatchToProps = {
  login
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
