import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import { Typography, Grid, Paper, Box } from '@mui/material'
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement,
  Title, Tooltip, Legend, Filler
} from 'chart.js'
import { Insights, ArrowDropUp, ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Line } from 'react-chartjs-2'
import numeral from 'numeral'
import { DateTime } from 'luxon'

import { sharedStyles } from '../common/styles'
import { Loading } from '../loading/Loading'
import { verticalLine, backgroundColors, borderColors } from '../chart/charts'
import { formatMoneyLabel, formatPercentLabel } from '../utilities/numbers'
import { getReports } from '../../actions/reports'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  noDataIcon: {
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light,
  },
  spendingText: {
    color: borderColors[1],
    fontWeight: theme.typography.fontWeightBold
  },
  lastMonthText: {
    color: borderColors[0],
    fontWeight: theme.typography.fontWeightBold
  },
  middleAlign: {
    verticalAlign: 'middle'
  },
  gridSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  }
}))

const SpendingVsLastMonth = () => {
  const { yearMonth, reports, isFetching } = withPropsValidation(
      useSelector(({ budgetMonthReducer, reportReducer }) => ({
        yearMonth: budgetMonthReducer.yearMonth,
        reports: reportReducer.reports,
        isFetching: reportReducer.isFetching,
      })))

  const [data, setData] = useState({
    labels: [],
    previousMonth: [],
    currentMonth: [],
    showChart: false
  })
  const [spendingDate, setSpendingDate] = useState('')
  const [spendingDifference, setSpendingDifference] = useState(0)
  const [previousValue, setPreviousValue] = useState(0)
  const [currentValue, setCurrentValue] = useState(0)

  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()

  /**
   * Fetch report data on load
   */
  useEffect(() => {
    dispatch(getReports(yearMonth))
  }, [yearMonth])

  /**
   * Get data to build the spending chart.
   */
  useEffect(() => {
    try {
      if (reports && Object.keys(reports).length > 0) {

        const spendingReport = reports && reports.spending_last_month_report ? reports.spending_last_month_report : null
        const previousMonthReport = spendingReport && spendingReport.previous_month ? spendingReport.previous_month : []
        const currentMonthReport = spendingReport && spendingReport.current_month ? spendingReport.current_month : []
        const currentBudgetYear = DateTime.local().toFormat('yyyy')
        const currentBudgetMonth = DateTime.local().toFormat('M')
        const isCurrentMonth = yearMonth === `${currentBudgetYear}${currentBudgetMonth}`

        // Get the previous month labels and add 1 month
        const previousMonthLabels = previousMonthReport.map((report) => DateTime.fromISO(report.date).plus({ months: 1 }).toLocaleString({
          month: 'long',
          day: 'numeric'
        }))
        // Get the current month labels
        const currentMonthLabels = currentMonthReport.map(report => DateTime.fromISO(report.date).toLocaleString({
          month: 'long',
          day: 'numeric'
        }))
        /**
         * If on the current month. Create a label for today in case
         * none currently exist. If not current month we will grab
         * the latest date.
         */
        const currentDateLabel = isCurrentMonth ? [DateTime.local().toLocaleString({
              month: 'long',
              day: 'numeric'
            })]
            : null
        // Combine the label sets
        const monthLabels = isCurrentMonth
            ? [...new Set([...previousMonthLabels, ...currentMonthLabels, ...currentDateLabel])]
            : [...new Set([...previousMonthLabels, ...currentMonthLabels])]
        // Order the array
        const labels = monthLabels.sort((a, b) => new Date(a) - new Date(b))
        const currentLabel = currentDateLabel && currentDateLabel[0] ? currentDateLabel[0] : null
        const currentSpendingDate = currentLabel && labels && labels.length > 0 ? currentLabel : labels[labels.length - 1]

        // Get previous month spending data
        let previousMonth = []
        for (const label of labels) {
          let found = false
          for (const report of previousMonthReport) {
            const date = DateTime.fromISO(report.date).plus({ months: 1 }).toLocaleString({
              month: 'long',
              day: 'numeric'
            })
            if (label === date) {
              previousMonth.push(report.total)
              found = true
            }
          }
          // If no record found for this date grab previous record
          if (!found) {
            if (previousMonth.length <= 0) {
              previousMonth.push(0)
            } else {
              const lastIndex = previousMonth.length - 1
              const previousValue = previousMonth[lastIndex]
              previousMonth.push(previousValue)
            }
          }
        }

        // Get current month spending data
        let currentMonth = []
        for (const label of labels) {
          let found = false
          for (const report of currentMonthReport) {
            const date = DateTime.fromISO(report.date).toLocaleString({
              month: 'long',
              day: 'numeric'
            })
            if (label === date) {
              currentMonth.push(report.total)
              found = true
            }
          }
          // If no record found for this date grab previous record
          if (!found) {
            if (currentMonth.length <= 0) {
              currentMonth.push(0)
            } else {
              const lastIndex = currentMonth.length - 1
              const previousValue = currentMonth[lastIndex]
              currentMonth.push(previousValue)
            }
          }
        }

        // Logic to hide chart until data is ready.
        let showChart = true
        if ((!previousMonth || (previousMonth && previousMonth.length <= 0))
            && (!currentMonth || (currentMonth && currentMonth.length <= 0))) {
          showChart = false
        }

        // Get the default current and previous spending to display.
        previousMonth = previousMonth && previousMonth.length > 0 ? previousMonth : [0]
        currentMonth = currentMonth && currentMonth.length > 0 ? currentMonth : [0]

        // Get the current metrics to display on load
        let currentLabelIndex = labels.indexOf(currentSpendingDate)
        currentLabelIndex = currentLabelIndex && currentLabelIndex >= 0 ? currentLabelIndex : 0
        const currentPreviousValue = previousMonth && previousMonth[currentLabelIndex] ? previousMonth[currentLabelIndex] : 0
        const currentCurrentValue = currentMonth && currentMonth[currentLabelIndex] ? currentMonth[currentLabelIndex] : 0
        const currentSpendingDifference = getSpendingDifference(currentPreviousValue, currentCurrentValue)

        setData({
          labels,
          previousMonth,
          currentMonth,
          showChart
        })
        setSpendingDate(currentSpendingDate)
        setSpendingDifference(currentSpendingDifference)
        setPreviousValue(currentPreviousValue)
        setCurrentValue(currentCurrentValue)
      }

    } catch (e) {
      // console.log(e)
    }

  }, [reports])

  /**
   * Chart data to build the chart.
   * @type {{datasets: [{backgroundColor: string, borderColor: string, data: ([]|*[]|*), borderWidth: number, label: string, fill: string},{backgroundColor: string, borderColor: string, data: ([]|*[]|*), borderWidth: number, label: string, fill: string}], labels: ([]|NodeListOf<HTMLLabelElement>|NodeList|*)}}
   */
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: 'Spending',
        data: data.currentMonth,
        backgroundColor: backgroundColors[1],
        borderColor: borderColors[1],
        borderWidth: 2,
        fill: 'start',
      },
      {
        label: 'Last Month',
        data: data.previousMonth,
        backgroundColor: backgroundColors[0],
        borderColor: borderColors[0],
        borderWidth: 2,
        fill: 'start',
      }
    ],
  }

  /**
   * Chart options
   * @type {{tension: number, plugins: {tooltip: {backgroundColor: string, callbacks: {label: options.plugins.tooltip.callbacks.label, title: options.plugins.tooltip.callbacks.title}, enabled: boolean}, title: {display: boolean, text: string}}, responsive: boolean, elements: {point: {radius: number}}, scales: {x: {ticks: {maxRotation: number, callback: ((function(*, *, *): (*|null))|*), minRotation: number}}, y: {ticks: {callback: (function(*, *, *): string)}}}, interaction: {mode: string, intersect: boolean}}}
   */
  const options = {
    plugins: {
      title: {
        display: false,
        text: 'Spending',
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'transparent',
        callbacks: {
          title: (xDatapoint) => {
            const currentValue = xDatapoint && xDatapoint[0] && xDatapoint[0].raw && xDatapoint[0].dataset && xDatapoint[0].dataset.label === 'Spending'
                ? xDatapoint[0].raw
                : 0
            // If current spending is hidden then last month could be the index 0.
            let previousValue = xDatapoint && xDatapoint[1] && xDatapoint[1].raw && xDatapoint[1].dataset && xDatapoint[1].dataset.label === 'Last Month'
                ? xDatapoint[1].raw
                : null
            if (!previousValue) {
              previousValue = xDatapoint && xDatapoint[0] && xDatapoint[0].raw && xDatapoint[0].dataset && xDatapoint[0].dataset.label === 'Last Month'
                  ? xDatapoint[0].raw
                  : 0
            }
            const spendingDifference = getSpendingDifference(previousValue, currentValue)
            setCurrentValue(currentValue)
            setPreviousValue(previousValue)
            setSpendingDifference(spendingDifference)
          },
          label: (yDatapoint) => {
            setSpendingDate(yDatapoint.label)
          },
        }
      }
    },
    tension: 0.1,
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          callback: function (value, index, ticks) {
            if (index % 3 === 0) return data.labels[index]
            return null
          },
        },
      },
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            return formatMoneyLabel(value)
          },
        },
      },
    },
  }

  /**
   * Get the current spending difference.
   * @param {number} previousValue - The previous spending value.
   * @param {number} currentValue - The current spending value.
   * @returns {number} - The difference.
   */
  const getSpendingDifference = (previousValue, currentValue) => numeral(previousValue).subtract(currentValue).value()

  /**
   * Builds the spending difference jsx elements for display.
   * @returns {JSX.Element}
   */
  const SpendingDifference = () => {
    try {
      let changeClass = null
      let arrowIcon = <ArrowRight className={classes.middleAlign} fontSize="medium" />
      if (spendingDifference > 0) {
        changeClass = classes.positiveStats
        arrowIcon = <ArrowDropDown className={classes.middleAlign} fontSize="medium" />
      } else if (spendingDifference < 0) {
        changeClass = classes.negativeStats
        arrowIcon = <ArrowDropUp className={classes.middleAlign} fontSize="medium" />
      }
      const percentChange = (currentValue - previousValue) / Math.abs(previousValue)
      const absolutePercentChange = Math.abs(percentChange)
      const absoluteSpendingDifference = Math.abs(spendingDifference)

      return (
          <Grid container item
                className={changeClass}
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
          >
            {arrowIcon}
            <Typography variant="body1">{formatMoneyLabel(absoluteSpendingDifference)}</Typography>
            {!isNaN(absolutePercentChange) && isFinite(absolutePercentChange)
                ? <Typography variant="body1">&nbsp;({formatPercentLabel(absolutePercentChange)})</Typography>
                : null
            }
          </Grid>
      )
    } catch (e) {
    }
  }

  return isFetching
      ? (
          <Box mt={1} mb={2}>
            <Loading />
          </Box>
      )
      : (
          <Fragment>
            <Paper elevation={3} className={classes.gridSpacing}>
              {data && data.showChart
                  ? (
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={6} align="left">
                          <Typography variant="body1" className={classes.spendingText}>
                            {formatMoneyLabel(currentValue)}
                          </Typography>
                          <Typography variant="body1">
                            {spendingDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} align="right">
                          <Typography variant="body1" className={classes.lastMonthText} align="center">
                            {formatMoneyLabel(previousValue)}
                          </Typography>
                          <SpendingDifference />
                        </Grid>
                        <Grid item xs={12}>
                          <Line options={options} plugins={[verticalLine]} data={chartData} />
                        </Grid>
                      </Grid>
                  ) : (
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                          <Insights fontSize="large" className={classes.noDataIcon} />
                        </Grid>
                        <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                          <Typography variant="h6">No spending data yet. </Typography>
                        </Grid>
                      </Grid>
                  )

              }
            </Paper>
          </Fragment>
      )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'SpendingVsLastMonth')
  return props
}

const propTypes = {
  yearMonth: PropTypes.string.isRequired,
  reports: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired
}

export default SpendingVsLastMonth
