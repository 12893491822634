import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import withStyles from '@mui/styles/withStyles'
import {
  Button,
  Link,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Divider
} from '@mui/material'
import { Loyalty, Today, CardGiftcard, Lock } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { createCheckoutSession, createCustomerPortal } from '../../actions/membership'
import { logout } from '../../actions/auth'

const styles = theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main
  },
  descriptionContainer: {
    marginTop: theme.spacing(4)
  },
  description: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.primary.main
  },
  descriptionIcon: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 48,
    width: 48,
    borderRadius: 100,
    fontSize: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ...sharedStyles(theme)
})

function Membership({
  classes,
  customer,
  createCheckoutSession,
  createCustomerPortal,
  checkoutSessionURL,
  logout
}) {
  const monthlyMembershipPriceId = process.env.REACT_APP_MONTHLY_MEMBERSHIP_PRICE_ID
  const annualMembershipPriceId = process.env.REACT_APP_ANNUAL_MEMBERSHIP_PRICE_ID
  const currentDate = DateTime.local().toISODate()
  const checkoutSession = priceId => createCheckoutSession(priceId)
  const customerSince =
    customer && customer[0] && customer[0].created
      ? DateTime.fromISO(customer[0].created).toLocaleString()
      : null

  // Get membership and subscription information
  let membershipActive = true
  let subscriptions = ''
  let subscriptionKey = ''
  let subscription = ''
  let subscriptionStatus = ''
  let subscriptionPeriodEnd = ''
  let subscriptionStore = ''
  try {
    const subscriber =
      customer && customer[0] && customer[0].subscriber ? customer[0].subscriber : null
    const member =
      subscriber && subscriber.subscriber && subscriber.subscriber.entitlements.member
        ? subscriber.subscriber.entitlements.member
        : null
    if (!member || Object.keys(member).length <= 0 || member.expires_date < currentDate)
      membershipActive = false
    if (membershipActive) {
      // Active membership
      subscriptions =
        subscriber && subscriber.subscriber && subscriber.subscriber.subscriptions
          ? subscriber.subscriber.subscriptions
          : null
      subscriptionKey = member && member.product_identifier ? member.product_identifier : null
      subscription = subscriptions && subscriptionKey ? subscriptions[subscriptionKey] : null
      subscriptionStatus = subscription && subscription.period_type ? subscription.period_type : ''
      if (subscriptionStatus && subscriptionStatus === 'normal') subscriptionStatus = 'Active'
      else if (subscriptionStatus && subscriptionStatus === 'trial')
        subscriptionStatus = 'Free Trial'
      subscriptionPeriodEnd =
        subscription && subscription.expires_date
          ? DateTime.fromISO(subscription.expires_date).toLocaleString()
          : ''
      subscriptionStore = subscription && subscription.store ? subscription.store : null
      if (subscriptionStore && subscriptionStore === 'app_store') subscriptionStore = 'App Store'
      else if (subscriptionStore && subscriptionStore === 'play_store')
        subscriptionStore = 'Play Store'
    }
  } catch (error) {
    // TODO: handle error
    membershipActive = false
  }

  useEffect(() => {
    if (checkoutSessionURL) window.open(checkoutSessionURL, '_self')
  }, [checkoutSessionURL])

  /**
   * Logout the user.
   * @param {Object} event - The event object.
   */
  function handleLogout(event) {
    logout()
  }

  return (
    <Fragment>
      {membershipActive ? (
        <Grid container>
          <Grid item xs={12}>
            <Card raised={true}>
              <CardContent>
                <CardHeader
                  title="Member"
                  subheader={customerSince ? `Since ${customerSince}` : ''}
                  titleTypographyProps={{ className: classes.title }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <Typography>
                      <b>Status:</b>&nbsp;{subscriptionStatus}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography>
                      <b>Period Ends:</b>&nbsp;{subscriptionPeriodEnd}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} align="center">
                    {subscriptionStore && subscriptionStore === 'stripe' ? (
                      <Button
                        fullWidth={true}
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => createCustomerPortal()}
                      >
                        <Loyalty className={classes.pr1} />
                        Manage Membership
                      </Button>
                    ) : (
                      <Typography>
                        Your membership was purchased through the {subscriptionStore} and can only
                        be managed there.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} align="center" className={classes.mb1}>
            <img
              alt="Ploutos Budget logo"
              height={80}
              src="/static/ploutos.svg"
              style={{ marginTop: -32 }}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <CardContent>
              <Grid item xs={12} align="center">
                <Typography variant="h5" component="h1" className={classes.title}>
                  How your free trial works
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                align="center"
                container
                direction="row"
                className={classes.descriptionContainer}
              >
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid className={classes.descriptionIcon}>
                    <Today fontSize="inherit" color="inherit" />
                  </Grid>
                </Grid>

                <Grid item className={classes.description} xs={7}>
                  <Typography variant="body1" noWrap align="left" className={classes.title}>
                    Today
                  </Typography>
                  <Typography variant="body1" align="left">
                    Free for one month
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                align="center"
                container
                direction="row"
                className={classes.descriptionContainer}
              >
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid className={classes.descriptionIcon}>
                    <CardGiftcard fontSize="inherit" color="inherit" />
                  </Grid>
                </Grid>

                <Grid item className={classes.description} xs={7}>
                  <Typography variant="body1" noWrap align="left" className={classes.title}>
                    Enjoy Free Trial
                  </Typography>
                  <Typography variant="body1" align="left">
                    Enjoy all of the features. You can cancel anytime during the one month free
                    trial right inside the application.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                align="center"
                container
                direction="row"
                className={classes.descriptionContainer}
              >
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid className={classes.descriptionIcon}>
                    <Loyalty fontSize="inherit" color="inherit" />
                  </Grid>
                </Grid>

                <Grid item className={classes.description} xs={7}>
                  <Typography variant="body1" noWrap align="left" className={classes.title}>
                    In 1 Month
                  </Typography>
                  <Typography variant="body1" align="left">
                    Your subscription starts and you will be charged based on the plan you selected.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card raised={true}>
              <CardContent>
                <CardHeader
                  title="Annual Membership"
                  subheader="Save $35.89"
                  titleTypographyProps={{ className: classes.title }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <Typography>$59.99/year</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography>After 1 month free trial</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth={true}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={() => checkoutSession(annualMembershipPriceId)}
                    >
                      <Loyalty className={classes.pr1} />
                      Select this plan
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card raised={true}>
              <CardContent>
                <CardHeader
                  title="Monthly Membership"
                  subheader="&nbsp;"
                  titleTypographyProps={{ className: classes.title }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} align="center">
                    <Typography>$7.99/month</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography>After 1 month free trial</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth={true}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={() => checkoutSession(monthlyMembershipPriceId)}
                    >
                      <Loyalty className={classes.pr1} />
                      Select this plan
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Box mt={5}>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={9}>
                <Typography align="center">
                  After 1 month free trial, cancel anytime. Subscriptions will automatically renew
                  unless canceled at least 24 hours before each renewal date.
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography align="center">
                  <Link
                    title="Terms of Use"
                    underline="always"
                    target="_blank"
                    href="https://www.ploutosbudget.com/terms/"
                  >
                    Terms of Use
                  </Link>
                  &nbsp;&nbsp;•&nbsp;&nbsp;
                  <Link
                    title="Privacy Policy"
                    underline="always"
                    target="_blank"
                    href="https://www.ploutosbudget.com/privacy/"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>

              <Grid item xs={6} align="center">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={e => handleLogout(e)}
                >
                  <Lock className={classes.pr1} />
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Fragment>
  )
}

Membership.propTypes = {
  createCheckoutSession: PropTypes.func.isRequired,
  createCustomerPortal: PropTypes.func.isRequired,
  checkoutSessionURL: PropTypes.string,
  customer: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  customer: state.membershipReducer.customer,
  checkoutSessionURL: state.membershipReducer.checkoutSessionURL
})

const mapDispatchToProps = {
  createCheckoutSession,
  createCustomerPortal,
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Membership))
