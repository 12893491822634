import {
  GET_SPLITS, SPLIT_LOADING, SPLIT_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  splits: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SPLITS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        splits: action.payload
      }
    case SPLIT_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case SPLIT_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}