import { GET_TRANSACTION_ID, EDIT_TRANSACTION_ID } from '../actions/types'

const initialState = {
  transactionId: -1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_ID:
      return {
        ...state
      }
    case EDIT_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload
      }
    default:
      return state
  }
}