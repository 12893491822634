import './index.css'
import 'regenerator-runtime/runtime.js'
import './components/App'


window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
        .then(function (registration) {
          // Successful registration
          registration.update() // Immediately update
        }).catch(function (error) {
      // Failed registration, service worker won’t be installed
      // console.log({ error })
    })
  }
})
