import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_TRANSACTIONS, EDIT_TRANSACTION, DELETE_TRANSACTION, ADD_TRANSACTION,
  TRANSACTION_LOADING, TRANSACTION_FAIL
} from './types'
import { getAccounts } from './accounts'
import { getCategories } from './categories'
import { getCategoryBudgets } from './categoryBudgets'
import { getLinkTransactions } from './link'
import { getPayees } from './payees'
import { getTransfers } from './transfers'
import { getSplits } from './splits'
import { getAccountGroups } from './accountGroups'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get Transactions
export const getTransactions = (yearMonth, filter) => (dispatch, getState) => {
  dispatch({ type: TRANSACTION_LOADING })
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/transactions/?m=${mParm}&f=${fParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_TRANSACTIONS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: TRANSACTION_FAIL })
      })
}

// Edit Transaction
export const editTransaction = (transaction, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter
  axios.put(`${proxy}/v1/transactions/${transaction.id}/`, transaction, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateTransaction: 'Transaction Updated' }))
        dispatch({
          type: EDIT_TRANSACTION,
          payload: res.data
        })
        dispatch(getTransactions(mParm, fParm))
        dispatch(getAccounts())
        dispatch(getCategories(mParm))
        dispatch(getCategoryBudgets(mParm))
        dispatch(getLinkTransactions())
        dispatch(getPayees())
        dispatch(getTransfers())
        dispatch(getSplits())
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Delete Transaction
export const deleteTransaction = (id, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter
  axios.delete(`${proxy}/v1/transactions/${id}/`, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ deleteTransaction: 'Transaction Deleted' }))
        dispatch({
          type: DELETE_TRANSACTION,
          payload: id
        })
        dispatch(getAccounts())
        dispatch(getCategories(mParm))
        dispatch(getCategoryBudgets(mParm))
        dispatch(getLinkTransactions())
        dispatch(getTransactions(mParm, fParm))
        dispatch(getPayees())
        dispatch(getTransfers())
        dispatch(getSplits())
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

/**
 * Add a transaction
 * @param transaction - The transaction form body to add.
 * @param yearMonth (optional) - If you want to add a yearMonth to fetching
 * @param filter (optional) - If you want to add a filter to fetching
 * categories and transactions on a successful transaction add.
 * @returns dispatch ADD_TRANSACTION reducer. Re-fetch Accounts,
 * Categories, and Transactions.
 */
export const addTransaction = (transaction, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter
  axios.post(`${proxy}/v1/transactions/`, transaction, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ addTransaction: 'Transaction Added' }))
        dispatch({
          type: ADD_TRANSACTION,
          payload: res.data
        })
        dispatch(getAccounts())
        dispatch(getCategories(mParm))
        dispatch(getCategoryBudgets(mParm))
        dispatch(getLinkTransactions())
        dispatch(getTransactions(mParm, fParm))
        dispatch(getPayees())
        dispatch(getTransfers())
        dispatch(getSplits())
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
