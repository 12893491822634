import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Grid, Box, Card, CardContent, Typography, Hidden, ListItem, ListItemText, Checkbox, ListItemButton, Button
} from '@mui/material'
import {
  Flag, PieChart, ShoppingBasket, MonetizationOn, UnfoldLess, UnfoldMore, AllInclusive, ErrorOutline, WarningAmber
} from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'
import Collections from '../collection/Collections'

const styles = theme => ({
  ...sharedStyles(theme),
})

/**
 * Displays the budget screen.
 */
class Budget extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expandCollections: [],
      checkAll: false,
      checkAllIndeterminate: false,
      collectionOptions: [],
      categoryOptions: [],
      categoryFilter: 0,
      collectionsChecked: [],
      categoriesChecked: []
    }

    this.setCollectionCategoryOptions = this.setCollectionCategoryOptions.bind(this)
    this.collectionClick = this.collectionClick.bind(this)
    this.handleCheckAllCheckbox = this.handleCheckAllCheckbox.bind(this)
    this.handleCollectionCheckbox = this.handleCollectionCheckbox.bind(this)
    this.handleCategoryCheckbox = this.handleCategoryCheckbox.bind(this)
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this)
  }

  componentDidMount() {
    const { collections, categories } = this.props

    if (collections) {
      const expandCollections = collections.map(c => c.id)
      this.setState({ expandCollections })
    }

    if (categories) {
      this.setCollectionCategoryOptions()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { collections, categories } = this.props
    const { categoryOptions, categoryFilter, categoriesChecked } = this.state

    if ((categoriesChecked && categoriesChecked !== prevState.categoriesChecked) ||
        (categoryOptions && categoryOptions !== prevState.categoryOptions)) {
      // Determine if the check all is checked or indeterminate.
      try {
        // Get the list of all the categoryOptions.
        const checkedCategoryCount = categoriesChecked && categoriesChecked.length ? categoriesChecked.length : 0
        const allCategoryCount = categoryOptions.filter(c => {
          if (!c.archived) {
            const name = c.name ? c.name.toLowerCase().trim() : null
            if (name && name !== 'budget' && name !== 'split') return c
          }
        }).length
        let checkAll = false
        let checkAllIndeterminate = false

        if ((checkedCategoryCount > 0) && checkedCategoryCount < allCategoryCount) {
          checkAll = false
          checkAllIndeterminate = true
        } else if (checkedCategoryCount >= allCategoryCount) {
          checkAll = true
          checkAllIndeterminate = false
        } else {
          checkAll = false
          checkAllIndeterminate = false
        }

        this.setState({ checkAll, checkAllIndeterminate })
      } catch (e) {

      }
    }

    if ((collections && collections !== prevProps.collections) ||
        (categories && categories !== prevProps.categories)) {
      // Set the category options
      this.setCollectionCategoryOptions()
    }

    if (categoryFilter !== prevState.categoryFilter) {
      // Filter changed
      this.setCollectionCategoryOptions()
    }
  }

  /**
   * This function is used to set and update the collection and
   * category options.
   */
  setCollectionCategoryOptions = () => {
    try {
      const { collections, categories, categoryBudgets } = this.props
      const { categoryFilter } = this.state

      // Get all collections.
      let collectionOptions = collections.map(c => c)

      // Get all categories that are not archived or reserved.
      let categoryOptions = categories.filter(c => {
        if (!c.archived) {
          const name = c.name ? c.name.toLowerCase().trim() : null
          if (name && name !== 'budget' && name !== 'split') return c
        }
      })

      // Apply any filters that are applicable.
      if (categoryFilter === 1) {
        // Overspent
        const overspentCategories = categoryBudgets.filter(cb => cb.balance < 0).map(cb => cb.category_id)
        categoryOptions = categoryOptions.filter(o => overspentCategories.includes(o.id))
      } else if (categoryFilter === 2) {
        // Underfunded
        const underfundedCategories = categoryBudgets.filter(cb => cb.goal > cb.budgeted).map(cb => cb.category_id)
        categoryOptions = categoryOptions.filter(o => underfundedCategories.includes(o.id))
      }

      // Filter out collections without a category in them.
      collectionOptions = collectionOptions.filter(collection => {
        const id = collection && collection.id ? collection.id : null
        for (const category of categoryOptions) {
          const collectionId = category && category.collection_id ? category.collection_id : null
          if ((id && collectionId) && (collectionId === id)) return collection
        }
      })

      this.setState({
        collectionOptions,
        categoryOptions
      })
    } catch (e) {

    }
  }

  /**
   * Used to toggle which filter is active.
   * Resets all checked values.
   * Category filter codes:
   * 0 - All
   * 1 - Overspent
   * 2 - Underfunded
   * @param {number} filter - The filter code to apply.
   */
  handleUpdateFilter = filter => {
    const { categoryFilter } = this.state

    if (filter !== categoryFilter) {
      this.setState({
        collectionsChecked: [],
        categoriesChecked: [],
        checkAll: false,
        checkAllIndeterminate: false,
        categoryFilter: filter
      })
    }
  }

  /**
   * Handles checking and unchecking all categories and collections.
   * Ignoring any archived categories.
   */
  handleCheckAllCheckbox = () => {
    try {
      const { checkAll, collectionOptions, categoryOptions } = this.state
      const newCheckAll = !checkAll
      let collectionsChecked = []
      let categoriesChecked = []

      if (newCheckAll) {
        collectionsChecked = collectionOptions.map(c => `${c.id}`)
        categoriesChecked = categoryOptions.filter(c => {
          if (!c.archived) {
            const name = c.name ? c.name.toLowerCase().trim() : null

            if (name && name !== 'budget' && name !== 'split') return c
          }
        }).map(c => `${c.id}`)
      }

      this.setState(
          {
            collectionsChecked,
            categoriesChecked
          })
    } catch (e) {
    }
  }

  /**
   * Handle adding and removing collections and categories from the
   * checked list.
   * @param {Object} e The on change event.
   */
  handleCollectionCheckbox = e => {
    try {
      const { categoryOptions, collectionsChecked, categoriesChecked } = this.state
      const collectionId = e.target.name
      const collectionCategories = categoryOptions
          .filter(c => !c.archived && `${c.collection_id}` === collectionId)
          .map(c => `${c.id}`)

      if (e.target.checked) {
        // Remove duplicates.
        const uniqueCategories = collectionCategories.filter(c => categoriesChecked.indexOf(c) === -1)
        // Checked true add item to list
        this.setState({
          collectionsChecked: [...collectionsChecked, collectionId],
          categoriesChecked: [...categoriesChecked, ...uniqueCategories]
        })
      } else {
        this.setState({
          collectionsChecked: collectionsChecked.filter(id => id !== collectionId),
          categoriesChecked: categoriesChecked.filter(id => collectionCategories.indexOf(id) < 0)
        })
      }
    } catch (e) {

    }
  }

  /**
   * Handle adding and removing categories from the checked list.
   * @param {Object} e The on change event.
   */
  handleCategoryCheckbox = e => {
    try {
      const { categoryOptions, collectionsChecked, categoriesChecked } = this.state
      const categoryId = e.target.name

      // Get collection Id.
      let collectionId = ''
      for (let c of categoryOptions) if (`${c.id}` === `${categoryId}`) {
        collectionId = `${c.collection_id}`
        break
      }

      let allChecked = true
      if (collectionId && e.target.checked) {
        // Get all the categories in this collection except for the one clicked on.
        const collectionCategories = categoryOptions
            .filter(c => !c.archived && `${c.collection_id}` === collectionId && `${c.id}` !== categoryId)
            .map(c => `${c.id}`)
        // check if all the categories in this collection are checked.
        for (const cc of collectionCategories) if (categoriesChecked.indexOf(`${cc}`) < 0) allChecked = false
      } else allChecked = false

      if (e.target.checked) {
        // Checked true add item to list
        if (allChecked) {
          // Add the checked category and the collection
          this.setState({
            categoriesChecked: [...categoriesChecked, categoryId],
            collectionsChecked: [...collectionsChecked, collectionId]
          })
        } else {
          // Just add the checked category
          this.setState({ categoriesChecked: [...categoriesChecked, categoryId], })
        }
      } else {
        // Checked false remove item from list
        this.setState({
          categoriesChecked: categoriesChecked.filter(id => id !== categoryId),
          collectionsChecked: collectionsChecked.filter(id => id !== collectionId),
        })
      }
    } catch (e) {

    }
  }

  /**
   * Controls toggling the collection accordion open and close.
   * @param {number} id - The collection Id
   */
  collectionClick = id => {
    const { expandCollections } = this.state

    try {
      const newExpandCollections = [...expandCollections]

      if (!newExpandCollections.includes(id)) {
        newExpandCollections.push(id)
      } else {
        newExpandCollections.splice(newExpandCollections.indexOf(id), 1)
      }
      this.setState({ expandCollections: newExpandCollections })
    } catch (e) {
    }
  }

  /**
   * This function collapses all the collections.
   */
  collapseAllCollections = () => this.setState({ expandCollections: [] })

  /**
   * This function expands all the collections.
   */
  expandAllCollections = () => {
    const { collections } = this.props

    if (collections) {
      const expandCollections = collections.map(c => c.id)
      this.setState({ expandCollections })
    }
  }

  render() {
    const {
      expandCollections, checkAll, checkAllIndeterminate, collectionOptions, collectionsChecked, categoryFilter,
      categoryOptions, categoriesChecked
    } = this.state
    const { categoryBudgets, transactions, classes, theme } = this.props
    let totalGoal = (categoryBudgets && categoryBudgets[0]) ? categoryBudgets[0].total_goal : 0
    let totalBudgeted = (categoryBudgets && categoryBudgets[0]) ? categoryBudgets[0].total_budgeted : 0
    let totalActivity = (transactions && transactions[0]) ? transactions[0].total_activity : 0
    let totalBalance = (categoryBudgets && categoryBudgets[0]) ? categoryBudgets[0].total_balance : 0

    return (
        <Fragment>
          <Box mt={1}>
            <Grid container spacing={2}
                  sx={{
                    width: 'calc(100% - 248px)'
                  }}
            >
              <Grid item xs={6} md={3}>
                <Card className={classes.cardBudget}>
                  <CardContent className={classes.budgetCardsContent}>
                    <Typography noWrap variant="subtitle1" className={classes.cardText}>
                      {formatMoneyLabel(totalGoal)}
                    </Typography>
                    <Typography noWrap variant="body2" className={classes.cardText}>
                      Goal
                    </Typography>
                    <Flag className={`${classes.cardIcon} ${classes.cardBudgetIcon}`} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <Card className={classes.cardAvailable}>
                  <CardContent className={classes.budgetCardsContent}>
                    <Typography noWrap variant="subtitle1" className={classes.cardText}>
                      {formatMoneyLabel(totalBudgeted)}
                    </Typography>
                    <Typography noWrap variant="body2" className={classes.cardText}>
                      Budgeted
                    </Typography>
                    <PieChart className={`${classes.cardIcon} ${classes.cardAvailableIcon}`} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <Card className={classes.cardActivity}>
                  <CardContent className={classes.budgetCardsContent}>
                    <Typography noWrap variant="subtitle1" className={classes.cardText}>
                      {formatMoneyLabel(totalActivity)}
                    </Typography>
                    <Typography noWrap variant="body2" className={classes.cardText}>
                      Activity
                    </Typography>
                    <ShoppingBasket className={`${classes.cardIcon} ${classes.cardActivityIcon}`} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <Card className={classes.cardInflow}>
                  <CardContent className={classes.budgetCardsContent}>
                    <Typography noWrap variant="subtitle1" className={classes.cardText}>
                      {formatMoneyLabel(totalBalance)}
                    </Typography>
                    <Typography noWrap variant="body2" className={classes.cardText}>
                      Balance
                    </Typography>
                    <MonetizationOn className={`${classes.cardIcon} ${classes.cardInflowIcon}`} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  width: 'calc(100% - 264px)',
                  marginTop: theme.spacing(1)
                }}
          >
            <Button color="inherit" variant="text" size="small"
                    onClick={this.expandAllCollections}
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(0.5), marginRight: theme.spacing(1) }}
            >
              <UnfoldMore fontSize="small" />&nbsp;Expand
            </Button>

            <Button color="inherit" variant="text" size="small"
                    onClick={this.collapseAllCollections}
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(0.5), marginRight: theme.spacing(1) }}
            >
              <UnfoldLess fontSize="small" />&nbsp;Collapse
            </Button>

            <Button color="inherit" variant={categoryFilter === 0 ? 'contained' : 'text'} size="small"
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(0.5), marginRight: theme.spacing(1) }}
                    onClick={() => this.handleUpdateFilter(0)}
            >
              <AllInclusive fontSize="small" />&nbsp;All
            </Button>

            <Button color="inherit" variant={categoryFilter === 1 ? 'contained' : 'text'} size="small"
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(0.5), marginRight: theme.spacing(1) }}
                    onClick={() => this.handleUpdateFilter(1)}
            >
              <ErrorOutline fontSize="small" />&nbsp;Overspent
            </Button>

            <Button color="inherit" variant={categoryFilter === 2 ? 'contained' : 'text'} size="small"
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(0.5) }}
                    onClick={() => this.handleUpdateFilter(2)}
            >
              <WarningAmber fontSize="small" />&nbsp;Underfunded
            </Button>
          </Grid>

          <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 'calc(100% - 264px)',
                  paddingRight: theme.spacing(1)
                }}
          >
            <Grid item xs={4} lg={3} zeroMinWidth>
              <ListItemButton dense sx={{ padding: 0 }}
                              onClick={this.handleCheckAllCheckbox}
              >
                <Checkbox
                    checked={checkAll}
                    indeterminate={checkAllIndeterminate}
                    size="small"
                    inputProps={{ 'aria-label': 'collection checkbox' }}
                />
                <ListItemText className={`${classes.m0} ${classes.p0}`}
                              primary={
                                <Typography variant="body2" align="left">Category</Typography>
                              } />
              </ListItemButton>
            </Grid>

            <Grid item xs={4} lg={3} zeroMinWidth>
              <ListItem disableGutters={true} dense className={`${classes.m0} ${classes.p0}`}>
                <ListItemText className={`${classes.m0} ${classes.p0}`}
                              primary={
                                <Typography className={classes.tableHeader} variant="body2" align="right">
                                  Budgeted / Goal
                                </Typography>
                              } />
              </ListItem>
            </Grid>

            <Hidden lgUp>
              <Grid item xs={4} md={4} zeroMinWidth>
                <ListItem disableGutters={true} dense className={`${classes.m0} ${classes.p0}`}>
                  <ListItemText className={`${classes.m0} ${classes.p0}`}
                                primary={
                                  <Typography className={classes.tableHeader} variant="body2" align="right">
                                    Activity / Balance
                                  </Typography>
                                } />
                </ListItem>
              </Grid>
            </Hidden>

            <Hidden lgDown>
              <Grid item lg={3} zeroMinWidth>
                <ListItem disableGutters={true} dense className={`${classes.m0} ${classes.p0}`}>
                  <ListItemText className={`${classes.m0} ${classes.p0}`}
                                primary={
                                  <Typography noWrap className={classes.tableHeader} variant="body2" align="right">
                                    Activity
                                  </Typography>
                                } />
                </ListItem>
              </Grid>
              <Grid item lg={3} zeroMinWidth>
                <ListItem disableGutters={true} dense className={`${classes.m0} ${classes.p0}`}>
                  <ListItemText className={`${classes.m0} ${classes.p0}`}
                                primary={
                                  <Typography noWrap className={classes.tableHeader} variant="body2" align="right">
                                    Balance
                                  </Typography>
                                } />
                </ListItem>
              </Grid>
            </Hidden>
          </Grid>

          <Collections categoryFilter={categoryFilter}
                       collections={collectionOptions}
                       collectionsChecked={collectionsChecked}
                       categories={categoryOptions}
                       categoriesChecked={categoriesChecked}
                       expandCollections={expandCollections}
                       handleExpandClick={this.collectionClick}
                       handleCollectionCheckbox={this.handleCollectionCheckbox}
                       handleCategoryCheckbox={this.handleCategoryCheckbox}
          />
        </Fragment>
    )
  }
}

Budget.propTypes = {
  collections: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
  transactions: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  collections: state.collectionReducer.collections,
  categories: state.categoryReducer.categories,
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
  transactions: state.transactionReducer.transactions,
})

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Budget))
