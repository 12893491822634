import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import MUIDataTable from 'mui-datatables'
import { Box, Grid, Tooltip } from '@mui/material'
import { ArrowDropUp, ArrowDropDown, Link, LinkOff } from '@mui/icons-material'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'
import { ROWS_PER_PAGE_OPTIONS, TableHeader } from '../utilities/tables'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  positiveAmount: {
    background: 'rgba(0, 255, 0, 0.2)',
    color: theme.palette.success.main,
    fontWeight: 500,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}`,
    whiteSpace: 'nowrap'
  },
  negativeAmount: {
    background: 'rgba(255, 0, 0, 0.2)',
    color: theme.palette.error.main,
    fontWeight: 500,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}`,
    whiteSpace: 'nowrap'
  }
}))

const HoldingList = ({ account, holdings }) => {

  const [pageSize, setPageSize] = useState(50)
  const [columns, setColumns] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()

  useEffect(() => {
    const columns = handleColumnsBuild()
  }, [])

  /**
   * This function handles when a user clicks on a holding row.
   * It will navigate the user to the detail page for the selected
   * holding.
   * @param {Array} rowData - The holding row actioned.
   */
  const holdingClick = rowData => navigate(`/holdings/${rowData[0]}`)

  /**
   * Builds the column data for the transaction table.
   * @return {Array} columns - The column data.
   */
  const handleColumnsBuild = () => {
    /**
     *  *** WARNING ***
     *  Some columns are indexed based, so if the position of a column
     *  is changed it could break the logic.
     */
    const tableColumns = [{
      label: 'ID', name: 'id',
      options: {
        display: 'excluded',
        viewColumns: false,
        filter: false,
        searchable: false,
        download: false
      }
    }, {
      label: 'Name', name: 'name', searchable: true,
      options: {
        display: 'excluded',
        viewColumns: false,
        filter: false,
        download: false
      }
    }, {
      label: 'Ticker', name: 'ticker_symbol', searchable: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const companyName = tableMeta && tableMeta.rowData && tableMeta.rowData[1] ? tableMeta.rowData[1] : ''
          return (
              <Grid container>
                <Grid item xs={12}>
                  {companyName}
                </Grid>
                <Grid item xs={12} style={{ fontSize: '12px' }}>
                  {value}
                </Grid>
              </Grid>
          )
        },
        filter: false,
      }
    }, {
      label: 'Price', name: 'institution_price', searchable: false,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
            value && value > 0
                ? formatMoneyLabel(value)
                : '-'
        ),
        filter: false,
      }
    }, {
      label: 'Shares', name: 'quantity', searchable: false,
      options: {
        filter: false,
      }
    }, {
      label: 'Cost Basis', name: 'cost_basis', searchable: false,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
            formatMoneyLabel(value)
        ),
        filter: false,
      }
    }, {
      label: 'Value', name: 'institution_value', searchable: false,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
            formatMoneyLabel(value)
        ),
        filter: false,
      }
    }, {
      label: 'Average Price', name: 'average_price', searchable: false,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
            formatMoneyLabel(value)
        ),
        filter: false,
      }
    }, {
      label: 'P/L', name: 'profit_loss', searchable: false,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const profitLoss = formatMoneyLabel(value)
          let plStyles = null
          let plArrow = null

          if (value !== 0) {
            plStyles = profitLoss.includes('-') ? classes.negativeAmount : classes.positiveAmount
            plArrow = profitLoss.includes('-')
                ? <ArrowDropDown className={classes.middleAlign} fontSize="small" color="error" />
                : <ArrowDropUp className={classes.middleAlign} fontSize="small" color="success" />
          }

          return (
              <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    wrap="nowrap"
                    className={plStyles}
              >
                {plArrow}
                {profitLoss}
              </Grid>
          )
        },
        filter: false,
      }
    }, {
      label: 'Last Updated', name: 'institution_price_as_of', searchable: true,
      options: {
        customBodyRender: value => (
            <Grid container>
              <Grid item xs={12}>
                {DateTime.fromISO(value).toLocaleString()}
              </Grid>
            </Grid>
        ),
        filter: false,
      }
    }, {
      label: 'Linked', name: 'link_account_id', searchable: false,
      options: {
        download: false,
        setCellHeaderProps: () => {
          return {
            style: {
              padding: '4px',
              maxWidth: '60px',
              minWidth: '60px'
            },
            className: clsx({
              [classes.centerTableHead]: true,
            })
          }
        },
        customBodyRender: (value, tableMeta) => {
          return !!value
              ? (
                  <Grid>
                    <Tooltip title="Holding Linked">
                      <Link fontSize="16" />
                    </Tooltip>
                  </Grid>
              ) : (<Tooltip title="Manual Holding">
                    <LinkOff fontSize="16" />
                  </Tooltip>
              )
        },
      },
    }]

    setColumns(tableColumns)
  }

  const options = {
    responsive: 'vertical',
    selectableRows: 'none',
    rowHover: true,
    rowsPerPage: pageSize,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    print: false,
    sort: false,
    filter: false,
    searchPlaceholder: 'Search Ticker',
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    onRowClick: rowData => holdingClick(rowData)

  }

  return (
      <Box mt={2}>
        <MUIDataTable
            title={<TableHeader
                title={account && account.available_balance ? formatMoneyLabel(account.available_balance) : '$0'}
                subtitle={(account && account.name || '')}
            />}
            data={holdings}
            columns={columns}
            options={options}
        />
      </Box>
  )
}


HoldingList.propTypes = {
  account: PropTypes.object.isRequired,
  holdings: PropTypes.array.isRequired
}

export default HoldingList
