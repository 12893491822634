import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import {
  Accordion, AccordionSummary, AccordionDetails, Checkbox, Grid, Hidden,
  List, ListItem, ListItemButton, IconButton, ListItemText, Typography
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { DragHandle } from '../common/DragHandle'
import Categories from '../category/Categories'
import { formatMoneyLabel } from '../utilities/numbers'

export const SortableCollections = SortableElement(
    ({
       handleCollectionCheckbox, handleCategoryCheckbox,
       collectionClick, collectionsChecked, indeterminate, categoriesChecked,
       collectionItem, categories, categoryBudgets, accounts, yearMonth, expandCollections, handleExpandClick,
       classes, theme
     }) => {
      let collection = collectionItem
      const displayCategories = categories.filter(c => c.name !== 'Budget' && c.name !== 'Split')
      let collectionTotalBudgeted = (collection.total_budgeted) ? collection.total_budgeted : 0
      let collectionTotalGoal = (collection.total_goal) ? collection.total_goal : 0
      let collectionTotalActivity = (collection.total_activity) ? collection.total_activity : 0
      let collectionTotalBalance = (collection.total_balance) ? collection.total_balance : 0
      return (
          <Accordion key={`collection-${collection.id}`} defaultExpanded
                     expanded={expandCollections.includes(collection.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore fontSize="small" style={{ color: '#FFF' }} />}
                              onClick={() => handleExpandClick(collection.id)}
            >
              <List sx={{ width: '100%', }}>

                <Grid container className={`${classes.dragHandleParent}`}>
                  <Grid item xs={4} lg={3} zeroMinWidth>
                    <ListItem disableGutters dense
                              onClick={(event) => event.stopPropagation()}
                              className={`${classes.m0} ${classes.p0}`}>
                      <IconButton aria-label="drag" size="small" className={classes.dragHandleButton} color="inherit">
                        <DragHandle classes={classes} />
                      </IconButton>
                      <Checkbox checked={collectionsChecked}
                                name={`${collection.id}`}
                                indeterminate={indeterminate}
                                onChange={handleCollectionCheckbox}
                                size="small"
                                inputProps={{ 'aria-label': 'collection checkbox' }}
                                sx={{ color: '#fff' }}
                      />
                      <ListItemButton dense disableGutters onClick={collectionClick.bind(this, collection.id)}>
                        <ListItemText className={`${classes.m0} ${classes.p0}`}
                                      primary={
                                        <Typography noWrap className={classes.collectionLabel}
                                                    variant="subtitle1" align="left">
                                          {collection.name}
                                        </Typography>
                                      } />
                      </ListItemButton>
                    </ListItem>
                  </Grid>

                  <Grid item xs={4} lg={3} zeroMinWidth>
                    <ListItem disableGutters={true} dense className={`${classes.m0} ${classes.p0}`}>
                      <ListItemText className={`${classes.m0} ${classes.p0}`}
                                    primary={
                                      <Typography noWrap
                                                  className={`${classes.collectionLabel} ${classes.line19}`}
                                                  variant="subtitle1" align="right">
                                        {formatMoneyLabel(collectionTotalBudgeted)}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography noWrap
                                                  className={`${classes.collectionLabel} ${classes.line19}`}
                                                  variant="subtitle1" align="right">
                                        {formatMoneyLabel(collectionTotalGoal)}
                                      </Typography>
                                    } />
                    </ListItem>
                  </Grid>

                  <Hidden lgUp>
                    <Grid item xs={4} md={4} zeroMinWidth>
                      <ListItem disableGutters dense className={`${classes.m0} ${classes.p0}`}>
                        <ListItemText className={`${classes.m0} ${classes.p0}`}
                                      primary={
                                        <Typography noWrap
                                                    className={`${classes.collectionLabel}  ${classes.line19}`}
                                                    variant="subtitle1" align="right">
                                          {formatMoneyLabel(collectionTotalActivity)}
                                        </Typography>
                                      }
                                      secondary={
                                        <Typography noWrap
                                                    className={`${classes.collectionLabel} ${classes.line19}`}
                                                    variant="subtitle1" align="right">
                                          {formatMoneyLabel(collectionTotalBalance)}
                                        </Typography>
                                      } />
                      </ListItem>
                    </Grid>
                  </Hidden>

                  <Hidden lgDown>
                    <Grid item lg={3} zeroMinWidth>
                      <ListItem disableGutters dense className={`${classes.m0} ${classes.p0}`}>
                        <ListItemText primary={
                          <Typography noWrap className={`${classes.collectionLabel} ${classes.line30}`}
                                      variant="subtitle1" align="right">
                            {formatMoneyLabel(collectionTotalActivity)}
                          </Typography>
                        } />
                      </ListItem>
                    </Grid>
                    <Grid item lg={3} zeroMinWidth>
                      <ListItem disableGutters dense className={`${classes.m0} ${classes.p0}`}>
                        <ListItemText primary={
                          <Typography noWrap className={`${classes.collectionLabel} ${classes.line30}`}
                                      variant="subtitle1" align="right">
                            {formatMoneyLabel(collectionTotalBalance)}
                          </Typography>
                        } />
                      </ListItem>
                    </Grid>
                  </Hidden>
                </Grid>
              </List>
            </AccordionSummary>

            <AccordionDetails>
              <Categories collection={collection}
                          categories={displayCategories}
                          categoriesChecked={categoriesChecked}
                          handleCategoryCheckbox={handleCategoryCheckbox}
                          categoryBudgets={categoryBudgets}
                          yearMonth={yearMonth}
                          accounts={accounts}
              />
            </AccordionDetails>


          </Accordion>
      )
    }
)
