import {
  GET_RECURRING_TRANSACTIONS, RECURRING_TRANSACTIONS_LOADING, RECURRING_TRANSACTIONS_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isRecurringTransactionsFetching: true,
  isRecurringTransactionsLoaded: false,
  recurringTransactions: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECURRING_TRANSACTIONS:
      return {
        ...state,
        error: false,
        isRecurringTransactionsFetching: false,
        isRecurringTransactionsLoaded: true,
        recurringTransactions: action.payload
      }
    case RECURRING_TRANSACTIONS_LOADING:
      return {
        ...state,
        error: false,
        isRecurringTransactionsFetching: true,
      }
    case RECURRING_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: true,
        isRecurringTransactionsFetching: false
      }
    default:
      return state
  }
}
