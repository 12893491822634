import { GET_CATEGORY_ID, EDIT_CATEGORY_ID } from '../actions/types'

const initialState = {
  categoryId: -1
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_ID:
      return {
        ...state
      }
    case EDIT_CATEGORY_ID:
      return {
        ...state,
        categoryId: action.payload
      }
    default:
      return state
  }
}