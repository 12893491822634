import React from 'react'
import PropTypes from 'prop-types'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { formatMoneyLabel } from '../utilities/numbers'
import { backgroundColors, borderColors } from '../chart/charts'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
  plugins: {
    legend: {
      display: true,
      labels: {
        color: '#fff'
      }
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  scales: {
    x: {
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        color: '#fff'
      },
    },
    y: {
      ticks: {
        color: '#fff',
        callback: function (value, index, ticks) {
          return formatMoneyLabel(value)
        },
      },
    },
  },
}

/**
 * Builds a bar chart with two bars for comparison.
 * @param props
 * @param {number} props.previous - The previous number to compare.
 * @param {number} props.current - The current number to compare.
 * @returns {JSX.Element}
 * @constructor
 */
const CompareActivityBar = props => {

  const data = {
    labels: ['Last Month', 'Current'],
    datasets: [
      {
        label: 'Activity',
        data: [Math.abs(props.previous), Math.abs(props.current)],
        backgroundColor: backgroundColors[1],
        borderColor: borderColors[1],
        borderWidth: 2,
      }
    ],
  }

  return (
      <Bar options={options} data={data} />
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'CompareActivityBar')
  return props
}

const propTypes = {}

CompareActivityBar.propTypes = {
  previous: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired
}

export default CompareActivityBar
