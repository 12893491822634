import {
  GET_REPORTS, REPORT_LOADING, REPORT_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  reports: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        reports: action.payload
      }
    case REPORT_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true,
        isLoaded: false
      }
    case REPORT_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
