import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import arrayMove from 'array-move'

import { sharedStyles } from '../common/styles'
import { SortContainer } from '../common/SortContainer'
import { SortableCategories } from './SortCategories'
import { editCategoryId } from '../../actions/categoryId'
import { editCategoryBudgetId } from '../../actions/categoryBudgetId'
import { editCategoryBalanceId } from '../../actions/categoryBalanceId'
import { reorderCategories } from '../../actions/categories'

const styles = theme => ({
  ...sharedStyles(theme),
})

class Categories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deriveProps: true,
      balanceDialogOpen: false,
      categories: this.props.categories
    }

    this.categoryClick = this.categoryClick.bind(this)
    this.categoryBudgetClick = this.categoryBudgetClick.bind(this)
    this.categoryBalanceClick = this.categoryBalanceClick.bind(this)
  }

  categoryClick = id => this.props.editCategoryId(id)

  categoryBudgetClick = id => {
    this.props.editCategoryBudgetId(id)
  }

  categoryBalanceClick = id => {
    this.props.editCategoryBalanceId(id)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    try {
      const { yearMonth, categories, reorderCategories } = this.props
      const oldPositionElement = categories[oldIndex]
      const newPositionElement = categories[newIndex]
      if (oldPositionElement !== newPositionElement) {
        this.setState({
          deriveProps: false, // Temporarily stop props affecting state
          categories: arrayMove(categories, oldIndex, newIndex),
        })
        oldPositionElement.new_index = newIndex
        reorderCategories(oldPositionElement, yearMonth)


        // After a very short wait, allow props to affect state again
        setTimeout(() => {
          this.setState(() => ({
            deriveProps: true,
          }))
        }, 3000)
      }
    } catch (e) {
      //  TODO: dispatch error
    }
  }

  // Update state with prop changes, except for a brief period after the order has changed,
  // to stop flickering from happening
  static getDerivedStateFromProps(props, state) {
    if (state.deriveProps) {
      return {
        categories: props.categories,
      }
    }
    return null
  }

  render() {
    const {
      accounts, collection, categoriesChecked, handleCategoryCheckbox,
      categoryBudgets, classes, theme
    } = this.props
    const { categories } = this.state

    return (
        <SortContainer onSortEnd={this.onSortEnd} useDragHandle className={classes.p0}>
          {categories.map((category, index) => {
            if (collection.id === category.collection_id) {
              const accountCategory = accounts.filter(a => a.category_id === category.id)
              const accountCategoryId = accountCategory && accountCategory[0] && accountCategory[0].category_id ? accountCategory[0].category_id : null
              const checked = (categoriesChecked && categoriesChecked.indexOf(`${category.id}`) >= 0)
              const archived = category.archived

              return !archived
                  ? (
                      <SortableCategories key={category.id}
                                          index={index}
                                          categoryClick={this.categoryClick}
                                          categoryBudgetClick={this.categoryBudgetClick}
                                          categoryBalanceClick={this.categoryBalanceClick}
                                          category={category}
                                          checked={checked}
                                          checkboxChange={handleCategoryCheckbox}
                                          categoryBudgets={categoryBudgets}
                                          classes={classes}
                                          theme={theme}
                                          accountCategoryId={accountCategoryId}
                      />
                  ) : null
            }
          })}
        </SortContainer>
    )
  }
}

Categories.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  editCategoryId: PropTypes.func.isRequired,
  editCategoryBudgetId: PropTypes.func.isRequired,
  editCategoryBalanceId: PropTypes.func.isRequired,
  reorderCategories: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  collection: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  categoriesChecked: PropTypes.array.isRequired,
  handleCategoryCheckbox: PropTypes.func.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
}

const mapDispatchToProps = {
  editCategoryId,
  editCategoryBudgetId,
  editCategoryBalanceId,
  reorderCategories,
}

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Categories))
