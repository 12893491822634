import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { TextField, Divider, Button, Grid, } from '@mui/material'
import { Send } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { resetPassword } from '../../actions/auth'
import AuthLayout from './AuthLayout'

const styles = theme => ({
  ...sharedStyles(theme),
})

class PasswordReset extends Component {
  state = {
    email: '',
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onSubmit = e => {
    e.preventDefault()
    const { email } = this.state
    const normalizedEmail = email ? email.toLowerCase() : ''

    this.props.resetPassword(normalizedEmail)
  }

  render() {
    if (this.props.isAuthenticated) return <Navigate to="/" />
    if (this.props.resetPasswordRequested) return <Navigate to="/reset-password-acknowledge" />

    const { email } = this.state
    const { classes } = this.props
    return (
        <AuthLayout heading="Request password reset">
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={email}
                           name="email"
                           type="email"
                           label="Email"
                           fullWidth={true} />
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth={true} type="submit" color="primary" variant="contained">
                  <Send className={classes.pr1} fontSize="small" />Reset Password
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid container item xs={12}
                    justifyContent="center">
                <Link to="/login" className={classes.textLinks}>Login</Link>
              </Grid>

              <Divider />

            </Grid>
          </form>
        </AuthLayout>
    )
  }
}

PasswordReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  resetPasswordRequested: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  resetPasswordRequested: state.authReducer.resetPasswordRequested,
})

const mapDispatchToProps = {
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordReset))
