import { GET_THEMES, THEME_LOADING, THEME_FAIL } from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  themes: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_THEMES:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        themes: action.payload
      }
    case THEME_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case THEME_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}