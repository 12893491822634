import { createTheme, lighten } from '@mui/material/styles'

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#206470',
      main: '#0f4e5b',
      dark: '#023943',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3A7985',
      main: '#3A7985',
      dark: '#0f4e5b',
      contrastText: '#fff',
    },
    accent: {
      main: '#FF7D00'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(to right, #0f4e5b, #206470) !important',
          color: '#fff',
          minHeight: '38px !important',
          padding: 0
        },
        content: {
          '&.Mui-expanded': {
            margin: 0
          },
          margin: 0,
        },
        expandIconWrapper: {
          padding: '5px'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#bdbdbd'
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#FF7100'
          },
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: '#FF7100'
          },
        },
        indeterminate: {
          color: '#bdbdbd !important'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#fff'
          }
        }
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottom: '2px solid #fff'
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: lighten('#206470', 0.75) + ' !important',
          },
          '&.Mui-selected > *': {
            color: 'rgba(0, 0, 0, 0.87) !important',
          }
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: 'linear-gradient(to right, #0f4e5b, #206470) !important',
          }
        }
      }
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87) !important',
          backgroundColor: lighten('#206470', 0.75) + ' !important',
        },
      }
    },
  },
  typography: {
    fontWeightBold: 600,
    h6: {
      fontWeight: 300
    },
    subtitle1: {
      fontWeight: 300
    },
    subtitle2: {
      fontWeight: 300
    },
    body2: {
      fontWeight: 300
    }
  }
})
