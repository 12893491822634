import {
  GET_PROFILE_SETTINGS, ADD_PROFILE_SETTINGS, EDIT_PROFILE_SETTINGS,
  PROFILE_SETTINGS_LOADING, PROFILE_SETTINGS_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  profile: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_SETTINGS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        profile: action.payload && action.payload.length > 0 ? action.payload[0] : {}
      }
    case ADD_PROFILE_SETTINGS:
    case EDIT_PROFILE_SETTINGS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        profile: action.payload
      }
    case PROFILE_SETTINGS_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case PROFILE_SETTINGS_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}