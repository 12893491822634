import { GET_HOLDING_ID, EDIT_HOLDING_ID } from './types'

// Get Holding ID
export const getHoldingId = () => (dispatch, getState) => {
  dispatch({
    type: GET_HOLDING_ID,
    payload: getState
  })
}

// Edit Holding ID
export const editHoldingId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_HOLDING_ID,
    payload: id
  })
}
