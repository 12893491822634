import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import {
  ListItem, ListItemButton, ListItemIcon, IconButton, ListItemText, Typography,
  Accordion, AccordionSummary, AccordionDetails, Grid
} from '@mui/material'
import { ExpandMore, AccountBalance } from '@mui/icons-material'

import { DragHandle } from '../common/DragHandle'
import Accounts from '../account/Accounts'
import { formatMoneyLabel } from '../utilities/numbers'

export const SortableAccountGroups = SortableElement(
    ({
       group, index, accounts, linkAccounts, count, handleGroupClick, openAccountForm, classes
     }) => {

      return (
          <Accordion key={`accountGroup-${group.id}`}
                     expanded={group.opened}
                     square
                     onChange={() => handleGroupClick(group)}
                     className={`${classes.topIndex} ${classes.dragHandleParent} `}
          >

            <AccordionSummary expandIcon={<ExpandMore fontSize="small" style={{ color: '#FFF' }} />}>
              <ListItem dense className={` ${classes.p0} `}>

                <IconButton aria-label="drag" className={classes.dragHandleButton} size="small" color="inherit">
                  <DragHandle classes={classes} />
                </IconButton>

                <ListItemText
                    primary={
                      <Typography variant="body2" align="left" noWrap={true}>
                        {group.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" align="left" noWrap={true}>
                        {formatMoneyLabel(group.group_total)}
                      </Typography>
                    }
                />
              </ListItem>

              {count && count > 0
                  ? <Grid container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.notificationIconContainer}
                  >
                    <Grid item className={classes.notificationIcon} />
                  </Grid>
                  : null
              }

            </AccordionSummary>

            <AccordionDetails>
              {group.count && group.count > 0
                  ? <Accounts
                      group={group}
                      accounts={accounts}
                      linkAccounts={linkAccounts}
                  />
                  : (
                      <ListItem dense alignItems="center" className={classes.p0}>
                        <ListItemButton dense sx={{ justifyContent: 'center' }} onClick={openAccountForm}>
                          <ListItemIcon sx={{ justifyContent: 'center' }}>
                            <AccountBalance fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Add Account" />
                        </ListItemButton>
                      </ListItem>
                  )
              }
            </AccordionDetails>

          </Accordion>
      )
    }
)
