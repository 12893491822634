import React from 'react'
import { Slide } from '@mui/material'

/**
 * Used to determine if the popup dialog should be fullscreen.
 * @param {theme} theme - The application theme.
 * @returns {boolean}
 */
export const fullScreen = theme => {
  try {
    const windowWidth = window.innerWidth
    return theme.breakpoints.values.sm >= windowWidth
  } catch (e) {
    return false
  }
}

/**
 * Used to return the transition styling component for popup dialogs.
 * @return {Component} Slide - The slide up component.
 */
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
