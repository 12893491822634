import { CREATE_TOAST, CREATE_MESSAGE, GET_ERRORS, CLEAR_MESSAGES } from './types'

/**
 * Creates a user interactive message. The user must take action to
 * dismiss message.
 * @param {string} title - The title of the alert.
 * @param {string} [content] - The content of the alert.
 * @param {Object[]} [buttons] - Buttons attribute to override.
 * @param {string} buttons[].text - The text to display on the button.
 * @param {function} buttons[].onPress - The text to display on the button.
 * @param {('default', 'cancel', 'destructive')} buttons[].style - The text to display on the button.
 */
export const createMessage = (title, content, buttons) => {
  return {
    type: CREATE_MESSAGE,
    payload: { title, content, buttons }
  }
}

// Create Toast
export const createToast = msg => {
  return {
    type: CREATE_TOAST,
    payload: msg
  }
}

// Return errors
export const returnErrors = (msg, status) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status }
  }
}

// Clear all messages
export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES
  }
}
