import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  TextField, Button, Grid, Card, CardContent, CardHeader, Autocomplete,
  Dialog, DialogTitle, DialogContent, DialogActions, Typography
} from '@mui/material'
import { Save, Cancel, Delete, DeleteForever } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from '../layout/DraggableDialogWrapper'
import { editProfile, deleteUserAccount } from '../../actions/auth'
import { editProfileSettings, addProfileSettings } from '../../actions/profile'
import { fullScreen, Transition } from '../utilities/dialogs'

const styles = theme => ({
  ...sharedStyles(theme),
})

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: this.props.auth.user.first_name,
      lastName: this.props.auth.user.last_name,
      theme: null,
      showDeleteConfirmation: false,
      confirmPassword: '',
      isValidPassword: false,
      passwordError: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.confirmationDialog = this.confirmationDialog.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    const { profile } = this.props

    if (profile && profile.theme) {
      const themeId = profile.theme_id
      const theme = profile.theme
      this.setState({ theme: { id: themeId, name: theme } })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { profile } = this.props

    if (profile && profile !== prevProps.profile) {
      const themeId = profile.theme_id
      const theme = profile.theme
      this.setState({ theme: { id: themeId, name: theme } })
    }
  }

  /**
   * Handles input change.
   * @param {Object} e - The input event object.
   */
  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  /**
   * Handles the theme autocomplete field.
   * @param {Object} event - The event object.
   * @param {Object} value - The selected item from the list.
   */
  handleAutocompleteChange = (event, value) => {
    if (value) this.setState({ theme: value })
  }

  /**
   * Submit the form to update user settings and profile information.
   * @param {Object} e - The submit event object.
   */
  handleSubmit = e => {
    e.preventDefault()

    try {
      const { firstName, lastName, theme } = this.state
      const { editProfile, editProfileSettings, addProfileSettings, profile } = this.props
      const { user } = this.props.auth
      const authProfile = { id: user.id, first_name: firstName, last_name: lastName }

      // Check if user updated their name.
      if (user.first_name !== firstName || user.last_name !== lastName) {
        editProfile(authProfile)
      }

      // Check if user changed theme. Also check if user has a theme yet.
      if (profile && profile.id > 0) {
        // Record exist. Check if user changed theme.
        if (theme.id !== profile.theme_id) {
          const settings = { id: profile.id, user_id: user.id, theme_id: theme.id }
          editProfileSettings(settings)
        }
      } else {
        // No record exist. Check if user added a theme
        if (profile && !profile.theme_id && theme && theme.id) {
          const new_setting = {
            user_id: user.id,
            theme_id: theme.id
          }
          addProfileSettings(new_setting)
        }
      }
    } catch (e) {

    }
  }

  /**
   * Update validation and password value as user types.
   * @param {Object} e - The input event
   */
  handlePasswordChange = e => {
    const value = e.target.value

    if (value && value.trim().length >= 8) {
      this.setState({
        confirmPassword: value,
        isValidPassword: true,
        passwordError: false
      })
    } else {
      this.setState({
        confirmPassword: value,
        isValidPassword: false
      })
    }
  }

  /**
   * Clears the confirm password field and closes the confirmation
   * dialog.
   */
  clearForm = () => {
    this.setState({
      showDeleteConfirmation: false,
      confirmPassword: '',
      isValidPassword: false,
      passwordError: false
    })
  }

  /**
   * Display the confirmation dialog for the user before permanently
   * deleting the users account and data.
   * @param {Object} e - The click event object.
   */
  confirmationDialog = e => this.setState({ showDeleteConfirmation: true })

  /**
   * *** DANGER ***
   * Permanently deletes the users account and data.
   * NOTE: This does not auto-cancel a users subscriptions or unlink
   * Ploutos Budget from their banking institutions.
   */
  handleDelete = () => {
    const { confirmPassword, isValidPassword } = this.state
    const { deleteUserAccount } = this.props

    if (!isValidPassword) {
      this.setState({ passwordError: true })
      return false
    }

    const password = {
      current_password: confirmPassword
    }

    deleteUserAccount(password)
  }

  render() {
    const {
      firstName, lastName, theme, showDeleteConfirmation,
      confirmPassword, passwordError
    } = this.state
    const { email } = this.props.auth.user
    const { classes, themes } = this.props

    return (
        <>

          <Grid container>
            <Grid item xs={12}>
              <Card raised={true}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={6}>
                      <CardHeader className={`${classes.pl0} ${classes.collectionLabel}`}
                                  title="Settings"
                                  subheader="Profile" />
                    </Grid>
                    <Grid item xs={6}>
                      <Button onClick={this.confirmationDialog}
                              className={`${classes.dangerButton} ${classes.floatRight}`}
                              variant="outlined">
                        <Delete className={classes.pr1} />Delete
                      </Button>
                    </Grid>
                  </Grid>
                  <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField required
                                   autoComplete="off"
                                   disabled
                                   value={email}
                                   type="email"
                                   label="Email"
                                   fullWidth={true} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField required
                                   autoComplete="off"
                                   onChange={this.handleChange}
                                   value={firstName}
                                   name="firstName"
                                   type="text"
                                   label="First Name"
                                   fullWidth={true} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            required
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={lastName}
                            name="lastName"
                            type="text"
                            label="Last Name"
                            fullWidth={true} />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                            name="theme"
                            clearOnEscape
                            openOnFocus
                            autoComplete
                            autoHighlight
                            includeInputInList
                            value={theme}
                            options={themes}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option => option.name ? option.name : ''}
                            onChange={this.handleAutocompleteChange}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            renderInput={(params) => (
                                <TextField {...params} label="Theme" margin="normal" />
                            )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button fullWidth={true} type="submit" color="primary" variant="contained">
                          <Save className={classes.pr1} />Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Dialog open={showDeleteConfirmation}
                  aria-labelledby="user-account-delete-confirmation"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={`${classes.fontError} ${classes.moveCursor}`}>Warning</DialogTitle>

            <DialogContent>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Deleting your account is permanent and means that all of your data will be deleted.
                    <br />
                    <br />
                    Deleting your account data does not auto-cancel your subscription.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField required
                             autoComplete="off"
                             onChange={this.handlePasswordChange}
                             value={confirmPassword}
                             name="confirmPassword"
                             label="Confirm Password"
                             type="password"
                             error={passwordError}
                             helperText={passwordError ? 'Password must be at least 8 characters long.' : null}
                             fullWidth={true} />
                </Grid>

              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleDelete} color="error" variant="contained">
                <DeleteForever />&nbsp;Delete Account
              </Button>
              <Button onClick={this.clearForm} color="inherit" variant="outlined">
                <Cancel />&nbsp;Cancel
              </Button>
            </DialogActions>
          </Dialog>

        </>
    )
  }
}

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  themes: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  themes: state.themeReducer.themes,
  profile: state.profileReducer.profile
})

const mapDispatchToProps = {
  editProfile,
  editProfileSettings,
  addProfileSettings,
  deleteUserAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings))
