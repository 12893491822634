import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { TextField, Divider, Button, Grid, } from '@mui/material'
import { PostAdd } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { register } from '../../actions/auth'
import { createToast } from '../../actions/messages'
import AuthLayout from './AuthLayout'

const styles = theme => ({
  ...sharedStyles(theme),
})

class Register extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    rePassword: ''
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onSubmit = e => {
    e.preventDefault()
    const { firstName, lastName, email, password, rePassword } = this.state
    if (password !== rePassword) {
      this.props.createToast({ passwordsNotMatch: 'Passwords do not match' })
    } else {
      const newUser = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        re_password: rePassword
      }

      this.props.register(newUser)
    }
  }

  render() {
    if (this.props.isAuthenticated) return <Navigate to="/" />
    if (this.props.accountCreated) return <Navigate to="/register-acknowledge" />

    const { firstName, lastName, email, password, rePassword } = this.state
    const { classes } = this.props
    return (
        <AuthLayout heading="Register">
          <form onSubmit={this.onSubmit}>
            <Grid container
                  spacing={3}>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={firstName}
                           name="firstName"
                           type="text"
                           label="First Name"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={lastName}
                           name="lastName"
                           label="Last Name"
                           type="text"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={email}
                           name="email"
                           type="email"
                           label="Email"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={password}
                           name="password"
                           label="Password"
                           type="password"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={rePassword}
                           name="rePassword"
                           label="Confirm Password"
                           type="password"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth={true} type="submit" color="primary" variant="contained">
                  <PostAdd className={classes.pr1} fontSize="small" />Register
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item xs={12}
                    justifyContent="center">
                <Link to="/login" className={classes.textLinks}>Have Account</Link>
              </Grid>
              <Divider />
            </Grid>
          </form>
        </AuthLayout>
    )
  }
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  accountCreated: PropTypes.bool
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  accountCreated: state.authReducer.accountCreated
})

const mapDispatchToProps = {
  register,
  createToast
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Register))
