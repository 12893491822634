import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Link as Anchor
} from '@mui/material'
import { Delete, Cancel } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { fullScreen, Transition } from '../utilities/dialogs'
import { formatISODateTime } from '../utilities/dates'
import { deleteLinkAccount } from '../../actions/link'
import { returnErrors } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

class LinkAccountForm extends Component {
  constructor(props) {
    super(props)

    this.handleDelete = this.handleDelete.bind(this)
  }

  /**
   * Delete the link account.
   * @param {Object} e - The event object.
   */
  handleDelete = e => {
    e.preventDefault()
    try {
      const { closeForm, linkAccount, deleteLinkAccount } = this.props

      deleteLinkAccount(linkAccount)
      closeForm()
    } catch (e) {
      this.props.returnErrors({ deleteAccount: 'Unable to delete. Please try again later.' }, 500)
    }
  }

  render() {
    const { theme, classes, open, closeForm, linkAccount } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="link-account-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Delete {linkAccount.name}
              <Button onClick={this.handleDelete}
                      className={`${classes.dangerButton} ${classes.floatRight}`}
                      variant="outlined">
                <Delete className={classes.pr1} />Delete
              </Button>
            </DialogTitle>
            <DialogContent>
              <Box p={3}>
                <Grid container spacing={2} justifyContent="center">

                  <Grid item xs={12}>

                    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                      <Typography variant="h6"
                                  style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: linkAccount.institution_primary_color
                                  }}
                      >
                        {linkAccount.institution_name}
                      </Typography>
                    </Grid>

                    {linkAccount && linkAccount.institution_url &&
                        <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                          <Anchor color="inherit" underline="hover" target="_blank" href={linkAccount.institution_url}>
                            {linkAccount.institution_url.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')}
                          </Anchor>
                        </Grid>
                    }

                    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                      <Typography variant="body1" style={{ color: theme.palette.text.secondary }}>
                        Last Updated: {formatISODateTime(linkAccount.updated)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Are you sure? This will delete the linked account
                      from Ploutos Budget. We will no longer be able
                      to pull in transactions for this account.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()} color="inherit" variant="outlined">
                <Cancel />&nbsp;Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
    )
  }
}

LinkAccountForm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  linkAccount: PropTypes.object.isRequired,
  deleteLinkAccount: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  deleteLinkAccount,
  returnErrors
}

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(LinkAccountForm))
