import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import MUIDataTable from 'mui-datatables'
import {
  Box, Paper, TableContainer, Table, TableBody, TableHead, TableRow,
  TableCell, Typography, Grid, ListItem, ListItemText,
  FormControl, MenuItem, Checkbox, InputLabel, Select, Tooltip
} from '@mui/material'
import { Verified, NewReleases, Inventory } from '@mui/icons-material'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'
import { ROWS_PER_PAGE_OPTIONS } from '../utilities/tables'
import LinkTransactionForm from '../layout/LinkTransactionForm'
import { editTransactionId } from '../../actions/transactionId'

const styles = theme => ({
  ...sharedStyles(theme),
  positiveAmount: {
    color: theme.palette.success.main,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  negativeAmount: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  tableChips: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
})

const TableHeader = ({ name, posted, pending, available }) => (
    <Grid container>
      <Grid item xs={12}>
        <ListItem dense disableGutters>
          <ListItemText disableTypography
                        sx={{
                          position: 'relative',
                          background: 'linear-gradient(to right, #3A7985, #0f4e5b, #0f4e5b, #3A7985)',
                          borderRadius: '4px',
                          border: '4px solid #3A7985',
                          color: '#fff',
                          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                          '&:after, &:before': {
                            left: '100%',
                            top: '50%',
                            border: 'solid transparent',
                            content: '""',
                            height: 0,
                            width: 0,
                            position: 'absolute'
                          },
                          '&:after': {
                            borderLeftColor: '#3A7985',
                            borderWidth: '14px',
                            marginTop: '-14px'
                          },
                          '&:before': {
                            borderLeftColor: '#3A7985',
                            borderWidth: '20px',
                            marginTop: '-20px'
                          }
                        }}
                        primary={
                          <Typography align="center" variant="body1" noWrap>
                            {formatMoneyLabel(available)}
                          </Typography>
                        }
                        secondary={
                          <Typography align="center" variant="subtitle1" noWrap>
                            {name}
                          </Typography>
                        }
          />
          <ListItemText disableTypography
                        primary={
                          <Typography align="center" variant="body2" noWrap>
                            {formatMoneyLabel(posted)}
                          </Typography>
                        }
                        secondary={
                          <Typography align="center" variant="subtitle2" noWrap>
                            Posted
                          </Typography>
                        }
          />
          <ListItemText disableTypography
                        sx={{ maxWidth: '15px' }}
                        primary={
                          <Typography align="center" variant="subtitle2" noWrap>
                            +
                          </Typography>
                        }
          />
          <ListItemText disableTypography
                        primary={
                          <Typography align="center" variant="body2" noWrap>
                            {formatMoneyLabel(pending)}
                          </Typography>
                        }
                        secondary={
                          <Typography align="center" variant="subtitle2" noWrap>
                            Pending
                          </Typography>
                        }
          />
        </ListItem>
      </Grid>
    </Grid>
)

class TransactionList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pageSize: 50,
      linkTransaction: null
    }

    this.transactionClick = this.transactionClick.bind(this)
    this.linkTransactionClick = this.linkTransactionClick.bind(this)
    this.clearLinkTransaction = this.clearLinkTransaction.bind(this)
    this.handleColumnsBuild = this.handleColumnsBuild.bind(this)
  }

  /**
   * This function is used to update the transaction form with
   * row that was clicked on.
   * @param {Array} rowData - The link transaction actioned.
   */
  transactionClick = rowData => this.props.editTransactionId(rowData[0])

  /**
   * This function is used to update the link transaction form with
   * row that was clicked on.
   * @param {Array} rowData - The link transaction actioned.
   */
  linkTransactionClick = rowData => {
    if (rowData && rowData.id) this.setState({ linkTransaction: rowData })
  }

  /**
   * This function is used to clear the selected link transaction.
   */
  clearLinkTransaction = () => {
    this.setState({ linkTransaction: null })
  }

  /**
   * Builds the column data for the transaction table.
   * @return {Array} columns - The column data.
   */
  handleColumnsBuild = () => {
    const { theme, classes, accountKey, flagColors, transactionFlags } = this.props
    const flagOptions = []

    // Get the users flag colors and labels.
    for (const flagColor of flagColors) {
      for (const transactionFlag of transactionFlags) {
        if (flagColor.id === transactionFlag.color_id) {
          flagOptions.push({
            id: transactionFlag.id,
            colorId: flagColor.id,
            color: flagColor.name,
            name: transactionFlag.name
          })
        }
      }
    }

    /**
     * Adjust columns based on viewing all accounts or specific account
     *  *** WARNING ***
     *  Some columns are indexed based, so if the position of a column
     *  is changed it could break the logic.
     */
    return accountKey === 0
        ? [{
          label: 'ID', name: 'id',
          options: {
            display: 'excluded',
            viewColumns: false,
            filter: false,
            searchable: false,
            download: false
          }
        }, {
          label: 'Flag Name', name: 'flag_name',
          options: {
            display: 'excluded',
            viewColumns: false,
            filter: false,
            searchable: false,
            download: false
          }
        }, {
          label: 'Flag', name: 'flag_color', searchable: true,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const flagColor = value
                  ? value
                  : theme.palette.divider
              const flagName = tableMeta && tableMeta.rowData && tableMeta.rowData[1]
                  ? tableMeta.rowData[1]
                  : ''
              return (
                  <Box className={classes.tableLabel}
                       style={{
                         border: `1px solid ${flagColor}`,
                         color: flagColor
                       }}
                  >
                    <Typography variant="caption" align="center" noWrap>
                      {flagName}
                    </Typography>
                  </Box>
              )
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                const selectedColors = []

                for (const flag of flagOptions) {
                  if (v.includes(flag.color)) {
                    const name = flag && flag.name ? flag.name.trim() : ''
                    if (name) {
                      selectedColors.push(name)
                    } else {
                      selectedColors.push(flag.color)
                    }
                  }
                }

                return selectedColors
              },
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1)
                return filterList
              }
            },
            filterOptions: {
              logic: (color, filters, row) => {
                if (filters.length) return !filters.includes(color)
                return false
              },
              display: (filterList, onChange, index, column) => (
                  <FormControl variant="standard">
                    <InputLabel>
                      Flag
                    </InputLabel>
                    <Select multiple
                            value={filterList[index]}
                            renderValue={selected => {
                              const selectedColors = []

                              for (const flag of flagOptions) {
                                if (selected.includes(flag.color)) {
                                  const name = flag && flag.name ? flag.name.trim() : ''
                                  if (name) {
                                    selectedColors.push(name)
                                  } else {
                                    selectedColors.push(flag.color)
                                  }
                                }
                              }

                              return selectedColors.join(', ')
                            }}
                            onChange={event => {
                              filterList[index] = event.target.value
                              onChange(filterList[index], index, column)
                            }}
                    >
                      {flagOptions.map(item => (
                          <MenuItem key={item.id} value={item.color}>
                            <Checkbox checked={filterList[index].indexOf(item.color) > -1}
                                      sx={{
                                        color: item.color,
                                        '&.Mui-checked': {
                                          color: item.color,
                                        },
                                      }}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              )
            }
          }
        }, {
          label: 'Date', name: 'date', searchable: true,
          options: {
            filter: true,
            filterType: 'multiselect',
            customBodyRender: value => DateTime.fromISO(value).toLocaleString()
          }
        }, {
          label: 'Payee', name: 'name', searchable: true,
          options: {
            filter: true,
            filterType: 'textField'
          }
        },
          {
            label: 'Account', name: 'account', searchable: true,
            options: {
              filter: true,
              filterType: 'multiselect'
            }
          }, {
            label: 'Category', name: 'category', searchable: true,
            options: {
              filter: true,
              filterType: 'multiselect'
            }
          },
          {
            label: 'Notes', name: 'notes', searchable: true,
            options: {
              filter: false,
            }
          }, {
            label: 'Amount', name: 'amount', searchable: true,
            options: {
              setCellHeaderProps: () => {
                return {
                  className: clsx({
                    [classes.rightTableHead]: true,
                  })
                }
              },
              setCellProps: value => {
                return {
                  style: {
                    textAlign: 'right'
                  },
                }
              },
              customBodyRender: value => {
                const transactionAmount = value ? formatMoneyLabel(value) : '???'
                return (
                    <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          wrap="nowrap"
                          className={transactionAmount && transactionAmount.includes('-') ? classes.negativeAmount : classes.positiveAmount}
                    >
                      {transactionAmount}
                    </Grid>
                )
              },
              filter: false,
            }
          }, {
            label: 'Posted', name: 'reconciled', searchable: true,
            options: {
              setCellHeaderProps: () => {
                return {
                  style: {
                    padding: '4px',
                    maxWidth: '60px',
                    minWidth: '60px'
                  },
                  className: clsx({
                    [classes.centerTableHead]: true,
                  })
                }
              },
              setCellProps: value => {
                return {
                  style: {
                    textAlign: 'center',
                    padding: '4px'
                  },
                  className: clsx({
                    [classes.positiveAmount]: value === true,
                    [classes.negativeAmount]: value === false,
                  })
                }
              },
              customBodyRender: (value, tableMeta) => {
                const archived = tableMeta && tableMeta.rowData && tableMeta.rowData[10]
                    ? tableMeta.rowData[10]
                    : false
                return value
                    ? archived ? (
                        <Grid>
                          <Tooltip title="Transaction Posted">
                            <Verified fontSize="16" color="success" />
                          </Tooltip>
                          <Tooltip title="Transaction Archived">
                            <Inventory fontSize="16" color="error" />
                          </Tooltip>
                        </Grid>
                    ) : (<Tooltip title="Transaction Posted">
                          <Verified fontSize="16" color="success" />
                        </Tooltip>
                    )
                    : archived ? (
                        <Grid>
                          <Tooltip title="Transaction Pending">
                            <NewReleases fontSize="16" color="disabled" />
                          </Tooltip>
                          <Tooltip title="Transaction Archived">
                            <Inventory fontSize="16" color="error" />
                          </Tooltip>
                        </Grid>
                    ) : (
                        <Tooltip title="Transaction Pending">
                          <NewReleases fontSize="16" color="disabled" />
                        </Tooltip>
                    )
              },
              filter: true,
              filterType: 'checkbox',
              filterOptions: {
                names: [true, false],
                renderValue: v => v
                    ? (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Posted
                        </Typography>
                    )
                    : (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Not Posted
                        </Typography>
                    ),
              },
              customFilterListOptions: {
                render: v => {
                  if (v === false) return 'Not Posted'
                  else if (v === true) return 'Posted'
                }
              },
            },
          }, {
            label: 'Archived', name: 'archived',
            options: {
              display: 'excluded',
              viewColumns: false,
              searchable: false,
              filter: true,
              filterType: 'checkbox',
              filterOptions: {
                names: [true, false],
                renderValue: v => v
                    ? (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Archived
                        </Typography>
                    )
                    : (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Not Archived
                        </Typography>
                    ),
              }, customFilterListOptions: {
                render: v => {
                  if (v === false) return 'Not Archived'
                  else if (v === true) return 'Archived'
                }
              },
            }
          }]
        : [{
          label: 'ID', name: 'id',
          options: {
            display: 'excluded',
            viewColumns: false,
            filter: false,
            searchable: false
          }
        }, {
          label: 'Flag Name', name: 'flag_name',
          options: {
            display: 'excluded',
            viewColumns: false,
            filter: false,
            searchable: false
          }
        }, {
          label: 'Balance', name: 'running_balance',
          options: {
            display: 'excluded',
            viewColumns: false,
            filter: false,
            searchable: false
          }
        }, {
          label: 'Flag', name: 'flag_color', searchable: true,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const flagColor = value
                  ? value
                  : theme.palette.divider
              const flagName = tableMeta && tableMeta.rowData && tableMeta.rowData[1]
                  ? tableMeta.rowData[1]
                  : ''
              return (
                  <Box className={classes.tableLabel}
                       style={{
                         border: `1px solid ${flagColor}`,
                         color: flagColor
                       }}
                  >
                    <Typography variant="caption" align="center" noWrap>
                      {flagName}
                    </Typography>
                  </Box>
              )
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                const selectedColors = []

                for (const flag of flagOptions) {
                  if (v.includes(flag.color)) {
                    const name = flag && flag.name ? flag.name.trim() : ''
                    if (name) {
                      selectedColors.push(name)
                    } else {
                      selectedColors.push(flag.color)
                    }
                  }
                }

                return selectedColors
              },
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1)
                return filterList
              }
            },
            filterOptions: {
              logic: (color, filters, row) => {
                if (filters.length) return !filters.includes(color)
                return false
              },
              display: (filterList, onChange, index, column) => (
                  <FormControl variant="standard">
                    <InputLabel>
                      Flag
                    </InputLabel>
                    <Select multiple
                            value={filterList[index]}
                            renderValue={selected => {
                              const selectedColors = []

                              for (const flag of flagOptions) {
                                if (selected.includes(flag.color)) {
                                  const name = flag && flag.name ? flag.name.trim() : ''
                                  if (name) {
                                    selectedColors.push(name)
                                  } else {
                                    selectedColors.push(flag.color)
                                  }
                                }
                              }

                              return selectedColors.join(', ')
                            }}
                            onChange={event => {
                              filterList[index] = event.target.value
                              onChange(filterList[index], index, column)
                            }}
                    >
                      {flagOptions.map(item => (
                          <MenuItem key={item.id} value={item.color}>
                            <Checkbox checked={filterList[index].indexOf(item.color) > -1}
                                      sx={{
                                        color: item.color,
                                        '&.Mui-checked': {
                                          color: item.color,
                                        },
                                      }}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              )
            }
          }
        }, {
          label: 'Date', name: 'date', searchable: true,
          options: {
            filter: true,
            filterType: 'multiselect',
            customBodyRender: value => DateTime.fromISO(value).toLocaleString()
          }
        }, {
          label: 'Payee', name: 'name', searchable: true,
          options: {
            filter: true,
            filterType: 'textField'
          }
        }, {
          label: 'Category', name: 'category', searchable: true,
          options: {
            filter: true,
            filterType: 'multiselect'
          }
        },
          {
            label: 'Notes', name: 'notes', searchable: true,
            options: {
              filter: false,
            }
          }, {
            label: 'Amount', name: 'amount', searchable: true,
            options: {
              setCellHeaderProps: () => {
                return {
                  className: clsx({
                    [classes.rightTableHead]: true,
                  })
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                const transactionAmount = value ? formatMoneyLabel(value) : '???'
                const runningBalance = tableMeta && tableMeta.rowData && tableMeta.rowData[2]
                    ? formatMoneyLabel(tableMeta.rowData[2])
                    : '---'
                return (
                    <Grid container>
                      <Grid item xs={12} style={{ textAlign: 'right', whiteSpace: 'nowrap' }}
                            className={transactionAmount && transactionAmount.includes('-') ? classes.negativeAmount : classes.positiveAmount}
                      >
                        {transactionAmount}
                      </Grid>
                      <Grid item xs={12}
                            style={{ textAlign: 'right', whiteSpace: 'nowrap', color: theme.palette.text.secondary }}
                      >
                        {runningBalance}
                      </Grid>
                    </Grid>
                )
              },
              filter: false,
            }
          }, {
            label: 'Posted', name: 'reconciled', searchable: true,
            options: {
              setCellHeaderProps: () => {
                return {
                  style: {
                    padding: '4px',
                    maxWidth: '60px',
                    minWidth: '60px'
                  },
                  className: clsx({
                    [classes.centerTableHead]: true,
                  })
                }
              },
              setCellProps: value => {
                return {
                  style: {
                    textAlign: 'center',
                    padding: '4px'
                  },
                  className: clsx({
                    [classes.positiveAmount]: value === true,
                    [classes.negativeAmount]: value === false,
                  })
                }
              },
              customBodyRender: (value, tableMeta) => {
                const archived = tableMeta && tableMeta.rowData && tableMeta.rowData[10]
                    ? tableMeta.rowData[10]
                    : false
                return value
                    ? archived ? (
                        <Grid>
                          <Tooltip title="Transaction Posted">
                            <Verified fontSize="16" color="success" />
                          </Tooltip>
                          <Tooltip title="Transaction Archived">
                            <Inventory fontSize="16" color="error" />
                          </Tooltip>
                        </Grid>
                    ) : (<Tooltip title="Transaction Posted">
                          <Verified fontSize="16" color="success" />
                        </Tooltip>
                    )
                    : archived ? (
                        <Grid>
                          <Tooltip title="Transaction Pending">
                            <NewReleases fontSize="16" color="disabled" />
                          </Tooltip>
                          <Tooltip title="Transaction Archived">
                            <Inventory fontSize="16" color="error" />
                          </Tooltip>
                        </Grid>
                    ) : (
                        <Tooltip title="Transaction Pending">
                          <NewReleases fontSize="16" color="disabled" />
                        </Tooltip>
                    )
              },
              filter: true,
              filterType: 'checkbox',
              filterOptions: {
                names: [true, false],
                renderValue: v => v
                    ? (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Posted
                        </Typography>
                    )
                    : (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Not Posted
                        </Typography>
                    ),
              },
              customFilterListOptions: {
                render: v => {
                  if (v === false) return 'Not Posted'
                  else if (v === true) return 'Posted'
                }
              },
            }
          }, {
            label: 'Archived', name: 'archived',
            options: {
              display: 'excluded',
              viewColumns: false,
              searchable: false,
              filter: true,
              filterType: 'checkbox',
              filterOptions: {
                names: [true, false],
                renderValue: v => v
                    ? (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Archived
                        </Typography>
                    )
                    : (
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '8px' }}>
                          Not Archived
                        </Typography>
                    ),
              }, customFilterListOptions: {
                render: v => {
                  if (v === false) return 'Not Archived'
                  else if (v === true) return 'Archived'
                }
              },
            }
          }
        ]
  }

  render() {
    const { pageSize, linkTransaction } = this.state
    const {
      classes, accountName, availableBalance, postedBalance, pendingBalance,
      currentTransactions, currentLinkTransactions
    } = this.props
    const pendingLinkTransactions = currentLinkTransactions.filter(t => t.pending)
    const postedLinkTransactions = currentLinkTransactions.filter(t => !t.pending)
    const columns = this.handleColumnsBuild()
    const options = {
      responsive: 'vertical',
      selectableRows: 'none',
      rowHover: true,
      rowsPerPage: pageSize,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      print: false,
      sort: false,
      download: true,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      onRowClick: rowData => this.transactionClick(rowData),
      setFilterChipProps: (colIndex, colName, data) => {
        return {
          color: 'primary',
          variant: 'contained',
          className: classes.tableChips,
        }
      }
    }

    return (
        <Fragment>
          {currentLinkTransactions && currentLinkTransactions.length > 0 &&
              <Box mt={2}>
                <TableContainer component={Paper} elevation={3}>
                  <Table size="small" aria-label="link transactions">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={6}>Link Transactions</TableCell>
                      </TableRow>
                    </TableHead>
                    {pendingLinkTransactions && pendingLinkTransactions.length > 0 &&
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={6} align="center">Pending</TableCell>
                          </TableRow>
                          {pendingLinkTransactions.map((row, index) => {
                            let amount = row.amount > 0 ? -Math.abs(row.amount) : Math.abs(row.amount)
                            const amountClasses = amount > 0 ? classes.positiveAmount : classes.negativeAmount
                            return (
                                <TableRow key={`pending-${row.transaction_id}-${index}`}
                                          onClick={() => this.linkTransactionClick(row)}>
                                  <TableCell>{DateTime.fromISO(row.date).toLocaleString()}</TableCell>
                                  <TableCell>{row.merchant_name ? row.merchant_name : row.name}</TableCell>
                                  <TableCell className={amountClasses} align="right">
                                    {formatMoneyLabel(amount)}
                                  </TableCell>
                                </TableRow>
                            )
                          })}
                        </TableBody>
                    }
                    {postedLinkTransactions && postedLinkTransactions.length > 0 &&
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={6} align="center">Posted</TableCell>
                          </TableRow>
                          {postedLinkTransactions.map((row, index) => {
                            let amount = row.amount > 0 ? -Math.abs(row.amount) : Math.abs(row.amount)
                            const amountClasses = amount > 0 ? classes.positiveAmount : classes.negativeAmount
                            return (
                                <TableRow key={`posted-${row.transaction_id}-${index}`}
                                          onClick={() => this.linkTransactionClick(row)}>
                                  <TableCell>{DateTime.fromISO(row.date).toLocaleString()}</TableCell>
                                  <TableCell>{row.merchant_name ? row.merchant_name : row.name}</TableCell>
                                  <TableCell className={amountClasses} align="right">
                                    {formatMoneyLabel(amount)}
                                  </TableCell>
                                </TableRow>
                            )
                          })}
                        </TableBody>
                    }
                  </Table>
                </TableContainer>
              </Box>
          }
          <Box mt={1}>
            <MUIDataTable
                title={<TableHeader name={accountName}
                                    posted={postedBalance}
                                    pending={pendingBalance}
                                    available={availableBalance}
                />}
                data={currentTransactions}
                columns={columns}
                options={options}
            />
          </Box>
          <LinkTransactionForm clearLinkTransaction={this.clearLinkTransaction}
                               linkTransaction={linkTransaction} />
        </Fragment>
    )
  }
}

TransactionList.propTypes = {
  editTransactionId: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  currentTransactions: PropTypes.array.isRequired,
  currentLinkTransactions: PropTypes.array.isRequired,
  flagColors: PropTypes.array.isRequired,
  transactionFlags: PropTypes.array.isRequired,
  accountKey: PropTypes.number.isRequired,
  availableBalance: PropTypes.number.isRequired,
  postedBalance: PropTypes.number.isRequired,
  pendingBalance: PropTypes.number.isRequired,
  yearMonth: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  flagColors: state.flagColorReducer.flagColors,
  transactionFlags: state.transactionFlagReducer.transactionFlags,
})

const mapDispatchToProps = {
  editTransactionId,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(TransactionList))
