import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid
} from '@mui/material'
import { Save, Cancel } from '@mui/icons-material'

import { sharedStyles, SwitchPositive } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { editCategoryBudget } from '../../actions/categoryBudgets'
import { editCategoryBudgetId } from '../../actions/categoryBudgetId'
import { fullScreen, Transition } from '../utilities/dialogs'
import { moneyInput } from '../utilities/numbers'
import { returnErrors } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

class CategoryBudgetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        budgeted: {
          error: null,
          helperText: '',
        },
        goal: {
          error: null,
          helperText: '',
        },
      },
      open: false,
      id: 0,
      goal: 0.00,
      budgeted: 0.00,
      goalPositive: true,
      budgetedPositive: true,
      categoryName: ''
    }

    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoalPositive = this.handleGoalPositive.bind(this)
    this.handleBudgetedPositive = this.handleBudgetedPositive.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { categories, categoryBudgetId, categoryBudgets } = this.props

    if (categoryBudgetId !== prevProps.categoryBudgetId && categoryBudgetId > 0) {
      for (let categoryBudget of categoryBudgets) {
        if (categoryBudgetId === categoryBudget.category_id) {
          for (let category of categories) {
            if (category.id === categoryBudgetId) {
              let goalPositive = categoryBudget.goal >= 0
              let budgetedPositive = categoryBudget.budgeted >= 0

              this.setState({
                open: true,
                id: categoryBudget.id,
                goal: Math.abs(categoryBudget.goal),
                budgeted: Math.abs(categoryBudget.budgeted),
                goalPositive,
                budgetedPositive,
                categoryName: category.name
              })

              // End loop
              break
            }
          }

          // End loop
          break
        }
      }
    }
  }

  clearForm = () => {
    this.props.editCategoryBudgetId(-1)
    this.setState({
      open: false,
      id: 0,
      goal: 0.00,
      budgeted: 0.00,
      goalPositive: true,
      budgetedPositive: true,
    })
  }

  handleChange = e => {
    if (moneyInput(e.target.value)) this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    try {
      const { id, goal, budgeted, goalPositive, budgetedPositive } = this.state
      const { yearMonth, categoryBudgetId, editCategoryBudget } = this.props
      const { user } = this.props.auth
      let budgetYear = yearMonth.substring(0, 4)
      let budgetMonth = yearMonth.substring(4, 6)
      let budgetDate = `${budgetYear}-${budgetMonth}-01`
      let netGoal = goalPositive ? goal : -Math.abs(goal)
      let netBudgeted = budgetedPositive ? budgeted : -Math.abs(budgeted)
      // Edit Budget
      const budget = {
        id,
        goal: netGoal,
        budgeted: netBudgeted,
        category_id: categoryBudgetId,
        goal_id: 1,
        user_id: user.id,
        date: budgetDate
      }

      editCategoryBudget(budget, yearMonth)
      this.clearForm()
    } catch (e) {
      this.props.returnErrors({ updateCategoryBudget: 'Unable to update category budget at this time. Please try again later.' }, 500)
    }
  }

  handleGoalPositive = () => this.setState({ goalPositive: !this.state.goalPositive })

  handleBudgetedPositive = () => this.setState({ budgetedPositive: !this.state.budgetedPositive })

  render() {
    const {
      error, open, goal, budgeted, goalPositive, budgetedPositive, categoryName
    } = this.state
    const { classes, theme } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="category-budget-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Budget for {categoryName}
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={4}>
                    <Grid container alignItems="center" justifyContent="center" spacing={1} wrap="nowrap">
                      <Grid item>-</Grid>
                      <Grid item>
                        <SwitchPositive checked={budgetedPositive}
                                        onChange={this.handleBudgetedPositive}
                                        name="budgetedPositive"
                                        inputProps={{ 'aria-label': 'amount-positive' }}
                        />
                      </Grid>
                      <Grid item>+</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField required
                               autoFocus
                               name="budgeted"
                               value={budgeted}
                               onChange={this.handleChange}
                               error={error.budgeted.error}
                               helperText={error.budgeted.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="categoryBudgetBudgeted"
                               label="Budgeted"
                               type="text"
                               fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container alignItems="center" justifyContent="center" spacing={1} wrap="nowrap">
                      <Grid item>-</Grid>
                      <Grid item>
                        <SwitchPositive checked={goalPositive}
                                        onChange={this.handleGoalPositive}
                                        name="goalPositive"
                                        inputProps={{ 'aria-label': 'amount-positive' }}
                        />
                      </Grid>
                      <Grid item>+</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField required
                               name="goal"
                               value={goal}
                               onChange={this.handleChange}
                               error={error.goal.error}
                               helperText={error.goal.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="categoryBudgetGoal"
                               label="Goal"
                               type="text"
                               fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={this.clearForm} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

CategoryBudgetForm.propTypes = {
  editCategoryBudget: PropTypes.func.isRequired,
  editCategoryBudgetId: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
  categoryBudgetId: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  yearMonth: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  categoryBudgetId: state.categoryBudgetIdReducer.categoryBudgetId,
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
  categories: state.categoryReducer.categories,
  yearMonth: state.budgetMonthReducer.yearMonth,
})

const mapDispatchToProps = {
  editCategoryBudgetId,
  editCategoryBudget,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CategoryBudgetForm))
