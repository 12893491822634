import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_ACCOUNT_GROUPS, EDIT_ACCOUNT_GROUPS, REORDER_ACCOUNT_GROUPS,
  ACCOUNT_GROUPS_LOADING, ACCOUNT_GROUPS_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get Account groups
export const getAccountGroups = () => (dispatch, getState) => {
  dispatch({ type: ACCOUNT_GROUPS_LOADING })
  axios.get(`${proxy}/v1/account_group_preferences/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_ACCOUNT_GROUPS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: ACCOUNT_GROUPS_FAIL })
      })
}

/**
 * Used to edit account group preferences.
 * @param {object} group - The group preference object.
 * @returns {(function(*, *): void)|*}
 */
export const editAccountGroups = (group) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/account_group_preferences/${group.id}/`, group, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateGroup: 'Group Updated' }))
        dispatch({
          type: EDIT_ACCOUNT_GROUPS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Reorder the account groups
export const reorderAccountGroups = (group) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/account_group_preferences/reorder/${group.id}/`, group, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateGroup: 'Groups Updated' }))
        dispatch({
          type: REORDER_ACCOUNT_GROUPS,
          payload: res.data
        })
        dispatch(getAccountGroups())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
