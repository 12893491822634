import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import MUIDataTable from 'mui-datatables'
import {
  Paper, Tooltip, IconButton, Toolbar
} from '@mui/material'
import { Edit } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import PayeeForm from '../layout/PayeeForm'

const styles = theme => ({
  ...sharedStyles(theme),
})

const ROWS_PER_PAGE_OPTIONS = [50, 100, 200]

const columns = [{ label: 'Payee', name: 'name' }]

class Payee extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      selectedPayees: [],
      selectedPayeesDataIndexes: [],
      pageSize: 50
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.payeeClick = this.payeeClick.bind(this)
    this.selectPayees = this.selectPayees.bind(this)
  }

  /**
   * Close the dialog and reset the selected payees.
   */
  closeDialog = () => this.setState({ open: false, selectedPayees: [], selectedPayeesDataIndexes: [] })

  /**
   * Open the dialog and set the selected payee.
   * @param {Object} row - The selected payee.
   */
  payeeClick = row => this.setState({ open: true, selectedPayees: row })

  /**
   * Select the payees, open the form, and update the payees state.
   * @param {Array.<Object>} selectedRows - The selected rows from the
   * table.
   * @param {Array.<Object>} displayData - All of the rows currently
   * displayed in the table.
   */
  selectPayees = (selectedRows, displayData) => {
    try {
      let selectedPayees = []
      const data = selectedRows && selectedRows.data ? selectedRows.data : []
      if (data.length > 0) {
        for (const row of data) {
          const rowIndex = row && (row.index || row.index === 0) ? row.index : null
          if (rowIndex != null && displayData[rowIndex].dataIndex === row.dataIndex) {
            const payee = displayData[rowIndex].data[0]
            selectedPayees.push(payee)
          }
        }

        this.setState({ open: true, selectedPayees })
      }
    } catch (e) {
      return false
    }
  }

  render() {
    const { open, selectedPayees, selectedPayeesDataIndexes, pageSize } = this.state
    const { payees } = this.props
    const payeeOptions = payees.filter(p => p.name
        && p.name.trim().toLowerCase() !== 'adjustment')

    const options = {
      responsive: 'standard',
      rowsPerPage: pageSize,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      print: false,
      viewColumns: false,
      download: false,
      filter: false,
      selectableRows: 'multiple',
      selectableRowsOnClick: true,
      rowsSelected: selectedPayeesDataIndexes,
      customToolbarSelect: (selectedRows, displayData) => (
          <Toolbar variant="dense">
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => this.selectPayees(selectedRows, displayData)}
                size="large">
                <Edit />
              </IconButton>
            </Tooltip>
          </Toolbar>
      ),
    }

    return (
        <Fragment>
          <Paper elevation={3}>
            <MUIDataTable title="Payees"
                          data={payeeOptions}
                          columns={columns}
                          options={options}
            />
          </Paper>
          <PayeeForm open={open}
                     closeDialog={this.closeDialog}
                     payees={selectedPayees} />
        </Fragment>
    )
  }
}

Payee.propTypes = {
  payees: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  payees: state.payeeReducer.payees,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Payee))
