import axios from 'axios'
import { returnErrors } from './messages'
import {
  GET_SPLITS, SPLIT_LOADING, SPLIT_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get Splits
export const getSplits = () => (dispatch, getState) => {
  dispatch({ type: SPLIT_LOADING })
  axios.get(`${proxy}/v1/splits/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_SPLITS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: SPLIT_FAIL })
      })
}
