import React, { Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { usePlaidLink } from 'react-plaid-link'
import { Button } from '@mui/material'
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material'

import { addLinkTransactions, createLinkToken } from '../../actions/link'
import { returnErrors } from '../../actions/messages'

const Link = props => {
  let { linkToken } = props

  const onSuccess = useCallback((publicToken, metadata) => {
    const { linkAccounts, addLinkTransactions, returnErrors } = props
    // verify not a duplicate
    let duplicate = false
    const accounts = metadata && metadata.accounts ? metadata.accounts : []
    const institution = metadata && metadata.institution ? metadata.institution.institution_id : null

    for (const account of accounts) {
      let mask = account.mask
      let name = account.name
      for (const linkAccount of linkAccounts) {
        if (linkAccount.mask === mask && linkAccount.name === name && linkAccount.institution === institution) {
          duplicate = true
        }
      }
    }

    if (duplicate) {
      returnErrors({ linkAPI: 'This account is already linked.' }, 500)
    } else {
      // send public_token to server
      addLinkTransactions(publicToken)
    }
  }, [])

  const onEvent = useCallback((eventName, metadata) => {
  }, [])

  /**
   * Plaid link exit event. Used to catch if token is expired.
   * TODO: Implement better error handling and token expiration check.
   * @param error
   * @param metadata
   */
  const onExit = useCallback((error, metadata) => {
    const { user, linkToken, createLinkToken } = props

    if (error && error.error_code === 'INVALID_LINK_TOKEN') {
      if (user.id && linkToken) {
        createLinkToken()
      }
    }
  }, [])

  const config = {
    token: linkToken,
    onSuccess,
    onEvent,
    onExit
  }

  const { open, ready, error } = usePlaidLink(config)

  return (
      <Fragment>
        {!ready || error || !linkToken
            ?
            <Button disabled color="primary" variant="contained">
              <LinkOffIcon />&nbsp;Loading...
            </Button>
            :
            <Button onClick={() => open()
            } color="primary" variant="contained">
              <LinkIcon />&nbsp;Connect
            </Button>
        }
      </Fragment>
  )
}

Link.propTypes = {
  user: PropTypes.object.isRequired,
  linkToken: PropTypes.string.isRequired,
  addLinkTransactions: PropTypes.func.isRequired,
  createLinkToken: PropTypes.func.isRequired,
  linkAccounts: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  linkToken: state.linkReducer.linkToken,
  user: state.authReducer.user,
  linkAccounts: state.linkReducer.linkAccounts
})

const mapDispatchToProps = {
  addLinkTransactions,
  createLinkToken,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(Link)
