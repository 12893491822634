import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

class Error404 extends Component {
  render() {
    return <Navigate to="/" />
  }
}

export default Error404
