import {
  GET_PAYEES, EDIT_PAYEES, PAYEE_LOADING, PAYEE_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  payees: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAYEES:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        payees: action.payload
      }
    case EDIT_PAYEES:
      return {
        ...state,
        error: false,
        isFetching: false
      }
    case PAYEE_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case PAYEE_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}