import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, ListItem, ListItemText
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Save, Cancel } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { bulkCategoryBudgetUpdate } from '../../actions/categoryBudgets'
import { editCategoryBalanceId } from '../../actions/categoryBalanceId'
import { fullScreen, Transition } from '../utilities/dialogs'
import { formatMoneyLabel, moneyInput } from '../utilities/numbers'
import { returnErrors } from '../../actions/messages'

const filter = createFilterOptions()
const styles = theme => ({
  ...sharedStyles(theme)
})

class MoveBalanceForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        amount: { error: null, helperText: '', },
        fromCategory: { error: null, helperText: '', },
      },
      filteredCategories: [],
      open: false,
      amount: '',
      toCategoryId: 0,
      toCategoryName: '',
      fromCategory: { id: 0, name: '' },
    }

    this.handleCategoryOptions = this.handleCategoryOptions.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { categoryBalanceId } = this.props

    /**
     * If category balance Id is updated open up the dialog. Filter
     * out the category they clicked on from the options.
     */
    if (categoryBalanceId !== prevProps.categoryBalanceId && categoryBalanceId > 0) {
      this.handleCategoryOptions()
    }
  }

  /**
   * Populates the category options for the dropdown.
   */
  handleCategoryOptions = () => {
    try {
      const { classes, categories, categoryBalanceId, categoryBudgets } = this.props

      const toCategory = categories.find(f => f.id === categoryBalanceId)
      const toBudget = categoryBudgets.find(f => f.category_id === categoryBalanceId)
      const toCategoryAmount = toBudget && toBudget.balance ? toBudget.balance : 0
      const amountNeeded = toCategoryAmount && toCategoryAmount < 0 ? Math.abs(toCategoryAmount) : 0
      const toCategoryName = toCategory ? toCategory.name : ''
      let filteredCategories = []

      for (const c of categories) {
        if (categoryBalanceId === c.id) continue
        for (const cb of categoryBudgets) {
          if (c.id === cb.category_id) {
            if (!c.archived) {
              const balance = cb.balance ? formatMoneyLabel(cb.balance) : '$0.00'
              const styles = cb.balance >= 0 ? classes.dropdownListItem : classes.dropdownListItemError
              const name = c.name ? c.name.toLowerCase().trim() : null

              if (name !== 'budget' && name !== 'split') {
                const label = `${c.name}: ${balance}`

                filteredCategories.push({
                  id: c.id,
                  name: c.name,
                  balance: balance,
                  label: label,
                  styles: styles
                })
              }
              // End inner loop
              break
            }
          }
        }
      }

      this.setState({
        open: true,
        toCategoryId: categoryBalanceId,
        toCategoryName,
        amount: amountNeeded,
        filteredCategories
      })
    } catch (e) {
      this.props.returnErrors({ updateCategoryBudget: 'Unable to move money at this time. Please try again later.' }, 500)
    }
  }

  /**
   * Used to clear the form and close the dialog.
   */
  clearForm = () => {
    this.props.editCategoryBalanceId(-1)
    this.setState({
      open: false,
      amount: '',
      toCategoryId: 0,
      toCategoryName: '',
      fromCategory: { id: 0, name: '' },
    })
  }

  handleInputChange = e => {
    if (moneyInput(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleCategoryChange = (e, values, reason) => {
    if (reason === 'clear') {
      this.setState({ fromCategory: { id: 0, name: '' } })
    } else if (values) {
      this.setState({ fromCategory: values })
    }
  }

  /**
   * Submit the balance form
   * @param {Object} e - The event object.
   */
  handleSubmit = e => {
    e.preventDefault()
    try {
      const { amount, toCategoryId, fromCategory } = this.state
      const { yearMonth, bulkCategoryBudgetUpdate } = this.props
      const { user } = this.props.auth
      const moveFromCategory = (fromCategory && fromCategory.id || null)
      const moveToCategory = (toCategoryId || null)
      if (!moveToCategory || !moveFromCategory) throw new Error('CategoryMissing')
      const categories = [moveFromCategory, moveToCategory]
      let budgetYear = yearMonth.substring(0, 4)
      let budgetMonth = yearMonth.substring(4, 6)
      let budgetDate = `${budgetYear}-${budgetMonth}-01`
      const moveAmount = Math.abs(amount)

      // Bulk edit category budgets
      const budget = {
        action: 'move_budget',
        goal_id: 1,
        user_id: user.id,
        date: budgetDate,
        categories: categories,
        budgeted: moveAmount,
        category_id: moveFromCategory
      }

      bulkCategoryBudgetUpdate(budget, yearMonth)
      this.clearForm()
    } catch (e) {
      this.props.returnErrors({ updateCategoryBudget: 'Unable to move money at this time. Please try again later.' }, 500)
    }
  }

  render() {
    const { error, filteredCategories, open, amount, toCategoryName, fromCategory } = this.state
    const { classes, theme } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="move-budget-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Add Money to {toCategoryName}
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField required
                               name="amount"
                               value={amount}
                               autoFocus
                               onChange={this.handleInputChange}
                               error={error.amount.error}
                               helperText={error.amount.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="transactionAmount"
                               label="Amount"
                               type="text"
                               fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        name="fromCategory"
                        clearOnEscape
                        openOnFocus
                        autoComplete
                        autoHighlight
                        includeInputInList
                        value={fromCategory}
                        options={filteredCategories}
                        isOptionEqualToValue={(option, value,) => value.value === option.value}
                        getOptionLabel={(option) => {
                          if (option.inputValue) return option.inputValue
                          else if (option.label) return option.label
                          else return option.name
                        }}
                        onChange={this.handleCategoryChange}
                        filterOptions={(options, params) => filter(options, params)}
                        renderOption={(props, option) => (
                            <ListItem key={option.id} {...props}>
                              <ListItemText align="left" primary={option.name} />
                              <ListItemText align="right" className={option.styles} primary={option.balance} />
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField required
                                       error={error.fromCategory.error}
                                       helperText={error.fromCategory.helperText}
                                       margin="dense"
                                       label="From Category"
                                       type="text"
                                       fullWidth {...params}
                            />
                        )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={() => this.clearForm()} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

MoveBalanceForm.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
  categoryBalanceId: PropTypes.number.isRequired,
  bulkCategoryBudgetUpdate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  categories: state.categoryReducer.categories,
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
  categoryBalanceId: state.categoryBalanceIdReducer.categoryBalanceId
})

const mapDispatchToProps = {
  editCategoryBalanceId,
  bulkCategoryBudgetUpdate,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MoveBalanceForm))
