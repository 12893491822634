import {
  CREATE_LINK_TOKEN, CREATE_LINK_TOKEN_FAIL, DELETE_LINK_TOKEN_SUCCESS,
  CREATE_FIX_TOKEN, CREATE_FIX_TOKEN_FAIL, DELETE_FIX_TOKEN_SUCCESS,
  GET_LINK_ACCOUNTS, LINK_ACCOUNTS_LOADING, LINK_ACCOUNTS_FAIL,
  CREATE_LINK_TRANSACTIONS, GET_LINK_TRANSACTIONS, EDIT_LINK_TRANSACTION,
  LINK_TRANSACTIONS_LOADING, LINK_TRANSACTIONS_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isAccountFetching: true,
  isTransactionFetching: true,
  isAccountLoaded: false,
  isTransactionLoaded: false,
  linkToken: null,
  fixToken: null,
  linkAccounts: [],
  linkTransactions: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_LINK_TOKEN:
      return {
        ...state,
        linkToken: action.payload,
      }
    case CREATE_FIX_TOKEN:
      return {
        ...state,
        fixToken: action.payload,
      }
    case GET_LINK_ACCOUNTS:
      return {
        ...state,
        error: false,
        isAccountFetching: false,
        isAccountLoaded: true,
        linkAccounts: action.payload
      }
    case CREATE_LINK_TRANSACTIONS:
      return {
        ...state,
        isTransactionFetching: false,
        isTransactionLoaded: true,
      }
    case GET_LINK_TRANSACTIONS:
      return {
        ...state,
        error: false,
        isTransactionFetching: false,
        isTransactionLoaded: true,
        linkTransactions: action.payload
      }
    case EDIT_LINK_TRANSACTION:
      return {
        ...state,
        error: false,
        isTransactionFetching: false,
        isTransactionLoaded: true,
        linkTransactions: state.linkTransactions.filter(transaction => transaction.id !== action.payload.id)
      } // Return the state minus the passed transaction id.
    case LINK_ACCOUNTS_LOADING:
      return {
        ...state,
        error: false,
        isAccountFetching: true,
      }
    case LINK_TRANSACTIONS_LOADING:
      return {
        ...state,
        error: false,
        isTransactionFetching: true,
        fixToken: null
      }
    case CREATE_FIX_TOKEN_FAIL:
    case DELETE_FIX_TOKEN_SUCCESS:
      return {
        ...state,
        fixToken: null
      }
    case DELETE_LINK_TOKEN_SUCCESS:
    case CREATE_LINK_TOKEN_FAIL:
    case LINK_ACCOUNTS_FAIL:
    case LINK_TRANSACTIONS_FAIL:
      localStorage.removeItem('ploutos-link-token')
      return {
        ...state,
        error: true,
        isAccountFetching: false,
        isTransactionFetching: false,
        linkToken: null,
        fixToken: null,
      }
    default:
      return state
  }
}
