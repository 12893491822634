import { GET_COLLECTION_ID, EDIT_COLLECTION_ID } from './types'

// Get Collection ID
export const getCollectionId = () => (dispatch, getState) => {
  dispatch({
    type: GET_COLLECTION_ID,
    payload: getState
  })
}

// Edit Collection ID
export const editCollectionId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_COLLECTION_ID,
    payload: id
  })
}
