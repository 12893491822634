export const verticalLine = {
  id: 'spendingChartVsLastMonth', //typescript crashes without id
  afterDraw: function (chart, ease) {
    try {
      if (chart && chart.tooltip && chart.tooltip._active && chart.tooltip._active.length && chart.tooltip._active.length > 0) {
        const activePoint = chart.tooltip._active[0]
        const ctx = chart.ctx
        const x = activePoint.element.x
        const topY = chart.scales.y.top
        const bottomY = chart.scales.y.bottom

        // line attributes
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, topY)
        ctx.lineTo(x, bottomY)
        ctx.lineWidth = 2
        ctx.strokeStyle = '#0f4e5b'
        ctx.stroke()
        ctx.restore()
      }
    } catch (e) {

    }
  }
}

// export const options = {
//   tooltips: {
//     titleFontSize: 16,
//     titleFontStyle: 'bold',
//     bodyFontSize: 16,
//     enabled: true,
//     intersect: false,
//     position: 'average',
//     mode: 'label',
//     custom: function (tooltipModel) {
//       tooltipModel.opacity = 0
//     }
//   },
//   legend: {
//     display: false
//   },
//   scales: {
//     xAxes: [{
//       ticks: {
//         autoSkip: false,
//         maxRotation: 45,
//         minRotation: 45
//       }
//     }]
//   }
// }

export const backgroundColors = [
  'rgba(255, 99, 132, 0.3)',
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 159, 64, 0.3)',
  'rgba(180, 0, 0, 0.3)',
  'rgba(0, 180, 0, 0.3)',
  'rgba(0, 0, 180, 0.3)',
  'rgba(0, 180, 180, 0.3)',
  'rgba(180, 180, 0, 0.3)',
  'rgba(180, 0, 180, 0.3)',
  'rgba(80, 0, 0, 0.3)',
  'rgba(0, 80, 0, 0.3)',
  'rgba(0, 0, 80, 0.3)',
  'rgba(80, 0, 80, 0.3)',
  'rgba(80, 80, 0, 0.3)',
  'rgba(0, 80, 80, 0.3)',
  'rgba(255, 0, 0, 0.3)',
  'rgba(0, 255, 0, 0.3)',
  'rgba(0, 0, 255, 0.3)',
  'rgba(255, 255, 0, 0.3)',
  'rgba(255, 0, 255, 0.3)',
  'rgba(0, 255, 255, 0.3)'
]

export const borderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(180, 0, 0, 1)',
  'rgba(0, 180, 0, 1)',
  'rgba(0, 0, 180, 1)',
  'rgba(0, 180, 180, 1)',
  'rgba(180, 180, 0, 1)',
  'rgba(180, 0, 180, 1)',
  'rgba(80, 0, 0, 1.0)',
  'rgba(0, 80, 0, 1.0)',
  'rgba(0, 0, 80, 1.0)',
  'rgba(80, 0, 80, 1.0)',
  'rgba(80, 80, 0, 1.0)',
  'rgba(0, 80, 80, 1.0)',
  'rgba(255, 0, 0, 1.0)',
  'rgba(0, 255, 0, 1.0)',
  'rgba(0, 0, 255, 1.0)',
  'rgba(255, 255, 0, 1.0)',
  'rgba(255, 0, 255, 1.0)',
  'rgba(0, 255, 255, 1.0)'
]
