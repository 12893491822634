import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import arrayMove from 'array-move'

import { sharedStyles } from '../common/styles'
import { SortContainer } from '../common/SortContainer'
import { SortableAccounts } from './SortAccounts'
import { editAccountId } from '../../actions/accountId'
import { editAccountKey } from '../../actions/accountKey'
import { reorderAccounts } from '../../actions/accounts'

const styles = theme => ({
  ...sharedStyles(theme)
})

class Accounts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deriveProps: true,
      accounts: this.props.accounts
    }

    this.accountClick = this.accountClick.bind(this)
  }

  /**
   * Update the edit account id. The edit account id is used to
   * decide whether to display the edit account form and which
   * account is trying to be edited.
   * @param {number} id - The edit account id to set.
   */
  accountClick = id => this.props.editAccountId(id)

  onSortEnd = ({ oldIndex, newIndex }) => {
    try {
      const { accounts, reorderAccounts } = this.props

      let oldPositionElement = accounts[oldIndex]
      let newPositionElement = accounts[newIndex]
      if (oldPositionElement !== newPositionElement) {
        this.setState({
          deriveProps: false, // Temporarily stop props affecting state
          accounts: arrayMove(accounts, oldIndex, newIndex),
        })
        oldPositionElement.new_index = newIndex
        reorderAccounts(oldPositionElement)

        // After a very short wait, allow props to affect state again
        setTimeout(() => {
          this.setState(() => ({
            deriveProps: true,
          }))
        }, 3000)
      }
    } catch (e) {
      //  TODO: dispatch error
    }
  }

  /**
   * Update state with prop changes, except for a brief period after
   * the order has changed, to stop flickering from happening
   * @param props
   * @param state
   * @returns {{accounts: *}|null}
   */
  static getDerivedStateFromProps(props, state) {
    if (state.deriveProps) {
      return {
        accounts: props.accounts,
      }
    }
    return null
  }

  render() {
    const { classes, linkAccounts, linkTransactions, group } = this.props
    const { accounts } = this.state

    return (
        <SortContainer onSortEnd={this.onSortEnd} useDragHandle className={classes.p0}>
          {accounts.map((account, index) => {
            const linkAccount = linkAccounts.find((link) => account.link_id === link.id)
            const count = linkTransactions.filter(t => linkAccount && !t.pending && linkAccount.id === t.account_id).length
            const groupId = account && account.group_id ? account.group_id : null
            const archived = account.archived
            return !archived && (groupId === group.group_id)
                ? (
                    <SortableAccounts
                        key={`sortable-account-${account.id}`}
                        index={index}
                        accountClick={this.accountClick}
                        account={account}
                        linkAccount={linkAccount}
                        count={count}
                        classes={classes}
                    />
                )
                : null
          })}
        </SortContainer>
    )
  }
}

Accounts.propTypes = {
  editAccountId: PropTypes.func.isRequired,
  editAccountKey: PropTypes.func.isRequired,
  reorderAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  group: PropTypes.object.isRequired,
  linkAccounts: PropTypes.array.isRequired,
  linkTransactions: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  linkTransactions: state.linkReducer.linkTransactions
})

const mapDispatchToProps = {
  editAccountId,
  editAccountKey,
  reorderAccounts,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Accounts))
