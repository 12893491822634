import {
  USER_LOADED, USER_LOADING, AUTH_ERROR, AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS,
  REGISTER_SUCCESS, REGISTER_FAIL, ACTIVATION_SUCCESS, ACTIVATION_FAIL,
  PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL, PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL, EDIT_PROFILE,
  DELETE_USER_ACCOUNT, DELETE_USER_ACCOUNT_FAIL
} from '../actions/types'

const initialState = {
  access: localStorage.getItem('ploutos-access'),
  refresh: localStorage.getItem('ploutos-refresh'),
  isAuthenticated: false,
  accountCreated: false,
  accountActivated: false,
  resetPasswordRequested: false,
  resetPasswordSuccess: false,
  isLoading: true,
  user: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      }
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      }
    case LOGIN_SUCCESS:
      if (action.payload.access) {
        localStorage.setItem('ploutos-access', action.payload.access)
      }
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        access: action.payload.access,
        refresh: action.payload.refresh
      }
    case EDIT_PROFILE:
      return {
        ...state,
        user: action.payload
      }
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        accountActivated: true,
        isLoading: false,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        accountCreated: action.payload,
        isLoading: false,
      }
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resetPasswordRequested: true,
        isLoading: false,
      }
    case PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
        isLoading: false,
      }
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      }
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case DELETE_USER_ACCOUNT:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
    case ACTIVATION_FAIL:
      localStorage.removeItem('ploutos-access')
      localStorage.removeItem('ploutos-refresh')
      localStorage.removeItem('ploutos-link-token')
      return {
        state: null,
        access: null,
        refresh: null,
        user: null,
        isAuthenticated: false,
        accountActivated: false,
        resetPasswordRequested: false,
        resetPasswordSuccess: false,
        isLoading: false,
        accountCreated: false,
      }
    case DELETE_USER_ACCOUNT_FAIL:
    case PASSWORD_RESET_FAIL:
    case PASSWORD_RESET_CONFIRM_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
