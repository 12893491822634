import { GET_ACCOUNT_ID, EDIT_ACCOUNT_ID } from './types'

// Get account Id
export const getAccountId = () => (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNT_ID,
    payload: getState
  })
}

// Edit account Id
export const editAccountId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_ACCOUNT_ID,
    payload: id
  })
}
