import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, TextField, Dialog, DialogActions, DialogContent,
  DialogTitle, Grid
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Save, Cancel, Delete, Inventory } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { addCategory, editCategory, deleteCategory } from '../../actions/categories'
import { editCategoryId } from '../../actions/categoryId'
import { fullScreen, Transition } from '../utilities/dialogs'

const styles = theme => ({
  ...sharedStyles(theme),
})

class CategoryForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        name: {
          error: null,
          helperText: '',
        },
        notes: {
          error: null,
          helperText: '',
        },
        collection: {
          error: null,
          helperText: '',
        },
      },
      open: false,
      name: '',
      notes: '',
      collection: null,
      canDelete: true
    }

    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCollectionChange = this.handleCollectionChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { categoryId, collections, categories } = this.props

    if (categoryId !== prevProps.categoryId && categoryId >= 0) {
      if (categoryId === 0) {
        // New category record.
        this.setState({ open: true })
      } else if (categoryId > 0) {
        // Update an existing category record.
        for (let category of categories) {
          if (categoryId === category.id) {
            for (let collection of collections) {
              if (collection.id === category.collection_id) {

                this.setState({
                  open: true,
                  name: category.name,
                  notes: category.notes,
                  collection,
                  canDelete: category.can_delete
                })

                // End loop
                break
              }
            }

            // End loop
            break
          }
        }
      }
    }
  }

  clearForm = () => {
    this.props.editCategoryId(-1)
    this.setState({
      open: false,
      name: '',
      notes: '',
      collection: null,
      canDelete: true
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleCollectionChange = (event, value) => {
    if (value) this.setState({ collection: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { name, notes, collection } = this.state
    const { user } = this.props.auth
    const { categoryId, yearMonth } = this.props
    const collectionId = collection && collection.id ? collection.id : null
    if (!collectionId) return false

    if (categoryId > 0) {
      // Edit Category
      const category = {
        id: categoryId,
        name,
        notes,
        collection_id: collectionId,
        user_id: user.id
      }

      this.props.editCategory(category, yearMonth)
    } else {
      // Add Category
      const category = {
        name,
        notes,
        collection_id: collectionId,
        user_id: user.id
      }

      this.props.addCategory(category, yearMonth)
    }
    this.clearForm()
  }

  /**
   * This will delete the category permanently if there are no
   * transactions or budget dollars tied to this category. If there are
   * then it will set the archive flag to true.
   */
  handleDelete = () => {
    const { yearMonth, categoryId } = this.props

    this.props.deleteCategory(categoryId, yearMonth)
    this.clearForm()
  }

  render() {
    const { open, name, notes, collection, canDelete, error } = this.state
    const { classes, theme, categoryId, collections } = this.props

    const deleteButton = canDelete
        ? (
            <Button onClick={this.handleDelete}
                    className={`${classes.dangerButton} ${classes.floatRight}`}
                    variant="outlined">
              <Delete className={classes.pr1} />Delete
            </Button>
        ) : (
            <Button onClick={this.handleDelete}
                    className={`${classes.dangerButton} ${classes.floatRight}`}
                    variant="outlined">
              <Inventory className={classes.pr1} />Archive
            </Button>
        )

    return (
        <Fragment>
          <Dialog
              open={open}
              aria-labelledby="category-form"
              TransitionComponent={Transition}
              fullScreen={fullScreen(theme)}
              PaperComponent={DraggableDialogWrapper}
              maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Category
              {categoryId > 0 ? deleteButton : ''}
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} justifyContent="center">

                  <Grid item xs={12}>
                    <Autocomplete
                        name="collection"
                        clearOnEscape
                        openOnFocus
                        autoComplete
                        autoHighlight
                        includeInputInList
                        value={collection}
                        options={collections}
                        isOptionEqualToValue={(option, value,) => value.value === option.value}
                        getOptionLabel={option => option.name ? option.name : ''}
                        onChange={this.handleCollectionChange}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        renderInput={(params) => (
                            <TextField required
                                       error={error.collection.error}
                                       helperText={error.collection.helperText}
                                       margin="dense"
                                       id="collection"
                                       label="Collection"
                                       type="text"
                                       fullWidth {...params}
                            />
                        )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField required
                               name="name"
                               value={name}
                               onChange={this.handleChange}
                               error={error.name.error}
                               helperText={error.name.helperText}
                               autoFocus
                               margin="dense"
                               id="categoryName"
                               label="Category Name"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="notes"
                               value={notes ? notes : ''}
                               onChange={this.handleChange}
                               error={error.notes.error}
                               helperText={error.notes.helperText}
                               margin="dense"
                               label="Notes"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={this.clearForm} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

CategoryForm.propTypes = {
  addCategory: PropTypes.func.isRequired,
  editCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  editCategoryId: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  yearMonth: PropTypes.string.isRequired,
  collections: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  collections: state.collectionReducer.collections,
  categoryId: state.categoryIdReducer.categoryId,
  categories: state.categoryReducer.categories,
})

const mapDispatchToProps = {
  addCategory,
  editCategory,
  deleteCategory,
  editCategoryId,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CategoryForm))
