import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import { Link, AccountBalance, ViewAgenda, Category, ShowChart, ReceiptLong } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import LinkForm from './LinkForm'
import { editAccountId } from '../../actions/accountId'
import { editCollectionId } from '../../actions/collectionId'
import { editCategoryId } from '../../actions/categoryId'
import { editHoldingId } from '../../actions/holdingId'
import { editTransactionId } from '../../actions/transactionId'

const styles = theme => ({
  ...sharedStyles(theme),
})

class ActionButtons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      linkConnectOpen: false
    }

    this.handleActionButtonToggle = this.handleActionButtonToggle.bind(this)
    this.handleLinkFormClose = this.handleLinkFormClose.bind(this)
    this.handleLinkClick = this.handleLinkClick.bind(this)
    this.handleAccountClick = this.handleAccountClick.bind(this)
    this.handleCollectionClick = this.handleCollectionClick.bind(this)
    this.handleCategoryClick = this.handleCategoryClick.bind(this)
    this.handleHoldingClick = this.handleHoldingClick.bind(this)
    this.handleTransactionClick = this.handleTransactionClick.bind(this)
  }

  handleActionButtonToggle = () => {
    const { open } = this.state

    this.setState({ open: !open })
  }

  handleLinkClick = () => this.setState({ linkConnectOpen: true, open: false })

  handleLinkFormClose = () => this.setState({ linkConnectOpen: false })

  handleAccountClick = () => {
    this.handleActionButtonToggle()
    this.props.editAccountId(0)
  }

  handleCollectionClick = () => {
    this.handleActionButtonToggle()
    this.props.editCollectionId(0)
  }

  handleCategoryClick = () => {
    this.handleActionButtonToggle()
    this.props.editCategoryId(0)
  }

  handleHoldingClick = () => {
    this.handleActionButtonToggle()
    this.props.editHoldingId(0)
  }

  handleTransactionClick = () => {
    this.handleActionButtonToggle()
    this.props.editTransactionId(0)
  }

  render() {
    const { open, linkConnectOpen } = this.state
    const { classes } = this.props
    const actions = [
      { icon: <ReceiptLong />, name: 'Transaction', handleClick: this.handleTransactionClick },
      { icon: <ShowChart />, name: 'Holding', handleClick: this.handleHoldingClick },
      { icon: <Category />, name: 'Category', handleClick: this.handleCategoryClick },
      { icon: <ViewAgenda />, name: 'Collection', handleClick: this.handleCollectionClick },
      { icon: <AccountBalance />, name: 'Account', handleClick: this.handleAccountClick },
      { icon: <Link />, name: 'Link', handleClick: this.handleLinkClick },
    ]

    return (
        <Fragment>
          <SpeedDial
              ariaLabel="Add data"
              transitionDuration={{ enter: 500, exit: 500 }}
              className={classes.fab}
              classes={{ fab: classes.fabButton }}
              icon={<SpeedDialIcon />}
              onClick={this.handleActionButtonToggle}
              open={open}
          >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    title={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    classes={{ staticTooltipLabel: classes.fabLabel, fab: classes.fabAction }}
                    tooltipOpen
                    onClick={action.handleClick}
                />
            ))}
          </SpeedDial>
          <LinkForm open={linkConnectOpen} onFormClose={this.handleLinkFormClose} />
        </Fragment>
    )
  }
}

ActionButtons.propTypes = {
  editAccountId: PropTypes.func.isRequired,
  editCollectionId: PropTypes.func.isRequired,
  editCategoryId: PropTypes.func.isRequired,
  editHoldingId: PropTypes.func.isRequired,
  editTransactionId: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
})

const mapDispatchToProps = {
  editAccountId,
  editCollectionId,
  editCategoryId,
  editHoldingId,
  editTransactionId,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActionButtons))
