import {
  GET_TRANSACTION_FLAGS, EDIT_TRANSACTION_FLAGS, TRANSACTION_FLAG_LOADING, TRANSACTION_FLAG_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  transactionFlags: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_FLAGS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        transactionFlags: action.payload
      }
    case EDIT_TRANSACTION_FLAGS:
      return {
        ...state,
        error: false,
        isFetching: false
      }
    case TRANSACTION_FLAG_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case TRANSACTION_FLAG_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
