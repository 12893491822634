import { combineReducers } from 'redux'
import yearMonth from './budgetMonth'
import transactions from './transactions'
import transactionFlags from './transactionFlags'
import flagColors from './flagColors'
import transactionId from './transactionId'
import accountKey from './accountKey'
import accounts from './accounts'
import accountId from './accountId'
import accountGroups from './accountGroups'
import accountTypes from './accountTypes'
import payees from './payees'
import transfers from './transfers'
import splits from './splits'
import reports from './reports'
import categories from './categories'
import categoryId from './categoryId'
import categoryBudgets from './categoryBudgets'
import categoryBudgetId from './categoryBudgetId'
import categoryBalanceId from './categoryBalanceId'
import collections from './collections'
import collectionId from './collectionId'
import holdings from './holdings'
import holdingId from './holdingId'
import securities from './securities'
import links from './link'
import recurring from './recurring'
import history from './history'
import membership from './membership'
import errors from './errors'
import messages from './messages'
import auth from './auth'
import profile from './profile'
import theme from './theme'

const appReducer = combineReducers({
  budgetMonthReducer: yearMonth,
  transactionReducer: transactions,
  transactionFlagReducer: transactionFlags,
  flagColorReducer: flagColors,
  transactionIdReducer: transactionId,
  accountKeyReducer: accountKey,
  accountReducer: accounts,
  accountIdReducer: accountId,
  accountGroupReducer: accountGroups,
  accountTypeReducer: accountTypes,
  payeeReducer: payees,
  transferReducer: transfers,
  splitReducer: splits,
  reportReducer: reports,
  categoryReducer: categories,
  categoryIdReducer: categoryId,
  categoryBudgetReducer: categoryBudgets,
  categoryBudgetIdReducer: categoryBudgetId,
  categoryBalanceIdReducer: categoryBalanceId,
  collectionReducer: collections,
  collectionIdReducer: collectionId,
  holdingReducer: holdings,
  holdingIdReducer: holdingId,
  securityReducer: securities,
  linkReducer: links,
  recurringReducer: recurring,
  historyReducer: history,
  membershipReducer: membership,
  errorReducer: errors,
  messageReducer: messages,
  authReducer: auth,
  profileReducer: profile,
  themeReducer: theme
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_SUCCESS') state = undefined
  return appReducer(state, action)
}

export default rootReducer
