import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import { Typography, Toolbar, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import { formatMoneyLabel } from '../utilities/numbers'
import { formatISODate } from '../utilities/dates'
import LinkTransactionForm from '../layout/LinkTransactionForm'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  positiveAmount: {
    color: theme.palette.success.main,
    fontWeight: 600
  },
  negativeAmount: {
    color: theme.palette.error.main,
    fontWeight: 600
  },
  tableRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)'
    }
  },
  tableLabel: {
    color: theme.palette.text.secondary
  },
  gridSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  }
}))

const LinkTransactions = () => {
  const { accounts, linkAccounts, linkTransactions } = withPropsValidation(
      useSelector(({ accountReducer, linkReducer }) => ({
        accounts: accountReducer.accounts,
        linkAccounts: linkReducer.linkAccounts,
        linkTransactions: linkReducer.linkTransactions
      })))

  const [linkTransaction, setLinkTransaction] = useState(null)
  const [pendingTransactions, setPendingTransactions] = useState([])
  const [postedTransactions, setPostedTransactions] = useState([])
  const [isExpanded, setIsExpanded] = useState(true)

  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()

  /**
   * Get the list of pending and posted link transactions.
   */
  useEffect(() => {
    const pending = linkTransactions.filter(link => link.pending)
    const posted = linkTransactions.filter(link => !link.pending)

    setPendingTransactions(pending)
    setPostedTransactions(posted)
  }, [linkTransactions])

  /**
   * This function is used to update the link transaction form with
   * row that was clicked on.
   * @param {Array} rowData - The link transaction actioned.
   */
  const handleLinkTransactionClick = rowData => {
    if (rowData && rowData.id) setLinkTransaction(rowData)
  }

  /**
   * This function is used to clear the selected link transaction.
   */
  const clearLinkTransaction = () => setLinkTransaction(null)

  return (
      <Fragment>
        {linkTransactions && linkTransactions.length > 0
            ? (
                <Accordion key={`uncategorized-link-transactions-group`}
                           expanded={isExpanded}
                           square
                           onChange={() => setIsExpanded(!isExpanded)}
                >

                  <AccordionSummary expandIcon={<ExpandMore fontSize="small" style={{ color: '#FFF' }} />}>
                    <Toolbar>
                      <Typography variant="h6" component="div">
                        Uncategorized Transactions
                      </Typography>
                    </Toolbar>
                  </AccordionSummary>

                  <AccordionDetails>
                    {pendingTransactions && pendingTransactions.length > 0
                        ? (
                            <Fragment>
                              <Grid container item xs={12} direction="row" justifyContent="center"
                                    alignItems="flex-end">
                                <Typography variant="body1">
                                  Pending
                                </Typography>
                              </Grid>

                              {pendingTransactions.map(row => {
                                let amount = row.amount > 0 ? -Math.abs(row.amount) : Math.abs(row.amount)
                                const amountClasses = amount > 0 ? classes.positiveAmount : classes.negativeAmount
                                const linkAccount = linkAccounts.find(l => row && l.id === row.account_id)
                                const account = accounts.find(a => linkAccount && a.link_id === linkAccount.id)

                                return (
                                    <Grid key={`pending-link-transaction-${row.id}`} container
                                          className={classes.tableRow}
                                          onClick={() => handleLinkTransactionClick(row)}
                                    >

                                      <Grid container item xs={6}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                      >
                                        <Typography variant="body1">
                                          {row.merchant_name ? row.merchant_name : row.name}
                                        </Typography>
                                        <Typography variant="body2" className={classes.tableLabel}>
                                          {formatISODate(row.date)}
                                        </Typography>
                                      </Grid>

                                      <Grid container item xs={6}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-end"
                                      >
                                        <Typography variant="body1" className={amountClasses}>
                                          {formatMoneyLabel(amount)}
                                        </Typography>
                                        <Typography variant="body2" className={classes.tableLabel}>
                                          {account && account.name ? account.name : ''}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                )
                              })}
                            </Fragment>
                        ) : null
                    }

                    {postedTransactions && postedTransactions.length > 0
                        ? (
                            <Fragment>
                              <Grid container item xs={12} direction="row" justifyContent="center"
                                    alignItems="flex-end">
                                <Typography variant="body1"
                                            sx={{ padding: `${theme.spacing(1)} 0` }}
                                >
                                  Posted
                                </Typography>
                              </Grid>

                              {postedTransactions.map(row => {
                                let amount = row.amount > 0 ? -Math.abs(row.amount) : Math.abs(row.amount)
                                const amountClasses = amount > 0 ? classes.positiveAmount : classes.negativeAmount
                                const linkAccount = linkAccounts.find(l => row && l.id === row.account_id)
                                const account = accounts.find(a => linkAccount && a.link_id === linkAccount.id)

                                return (
                                    <Grid key={`pending-link-transaction-${row.id}`} container
                                          className={classes.tableRow}
                                          onClick={() => handleLinkTransactionClick(row)}
                                    >

                                      <Grid container item xs={6}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                      >
                                        <Typography variant="body1">
                                          {row.merchant_name ? row.merchant_name : row.name}
                                        </Typography>
                                        <Typography variant="body2" className={classes.tableLabel}>
                                          {formatISODate(row.date)}
                                        </Typography>
                                      </Grid>

                                      <Grid container item xs={6}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-end"
                                      >
                                        <Typography variant="body1" className={amountClasses}>
                                          {formatMoneyLabel(amount)}
                                        </Typography>
                                        <Typography variant="body2" className={classes.tableLabel}>
                                          {account && account.name ? account.name : ''}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                )
                              })}
                            </Fragment>
                        ) : null
                    }
                  </AccordionDetails>
                </Accordion>
            ) : null
        }
        <LinkTransactionForm clearLinkTransaction={clearLinkTransaction}
                             linkTransaction={linkTransaction}
        />
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'LinkTransactions')
  return props
}

const propTypes = {
  accounts: PropTypes.array.isRequired,
  linkAccounts: PropTypes.array.isRequired,
  linkTransactions: PropTypes.array.isRequired
}

export default LinkTransactions
