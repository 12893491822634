import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Typography, Button, Grid, } from '@mui/material'
import { Home, Refresh } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import AuthLayout from '../users/AuthLayout'

const styles = theme => ({
  ...sharedStyles(theme),
})

class ErrorBoundary extends Component {
  state = { error: null }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      auth, accountTypeError, accountError, collectionError,
      categoryError, categoryBudgetError, transactionError
    } = this.props

    // Only check for errors if the user is logged in
    if (!auth.isLoading && auth.isAuthenticated) {
      if (accountTypeError && accountTypeError !== prevProps.accountTypeError) {
        this.setState({ error: 'Error retrieving account types.' })
      }
      if (accountError && accountError !== prevProps.accountError) {
        this.setState({ error: 'Error retrieving your accounts.' })
      }
      if (collectionError && collectionError !== prevProps.collectionError) {
        this.setState({ error: 'Error retrieving your collections.' })
      }
      if (categoryError && categoryError !== prevProps.categoryError) {
        this.setState({ error: 'Error retrieving your categories.' })
      }
      if (categoryBudgetError && categoryBudgetError !== prevProps.categoryBudgetError) {
        this.setState({ error: 'Error retrieving your budget.' })
      }
      if (transactionError && transactionError !== prevProps.transactionError) {
        this.setState({ error: 'Error retrieving your transactions.' })
      }
    }

  }

  render() {
    const { error } = this.state
    const { classes } = this.props

    if (error) {
      return (
          <AuthLayout heading="Ope... Something went wrong.">
            <Grid container
                  spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {error}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  We are sorry for the inconvenience. If the problem persists please contact us.
                </Typography>
              </Grid>
              <Grid container item xs={6} justifyContent="center">
                <Button fullWidth={true} type="submit" color="primary" variant="contained"
                        onClick={() => window.location.href = '/'}>
                  <Home className={classes.pr1} fontSize="small" />Home
                </Button>
              </Grid>
              <Grid container item xs={6} justifyContent="center">
                <Button fullWidth={true} type="submit" color="primary" variant="contained"
                        onClick={() => window.location.reload(false)}>
                  <Refresh className={classes.pr1} fontSize="small" />Refresh
                </Button>
              </Grid>
            </Grid>
          </AuthLayout>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  auth: PropTypes.object.isRequired,
  accountTypeError: PropTypes.bool.isRequired,
  accountError: PropTypes.bool.isRequired,
  collectionError: PropTypes.bool.isRequired,
  categoryError: PropTypes.bool.isRequired,
  categoryBudgetError: PropTypes.bool.isRequired,
  transactionError: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  accountTypeError: state.accountTypeReducer.error,
  accountError: state.accountReducer.error,
  collectionError: state.collectionReducer.error,
  categoryError: state.categoryReducer.error,
  categoryBudgetError: state.categoryBudgetReducer.error,
  transactionError: state.transactionReducer.error,
})

export default connect(mapStateToProps)(withStyles(styles)(ErrorBoundary))
