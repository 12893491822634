import { lighten } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import { AppBar, Switch, Tabs, Tab } from '@mui/material'

const drawerWidth = 200

export const sharedStyles = theme => ({
  appBar: {
    zIndex: 1201,
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  budgetRow: {
    position: 'relative',
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  progressBar: {
    transition: 'all 2s ease',
    position: 'absolute',
    height: '2px',
    content: '""',
    left: 0,
    bottom: '-2px'
  },
  sideNavAccountItem: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: `0 0 0 ${theme.spacing(2)}`,
    },
  },
  budgetCardsContent: {
    padding: '8px !important'
  },
  cardActivity: {
    position: 'relative',
    color: '#fff',
    background: 'linear-gradient(to right, #F45C43, #EB3349)',
  },
  cardActivityIcon: {
    color: 'rgba(244, 92, 67, 0.9)'
  },
  cardAvailable: {
    position: 'relative',
    color: '#fff',
    background: 'linear-gradient(to right, #3498db, #2c3e50)',
  },
  cardAvailableIcon: {
    color: 'rgba(52, 152, 219, 0.4)'
  },
  cardBudget: {
    position: 'relative',
    color: '#fff',
    background: 'linear-gradient(to right, #FFC837, #FF8008)',
  },
  cardBudgetIcon: {
    color: 'rgba(255, 200, 55, 0.3)'
  },
  cardInflow: {
    position: 'relative',
    color: '#fff',
    background: 'linear-gradient(to right, #a8e063, #56ab2f)',
  },
  cardInflowIcon: {
    color: 'rgba(168, 224, 99, 0.4)'
  },
  cardIcon: {
    top: '8px',
    right: '8px',
    position: 'absolute',
    fontSize: '56px',
  },
  cardText: {
    position: 'relative',
    zIndex: 1,
  },
  categoryLabel: {
    fontWeight: theme.typography.fontWeightRegular
  },
  centerText: {
    textAlign: 'center'
  },
  collectionLabel: {
    fontWeight: theme.typography.fontWeightRegular
  },
  content: {
    maxWidth: '100%',
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    marginBottom: '200px',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  accountCategoryIcon: {
    color: theme.palette.accent.main,
    position: 'absolute'
  },
  dragHandleParent: {
    '&:hover svg': {
      visibility: 'visible',
    },
    '&:hover $accountCategoryIcon': {
      visibility: 'hidden',
    },
  },
  dragHandleButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  dragHandle: {
    cursor: 'move',
    visibility: 'hidden',
  },
  moveCursor: {
    cursor: 'move'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    zIndex: 999,
  },
  dangerButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    }
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1201,
    cursor: 'pointer',
  },
  fabButton: {
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
  fabAction: {
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
  fabLabel: {
    // color: theme.palette.secondary.dark,
  },
  dialogActionLeftButton: {
    position: 'absolute',
    left: 0,
  },
  floatLeft: {
    float: 'left'
  },
  floatRight: {
    float: 'right'
  },
  budgetError: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    borderRadius: theme.spacing(0.5)
  },
  fontError: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  fontWarning: {
    color: theme.palette.warning.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    // color: theme.palette.primary.main,
  },
  line19: {
    lineHeight: '19px'
  },
  line30: {
    lineHeight: '30px'
  },
  listItemMoreButton: {
    position: 'absolute',
    right: 0,
  },
  m0: {
    margin: 0
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dropdownListItem: {
    borderBottom: 'none'
  },
  dropdownListItemError: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: 'none'
  },
  splitGroup: {
    backgroundColor: theme.palette.divider,
  },
  firstSplit: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  lastSplit: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  splitAddButton: {
    marginTop: '16px'
  },
  p0: {
    padding: 0,
  },
  pl0: {
    paddingLeft: 0
  },
  pr1: {
    paddingRight: theme.spacing(1),
  },
  tableLabel: {
    borderRadius: '4px',
    padding: 0,
    textAlign: 'center'
  },
  rightTableHead: {
    '& > div': {
      textAlign: 'right'
    },
  },
  centerTableHead: {
    '& > div': {
      textAlign: 'center'
    },
  },
  tableHeader: {
    // color: theme.palette.secondary.light
  },
  selectedRow: {
    // color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1) !important' : 'rgba(255, 255, 255, 0.1) !important',
    // '&:hover svg': {
    //   color: theme.palette.primary.main
    // }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  topIndex: {
    zIndex: 1201,
  },
  customButton: {
    padding: '5px 15px !important',
    background: 'transparent !important',
    border: '1px solid rgba(6, 190, 182, 0.5) !important',
    '&:hover': {
      border: '1px solid #0f4e5b !important',
      backgroundColor: 'rgba(6, 190, 182, 0.04) !important',
      textDecoration: 'none !important',
    },
    verticalAlign: 'middle',
    borderRadius: '4px'
  },
  textLinks: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  positiveStats: {
    backgroundColor: 'rgba(0, 255, 0, 0.2)',
    color: theme.palette.success.main,
    fontWeight: 500,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    paddingHorizontal: theme.spacing(1)
  },
  negativeStats: {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    color: theme.palette.error.main,
    fontWeight: 500,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    paddingHorizontal: theme.spacing(1)
  }
})

export const authLayout = theme => ({
  root: {
    height: '100vh',
    paddingTop: theme.spacing(5),
  },
  image: {
    height: '125px',
    width: '125px',
    margin: 'auto',
  },
  centerGrid: {
    margin: '0 auto 24px',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  centerCard: {
    background: 'linear-gradient(to right, #0f4e5b, #206470)',
    color: theme.palette.secondary.contrastText
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  }
})

// The styling for the switch is the same, so we just use the light theme.
export const SwitchPositive = withStyles(theme => ({
  switchBase: {
    color: theme.palette.error.main,
    '&$checked': {
      color: theme.palette.success.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.success.light,
    },
  },
  checked: {},
  track: {},
}))(Switch)

// Tab styling
export const TabBar = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '4px',
    position: 'static',
    height: '64px'
  }
}))(AppBar)

export const TabList = withStyles(theme => ({
  indicator: {
    height: '52px',
    borderRadius: '4px',
    bottom: '6px',
    backgroundColor: '#fff',
    zIndex: 0,
  }
}))(Tabs)

export const TabButton = withStyles(theme => ({
  root: {
    color: '#fff',
    borderRadius: '4px',
    margin: '6px',
    padding: '8px 12px',
    height: '52px',
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff'
    },
    '&:focus': {
      outline: 'none'
    },
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  }
}))(Tab)
