import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Grid, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Typography, Paper
} from '@mui/material'
import { ArrowDropUp, ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { sharedStyles } from '../common/styles'
import { backgroundColors, borderColors } from '../chart/charts'
import { formatMoneyLabel, formatMoney, formatPercentLabel } from '../utilities/numbers'

const styles = theme => ({
  ...sharedStyles(theme),
  positiveAmount: {
    color: theme.palette.success.main,
    fontWeight: 500
  },
  negativeAmount: {
    color: theme.palette.error.main,
    fontWeight: 500
  },
  middleAlign: {
    verticalAlign: 'middle'
  }
})

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
  plugins: {
    title: {
      display: false,
      text: 'Net Worth',
    },
  },
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  scales: {
    x: {
      ticks: {
        maxRotation: 45,
        minRotation: 45
      },
    },
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return formatMoneyLabel(value)
        },
      },
    },
  },
}

function NetWorth(props) {

  const { classes, reports } = withPropsValidation(
      useSelector(({ reportReducer }) => ({
        classes: props.classes,
        reports: reportReducer.reports,
      })))

  let netWorthReport = reports && reports.net_worth_report ? reports.net_worth_report : []
  const netWorthTable = [...netWorthReport].reverse()
  netWorthReport = netWorthReport.slice(-12)
  const labels = netWorthReport.map((report) => DateTime.fromISO(report.date).toLocaleString({
    month: 'short',
    year: 'numeric'
  }))
  const netWorth = netWorthReport.map((report) => formatMoney(report.net_worth))

  const data = {
    labels,
    datasets: [
      {
        label: 'Net Worth',
        data: netWorth,
        backgroundColor: backgroundColors[1],
        borderColor: borderColors[1],
        borderWidth: 2,
      }
    ],
  }

  return (
      <Fragment>
        <Typography variant="h6">{formatMoneyLabel(reports.net_worth)}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Bar options={options} data={data} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="net worth table">
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="center">Net Worth</TableCell>
                    <TableCell align="center">Change</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {netWorthTable.map((row, index) => {
                    let changeClass = null
                    let arrowIcon = <ArrowRight className={classes.middleAlign} fontSize="small" />
                    if (row.percent_change > 0) {
                      changeClass = classes.positiveStats
                      arrowIcon = <ArrowDropUp className={classes.middleAlign} fontSize="small" />
                    } else if (row.percent_change < 0) {
                      changeClass = classes.negativeStats
                      arrowIcon = <ArrowDropDown className={classes.middleAlign} fontSize="small" />
                    }
                    return (
                        <TableRow key={`net-worth-${index}`} hover>
                          <TableCell>{DateTime.fromISO(row.date).toLocaleString({
                            month: 'short',
                            year: 'numeric'
                          })}</TableCell>
                          <TableCell align="center">{formatMoneyLabel(row.net_worth)}</TableCell>
                          <TableCell align="center">
                            <div className={changeClass}>
                              {arrowIcon}
                              {formatPercentLabel(row.percent_change)}
                            </div>
                          </TableCell>
                        </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', '')
  return props
}

const propTypes = {
  classes: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired
}


export default withStyles(styles)(NetWorth)
