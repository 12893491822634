import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import MUIDataTable from 'mui-datatables'
import { Card, Grid } from '@mui/material'
import { DateTime } from 'luxon'

import { sharedStyles } from '../common/styles'
import ArchiveForm from '../layout/ArchiveForm'
import { formatMoneyLabel } from '../utilities/numbers'
import { returnErrors } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25]

const accountColumns = [
  {
    label: 'ID', name: 'id',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
    }
  },
  { label: 'Account', name: 'name' },
  {
    label: 'Account Balance',
    name: 'available_balance',
    options: {
      customBodyRender: value => formatMoneyLabel(value),
    }
  }, {
    label: 'Archived', name: 'archived',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
    }
  },
  {
    label: 'Last Updated', name: 'updated',
    options: {
      customBodyRender: value => DateTime.fromISO(value).toLocaleString()
    }
  }
]
const CategoryColumns = [
  {
    label: 'ID', name: 'id',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
    }
  },
  { label: 'Categories', name: 'name' },
  {
    label: 'Archived', name: 'archived',
    options: {
      display: 'excluded',
      viewColumns: false,
      filter: false,
    }
  },
  {
    label: 'Last Updated', name: 'updated',
    options: {
      customBodyRender: value => DateTime.fromISO(value).toLocaleString()
    }
  }
]

class Archive extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      item: {},
      pageSize: 5
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.handleAccountArchiveClick = this.handleAccountArchiveClick.bind(this)
    this.handleCategoryArchiveClick = this.handleCategoryArchiveClick.bind(this)
  }

  /**
   * Close the dialog and reset the selected payees.
   */
  closeDialog = () => this.setState({ open: false, item: {} })

  /**
   * Open the dialog and set the selected account item.
   * @param {Array} row - The selected item.
   */
  handleAccountArchiveClick = row => {
    try {
      const { accounts } = this.props
      const rowId = row && row[0] ? row[0] : null
      let account = accounts.filter(a => a.id === rowId)
      account = account && account[0] ? account[0] : null

      if (!account) throw Error('Not found')

      account['table'] = 'account'

      this.setState({ open: true, item: account })
    } catch (e) {
      this.props.returnErrors({ updateAccount: 'Unable to unarchive account. Please try again later.' }, 500)
    }
  }

  /**
   * Open the dialog and set the selected category item.
   * @param {Array} row - The selected item.
   */
  handleCategoryArchiveClick = row => {
    try {
      const { categories } = this.props
      const rowId = row && row[0] ? row[0] : null
      let category = categories.filter(c => c.id === rowId)
      category = category && category[0] ? category[0] : null

      if (!category) throw Error('Not found')

      category['table'] = 'category'

      this.setState({ open: true, item: category })
    } catch (e) {
      this.props.returnErrors({ updateCategory: 'Unable to unarchive category. Please try again later.' }, 500)
    }
  }

  render() {
    const { open, item, pageSize } = this.state
    const { accounts, categories } = this.props
    const accountOptions = accounts.filter(a => a.archived)
    const categoryOptions = categories.filter(c => c.archived)

    const accountTableOptions = {
      responsive: 'standard',
      rowsPerPage: pageSize,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      print: false,
      viewColumns: false,
      download: false,
      filter: false,
      selectableRows: 'none',
      onRowClick: rowData => this.handleAccountArchiveClick(rowData)
    }

    const categoryTableOptions = {
      responsive: 'standard',
      rowsPerPage: pageSize,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      print: false,
      viewColumns: false,
      download: false,
      filter: false,
      selectableRows: 'none',
      onRowClick: rowData => this.handleCategoryArchiveClick(rowData)
    }

    return (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card raised={false}>
                <MUIDataTable title="Accounts"
                              data={accountOptions}
                              columns={accountColumns}
                              options={accountTableOptions}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card raised={false}>
                <MUIDataTable title="Categories"
                              data={categoryOptions}
                              columns={CategoryColumns}
                              options={categoryTableOptions}
                />
              </Card>
            </Grid>
          </Grid>

          <ArchiveForm open={open}
                       closeForm={this.closeDialog}
                       item={item}
          />

        </Fragment>
    )
  }
}

Archive.propTypes = {
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  accounts: state.accountReducer.accounts,
  categories: state.categoryReducer.categories,
})

const mapDispatchToProps = {
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Archive))
