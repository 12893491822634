import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar'
import { useTheme } from '@mui/styles'
import { DateTime } from 'luxon'

import '../../styles/calendar.css'

const localizer = luxonLocalizer(DateTime)
const NOW = new Date()
const CURRENT_YEAR_MONTH = `${NOW.getFullYear()}${NOW.getMonth() + 1}`

/**
 * The calendar runs off only javascript dates.
 * Note: A javascript month is 0 based. Meaning 0 is January.
 * @param {Array} events - The list of events.
 * @param {function} onShowMore - Call when a user selects the show
 * more button on the calendar.
 * @param {function} onSelectEvent - Call when a user selects an event.
 * @returns {JSX.Element}
 * @constructor
 */
const RecurringCalendar = ({ events, onShowMore, onSelectEvent }) => {
  const { yearMonth } = withPropsValidation(
      useSelector(({ budgetMonthReducer }) => ({
        yearMonth: budgetMonthReducer.yearMonth,
      })))

  const [calendarDate, setCalendarDate] = useState(NOW)

  const theme = useTheme()

  /**
   * If viewing current month set the initial date to current date.
   * If not current month set calendar date to the first of the month.
   */
  useEffect(() => {
    let dayOfMonth = 1
    let budgetYear = yearMonth.substring(0, 4)
    let budgetMonth = yearMonth.substring(4, 6)
    if (CURRENT_YEAR_MONTH === yearMonth) dayOfMonth = DateTime.local().toFormat('dd')
    const calendarDate = new Date(budgetYear, budgetMonth - 1, dayOfMonth, 0, 0, 0)

    setCalendarDate(calendarDate)
  }, [yearMonth])

  /**
   * Events prop getting to add custom styling to each event.
   */
  const eventPropGetter = useCallback(
      (event, start, end, isSelected) => ({
        style: {
          background: '#FF7100',
          fontSize: 14,
          padding: '2px 4px'
        },
        ...(event.lastAmountPositive
            ? {
              style: {
                backgroundColor: theme.palette.success.main,
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightBold,
                padding: '2px 4px',
                color: '#ffffff'
              },
            } : {
              style: {
                backgroundColor: theme.palette.error.main,
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightBold,
                padding: '2px 4px',
                color: '#ffffff'
              },
            }),
      }), [])

  const handleShowMore = (events, date) => onShowMore(events, date)

  const handleSelectEvent = (calEvent) => onSelectEvent(calEvent)

  return (
      <Calendar localizer={localizer}
          // defaultDate={calendarDate}
                getNow={() => calendarDate}
                events={events}
                defaultView={Views.MONTH}
                style={{ height: 625 }}
                eventPropGetter={eventPropGetter}
                toolbar={false}
                drilldownView={null}
                selectable={false}
                showAllEvents={false}
                onKeyPressEvent={() => false}
                onShowMore={handleShowMore}
                onSelectEvent={handleSelectEvent}
                onDoubleClickEvent={() => false}
                onSelectSlot={() => false}
                onSelecting={() => false}
                onDrillDown={() => false}
      />
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'RecurringCalendar')
  return props
}

const propTypes = {
  yearMonth: PropTypes.string.isRequired,
}

RecurringCalendar.propTypes = {
  events: PropTypes.array.isRequired,
  onShowMore: PropTypes.func.isRequired,
  onSelectEvent: PropTypes.func.isRequired
}

export default RecurringCalendar
