import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IdleTimer from 'react-idle-timer'

import { logout } from '../../actions/auth'

const TIMEOUT = 7200000 // 2 hours
const DEBOUNCE = 250

class Idle extends Component {
  constructor(props) {
    super(props)

    this.idleTimer = null
    this.onIdle = this.onIdle.bind(this)
  }

  static propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
  }

  onIdle(e) {
    if (this.props.isAuthenticated) this.props.logout()
  }

  render() {
    return (
        <IdleTimer
            ref={ref => {
              this.idleTimer = ref
            }}
            element={document}
            onIdle={this.onIdle}
            debounce={DEBOUNCE}
            timeout={TIMEOUT}
        />
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Idle)