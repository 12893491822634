import {
  GET_CATEGORY_BUDGETS, EDIT_CATEGORY_BUDGET, CATEGORY_BUDGET_LOADING,
  CATEGORY_BUDGET_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  categoryBudgets: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_BUDGETS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        categoryBudgets: action.payload
      }
    case EDIT_CATEGORY_BUDGET:
      return {
        ...state,
        error: false,
        isFetching: false,
        // categoryBudgets: state.categoryBudgets.map(budget => {
        //   return budget.id === action.payload.id ? action.payload : budget
        // }) // Replace matched budget and returns the state.
      }
    case CATEGORY_BUDGET_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case CATEGORY_BUDGET_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
