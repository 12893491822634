import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_HISTORY_TRANSACTIONS, HISTORY_TRANSACTION_LOADING, HISTORY_TRANSACTION_FAIL
} from './types'
import { tokenConfig } from './auth'
import { downloadFile, ROWS_PER_PAGE_OPTIONS } from '../components/utilities/tables'

const PROXY = process.env.REACT_APP_PROXY

/**
 * Gets paginated transaction history
 * @param {number} [page=0] - The page of results to retrieve. 0 indexed.
 * @param {number} [pageSize] - The page size of results to retrieve.
 * Defaults to ROWS_PER_PAGE_OPTIONS[0].
 * @param {string} [query] - An optional search query to search
 * against. Currently, limits string to 10 characters.
 * @param {string} [startDate] - The starting date to search.
 * yyyymmdd format.
 * @param {string} [endDate] - The end date to search. yyyymmdd format.
 * @param {string} [flags] - A list of flags comma separated.
 * @param {string} [payeeQuery] - A search string to apply to payee
 * @param {string} [accounts] - A list of accounts comma separated.
 * @param {string} [categories] - A list of categories comma separated.
 * @param {boolean} [posted] - Filter on whether posted or not.
 * @param {boolean} [archived] - Filter on whether archived or not.
 * @returns {(function(*, *): void)|*}
 */
export const getTransactionHistory = (page, pageSize, query, startDate, endDate, flags, payeeQuery, accounts, categories, posted, archived) => (dispatch, getState) => {
  try {
    dispatch({ type: HISTORY_TRANSACTION_LOADING })
    const pageParam = (page || 0)
    const pageSizeParam = (pageSize || ROWS_PER_PAGE_OPTIONS[0])
    const search = query ? query.substring(0, 10) : ''
    const start = (startDate || '')
    const end = (endDate || '')
    const flagParams = (flags || '')
    const payeeQueryParam = payeeQuery ? payeeQuery.substring(0, 10) : ''
    const accountParams = (accounts || '')
    const categoryParams = (categories || '')
    const postedParam = posted === true ? 't' : posted === false ? 'f' : ''
    const archivedParam = archived === true ? 't' : archived === false ? 'f' : ''

    const header = tokenConfig(getState)
    header.params = {
      p: pageParam,
      ps: pageSizeParam,
      q: search,
      sd: start,
      ed: end,
      f: flagParams,
      pq: payeeQueryParam,
      a: accountParams,
      c: categoryParams,
      po: postedParam,
      ar: archivedParam
    }

    axios.get(`${PROXY}/v1/history_transactions/`, header)
        .then(res => {
          dispatch({
            type: GET_HISTORY_TRANSACTIONS,
            payload: res.data
          })
        })
        .catch(err => {
          const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
          const status = err.response && err.response.status ? err.response.status : 500
          // dispatch(returnErrors(error, status))
          dispatch({ type: HISTORY_TRANSACTION_FAIL })
        })
  } catch (e) {
    dispatch({ type: HISTORY_TRANSACTION_FAIL })
  }
}

/**
 * Downloads history transactions to a CSV file based on passed
 * filters.
 * @param {Array} columns - The list of columns to download.
 * @param {string} [query] - An optional search query to search
 * against. Currently, limits string to 10 characters.
 * @param {string} [startDate] - The starting date to search.
 * yyyymmdd format.
 * @param {string} [endDate] - The end date to search. yyyymmdd format.
 * @param {string} [flags] - A list of flags comma separated.
 * @param {string} [payeeQuery] - A search string to apply to payee
 * @param {string} [accounts] - A list of accounts comma separated.
 * @param {string} [categories] - A list of categories comma separated.
 * @param {boolean} [posted] - Filter on whether posted or not.
 * @param {boolean} [archived] - Filter on whether archived or not.
 * @returns {(function(*, *): void)|*}
 */
export const downloadTransactionHistory = (columns, query, startDate, endDate, flags, payeeQuery, accounts, categories, posted, archived) => (dispatch, getState) => {
  const search = query ? query.substring(0, 10) : ''
  const start = (startDate || '')
  const end = (endDate || '')
  const flagParams = (flags || '')
  const payeeQueryParam = payeeQuery ? payeeQuery.substring(0, 10) : ''
  const accountParams = (accounts || '')
  const categoryParams = (categories || '')
  const postedParam = posted === true ? 't' : posted === false ? 'f' : ''
  const archivedParam = archived === true ? 't' : archived === false ? 'f' : ''

  const filters = {
    cl: columns,
    q: search,
    sd: start,
    ed: end,
    f: flagParams,
    pq: payeeQueryParam,
    a: accountParams,
    c: categoryParams,
    po: postedParam,
    ar: archivedParam
  }

  axios.post(`${PROXY}/v1/history_transactions/`, filters, tokenConfig(getState))
      .then(res => {
        downloadFile(res.data, 'transactions.csv')
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
