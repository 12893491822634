import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import arrayMove from 'array-move'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'

import { sharedStyles } from '../common/styles'
import { SortContainer } from '../common/SortContainer'
import { SortableAccountGroups } from './SortAccountGroups'
import { formatMoneyLabel } from '../utilities/numbers'
import { editAccountId } from '../../actions/accountId'
import { editAccountGroups, reorderAccountGroups } from '../../actions/accountGroups'

const styles = theme => ({
  ...sharedStyles(theme),
  notificationIconContainer: {
    width: 8
  },
  notificationIcon: {
    backgroundColor: theme.palette.accent.main,
    height: 8,
    width: 8,
    borderRadius: 100,
  },
})

class AccountGroups extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deriveProps: true,
      accountGroups: this.props.accountGroups,
    }

    this.openAccountForm = this.openAccountForm.bind(this)
    this.handleGroupClick = this.handleGroupClick.bind(this)
  }

  /**
   * Open the new account form.
   */
  openAccountForm = () => this.props.editAccountId(0)

  /**
   * Handles collapsing and opening the account groups.
   * @param {Object} group - The account group.
   */
  handleGroupClick = group => {
    group['opened'] = group ? !group.opened : false

    this.props.editAccountGroups(group)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    try {
      const { accountGroups, reorderAccountGroups } = this.props

      let oldPositionElement = accountGroups[oldIndex]
      let newPositionElement = accountGroups[newIndex]
      if (oldPositionElement !== newPositionElement) {
        this.setState({
          deriveProps: false, // Temporarily stop props affecting state
          accountGroups: arrayMove(accountGroups, oldIndex, newIndex),
        })
        oldPositionElement.new_index = newIndex
        reorderAccountGroups(oldPositionElement)

        // After a very short wait, allow props to affect state again
        setTimeout(() => {
          this.setState(() => ({
            deriveProps: true,
          }))
        }, 3000)
      }
    } catch (e) {
      //  TODO: dispatch error
    }
  }

  // Update state with prop changes, except for a brief period after the order has changed,
  // to stop flickering from happening
  static getDerivedStateFromProps(props, state) {
    if (state.deriveProps) {
      return {
        accountGroups: props.accountGroups,
      }
    }
    return null
  }

  render() {
    const { classes, accounts, linkAccounts, linkTransactions } = this.props
    const { accountGroups } = this.state
    const totalAvailable = (accounts && accounts[0]) ? accounts[0].total_available : 0

    return (
        <Fragment>
          <Box mb={3}>

            <List dense>
              <ListItem dense>
                <ListItemText primary={
                  <Typography variant="body2" align="left" noWrap={true}>
                    Total
                  </Typography>
                } />
                <ListItemText primary={
                  <Typography variant="body2" align="right" noWrap={true}>
                    {formatMoneyLabel(totalAvailable)}
                  </Typography>
                } />
              </ListItem>
            </List>

            <SortContainer onSortEnd={this.onSortEnd} useDragHandle className={classes.p0}>
              {accountGroups.map((group, index) => {
                const accountLinkIds = accounts.filter((account) => account.group_id === group.group_id).map(a => a.link_id)
                const linkAccount = linkAccounts.filter((link) => accountLinkIds.includes(link.id)).map(l => l.id)
                const count = linkTransactions.filter(t => !t.pending && linkAccount.includes(t.account_id)).length
                return (
                    <SortableAccountGroups key={group.id}
                                           index={index}
                                           group={group}
                                           accounts={accounts}
                                           linkAccounts={linkAccounts}
                                           count={count}
                                           handleGroupClick={this.handleGroupClick}
                                           openAccountForm={this.openAccountForm}
                                           classes={classes}
                    />
                )
              })}
            </SortContainer>

          </Box>
        </Fragment>
    )
  }
}

AccountGroups.propTypes = {
  editAccountId: PropTypes.func.isRequired,
  accountGroups: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  linkAccounts: PropTypes.array.isRequired,
  linkTransactions: PropTypes.array.isRequired,
  editAccountGroups: PropTypes.func.isRequired,
  reorderAccountGroups: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  accountGroups: state.accountGroupReducer.accountGroups,
  accounts: state.accountReducer.accounts,
  linkAccounts: state.linkReducer.linkAccounts,
  linkTransactions: state.linkReducer.linkTransactions
})

const mapDispatchToProps = {
  editAccountId,
  editAccountGroups,
  reorderAccountGroups,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountGroups))
