import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { TextField, Button, Grid, } from '@mui/material'
import { Save } from '@mui/icons-material'

import { withRouter } from '../utilities/withRouter'
import { sharedStyles } from '../common/styles'
import { resetPasswordConfirm } from '../../actions/auth'
import { createToast } from '../../actions/messages'
import AuthLayout from './AuthLayout'

const styles = theme => ({
  ...sharedStyles(theme),
})

class PasswordResetConfirm extends Component {
  state = {
    uid: '',
    token: '',
    newPassword: '',
    reNewPassword: ''
  }

  componentDidMount() {
    const { match: { params } } = this.props

    if (typeof params !== 'undefined' && params != null) {
      this.setState({
        uid: params.uid,
        token: params.token
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match: { params } } = this.props

    if ((params.uid && prevProps.match.params.uid !== params.uid)
        || (params.token && prevProps.match.params.token !== params.token)) {
      this.setState({
        uid: params.uid,
        token: params.token
      })
    }
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onSubmit = e => {
    e.preventDefault()
    const { uid, token, newPassword, reNewPassword } = this.state
    if (newPassword !== reNewPassword) {
      this.props.createToast({ passwordsNotMatch: 'Passwords do not match' })
    } else {
      this.props.resetPasswordConfirm(uid, token, newPassword, reNewPassword)
    }
  }

  render() {
    if (this.props.isAuthenticated) return <Navigate to="/" />
    if (this.props.resetPasswordSuccess) return <Navigate to="/login" />

    const { newPassword, reNewPassword } = this.state
    const { classes } = this.props
    return (
        <AuthLayout heading="Update password">
          <form onSubmit={this.onSubmit}>
            <Grid container
                  spacing={3}>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={newPassword}
                           name="newPassword"
                           label="New Password"
                           type="password"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField required
                           autoComplete="off"
                           onChange={this.onChange}
                           value={reNewPassword}
                           name="reNewPassword"
                           label="Confirm Password"
                           type="password"
                           fullWidth={true} />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth={true} type="submit" color="primary" variant="contained">
                  <Save className={classes.pr1} fontSize="small" />Update Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </AuthLayout>
    )
  }
}

PasswordResetConfirm.propTypes = {
  resetPasswordConfirm: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  resetPasswordSuccess: PropTypes.bool.isRequired,
  uid: PropTypes.string,
  token: PropTypes.string
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  resetPasswordSuccess: state.authReducer.resetPasswordSuccess,
})

const mapDispatchToProps = {
  resetPasswordConfirm,
  createToast,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(PasswordResetConfirm)))
