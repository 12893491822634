import {
  GET_COLLECTIONS, EDIT_COLLECTION, REORDER_COLLECTIONS, DELETE_COLLECTION,
  ADD_COLLECTION, COLLECTION_LOADING, COLLECTION_FAIL,
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  collections: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTIONS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        collections: action.payload
      }
    case EDIT_COLLECTION:
    case REORDER_COLLECTIONS:
      return {
        ...state,
        error: false,
        isFetching: false,
        collections: state.collections.map(collection => {
          return collection.id === action.payload.id ? action.payload : collection
        }) // Replace matched collection and returns the state.
      }
    case DELETE_COLLECTION:
      return {
        ...state,
        error: false,
        isFetching: false,
        collections: state.collections.filter(collection => collection.id !== action.payload)
      }
    case ADD_COLLECTION:
      return {
        ...state,
        error: false,
        isFetching: false,
        collections: [...state.collections, action.payload]
      }
    case COLLECTION_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true
      }
    case COLLECTION_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}