import React from 'react'
import { Paper } from '@mui/material'
import Draggable from 'react-draggable'

function DraggableDialogWrapper(props) {
  return (
      <Draggable handle=".MuiDialogTitle-root">
        <Paper {...props} />
      </Draggable>
  )
}

export default DraggableDialogWrapper
