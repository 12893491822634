import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withRouter } from '../utilities/withRouter'
import Transactions from '../transaction/Transactions'
import Holdings from '../holdings/Holdings'

class AccountDetail extends Component {

  render() {
    const { accounts, accountKey } = this.props
    const account = accounts.find(a => a.id === accountKey)
    const accountType = account && account.type_code ? account.type_code : null

    return accountType !== 'F'
        ? <Transactions accountKey={accountKey} />
        : <Holdings accountKey={accountKey} />
  }
}

AccountDetail.propTypes = {
  accounts: PropTypes.array.isRequired,
  accountKey: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  accounts: state.accountReducer.accounts,
  accountKey: state.accountKeyReducer.accountKey
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountDetail))
