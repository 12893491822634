import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import numeral from 'numeral'

import HoldingList from './HoldingList'
import { sharedStyles } from '../common/styles'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
}))

const Holdings = ({ accountKey }) => {

  const { accounts } = useSelector(({ accountReducer }) => accountReducer)
  const { holdings } = useSelector(({ holdingReducer }) => holdingReducer)

  const [holdingList, setHoldingList] = useState([])
  const [account, setAccount] = useState({})

  const classes = useStyles()

  useEffect(() => {
    updateHoldingList(accountKey)
  }, [accountKey, accounts, holdings])

  /**
   * Update the holding list state array with the current holdings
   * for this account. Add some calculated values and reorder the list.
   * @param {number} key - The unique account key.
   */
  const updateHoldingList = (key) => {

    const account = accounts.find(a => a.id === key)
    const accountHoldings = holdings.filter(h => (h.link_account_id && h.link_account_id === account.link_id) || (h.account_id && h.account_id === account.id))
    const activeHoldings = []
    const soldHoldings = []
    for (const accountHolding of accountHoldings) {
      // TODO: This logic should probably move to the backend.
      let institutionValue = accountHolding && accountHolding.institution_value ? accountHolding.institution_value : 0
      institutionValue = numeral(institutionValue)
      let costBasis = accountHolding && accountHolding.cost_basis ? accountHolding.cost_basis : 0
      const profitLoss = institutionValue.subtract(costBasis)
      const shares = accountHolding && accountHolding.quantity ? accountHolding.quantity : 0
      costBasis = numeral(costBasis)
      const averagePrice = shares > 0 ? costBasis.divide(shares) : costBasis
      // If holding is added manually calculate the price (institution_price).
      let institutionPrice = accountHolding && accountHolding.institution_price ? accountHolding.institution_price : 0
      if (!institutionPrice) {
        let marketValue = accountHolding && accountHolding.institution_value ? accountHolding.institution_value : 0
        marketValue = numeral(marketValue)
        institutionPrice = marketValue.divide(shares)
        institutionPrice = institutionPrice.value()
      }

      if (shares > 0) {
        activeHoldings.push({
          ...accountHolding,
          average_price: averagePrice.value(),
          profit_loss: profitLoss.value(),
          institution_price: institutionPrice
        })
      } else {
        soldHoldings.push({
          ...accountHolding,
          average_price: averagePrice.value(),
          profit_loss: profitLoss.value(),
          institution_price: institutionPrice
        })
      }
    }


    const combinedList = activeHoldings.concat(soldHoldings)
    setAccount(account)
    setHoldingList(combinedList)
  }

  return (
      <HoldingList account={account} holdings={holdingList} />
  )
}

Holdings.propTypes = {
  accountKey: PropTypes.number.isRequired
}

export default Holdings
