import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_SECURITIES, EDIT_SECURITY, DELETE_SECURITY, ADD_SECURITY, SECURITY_LOADING, SECURITY_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get Securities
export const getSecurities = (yearMonth) => (dispatch, getState) => {
  dispatch({ type: SECURITY_LOADING })
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/securities/?m=${mParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_SECURITIES,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: SECURITY_FAIL })
      })
}

// Edit Security
export const editSecurity = (security) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/securities/${security.id}/`, security, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateSecurity: 'Security Updated' }))
        dispatch({
          type: EDIT_SECURITY,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Delete Security
export const deleteSecurity = (id) => (dispatch, getState) => {
  axios.delete(`${proxy}/v1/securities/${id}/`, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ deleteSecurity: 'Security Deleted' }))
        dispatch({
          type: DELETE_SECURITY,
          payload: id
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Add Security
export const addSecurity = (security) => (dispatch, getState) => {
  axios.post(`${proxy}/v1/securities/`, security, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ addSecurity: 'Security Added' }))
        dispatch({
          type: ADD_SECURITY,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
