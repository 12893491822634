import React, { useState, useEffect, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import {
  Container, Box, Typography, Toolbar, AppBar, Divider, Drawer, Hidden,
  IconButton, Grid, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material'
import {
  Menu,
  BarChart,
  Explore,
  MapOutlined,
  AccountBalance,
  EventRepeat,
  ManageSearch,
  Insights
} from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import HeaderLoading from '../loading/HeaderLoading'
import ProfileMenu from './navigation/ProfileMenu'
import AccountGroups from '../accountGroups/AccountGroups'
import MonthPicker from '../layout/MonthPicker'
import HeaderSummary from './HeaderSummary'
import MoveBudgetForm from './MoveBudgetForm'
import { editAccountKey } from '../../actions/accountKey'
import { formatMoneyLabel } from '../utilities/numbers'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  drawerContainer: {
    marginBottom: theme.spacing(10)
  },
  appTitle: {
    fontFamily: 'Yellowtail, Roboto, Helvetica, Arial, sans-serif',
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.text.primary
  },
  selected: {
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '-3px'
  },
  selectedAnchor: {
    borderLeft: '3px solid',
    borderColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.light
  },
  beta: {
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightRegular,
    float: 'right'
  }
}))

const Header = (props) => {
  const { children, isAuthenticated, user, accounts } = withPropsValidation(
      useSelector(({ authReducer, accountReducer }) => ({
        isAuthenticated: authReducer.isAuthenticated,
        user: authReducer.user,
        accounts: accountReducer.accounts,
        children: props.children
      })))

  const [open, setOpen] = useState(false)
  const [budgetDialogOpen, setBudgetDialogOpen] = useState(false)
  const [needToBudget, setNeedToBudget] = useState(0)
  const [needToBudgetColor, setNeedToBudgetColor] = useState(null)

  const { pathname } = useLocation()
  const theme = useTheme()
  const classes = useStyles()

  /**
   * Sets the need to budget amount and color.
   */
  useEffect(() => {
    const needToBudget = (accounts && accounts[0] && accounts[0].need_to_budget) ? accounts[0].need_to_budget : 0
    const needToBudgetColor = needToBudget >= 0 ? null : classes.budgetError

    setNeedToBudget(needToBudget)
    setNeedToBudgetColor(needToBudgetColor)
  }, [accounts])

  /**
   * This function handles when the accounts tab is clicked.
   * @param {number} id - 0 to show all.
   */
  const accountDetailClick = id => editAccountKey(id)

  /**
   * Handles opening and closing the navigation side drawer on smaller
   * screens.
   */
  const handleDrawerToggle = () => setOpen(!open)

  /**
   * Opens and closes the move budget money dialog
   */
  const handleBudgetDialogToggle = () => setBudgetDialogOpen(!budgetDialogOpen)

  const drawer = (
      <div className={classes.drawerContainer}>

        <List style={{
          paddingBottom: 0,
        }} align="center">
          <img alt="Ploutos Budget logo" height={40} src="/static/ploutos.svg" />
        </List>

        <List>

          <ProfileMenu user={user} />

          <ListItem button key="Dashboard" to="/dashboard" component={Link}
                    className={pathname === '/dashboard' || pathname === '/' ? classes.selectedAnchor : null}
          >
            <ListItemIcon>
              <Explore className={pathname === '/dashboard' || pathname === '/' ? classes.selected : null} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="subtitle2" noWrap
                          className={pathname === '/dashboard' || pathname === '/' ? classes.selected : null}
              >
                Dashboard
              </Typography>
            } />
          </ListItem>

          <ListItem button key="Budget" to="/budget" component={Link}
                    className={pathname === '/budget' ? classes.selectedAnchor : null}
          >
            <ListItemIcon><MapOutlined className={pathname === '/budget' ? classes.selected : null} /></ListItemIcon>
            <ListItemText primary={
              <Typography variant="subtitle2" noWrap className={pathname === '/budget' ? classes.selected : null}>
                Budget
              </Typography>
            } />
          </ListItem>

          <ListItem button key="Recurring" to="/recurring" component={Link}
                    className={pathname === '/recurring' ? classes.selectedAnchor : null}
          >
            <ListItemIcon>
              <EventRepeat className={pathname === '/recurring' ? classes.selected : null} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="subtitle2" noWrap className={pathname === '/recurring' ? classes.selected : null}>
                Recurring
                {/*<i className={classes.beta}>Beta</i>*/}
              </Typography>
            } />
          </ListItem>

          <ListItem button key="Reports" to="/reports" component={Link}
                    className={pathname === '/reports' ? classes.selectedAnchor : null}
          >
            <ListItemIcon><BarChart className={pathname === '/reports' ? classes.selected : null} /></ListItemIcon>
            <ListItemText primary={
              <Typography variant="subtitle2" noWrap className={pathname === '/reports' ? classes.selected : null}>
                Reports
              </Typography>
            } />
          </ListItem>

          <ListItem button key="History" to="/history" component={Link}
                    className={pathname === '/history' ? classes.selectedAnchor : null}
          >
            <ListItemIcon><ManageSearch className={pathname === '/history' ? classes.selected : null} /></ListItemIcon>
            <ListItemText primary={
              <Typography variant="subtitle2" noWrap className={pathname === '/history' ? classes.selected : null}>
                History
              </Typography>
            } />
          </ListItem>

          <ListItem button key="Account" to="/accounts" component={Link}
                    onClick={() => accountDetailClick(0)}
                    className={pathname === '/accounts' ? classes.selectedAnchor : null}
          >
            <ListItemIcon><AccountBalance
                className={pathname === '/accounts' ? classes.selected : null} /></ListItemIcon>
            <ListItemText primary={
              <Typography variant="subtitle2" noWrap className={pathname === '/accounts' ? classes.selected : null}>
                Accounts
              </Typography>
            } />
          </ListItem>

          {/*<ListItem button key="Investing" to="/accounts" component={Link}*/}
          {/*          onClick={() => accountDetailClick(0)}>*/}
          {/*  <ListItemIcon><Insights className={classes.icon} /></ListItemIcon>*/}
          {/*  <ListItemText primary={*/}
          {/*    <Typography variant="subtitle2" noWrap>*/}
          {/*      Investing*/}
          {/*    </Typography>*/}
          {/*  } />*/}
          {/*</ListItem>*/}

        </List>

        <Divider />

        <List>
          <AccountGroups />
        </List>

      </div>
  )

  // If not authorized don't show header
  return !isAuthenticated
      ? children
      : (
          <Fragment>
            <HeaderLoading>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      className={classes.menuButton}
                      size="large">
                    <Menu />
                  </IconButton>
                  {/*Header content*/}
                  <Grid container>
                    <Grid item xs={4}>
                      <MonthPicker />
                    </Grid>
                    <Grid item xs={4}>
                      <List disablePadding>
                        <ListItem button dense disableGutters onClick={handleBudgetDialogToggle}>
                          <ListItemText disableTypography className={needToBudgetColor}
                                        primary={
                                          <Typography align="center" variant="h6" noWrap>
                                            {formatMoneyLabel(needToBudget)}
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography align="center" variant="subtitle2" noWrap>
                                            Budget
                                          </Typography>
                                        }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={4}>
                      <HeaderSummary />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>

              <nav className={classes.drawer} aria-label="accounts">
                <Hidden smUp implementation="css">
                  <Drawer
                      variant="temporary"
                      anchor="left"
                      open={open}
                      onClose={handleDrawerToggle}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}>
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                  <Drawer
                      variant="permanent"
                      open
                      classes={{
                        paper: classes.drawerPaper,
                      }}>
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>

            </HeaderLoading>
            <Container className={classes.content}>
              <Box className={classes.toolbar} />
              {children}
            </Container>
            <MoveBudgetForm open={budgetDialogOpen} closeBudgetForm={handleBudgetDialogToggle} />
          </Fragment>
      )

}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'Header')
  return props
}

const propTypes = {
  user: PropTypes.object,
  children: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  accounts: PropTypes.array.isRequired
}

export default Header
