import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  FormControl, FormHelperText, InputLabel, Select, MenuItem, Button,
  TextField, Dialog, DialogActions, DialogContent, Typography,
  DialogTitle, Grid
} from '@mui/material'
import { PlaidLink } from 'react-plaid-link'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Save, Cancel, Delete, Inventory, Refresh, LinkOff, Build } from '@mui/icons-material'

import { sharedStyles, SwitchPositive } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { addAccount, editAccount, deleteAccount } from '../../actions/accounts'
import { editAccountId } from '../../actions/accountId'
import { refreshLinkTransactions, fixLinkToken, deleteFixToken } from '../../actions/link'
import { fullScreen, Transition } from '../utilities/dialogs'
import { moneyInput, formatMoney } from '../utilities/numbers'
import { returnErrors } from '../../actions/messages'

const filter = createFilterOptions()
const styles = theme => ({
  ...sharedStyles(theme),
})

class AccountForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        name: { error: null, helperText: '', },
        type: { error: null, helperText: '', },
        availableBalance: { error: null, helperText: '', },
        linkAccount: { error: null, helperText: '', },
      },
      categoryError: false,
      categoryHelperText: '',
      open: false,
      name: '',
      typeId: '',
      accountTypeCode: '',
      categoryOptions: [],
      category: { id: 0, name: '' },
      amountPositive: true,
      availableBalance: '',
      canDelete: true,
      linkAccount: { id: 0, name: '', status: null }
    }

    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleLinkAccountChange = this.handleLinkAccountChange.bind(this)
    this.updateCategoryOptions = this.updateCategoryOptions.bind(this)
    this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleAmountPositive = this.handleAmountPositive.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleRefresh = this.handleRefresh.bind(this)
    this.handleFix = this.handleFix.bind(this)
  }

  componentDidMount() {
    this.updateCategoryOptions()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accountId, accounts, linkAccounts, categories } = this.props

    if (accountId !== prevProps.accountId && accountId >= 0) {
      if (accountId === 0) {
        // New account record.
        this.setState({ open: true })
      } else if (accountId > 0) {
        // Update an existing account record.
        let linkCategory = { id: 0, name: '' }
        let linkAccount = { id: 0, name: '', status: null }

        for (let account of accounts) {
          if (accountId === account.id) {

            // Get the link account if exists
            for (const link of linkAccounts) {
              if (link.id === account.link_id) {
                linkAccount = link
                break
              }
            }

            // Get the link category if exists
            for (const category of categories) {
              if (category.id === account.category_id) {
                linkCategory = category
                break
              }
            }

            let accountTypeCode = account.type_code ? account.type_code.trim().toUpperCase() : ''
            let amountPositive = account.available_balance >= 0
            let amount = Math.abs(account.available_balance)
            let canDelete = account.can_delete

            this.setState({
              open: true,
              name: account.name,
              amountPositive: amountPositive,
              typeId: account.type_id,
              accountTypeCode,
              availableBalance: amount,
              canDelete,
              linkAccount,
              category: linkCategory
            })

            // End loop
            break
          }
        }
      }
    }

    // Update category options
    if (categories && categories !== prevProps.categories) this.updateCategoryOptions()
  }

  clearForm = () => {
    this.props.editAccountId(-1)
    this.setState({
      open: false,
      name: '',
      amountPositive: true,
      typeId: '',
      accountTypeCode: '',
      category: { id: 0, name: '' },
      availableBalance: '',
      canDelete: true,
      linkAccount: { id: 0, name: '', status: null },
      categoryError: false,
      categoryHelperText: ''
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleInputChange = e => {
    if (moneyInput(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleAmountPositive = () => this.setState({ amountPositive: !this.state.amountPositive })

  /**
   * If the categories prop is updated then update the link
   * category options.
   */
  updateCategoryOptions = () => {
    const { categories } = this.props
    let categoryOptions = []

    categories.map(c => {
      if (c.name && c.name.trim().toLowerCase() !== 'budget' && c.name.trim().toLowerCase() !== 'split') {
        categoryOptions.push(c)
      }
    })

    this.setState({ categoryOptions })
  }

  handleAccountTypeChange = e => {
    const { accountTypes } = this.props
    const typeId = e.target.value

    let accountTypeCode = accountTypes.filter(t => t.id === typeId)
    accountTypeCode = accountTypeCode && accountTypeCode[0] && accountTypeCode[0].code ? accountTypeCode[0].code : ''
    accountTypeCode = accountTypeCode.trim().toUpperCase()

    this.setState({
      [e.target.name]: typeId,
      accountTypeCode,
      category: { id: 0, name: '' },
      categoryError: false,
      categoryHelperText: ''
    })
  }

  handleCategoryChange = (e, value, reason) => {
    if (reason === 'clear') {
      this.setState({ category: { id: 0, name: '' } })
    } else if (value) {

      this.setState({
        category: value,
        categoryError: false,
        categoryHelperText: ''
      })
    }
  }

  handleLinkAccountChange = (e, value, reason) => {
    if (reason === 'clear') {
      this.setState({ linkAccount: { id: 0, name: '', status: null } })
    } else if (value) {
      this.setState({ linkAccount: value })
    }
  }

  /**
   * Submit the new account form.
   * current_balance is set on account creation and never changed.
   * @param {Object} e - The event object
   */
  handleSubmit = e => {
    e.preventDefault()

    try {

      const { name, typeId, accountTypeCode, category, amountPositive, availableBalance, linkAccount } = this.state
      const { accountId, yearMonth } = this.props
      const { user } = this.props.auth

      const linkAccountId = linkAccount && linkAccount.id ? linkAccount.id : null
      const categoryId = category && category.id ? category.id : null
      let netAmount = amountPositive ? availableBalance : -Math.abs(availableBalance)
      netAmount = formatMoney(netAmount)

      // If the account type is an investment then balance is 0.
      if (accountTypeCode === 'F') netAmount = 0


      // If the account type is a loan then a link category is required.
      if ((accountTypeCode === 'E') && !categoryId) {
        this.setState({
          categoryError: true,
          categoryHelperText: 'Loan accounts need a link category. Select a current category or create a new one.'
        })

        return false
      }

      if (accountId > 0) {
        const account = {
          id: accountId,
          name,
          type_id: typeId,
          category_id: categoryId,
          available_balance: netAmount,
          user_id: user.id,
          link_id: linkAccountId
        }

        this.props.editAccount(account, yearMonth)
      } else {
        const account = {
          name,
          type_id: typeId,
          category_id: categoryId,
          current_balance: netAmount,
          available_balance: netAmount,
          user_id: user.id,
          link_id: linkAccountId
        }

        this.props.addAccount(account, yearMonth)
      }

      this.clearForm()
    } catch (e) {
      this.props.returnErrors({ updateAccount: 'Unable to add an account at this time. Please try again later.' }, 500)
      return false
    }
  }

  /**
   * This will delete the account permanently if there are no
   * transactions or investment holdings tied to this account.
   * Otherwise, if there are transactions tied to this account it will
   * set the archive flag to true.
   */
  handleDelete = () => {
    const { accountId, yearMonth } = this.props

    this.props.deleteAccount(accountId, yearMonth)
    this.clearForm()
  }

  /**
   * If the account is a linked then we can use the account id to
   * refresh the link transactions.
   * @param {Object} linkAccount - The selected link account.
   */
  handleRefresh = linkAccount => {
    const id = linkAccount && linkAccount.id > 0 ? linkAccount.id : 0
    if (id > 0) this.props.refreshLinkTransactions(id)
    this.clearForm()
  }

  /**
   * Used to fix the link account.
   * @param {Object} linkAccount - The selected link account.
   */
  handleFix = linkAccount => {
    const id = linkAccount && linkAccount.id > 0 ? linkAccount.id : 0
    if (id > 0) this.props.fixLinkToken(id)
  }

  /**
   * The user exited the Link flow.
   * @param err
   * @param metadata
   * @return {boolean}
   */
  onExit = (err, metadata) => {
    this.props.deleteFixToken()
    if (err != null) {
      // The user encountered a Plaid API error prior to exiting.
      return false
    }
  }

  /**
   * The Link was successfully updated.
   * You do not need to repeat the /item/public_token/exchange
   * process when a user uses Link in update mode.
   * The Item's access_token has not changed.
   * @param public_token
   * @param metadata
   */
  onSuccess = (public_token, metadata) => {
    const { linkAccount } = this.state
    const id = linkAccount && linkAccount.id > 0 ? linkAccount.id : 0

    this.props.deleteFixToken()
    this.props.refreshLinkTransactions(id)
    this.clearForm()
  }

  render() {
    const {
      open, error, name, typeId, accountTypeCode, category, categoryOptions,
      amountPositive, availableBalance, canDelete, linkAccount,
      categoryError, categoryHelperText
    } = this.state
    const {
      classes, theme, accountId, accountTypes, linkAccounts, fixToken
    } = this.props

    const deleteButton = canDelete
        ? (
            <Button onClick={this.handleDelete}
                    className={`${classes.dangerButton} ${classes.floatRight}`}
                    variant="outlined">
              <Delete className={classes.pr1} />Delete
            </Button>
        )
        : (
            <Button onClick={this.handleDelete}
                    className={`${classes.dangerButton} ${classes.floatRight}`}
                    variant="outlined">
              <Inventory className={classes.pr1} />Archive
            </Button>
        )

    const fixButton = (
        <Button onClick={() => this.handleFix(linkAccount)}
                className={classes.ml1}
                color="inherit"
                variant="outlined">
          <LinkOff className={classes.pr1} fontSize="medium" />Link Error
        </Button>
    )

    const refreshButton = (
        <DialogActions className={classes.dialogActionLeftButton}>
          <Button onClick={() => this.handleRefresh(linkAccount)}
                  color="inherit"
                  variant="outlined">
            <Refresh className={classes.pr1} fontSize="medium" />Refresh
          </Button>
        </DialogActions>
    )

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="account-form"
                  disableEnforceFocus
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Account
              {accountId > 0 && linkAccount && linkAccount.status && fixButton}
              {accountId > 0 && deleteButton}
              {linkAccount && linkAccount.status && fixToken &&
                  <PlaidLink token={fixToken}
                             className={`${classes.ml1} ${classes.customButton} MuiButtonBase-root MuiButton-root MuiButton-outlined AccountForm-ml1-316 MuiButton-outlinedPrimary`}
                             onExit={this.onExit}
                             onSuccess={this.onSuccess}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Build className={classes.pr1} fontSize="medium" />
                      <Typography align="center" variant="button" noWrap>Fix</Typography>
                    </Grid>
                  </PlaidLink>
              }
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField required
                               name="name"
                               value={name}
                               onChange={this.handleChange}
                               error={error.name.error}
                               helperText={error.name.helperText}
                               autoFocus
                               margin="dense"
                               id="accountName"
                               label="Account Name"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth error={error.type.error}>
                      <InputLabel id="accountTypeLabel">Account Type</InputLabel>
                      <Select required
                              variant="standard"
                              labelId="accountTypeLabel"
                              name="typeId"
                              value={typeId}
                              onChange={this.handleAccountTypeChange}>
                        {accountTypes.map(type => {
                          return (
                              <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                          )
                        })}
                      </Select>
                      {error.type.error
                          ? <FormHelperText>{error.type.helperText}</FormHelperText>
                          : null}
                    </FormControl>
                  </Grid>

                  {accountTypeCode === 'E' &&
                      <Grid item xs={12}>
                        <Autocomplete
                            name="category"
                            clearOnEscape
                            openOnFocus
                            autoComplete
                            autoHighlight
                            includeInputInList
                            value={category}
                            options={categoryOptions}
                            isOptionEqualToValue={(option, value,) => value.value === option.value}
                            getOptionLabel={option => option.name ? option.name : ''}
                            onChange={this.handleCategoryChange}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                            filterOptions={(options, params) => filter(options, params)}
                            renderInput={(params) => (
                                <TextField error={categoryError}
                                           helperText={categoryHelperText}
                                           margin="dense"
                                           id="category"
                                           label="Link Category"
                                           type="text"
                                           fullWidth {...params}
                                />
                            )}
                        />
                      </Grid>
                  }

                  <Grid item xs={12}>
                    <Autocomplete
                        name="linkAccount"
                        clearOnEscape
                        openOnFocus
                        autoComplete
                        autoHighlight
                        includeInputInList
                        value={linkAccount}
                        options={linkAccounts}
                        isOptionEqualToValue={(option, value,) => value.value === option.value}
                        getOptionLabel={option => option.name && option.official_name ? `${option.name} - ${option.official_name}` : option.name ? option.name : ''}
                        onChange={this.handleLinkAccountChange}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              {option.name && option.official_name ? `${option.name} - ${option.official_name}` : option.name ? option.name : ''}
                            </li>
                        )}
                        filterOptions={(options, params) => filter(options, params)}
                        renderInput={(params) => (
                            <TextField error={error.linkAccount.error}
                                       helperText={error.linkAccount.helperText}
                                       margin="dense"
                                       id="linkAccount"
                                       label="Link Account (optional)"
                                       type="text"
                                       fullWidth {...params}
                            />
                        )}
                    />
                  </Grid>

                  {accountTypeCode !== 'F' &&
                      <>
                        <Grid item xs={4}>
                          <Grid container alignItems="center" justifyContent="center" spacing={1} wrap="nowrap">
                            <Grid item>-</Grid>
                            <Grid item>
                              <SwitchPositive checked={amountPositive}
                                              onChange={this.handleAmountPositive}
                                              name="amountPositive"
                                              inputProps={{ 'aria-label': 'amount-positive' }}
                              />
                            </Grid>
                            <Grid item>+</Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField required
                                     name="availableBalance"
                                     value={availableBalance}
                                     onChange={this.handleInputChange}
                                     error={error.availableBalance.error}
                                     helperText={error.availableBalance.helperText}
                                     inputProps={{ inputMode: 'decimal' }}
                                     margin="dense"
                                     id="accountAvailableBalance"
                                     label="Account Balance"
                                     type="text"
                                     fullWidth
                          />
                        </Grid>
                      </>
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                {accountId > 0 && linkAccount && linkAccount.id > 0 && refreshButton}
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={this.clearForm} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

AccountForm.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  addAccount: PropTypes.func.isRequired,
  editAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  editAccountId: PropTypes.func.isRequired,
  returnErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accountTypes: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  accountId: PropTypes.number.isRequired,
  linkAccounts: PropTypes.array.isRequired,
  refreshLinkTransactions: PropTypes.func.isRequired,
  fixLinkToken: PropTypes.func.isRequired,
  deleteFixToken: PropTypes.func.isRequired,
  fixToken: PropTypes.string
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  accountTypes: state.accountTypeReducer.accountTypes,
  categories: state.categoryReducer.categories,
  accounts: state.accountReducer.accounts,
  accountId: state.accountIdReducer.accountId,
  linkAccounts: state.linkReducer.linkAccounts,
  fixToken: state.linkReducer.fixToken
})

const mapDispatchToProps = {
  addAccount,
  editAccount,
  deleteAccount,
  editAccountId,
  refreshLinkTransactions,
  fixLinkToken,
  deleteFixToken,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AccountForm))
