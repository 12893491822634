import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Typography, Divider, Grid, Button, Link } from '@mui/material'
import { VerifiedUser } from '@mui/icons-material'

import { withRouter } from '../utilities/withRouter'
import { activate } from '../../actions/auth'
import AuthLayout from './AuthLayout'
import { sharedStyles } from '../common/styles'

const styles = theme => ({
  ...sharedStyles(theme),
})

class Activate extends Component {

  activateAccount = e => {
    const { match: { params } } = this.props
    if (typeof params !== 'undefined' && params != null) {
      const uid = params.uid
      const token = params.token
      this.props.activate(uid, token)
    }
  }

  render() {
    const { classes, isAuthenticated, accountActivated } = this.props

    if (isAuthenticated) return <Navigate to="/" />
    if (accountActivated) return <Navigate to="/login" />

    return (
        <AuthLayout heading="Verify your Email">
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Typography>
                Thank you for signing up. Please confirm this is your email address to create your new budget.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12} justifyContent="center">
              <Button fullWidth={true} type="submit" color="primary" variant="contained"
                      onClick={this.activateAccount}>
                <VerifiedUser className={classes.pr1} fontSize="small" />Confirm
              </Button>
            </Grid>

            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <Link underline="always" target="_blank" color="inherit" href="https://www.ploutosbudget.com/terms">
                Terms of service
              </Link>
            </Grid>
            <Divider />
          </Grid>
        </AuthLayout>
    )
  }
}

Activate.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  accountActivated: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  accountActivated: state.authReducer.accountActivated
})

const mapDispatchToProps = {
  activate
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Activate)))
