import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Grid, Table, TableBody, TableContainer, TableCell, TableHead,
  TableRow, Paper
} from '@mui/material'
import { DateTime } from 'luxon'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title,
  Tooltip, Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { backgroundColors, borderColors } from '../chart/charts'
import { formatMoneyLabel } from '../utilities/numbers'
import { sharedStyles } from '../common/styles'

const styles = theme => ({
  ...sharedStyles(theme),
})

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
  plugins: {
    title: {
      display: false,
      text: 'Income Vs Expense',
    },
  },
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        maxRotation: 45,
        minRotation: 45
      },
    },
    y: {
      stacked: true,
      ticks: {
        callback: function (value, index, ticks) {
          return formatMoneyLabel(value)
        },
      },
    },
  },
}

function IncomeVsExpense() {

  const { reports } = withPropsValidation(
      useSelector(({ reportReducer }) => ({
        reports: reportReducer.reports,
      })))

  let incomeExpenseReport = reports && reports.income_expense_report ? reports.income_expense_report : []
  const incomeExpenseTable = [...incomeExpenseReport].reverse()
  incomeExpenseReport = incomeExpenseReport.slice(-12)
  const labels = incomeExpenseReport.map((report) => DateTime.fromISO(report.date).toLocaleString({
    month: 'short',
    year: 'numeric'
  }))
  const inflow = incomeExpenseReport.map((report) => report.inflow)
  const outflow = incomeExpenseReport.map((report) => report.outflow)
  const surplus = incomeExpenseReport.map((report) => report.inflow - report.outflow)

  const data = {
    labels,
    datasets: [
      {
        label: 'Inflow',
        data: inflow,
        backgroundColor: backgroundColors[3],
        borderColor: borderColors[3],
        borderWidth: 2,
        stack: 'Stack 0',
      },
      {
        label: 'Outflow',
        data: outflow,
        backgroundColor: backgroundColors[0],
        borderColor: borderColors[0],
        borderWidth: 2,
        stack: 'Stack 1',
      },
      {
        label: 'Surplus',
        data: surplus,
        backgroundColor: backgroundColors[1],
        borderColor: borderColors[1],
        borderWidth: 2,
        stack: 'Stack 2',
      },
    ],
  }

  return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Bar options={options} data={data} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="income vs expense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="center">Inflow</TableCell>
                    <TableCell align="center">Outflow</TableCell>
                    <TableCell align="center">Surplus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {incomeExpenseTable.map((row, index) => {
                    let surplus = row.inflow - row.outflow
                    surplus = formatMoneyLabel(surplus)
                    const inflow = formatMoneyLabel(row.inflow)
                    const outflow = formatMoneyLabel(row.outflow)
                    return (
                        <TableRow key={`income-vs-expense-${index}`} hover>
                          <TableCell>{DateTime.fromISO(row.date).toLocaleString({
                            month: 'short',
                            year: 'numeric'
                          })}</TableCell>
                          <TableCell align="center">{inflow}</TableCell>
                          <TableCell align="center">{outflow}</TableCell>
                          <TableCell align="center">{surplus}</TableCell>
                        </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', '')
  return props
}

const propTypes = {
  reports: PropTypes.object.isRequired
}


export default withStyles(styles)(IncomeVsExpense)
