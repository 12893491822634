import React, { Fragment, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid, Paper } from '@mui/material'
import { DateTime } from 'luxon'
import { CheckCircle, Warning, Report } from '@mui/icons-material'

import RecurringCalendar from './RecurringCalendar'
import RecurringTransactionList from './RecurringTransactionList'
import RecurringDateDetail from '../layout/RecurringDateDetail'
import RecurringTransactionDetail from '../layout/RecurringTransactionDetail'
import { formatMoneyLabel } from '../utilities/numbers'

const NOW = new Date()

/**
 * Dashboard for the recurring page.
 *
 * Note: calendarEvents is the same as the events state object with the
 * exception that calendarEvents contains the last event and the
 * upcoming event. The event state object only contains the upcoming
 * event if known otherwise the last event.
 * @returns {JSX.Element}
 * @constructor
 */
const Dashboard = () => {
  const { recurringTransactions, accounts } = withPropsValidation(
      useSelector(({ recurringReducer, accountReducer }) => ({
        recurringTransactions: recurringReducer.recurringTransactions,
        accounts: accountReducer.accounts
      })))

  const [calendarEvents, setCalendarEvents] = useState([])
  const [events, setEvents] = useState([])
  const [dateDetailOpen, setDateDetailOpen] = useState(false)
  const [transactionDetailOpen, setTransactionDetailOpen] = useState(false)
  const [dateEvents, setDateEvents] = useState([])
  const [transactionDetails, setTransactionDetails] = useState({})
  const [recurringDate, setRecurringDate] = useState(NOW)


  /**
   * Link transactions are reversed in this case.
   * A positive is an outflow and a negative is an inflow.
   * Let's flip them.
   */
  useEffect(() => {
    const calendarEvents = []
    const events = []

    for (const rt of recurringTransactions) {
      // Get the account associated with this event.
      const account = accounts.find(a => a.link_id === rt.account_id)

      // Handle the last and average transaction amount for this event.
      let lastAmount = rt.last_amount > 0 ? -Math.abs(rt.last_amount) : Math.abs(rt.last_amount)
      const lastAmountPositive = (lastAmount > 0)
      lastAmount = formatMoneyLabel(lastAmount)
      let averageAmount = rt.average_amount > 0 ? -Math.abs(rt.average_amount) : Math.abs(rt.average_amount)
      const averageAmountPositive = (averageAmount > 0)
      averageAmount = formatMoneyLabel(averageAmount)

      // Format a title string.
      const title = rt.description && rt.merchant_name
          ? `${lastAmount}: ${rt.description} ${rt.merchant_name}`
          : rt.description
              ? `${lastAmount}: ${rt.description} `
              : lastAmount

      /**
       * Get the last event date and next event date.
       * Both events as a JS Date and in pretty format.
       */
      const lastDate = DateTime.fromISO(rt.last_date).toJSDate()
      const prettyLastDate = DateTime.fromISO(rt.last_date).toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })
      const frequency = rt.frequency ? rt.frequency : ''
      const isActive = (rt.active || false)
      const { prettyFrequency, nextEvent } = handleFrequency(rt.last_date, frequency)
      const prettyNextEvent = nextEvent ? DateTime.fromJSDate(nextEvent).toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }) : 'Unknown'
      const statusIcon = handleStatus(rt.status)

      const event = {
        id: rt.id,
        title,
        // start: DateTime.local().toJSDate(),
        // end: DateTime.local().toJSDate(),
        start: nextEvent ? nextEvent : lastDate,
        end: nextEvent ? nextEvent : lastDate,
        allDay: true,
        resourceId: rt.id,
        account: account ? account.name : '',
        description: (rt.description || ''),
        merchantName: (rt.merchant_name || ''),
        lastAmount,
        lastAmountPositive,
        lastDate,
        prettyLastDate,
        averageAmount,
        nextDate: nextEvent,
        prettyNextDate: prettyNextEvent,
        frequency: frequency,
        prettyFrequency: (prettyFrequency || 'Unknown'),
        isActive: isActive,
        status: rt.status,
        statusIcon,
        linkAccount: (rt.account || ''),
        linkAccountOfficial: (rt.account_official || ''),
        linkTransactions: rt.link_transactions
      }

      /**
       * If the next event is known then add the last event to the
       * calendar state object
       */
      if (nextEvent) {
        calendarEvents.push({
          ...event,
          start: lastDate,
          end: lastDate
        })
      }

      calendarEvents.push(event)
      events.push(event)
    }

    setCalendarEvents(calendarEvents)
    setEvents(events)
  }, [recurringTransactions])

  /**
   * This function calculates the next estimated date and returns the
   * frequency in a prettier format.
   *
   * Frequency options:
   * UNKNOWN, WEEKLY, BIWEEKLY, SEMI_MONTHLY, MONTHLY, ANNUALLY
   * @param {string} frequency
   * @param {string} lastDate - The last time the recurring transaction
   * occurred.
   * @returns {{nextEvent: date, prettyFrequency: string}|{nextEvent: null, prettyFrequency: null}}
   */
  const handleFrequency = (lastDate, frequency) => {
    let prettyFrequency = null
    let nextEvent = null

    try {

      switch (frequency) {
        case 'WEEKLY':
          prettyFrequency = 'Weekly'
          nextEvent = DateTime.fromISO(lastDate).plus({ weeks: 1 }).toJSDate()
          break
        case 'BIWEEKLY':
          prettyFrequency = 'Every 2 weeks'
          nextEvent = DateTime.fromISO(lastDate).plus({ weeks: 2 }).toJSDate()
          break
        case 'SEMI_MONTHLY':
          prettyFrequency = 'Twice a month'
          nextEvent = DateTime.fromISO(lastDate).plus({ months: 0.5 }).toJSDate()
          break
        case 'MONTHLY':
          prettyFrequency = 'Monthly'
          nextEvent = DateTime.fromISO(lastDate).plus({ months: 1 }).toJSDate()
          break
        case 'ANNUALLY':
          prettyFrequency = 'Annually'
          nextEvent = DateTime.fromISO(lastDate).plus({ years: 1 }).toJSDate()
          break
        default:
          prettyFrequency = null
          nextEvent = null
      }

      return { prettyFrequency, nextEvent }
    } catch (e) {
      return { prettyFrequency, nextEvent }
    }
  }

  /**
   * Status options:
   * UNKNOWN, MATURE, EARLY_DETECTION, TOMBSTONED
   * @param {string} status
   */
  const handleStatus = (status) => {
    try {
      let statusColor

      switch (status) {
        case 'MATURE':
          statusColor = <CheckCircle fontSize="small" color="success" />
          break
        case 'EARLY_DETECTION':
          statusColor = <Warning fontSize="small" color="warning" />
          break
        case 'TOMBSTONED':
          statusColor = <Report fontSize="small" color="error" />
          break
        default:
          statusColor = <Report fontSize="small" color="error" />
      }

      return statusColor
    } catch (e) {
      return <Report fontSize="small" color="error" />
    }
  }


  /**
   * Handles when a user clicks or uses the keyboard to action the
   * show more button.
   */
  const handleShowMore = useCallback(
      (events, date) => {
        setDateEvents(events)
        setRecurringDate(date)
        setDateDetailOpen(true)
      }, [])

  /**
   * Handles when a user clicks or uses the keyboard to action an
   * event.
   * @param {Object} calendarEvent - The selected event object.
   */
  const handleSelectEvent = useCallback((calendarEvent) => {
    if (calendarEvent) {
      setTransactionDetails(calendarEvent)
      setTransactionDetailOpen(true)
    }
  }, [])

  /**
   * Close the recurring date detail modal.
   */
  const handleDateDetailClose = () => setDateDetailOpen(false)

  /**
   * Close the recurring transaction details modal.
   */
  const handleTransactionClose = () => setTransactionDetailOpen(false)

  return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <RecurringCalendar events={calendarEvents} onShowMore={handleShowMore}
                                 onSelectEvent={handleSelectEvent}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3}>
              <RecurringTransactionList events={events} onSelectEvent={handleSelectEvent} />
            </Paper>
          </Grid>
        </Grid>

        <RecurringDateDetail open={dateDetailOpen}
                             handleClose={handleDateDetailClose}
                             events={dateEvents}
                             recurringDate={recurringDate}
                             onSelectEvent={handleSelectEvent}
        />
        <RecurringTransactionDetail open={transactionDetailOpen}
                                    handleClose={handleTransactionClose}
                                    event={transactionDetails}
        />
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'Dashboard')
  return props
}

const propTypes = {
  recurringTransactions: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired
}

export default Dashboard
