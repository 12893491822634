import { DateTime } from 'luxon'

/**
 * Format the date to the logged-in users date format preference from
 * an ISO date.
 * @param input
 * @returns {string} - The input formatted to the users' date
 * preference.
 */
export const formatISODateTime = input => {
  return DateTime.fromISO(input).toLocaleString(DateTime.DATETIME_FULL)
}

/**
 * Format the date to the logged-in users date format preference from
 * an ISO date.
 * @param input
 * @returns {string} - The input formatted to the users' date
 * preference.
 */
export const formatISODate = input => {
  return DateTime.fromISO(input).toLocaleString()
}

/**
 * Format the date to the logged-in users date format preference from
 * a JS datetime.
 * @param input
 * @returns {string} - The input formatted to the users' date
 * preference.
 */
export const formatJSDate = input => {
  return DateTime.fromJSDate(input).toLocaleString()
}

/**
 * Used to format a javascript date to ISO format.
 * @param input
 * @returns {string} - The input formatted to ISO date format
 */
export const formatToISODate = input => {
  return DateTime.fromJSDate(input).toFormat('yyyy-MM-dd')
}

/**
 * Array of the months full name.
 * @type {string[]} - All of the months.
 */
export const months = [undefined, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

/**
 * Array of the months first initial.
 * @type {string[]} - All of the months.
 */
export const monthInitials = [undefined, 'J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
