import { GET_CATEGORY_ID, EDIT_CATEGORY_ID } from './types'

// Get category Id
export const getCategoryId = () => (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORY_ID,
    payload: getState
  })
}

// Edit category Id
export const editCategoryId = (id) => (dispatch, getState) => {
  dispatch({
    type: EDIT_CATEGORY_ID,
    payload: id
  })
}
