import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Save, Cancel } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { bulkPayeeUpdate } from '../../actions/payees'
import { fullScreen, Transition } from '../utilities/dialogs'
import { returnErrors } from '../../actions/messages'

const styles = theme => ({
  ...sharedStyles(theme),
})

class PayeeForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        payee: { error: null, helperText: '', },
      },
      payee: '',
    }

    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /**
   * Used to clear the form and close the dialog.
   */
  clearForm = () => {
    this.setState({ payee: '' })
    this.props.closeDialog()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  /**
   * Submit the edit payee form
   * @param {Object} e - The event object.
   */
  handleSubmit = e => {
    e.preventDefault()
    try {
      const { payee } = this.state
      const { yearMonth, payees, bulkPayeeUpdate, returnErrors } = this.props
      const { user } = this.props.auth
      const name = payee ? payee.trim() : null

      // Payee cannot be blank
      if (!name) {
        returnErrors({ payee: 'Payee cannot be blank.' }, 500)
        return false
      }

      // Payee cannot equal adjustment
      if (name && name.toLowerCase() === 'adjustment') {
        returnErrors({ payee: 'Cannot update payee to "Adjustment".' }, 500)
        return false
      }

      // Edit payees
      const payeeForm = {
        name,
        payees,
        user_id: user.id
      }

      bulkPayeeUpdate(payeeForm, yearMonth)
      this.clearForm()
    } catch (e) {
      this.props.returnErrors({ payee: 'Unable to update payees. Please try again later.' }, 500)
    }
  }

  render() {
    const { error, payee } = this.state
    const { classes, theme, open } = this.props

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="edit-payee-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  maxWidth="sm"
                  PaperComponent={DraggableDialogWrapper}
                  fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Edit Payee
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField required
                               name="payee"
                               value={payee}
                               autoFocus
                               onChange={this.handleChange}
                               error={error.payee.error}
                               helperText={error.payee.helperText}
                               margin="dense"
                               label="Payee"
                               type="text"
                               fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={this.clearForm} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

PayeeForm.propTypes = {
  auth: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  yearMonth: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  payees: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
})

const mapDispatchToProps = {
  bulkPayeeUpdate,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PayeeForm))
