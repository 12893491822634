import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class HeaderLoading extends Component {

  render() {
    const { accountsLoaded, auth } = this.props
    return (auth.isLoading || !accountsLoaded) ? null : this.props.children
  }
}

HeaderLoading.propTypes = {
  auth: PropTypes.object.isRequired,
  accountsLoaded: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  accountsLoaded: state.accountReducer.isLoaded,
})

export default connect(mapStateToProps)(HeaderLoading)