import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  CREATE_LINK_TOKEN, CREATE_LINK_TOKEN_FAIL, DELETE_LINK_TOKEN_SUCCESS,
  CREATE_FIX_TOKEN, CREATE_FIX_TOKEN_FAIL, DELETE_FIX_TOKEN_SUCCESS,
  GET_LINK_ACCOUNTS, LINK_ACCOUNTS_LOADING, LINK_ACCOUNTS_FAIL,
  CREATE_LINK_TRANSACTIONS, GET_LINK_TRANSACTIONS, EDIT_LINK_TRANSACTION,
  LINK_TRANSACTIONS_LOADING, LINK_TRANSACTIONS_FAIL
} from './types'

import { tokenConfig } from './auth'
import { getAccounts } from './accounts'

const proxy = process.env.REACT_APP_PROXY

// Create link token
export const createLinkToken = () => (dispatch, getState) => {
  dispatch({ type: DELETE_LINK_TOKEN_SUCCESS })
  axios.post(`${proxy}/v1/link_tokens/`, {}, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: CREATE_LINK_TOKEN,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: CREATE_LINK_TOKEN_FAIL
        })
      })
}

/**
 * Fix the link token
 */
export const fixLinkToken = id => (dispatch, getState) => {
  dispatch({ type: DELETE_FIX_TOKEN_SUCCESS })
  axios.post(`${proxy}/v1/fix_link_tokens/`,
      { 'id': id }, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: CREATE_FIX_TOKEN,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: CREATE_FIX_TOKEN_FAIL
        })
      })
}

/**
 * Used to delete the fix token when done with it.
 * @return {function(*): void}
 */
export const deleteFixToken = () => (dispatch) => {
  dispatch({ type: DELETE_FIX_TOKEN_SUCCESS })
}

// Get link accounts.
export const getLinkAccounts = () => (dispatch, getState) => {
  dispatch({ type: LINK_ACCOUNTS_LOADING })
  axios.get(`${proxy}/v1/link_accounts/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_LINK_ACCOUNTS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: LINK_ACCOUNTS_FAIL })
      })
}

/**
 * Delete the link account.
 * @param {Object} linkAccount - The link account.
 * @return {function(*, *=): void}
 */
export const deleteLinkAccount = (linkAccount) => (dispatch, getState) => {
  axios.post(`${proxy}/v1/link_accounts/`, linkAccount, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ deleteAccount: 'Link Account Deleted' }))
        dispatch({
          type: LINK_ACCOUNTS_LOADING
        })
        dispatch(getLinkAccounts())
        dispatch(getLinkTransactions())
        dispatch(getAccounts())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Adds the plaid link service for transactions.
export const addLinkTransactions = publicToken => (dispatch, getState) => {
  dispatch({ type: LINK_TRANSACTIONS_LOADING })
  axios.post(`${proxy}/v1/plaid_transactions/`,
      { 'public_token': publicToken }, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: CREATE_LINK_TRANSACTIONS,
          payload: res.data
        })
        dispatch(getLinkAccounts())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: LINK_TRANSACTIONS_FAIL })
      })
}

// Get link transactions.
export const getLinkTransactions = () => (dispatch, getState) => {
  dispatch({ type: LINK_TRANSACTIONS_LOADING })
  axios.get(`${proxy}/v1/link_transactions/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_LINK_TRANSACTIONS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: LINK_TRANSACTIONS_FAIL })
      })
}

/**
 * Refresh link transactions.
 * This function is used to ping plaid to see if more transaction
 * data is available.
 */
export const refreshLinkTransactions = id => (dispatch, getState) => {
  dispatch({ type: LINK_TRANSACTIONS_LOADING })
  axios.post(`${proxy}/v1/link_transactions/`,
      { 'id': id }, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ linkAPI: 'Link transactions refreshed.' }))
        dispatch({
          type: LINK_TRANSACTIONS_LOADING,
          payload: res.data
        })
        dispatch(getLinkTransactions())
        dispatch(getLinkAccounts())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: LINK_TRANSACTIONS_FAIL })
      })
}

// Edit link transactions.
export const editLinkTransaction = linkTransaction => (dispatch, getState) => {
  axios.put(`${proxy}/v1/link_transactions/${linkTransaction.id}/`, linkTransaction, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ deleteTransaction: 'Transaction Deleted' }))
        dispatch({
          type: EDIT_LINK_TRANSACTION,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: LINK_TRANSACTIONS_FAIL })
      })
}
