import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Save, Cancel, Delete } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { DatePicker } from '@mui/lab'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { investmentInput } from '../utilities/numbers'
import { addHolding, editHolding, deleteHolding } from '../../actions/holdings'
import { editHoldingId } from '../../actions/holdingId'
import { fullScreen, Transition } from '../utilities/dialogs'
import { createToast } from '../../actions/messages'

const filter = createFilterOptions()

const styles = theme => ({
  ...sharedStyles(theme),
})

class HoldingForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {
        name: { error: null, helperText: '' },
        ticker: { error: null, helperText: '' },
        shares: { error: null, helperText: '' },
        costBasis: { error: null, helperText: '' },
        marketValue: { error: null, helperText: '' },
        date: { error: null, helperText: '', },
        account: { error: null, helperText: '' }
      },
      open: false,
      datePickerOpen: false,
      name: '',
      ticker: '',
      shares: 0,
      costBasis: 0,
      marketValue: 0,
      date: DateTime.local(),
      account: { id: 0, name: '' },
      accountOptions: [],

    }

    this.updateAccountOptions = this.updateAccountOptions.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleTickerChange = this.handleTickerChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleAccountChange = this.handleAccountChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    this.updateAccountOptions()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { holdingId, history, accounts } = this.props

    if (holdingId !== prevProps.holdingId && holdingId >= 0) {
      if (holdingId === 0) {
        // New holding record.
        this.setState({ open: true })
      } else if (holdingId > 0) {
        // Update an existing holding record.
        for (let holding of history) {
          if (holdingId === holding.id) {

            // We don't allow linked holdings to be edited.
            if (holding && holding.link_account_id) {
              this.props.editHoldingId(-1)
              this.props.createToast({
                holdingInfo: 'Linked holdings cannot be edited.'
              })
              break
            }

            // Get account
            let account = { id: 0, name: '' }
            for (const a of accounts) {
              if (a.id === holding.account_id) {
                account = a
                break
              }
            }

            this.setState({
              open: true,
              name: holding.name,
              ticker: holding.ticker_symbol,
              shares: holding.quantity,
              costBasis: holding.cost_basis,
              marketValue: holding.institution_value,
              date: DateTime.fromISO(holding.institution_price_as_of),
              account
            })

            // End loop
            break
          }
        }
      }
    }

    // Update account options
    if (accounts && accounts !== prevProps.accounts) {
      this.updateAccountOptions()
    }
  }

  /**
   * Update the available account options to assign a holdings to.
   * Investment (F) types only.
   */
  updateAccountOptions = () => {
    const { accounts } = this.props

    const accountOptions = []
    for (const account of accounts) {
      if (account.archived) continue
      const accountTypeCode = account.type_code ? account.type_code.trim().toUpperCase() : null
      let group = 'Investments'

      if (accountTypeCode !== 'F') continue

      accountOptions.push({ group, ...account })
    }

    this.setState({ accountOptions })
  }

  clearForm = () => {
    this.props.editHoldingId(-1)
    this.setState({
      error: {
        name: { error: null, helperText: '' },
        ticker: { error: null, helperText: '' },
        shares: { error: null, helperText: '' },
        costBasis: { error: null, helperText: '' },
        marketValue: { error: null, helperText: '' },
        date: { error: null, helperText: '' },
        account: { error: null, helperText: '' }
      },
      open: false,
      name: '',
      ticker: '',
      shares: 0,
      costBasis: 0,
      marketValue: 0,
      account: { id: 0, name: '' },
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  /**
   * Change event for the ticker field to autocapitalize the input
   * @param e
   */
  handleTickerChange = e => {
    let value = e.target.value
    value = value ? value.toUpperCase() : ''

    this.setState({ [e.target.name]: value })
  }

  /**
   * Update investment numbers change
   * @param {Object} e - The event object.
   */
  handleInputChange = e => {

    if (investmentInput(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  handleDateChange = date => this.setState({ date })

  /**
   * This function is used to update the account state.
   * @param {Object} e - The event object
   * @param {Object} value - The selected value object. ie {id, name}
   * @param {string} reason - The reason the event was triggered.
   */
  handleAccountChange = (e, value, reason) => {
    const { error } = this.state

    if (reason === 'clear') {
      this.setState({
        account: { id: 0, name: '' },
        error: { ...error, account: { error: null, helperText: '' } }
      })
    } else if (value) {
      this.setState({
        account: value,
        error: { ...error, account: { error: null, helperText: '' } }
      })
    }
  }

  /**
   * Submit form.
   * @param {Object} e - The event object
   */
  handleSubmit = e => {
    e.preventDefault()
    const { error, name, ticker, shares, costBasis, marketValue, date, account } = this.state
    const { user } = this.props.auth
    const { holdingId, yearMonth } = this.props
    const accountId = account && account.id ? account.id : 0
    if (accountId === 0) {
      this.setState({
        error: {
          ...error,
          account: {
            error: true,
            helperText: 'Account is required.'
          }
        }
      })

      return false
    }

    if (holdingId > 0) {
      // Edit Holding
      const holding = {
        id: holdingId,
        name,
        ticker_symbol: ticker,
        quantity: shares,
        cost_basis: costBasis,
        institution_value: marketValue,
        institution_price_as_of: date.toISODate(),
        account_id: account.id,
        user_id: user.id
      }

      this.props.editHolding(holding, yearMonth)
    } else {
      // Add Holding
      const holding = {
        name,
        ticker_symbol: ticker,
        quantity: shares,
        cost_basis: costBasis,
        institution_value: marketValue,
        institution_price_as_of: date.toISODate(),
        account_id: account.id,
        user_id: user.id
      }

      this.props.addHolding(holding, yearMonth)
    }

    this.clearForm()
  }

  handleDelete = () => {
    const { holdingId } = this.props

    this.props.deleteHolding(holdingId)
    this.clearForm()
  }

  render() {
    const {
      open,
      datePickerOpen,
      error,
      name,
      ticker,
      shares,
      costBasis,
      marketValue,
      date,
      account,
      accountOptions
    } = this.state
    const { classes, theme, holdingId } = this.props

    const deleteButton = (
        <Button onClick={this.handleDelete}
                className={`${classes.dangerButton} ${classes.floatRight}`}
                variant="outlined">
          <Delete className={classes.pr1} />Delete
        </Button>
    )

    return (
        <Fragment>
          <Dialog open={open}
                  aria-labelledby="holding-form"
                  TransitionComponent={Transition}
                  fullScreen={fullScreen(theme)}
                  PaperComponent={DraggableDialogWrapper}
                  maxWidth="sm" fullWidth={true}
          >
            <DialogTitle className={classes.moveCursor}>
              Holding
              {holdingId > 0 ? deleteButton : ''}
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <Grid container justifyContent="center" spacing={2}>

                  <Grid item xs={12}>
                    <FormControl fullWidth={true} error={error.date.error}>
                      <DatePicker
                          name="date"
                          label="Date"
                          open={datePickerOpen}
                          onClose={() => this.setState({ datePickerOpen: false })}
                          showTodayButton
                          autoOk
                          animateYearScrolling
                          showDaysOutsideCurrentMonth
                          clearable={false}
                          value={date}
                          toolbarFormat="MMMM d yyyy"
                          inputProps={{ readOnly: true }}
                          onChange={date => this.handleDateChange(date)}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  onClick={() => this.setState({ datePickerOpen: true })}
                              />)}
                          okText={<span style={{ color: theme.palette.text.primary }}>Ok</span>}
                          cancelText={<span style={{ color: theme.palette.text.primary }}>Cancel</span>}
                          todayText={<span style={{ color: theme.palette.text.primary }}>Today</span>}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField required
                               name="name"
                               value={name}
                               onChange={this.handleChange}
                               error={error.name.error}
                               helperText={error.name.helperText}
                               autoFocus
                               margin="dense"
                               id="holdingName"
                               label="Name"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField required
                               name="ticker"
                               value={ticker}
                               onChange={this.handleTickerChange}
                               error={error.ticker.error}
                               helperText={error.ticker.helperText}
                               margin="dense"
                               id="holdingTicker"
                               label="Ticker Symbol"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField required
                               name="shares"
                               value={shares}
                               onChange={this.handleInputChange}
                               error={error.shares.error}
                               helperText={error.shares.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="holdingShares"
                               label="Shares"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField required
                               name="costBasis"
                               value={costBasis}
                               onChange={this.handleInputChange}
                               error={error.costBasis.error}
                               helperText={error.costBasis.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="holdingCostBasis"
                               label="Cost Basis (Total Cost for all shares.)"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField required
                               name="marketValue"
                               value={marketValue}
                               onChange={this.handleInputChange}
                               error={error.marketValue.error}
                               helperText={error.marketValue.helperText}
                               inputProps={{ inputMode: 'decimal' }}
                               margin="dense"
                               id="holdingMarketValue"
                               label="Market Value (Price x Shares)"
                               type="text"
                               fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                        name="account"
                        clearOnEscape
                        openOnFocus
                        autoComplete
                        autoHighlight
                        includeInputInList
                        value={account}
                        options={accountOptions}
                        groupBy={(option) => option.group}
                        isOptionEqualToValue={(option, value,) => value.value === option.value}
                        getOptionLabel={(option) => option.inputValue ? option.inputValue : option.name}
                        onChange={this.handleAccountChange}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        filterOptions={(options, params) => filter(options, params)}
                        renderInput={(params) => (
                            <TextField required
                                       error={error.account.error}
                                       helperText={error.account.helperText}
                                       margin="dense"
                                       label="Account"
                                       type="text"
                                       fullWidth {...params}
                            />
                        )}
                    />
                  </Grid>

                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  <Save />&nbsp;Save
                </Button>
                <Button onClick={this.clearForm} color="inherit" variant="outlined">
                  <Cancel />&nbsp;Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Fragment>
    )
  }
}

HoldingForm.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  addHolding: PropTypes.func.isRequired,
  editHolding: PropTypes.func.isRequired,
  deleteHolding: PropTypes.func.isRequired,
  editHoldingId: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  holdingId: PropTypes.number.isRequired,
  accounts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  yearMonth: state.budgetMonthReducer.yearMonth,
  accounts: state.accountReducer.accounts,
  holdingId: state.holdingIdReducer.holdingId,
  history: state.holdingReducer.history
})

const mapDispatchToProps = {
  addHolding,
  editHolding,
  deleteHolding,
  editHoldingId,
  createToast
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(HoldingForm))
