import React, { Fragment } from 'react'

import AppLoading from '../loading/AppLoading'
import Membership from './Membership'
import AccountForm from '../layout/AccountForm'

export default function MembershipDashboard() {
  return (
      <AppLoading>
        <Fragment>
          <Membership />
          <AccountForm />
        </Fragment>
      </AppLoading>
  )
}
