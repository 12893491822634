import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Loading } from './Loading'
import { getThemes } from '../../actions/theme'
import { getAccountGroups } from '../../actions/accountGroups'
import { getAccountTypes } from '../../actions/accountTypes'
import { getPayees } from '../../actions/payees'
import { getTransfers } from '../../actions/transfers'
import { getSplits } from '../../actions/splits'
import { getAccounts } from '../../actions/accounts'
import { getCollections } from '../../actions/collections'
import { getCategories } from '../../actions/categories'
import { getCategoryBudgets } from '../../actions/categoryBudgets'
import { getTransactions } from '../../actions/transactions'
import { getTransactionFlags } from '../../actions/transactionFlags'
import { getFlagColors } from '../../actions/flagColors'
import { getLinkAccounts, getLinkTransactions } from '../../actions/link'
import { getRecurringTransactions } from '../../actions/recurring'
import { getTransactionHistory } from '../../actions/history'
import { getHoldings } from '../../actions/holdings'
import { getSecurities } from '../../actions/securities'

class AppLoading extends Component {

  componentDidMount() {
    const {
      yearMonth, themeLoaded, accountGroupsLoaded, accountTypesLoaded, payeesLoaded, transfersLoaded,
      splitsLoaded, accountsLoaded, collectionsLoaded, categoriesLoaded,
      categoryBudgetsLoaded, transactionsLoaded, transactionFlagsLoaded,
      flagColorsLoaded, linkAccountsLoaded, linkTransactionsLoaded, recurringTransactionsLoaded,
      historyTransactionsLoaded, holdingsLoaded, securitiesLoaded
    } = this.props

    if (!themeLoaded) this.props.getThemes()
    if (!accountGroupsLoaded) this.props.getAccountGroups()
    if (!accountTypesLoaded) this.props.getAccountTypes()
    if (!payeesLoaded) this.props.getPayees()
    if (!transfersLoaded) this.props.getTransfers()
    if (!splitsLoaded) this.props.getSplits()
    if (!accountsLoaded) this.props.getAccounts()
    if (!collectionsLoaded) this.props.getCollections(yearMonth)
    if (!categoriesLoaded) this.props.getCategories(yearMonth)
    if (!categoryBudgetsLoaded) this.props.getCategoryBudgets(yearMonth)
    if (!transactionsLoaded) this.props.getTransactions(yearMonth)
    if (!transactionFlagsLoaded) this.props.getTransactionFlags()
    if (!flagColorsLoaded) this.props.getFlagColors()
    if (!linkAccountsLoaded) this.props.getLinkAccounts()
    if (!linkTransactionsLoaded) this.props.getLinkTransactions()
    if (!recurringTransactionsLoaded) this.props.getRecurringTransactions()
    if (!historyTransactionsLoaded) this.props.getTransactionHistory()
    if (!holdingsLoaded) this.props.getHoldings()
    if (!securitiesLoaded) this.props.getSecurities(yearMonth)
  }

  render() {
    const {
      themeLoaded, accountGroupsLoaded, accountTypesLoaded, payeesLoaded, transfersLoaded, splitsLoaded,
      accountsLoaded, collectionsLoaded, categoriesLoaded,
      categoryBudgetsLoaded, transactionsLoaded, flagColorsLoaded,
      transactionFlagsLoaded, holdingsLoaded, securitiesLoaded
    } = this.props

    if (themeLoaded && accountGroupsLoaded && accountTypesLoaded && payeesLoaded && transfersLoaded && splitsLoaded
        && accountsLoaded && collectionsLoaded && categoriesLoaded && categoryBudgetsLoaded && transactionsLoaded
        && flagColorsLoaded && transactionFlagsLoaded && holdingsLoaded && securitiesLoaded) {
      return this.props.children
    } else {
      return <Loading />
    }
  }
}

AppLoading.propTypes = {
  yearMonth: PropTypes.string.isRequired,
  themeLoaded: PropTypes.bool.isRequired,
  accountGroupsLoaded: PropTypes.bool.isRequired,
  accountTypesLoaded: PropTypes.bool.isRequired,
  payeesLoaded: PropTypes.bool.isRequired,
  transfersLoaded: PropTypes.bool.isRequired,
  splitsLoaded: PropTypes.bool.isRequired,
  accountsLoaded: PropTypes.bool.isRequired,
  collectionsLoaded: PropTypes.bool.isRequired,
  categoriesLoaded: PropTypes.bool.isRequired,
  categoryBudgetsLoaded: PropTypes.bool.isRequired,
  transactionsLoaded: PropTypes.bool.isRequired,
  transactionFlagsLoaded: PropTypes.bool.isRequired,
  flagColorsLoaded: PropTypes.bool.isRequired,
  holdingsLoaded: PropTypes.bool.isRequired,
  securitiesLoaded: PropTypes.bool.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  getPayees: PropTypes.func.isRequired,
  getTransfers: PropTypes.func.isRequired,
  getSplits: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getCategoryBudgets: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  getTransactionFlags: PropTypes.func.isRequired,
  getFlagColors: PropTypes.func.isRequired,
  getLinkAccounts: PropTypes.func.isRequired,
  getLinkTransactions: PropTypes.func.isRequired,
  getRecurringTransactions: PropTypes.func.isRequired,
  getTransactionHistory: PropTypes.func.isRequired,
  getHoldings: PropTypes.func.isRequired,
  getSecurities: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  yearMonth: state.budgetMonthReducer.yearMonth,
  themeLoaded: state.themeReducer.isLoaded,
  accountGroupsLoaded: state.accountGroupReducer.isLoaded,
  accountTypesLoaded: state.accountTypeReducer.isLoaded,
  payeesLoaded: state.payeeReducer.isLoaded,
  transfersLoaded: state.transferReducer.isLoaded,
  splitsLoaded: state.splitReducer.isLoaded,
  accountsLoaded: state.accountReducer.isLoaded,
  collectionsLoaded: state.collectionReducer.isLoaded,
  categoriesLoaded: state.categoryReducer.isLoaded,
  categoryBudgetsLoaded: state.categoryBudgetReducer.isLoaded,
  transactionsLoaded: state.transactionReducer.isLoaded,
  transactionFlagsLoaded: state.transactionFlagReducer.isLoaded,
  flagColorsLoaded: state.flagColorReducer.isLoaded,
  linkAccountsLoaded: state.linkReducer.isAccountLoaded,
  linkTransactionsLoaded: state.linkReducer.isTransactionLoaded,
  recurringTransactionsLoaded: state.linkReducer.isRecurringTransactionsLoaded,
  historyTransactionsLoaded: state.historyReducer.isLoaded,
  holdingsLoaded: state.holdingReducer.isLoaded,
  securitiesLoaded: state.securityReducer.isLoaded
})

const mapDispatchToProps = {
  getThemes,
  getAccountGroups,
  getAccountTypes,
  getPayees,
  getTransfers,
  getSplits,
  getAccounts,
  getCollections,
  getCategories,
  getCategoryBudgets,
  getTransactions,
  getTransactionFlags,
  getFlagColors,
  getLinkAccounts,
  getLinkTransactions,
  getRecurringTransactions,
  getTransactionHistory,
  getHoldings,
  getSecurities
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLoading)
