import { GET_ACCOUNT_KEY, EDIT_ACCOUNT_KEY } from './types'

// Get account key
export const getAccountKey = () => (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNT_KEY,
    payload: getState
  })
}

// Edit account key
export const editAccountKey = (accountKey) => (dispatch, getState) => {
  dispatch({
    type: EDIT_ACCOUNT_KEY,
    payload: accountKey
  })
}