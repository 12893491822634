import {
  GET_ACCOUNT_GROUPS, EDIT_ACCOUNT_GROUPS, REORDER_ACCOUNT_GROUPS,
  ACCOUNT_GROUPS_LOADING, ACCOUNT_GROUPS_FAIL
} from '../actions/types'

const initialState = {
  error: false,
  isFetching: true,
  isLoaded: false,
  accountGroups: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_GROUPS:
      return {
        ...state,
        error: false,
        isFetching: false,
        isLoaded: true,
        accountGroups: action.payload
      }
    case EDIT_ACCOUNT_GROUPS:
    case REORDER_ACCOUNT_GROUPS:
      return {
        ...state,
        error: false,
        isFetching: false,
        accountGroups: state.accountGroups.map(group => {
          return group.id === action.payload.id ? action.payload : group
        }) // Replace matched group and returns the state.
      }
    case ACCOUNT_GROUPS_LOADING:
      return {
        ...state,
        error: false,
        isFetching: true,
      }
    case ACCOUNT_GROUPS_FAIL:
      return {
        ...state,
        error: true,
        isFetching: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
