import axios from 'axios'

import { createToast, returnErrors } from './messages'
import {
  GET_CATEGORIES, DELETE_CATEGORY, ADD_CATEGORY, EDIT_CATEGORY,
  REORDER_CATEGORIES, CATEGORY_LOADING, CATEGORY_FAIL,
} from './types'
import { tokenConfig } from './auth'
import { getTransactions } from './transactions'
import { getCategoryBudgets } from './categoryBudgets'

const proxy = process.env.REACT_APP_PROXY

// Get Categories
export const getCategories = (yearMonth) => (dispatch, getState) => {
  dispatch({ type: CATEGORY_LOADING })
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.get(`${proxy}/v1/categories/?m=${mParm}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_CATEGORIES,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: CATEGORY_FAIL })
      })
}

// Edit Category
export const editCategory = (category, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.put(`${proxy}/v1/categories/${category.id}/`, category, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCategory: 'Categories Updated' }))
        dispatch({
          type: EDIT_CATEGORY,
          payload: res.data
        })
        dispatch(getCategories(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Reorder the Categories
export const reorderCategories = (category, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.put(`${proxy}/v1/categories/reorder/${category.id}/`, category, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCategory: 'Categories Updated' }))
        dispatch({
          type: REORDER_CATEGORIES,
          payload: res.data
        })
        dispatch(getCategories(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Delete Category
export const deleteCategory = (id, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter

  axios.delete(`${proxy}/v1/categories/${id}/`, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCategory: 'Categories Updated' }))
        dispatch({
          type: DELETE_CATEGORY,
          payload: id
        })
        dispatch(getCategories(mParm))
        dispatch(getTransactions(mParm, fParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Unarchive Category
export const unarchiveCategory = (category, yearMonth, filter) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  let fParm = (typeof filter === 'undefined' || filter == null) ? '' : filter

  axios.put(`${proxy}/v1/categories/${category.id}/`, category, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateCategory: 'Categories Updated' }))
        dispatch({
          type: EDIT_CATEGORY,
          payload: res.data
        })
        dispatch(getCategories(mParm))
        dispatch(getTransactions(mParm, fParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// Add Category
export const addCategory = (category, yearMonth) => (dispatch, getState) => {
  let mParm = (typeof yearMonth === 'undefined' || yearMonth == null) ? '' : yearMonth
  axios.post(`${proxy}/v1/categories/`, category, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ addCategory: 'Categories Added' }))
        dispatch({
          type: ADD_CATEGORY,
          payload: res.data
        })
        dispatch(getCategoryBudgets(mParm))
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}
