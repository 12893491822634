import axios from 'axios'
import { createMessage, createToast, returnErrors } from './messages'
import {
  USER_LOADED, USER_LOADING, AUTH_ERROR, AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS,
  REGISTER_SUCCESS, REGISTER_FAIL, ACTIVATION_SUCCESS, ACTIVATION_FAIL,
  PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL, PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL, EDIT_PROFILE,
  DELETE_USER_ACCOUNT, DELETE_USER_ACCOUNT_FAIL
} from './types'


import { getProfileSettings } from './profile'
import { getCustomer } from './membership'

const proxy = process.env.REACT_APP_PROXY

// export const checkAuthenticated = () => (dispatch, getState) => {
//
//   if (localStorage.getItem('access')) {
//     axios.post('/v1/auth/jwt/verify/', tokenConfig(getState))
//         .then(res => {
//           if (res.data.code !== 'token_not_valid') {
//             dispatch({
//               type: AUTHENTICATED_SUCCESS
//             })
//           } else {
//             dispatch({
//               type: AUTHENTICATED_FAIL
//             })
//           }
//         })
//         .catch(err => {
//           dispatch(returnErrors(err.response.data, err.response.status))
//           dispatch({
//             type: AUTHENTICATED_FAIL
//           })
//         })
//   } else {
//     dispatch({
//       type: AUTHENTICATED_FAIL
//     })
//   }
// }

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING })

  if (localStorage.getItem('ploutos-access')) {
    axios.get(`${proxy}/v1/auth/users/me/`, tokenConfig(getState))
        .then(res => {
          dispatch({
            type: USER_LOADED,
            payload: res.data
          })
          dispatch(getProfileSettings())
          dispatch(getCustomer())
        })
        .catch(err => {
          const error = err.response && err.response.data ? err.response.data : 'Unable to login. Please try again later.'
          const status = err.response && err.response.status ? err.response.status : 500
          dispatch(returnErrors(error, status))
          dispatch({
            type: AUTH_ERROR
          })
        })
  } else {
    dispatch({
      type: AUTH_ERROR
    })
  }
}

// update user profile
export const editProfile = (profile) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/auth/users/me/`, profile, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateProfile: 'Profile Updated' }))
        dispatch({
          type: EDIT_PROFILE,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
      })
}

// LOGIN USER
export const login = (email, password) => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request Body
  const body = JSON.stringify({ email, password })

  axios.post(`${proxy}/v1/auth/jwt/create/`, body, config)
      .then(res => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        })
        dispatch(loadUser())
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Unable to login. Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: LOGIN_FAIL
        })
      })
}

// Register user
export const register = ({ first_name, last_name, email, password, re_password }) => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request Body
  const body = JSON.stringify({
    first_name,
    last_name,
    email,
    password,
    re_password,
    platform_origin: 1
  })

  axios.post(`${proxy}/v1/auth/users/`, body, config)
      .then(res => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: email
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: REGISTER_FAIL
        })
      })
}

// Activate user
export const activate = (uid, token) => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request Body
  const body = JSON.stringify({ uid, token })

  axios.post(`${proxy}/v1/auth/users/activation/`, body, config)
      .then(res => {
        dispatch({
          type: ACTIVATION_SUCCESS
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: ACTIVATION_FAIL
        })
      })
}

// Resend activation email
export const resendActivationEmail = (email) => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request Body
  const body = JSON.stringify({ email })

  axios.post(`${proxy}/v1/auth/users/resend_activation/`, body, config)
      .then(res => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: email
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: REGISTER_FAIL
        })
      })
}

// Setup config with token - helper function
export const tokenConfig = getState => {
  // Get token from state
  const token = getState().authReducer.access

  // Headers
  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  // If token, add to headers config
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }

  return config
}

// Reset the users password
export const resetPassword = email => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request Body
  const body = JSON.stringify({ email })
  axios.post(`${proxy}/v1/auth/users/reset_password/`, body, config)
      .then(res => {
        dispatch({
          type: PASSWORD_RESET_SUCCESS,
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: PASSWORD_RESET_FAIL,
        })
      })
}

// Confirm new password
export const resetPasswordConfirm = (uid, token, new_password, re_new_password) => dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request Body
  const body = JSON.stringify({ uid, token, new_password, re_new_password })
  axios.post(`${proxy}/v1/auth/users/reset_password_confirm/`, body, config)
      .then(res => {
        dispatch({
          type: PASSWORD_RESET_CONFIRM_SUCCESS,
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({
          type: PASSWORD_RESET_CONFIRM_FAIL,
        })
      })
}

// Logout user
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT_SUCCESS })
}

/**
 * *** DANGER! ***
 * This deletes a users account and all of their data. This is
 * permanent and cannot be undone.
 * NOTE: This does not auto-cancel a users subscriptions or unlink
 * Ploutos Budget from their banking institutions.
 * @param {Object} currentPassword
 * @param {string} currentPassword.current_password - The logged in
 * users current password.
 */
export const deleteUserAccount = (currentPassword) => async (dispatch, getState) => {
  const config = tokenConfig(getState)
  if (!config) return false

  // Add request body to config data attribute.
  config['data'] = currentPassword

  await axios.delete(`${proxy}/v1/auth/users/me/`, config)
      .then(res => {
        dispatch({
          type: DELETE_USER_ACCOUNT,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch({ type: DELETE_USER_ACCOUNT_FAIL })
        dispatch(createMessage('Account Deletion Failed',
            'Please verify you have the correct password and try again. If the problem persists please email support.')
        )
      })
}
