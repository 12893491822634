import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import MonthPickerInput from '../common/MonthPickerInput'
import { categoryBudgetMonthChange } from '../../actions/categoryBudgets'
import { editBudgetMonth } from '../../actions/budgetMonth'

class MonthPicker extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { yearMonth, categoryBudgetMonthChange } = this.props

    if (yearMonth !== prevProps.yearMonth) categoryBudgetMonthChange(yearMonth)
  }

  handleChange = date => this.props.editBudgetMonth(`${date.getFullYear()}${date.getMonth() + 1}`)

  render() {
    const { yearMonth, categoryBudgets, accounts } = this.props
    // Get the current budget date
    let budgetYear = yearMonth.substring(0, 4)
    let budgetMonth = yearMonth.substring(4, 6)
    const budgetDate = new Date(budgetYear, budgetMonth - 1, 1, 0, 0, 0)
    const dwp = (accounts && accounts[0]) ? accounts[0].dwp : 0


    // Get the min and max allowed dates to budget
    let budgetDateRange = categoryBudgets
    let budgetDateMin = DateTime.local().toJSDate()
    let budgetDateMax = DateTime.local().toJSDate()
    if (typeof budgetDateRange !== 'undefined' && budgetDateRange != null) budgetDateRange = categoryBudgets[0]
    if (typeof budgetDateRange !== 'undefined' && budgetDateRange != null) {
      const min = budgetDateRange['budget_month_min']
      const max = budgetDateRange['budget_month_max']
      if (typeof min !== 'undefined' && min != null) budgetDateMin = DateTime.fromISO(min).toJSDate()
      if (typeof max !== 'undefined' && max != null) budgetDateMax = DateTime.fromISO(max).toJSDate()
    }

    return (
        <DatePicker selected={budgetDate}
                    todayButton="Current Month"
                    onChange={this.handleChange}
                    minDate={budgetDateMin}
                    maxDate={budgetDateMax}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    customInput={<MonthPickerInput dwp={dwp} />}
        />
    )
  }
}

MonthPicker.propTypes = {
  editBudgetMonth: PropTypes.func.isRequired,
  categoryBudgetMonthChange: PropTypes.func.isRequired,
  yearMonth: PropTypes.string.isRequired,
  categoryBudgets: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  yearMonth: state.budgetMonthReducer.yearMonth,
  categoryBudgets: state.categoryBudgetReducer.categoryBudgets,
  accounts: state.accountReducer.accounts,
})

const mapDispatchToProps = {
  categoryBudgetMonthChange,
  editBudgetMonth
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthPicker)
