import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography,
  Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { Cancel, ReceiptLong } from '@mui/icons-material'

import { sharedStyles } from '../common/styles'
import DraggableDialogWrapper from './DraggableDialogWrapper'
import { fullScreen, Transition } from '../utilities/dialogs'
import { formatMoneyLabel } from '../utilities/numbers'
import { formatISODate } from '../utilities/dates'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
}))

/**
 *
 * @param {boolean} open - Is the modal open or closed.
 * @param {function} handleClose - Function to close the modal.
 * @param {Object} event - The recurring event.
 * @returns {JSX.Element}
 * @constructor
 */
const RecurringTransactionDetail = ({ open, handleClose, event }) => {
  const [title, setTitle] = useState('')
  const [linkTransactions, setLinkTransactions] = useState([])

  const theme = useTheme()
  const classes = useStyles()

  /**
   * When the user selects an event update the link transaction list
   * to display all the transactions for that event.
   */
  useEffect(() => {
    const linkTransactions = event && event.linkTransactions ? event.linkTransactions : []
    const firstLinkTransaction = linkTransactions && linkTransactions[0] ? linkTransactions[0] : null
    let title = ''
    if (firstLinkTransaction) {
      title = firstLinkTransaction.name && firstLinkTransaction.merchant_name
          ? `${firstLinkTransaction.name} - ${firstLinkTransaction.merchant_name}`
          : firstLinkTransaction.name
              ? firstLinkTransaction.name
              : ''
    }

    setTitle(title)
    setLinkTransactions(linkTransactions)
  }, [event])

  return (
      <Fragment>
        <Dialog open={open}
                aria-labelledby="recurring-transactions-list"
                TransitionComponent={Transition}
                fullScreen={fullScreen(theme)}
                PaperComponent={DraggableDialogWrapper}
                maxWidth="sm" fullWidth={true}
        >
          <DialogTitle className={classes.moveCursor}>
            <div>
              <Typography variant="h6">{(event && event.account || '')}</Typography>
              <Typography variant="subtitle2">{(event && event.linkAccount || '')}</Typography>
              <Typography variant="subtitle2">{(event && event.linkAccountOfficial || '')}</Typography>
              <Grid container item xs={12}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
              >
                <Box mt={2}>
                  <Typography variant="h6" align="center">{title}</Typography>
                  <Typography variant="subtitle2" align="center">{(event && event.prettyFrequency || '')}</Typography>
                </Box>
              </Grid>
            </div>
          </DialogTitle>
          <DialogContent>

            {linkTransactions && linkTransactions.length > 0
                ? (
                    <Table sx={{ minWidth: 400 }} aria-label="recurring transaction table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Posted</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {linkTransactions.map((row, index) => {
                          let amount = row.amount > 0 ? -Math.abs(row.amount) : Math.abs(row.amount)
                          return (
                              <TableRow key={`recurring-event-transaction-${index}`}>
                                <TableCell>{formatISODate(row.date)}</TableCell>
                                <TableCell>{formatMoneyLabel(amount)}</TableCell>
                              </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                ) : (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                        <ReceiptLong fontSize="large" className={classes.noDataIcon} />
                      </Grid>
                      <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="h6">No transaction history yet.</Typography>
                      </Grid>
                      <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle2">
                          All the charges related to this recurring transaction
                          will show up here.
                        </Typography>
                      </Grid>
                    </Grid>
                )}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit" variant="outlined">
              <Cancel />&nbsp;Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
  )
}

const withPropsValidation = props => {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'RecurringTransactionDetail')
  return props
}

const propTypes = {}

RecurringTransactionDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
}

export default RecurringTransactionDetail
