import axios from 'axios'
import { createToast, returnErrors } from './messages'
import {
  GET_PROFILE_SETTINGS, ADD_PROFILE_SETTINGS, EDIT_PROFILE_SETTINGS,
  PROFILE_SETTINGS_LOADING, PROFILE_SETTINGS_FAIL
} from './types'
import { tokenConfig } from './auth'

const proxy = process.env.REACT_APP_PROXY

// Get profile settings
export const getProfileSettings = () => (dispatch, getState) => {
  dispatch({ type: PROFILE_SETTINGS_LOADING })
  axios.get(`${proxy}/v1/profiles/`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_PROFILE_SETTINGS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        // dispatch(returnErrors(error, status))
        dispatch({ type: PROFILE_SETTINGS_FAIL })
      })
}

// Add Profile
export const addProfileSettings = (profile) => (dispatch, getState) => {
  axios.post(`${proxy}/v1/profiles/`, profile, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateProfile: 'Profile Updated' }))
        dispatch({
          type: ADD_PROFILE_SETTINGS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: PROFILE_SETTINGS_FAIL })
      })
}

// Edit Profile
export const editProfileSettings = (profile) => (dispatch, getState) => {
  axios.put(`${proxy}/v1/profiles/${profile.id}/`, profile, tokenConfig(getState))
      .then(res => {
        dispatch(createToast({ updateProfile: 'Profile Updated' }))
        dispatch({
          type: EDIT_PROFILE_SETTINGS,
          payload: res.data
        })
      })
      .catch(err => {
        const error = err.response && err.response.data ? err.response.data : 'Please try again later.'
        const status = err.response && err.response.status ? err.response.status : 500
        dispatch(returnErrors(error, status))
        dispatch({ type: PROFILE_SETTINGS_FAIL })
      })
}
