import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ThemeProvider, StyledEngineProvider } from '@mui/material';

import { lightTheme } from '../common/lightTheme'
import { darkTheme } from '../common/darkTheme'

class ThemeWrapper extends Component {
  state = {
    theme: lightTheme
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { profile } = this.props

    if (profile && profile !== prevProps.profile) {
      const themeId = profile.theme_id
      const theme = themeId === 2 ? darkTheme : lightTheme
      this.setState({ theme })
    }
  }

  render() {
    const { theme } = this.state

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {this.props.children}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

ThemeWrapper.propTypes = {
  profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  profile: state.profileReducer.profile,
})

export default connect(mapStateToProps)(ThemeWrapper)